import { useCallback, useEffect, useState } from 'react'
import { FormState } from '../../../types/interface/form.interface'
import useReactQueryQuote from '../../../hooks/shared/useReactQueryQuote'
import { ProductPropV2Type, QuoteV2Type } from '../../../types/ApiV2'
import useReactQueryDatasource from '../../../hooks/shared/useReactQueryDatasource'
import { get } from 'lodash'
import FormConfig from 'FormConfig'
import { getFlowName, getProductName } from '@dg-util'
import { FORM_ID, productPropsFieldNamesMap } from '../../../constants'

export type UpdateFormStateDataType = FormState<{ pristine: boolean; values: Partial<QuoteV2Type> }>

export interface HookChange {
  loading: boolean
  quote: Partial<QuoteV2Type> | null
  formBtnDisabled: boolean
  handleQuoteUpdate(values: Partial<QuoteV2Type>): void
  updateFormState(data: UpdateFormStateDataType): void
  initialFormValues: Record<string, unknown>
  formOptionsValues: Record<string, Record<string, unknown>[]>
}

const useLogic = (): HookChange => {
  const [initialFormValues, setInitialFormValues] = useState<Record<string, unknown>>()
  const [formOptionsValues, setFormOptionsValues] =
    useState<Record<string, Record<string, unknown>[]>>()

  const [formBtnDisabled, setFormBtnDisabled] = useState(true)
  const { quote: fetchedQuote, isLoading, updateQuote } = useReactQueryQuote()
  const { productProps, isLoading: isProductPropsLoading } = useReactQueryDatasource()
  const [quote, setQuote] = useState<Partial<QuoteV2Type> | null>(null)

  const formInitialValues = useCallback(
    (quote: Partial<QuoteV2Type>) => {
      const flowName = getFlowName()
      const productName = getProductName()
      const formConfig = get(FormConfig, [flowName, FORM_ID.CHANGE, productName])
      const formInitialValues: { [key in keyof QuoteV2Type]?: unknown } = {}
      const formOptionsValues: { [key in keyof QuoteV2Type]?: ProductPropV2Type['values'] } = {}

      Object.keys(formConfig.fields).forEach((fieldName: keyof QuoteV2Type) => {
        // Conversion Quote field value to string if it is number for rendering in form
        // Fill form initial values from the quote data
        formInitialValues[fieldName] =
          typeof quote[fieldName] === 'number' ? String(quote[fieldName]) : quote[fieldName]

        // Fill options values for each field (in case they are available in productProps)
        const productPropMappedFieldName = productPropsFieldNamesMap[fieldName]
        formOptionsValues[fieldName] = productProps[productPropMappedFieldName]?.values
      })

      // Set form options from available product props
      setFormOptionsValues(formOptionsValues)

      return setInitialFormValues(formInitialValues)
    },
    [productProps]
  )

  useEffect(() => {
    if (fetchedQuote) {
      setQuote(fetchedQuote)
      formInitialValues(fetchedQuote)
    }
  }, [fetchedQuote, formInitialValues])

  const handleQuoteUpdate = async (values: Partial<QuoteV2Type>) => {
    setFormBtnDisabled(true)

    await updateQuote(values)
  }

  const updateFormState = (data: UpdateFormStateDataType) => {
    const { pristine, values } = data

    if (!pristine) {
      setFormBtnDisabled(false)
    }
    if (quote) {
      setQuote(Object.assign({}, quote, values))
    }
  }

  return {
    loading: isLoading || isProductPropsLoading,
    quote,
    formBtnDisabled,
    initialFormValues,
    formOptionsValues,
    handleQuoteUpdate,
    updateFormState,
  }
}

export default useLogic
