import { Typography } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import CompareCoversTable from '@dg-shared/landing/CompareCoversTable'
import InfoBox from '@dg-shared/landing/InfoBox'
import { TRANSLATIONS as T } from '../../../constants'
import LandingAboutImage from 'components/LandingAboutImage'
import { Phone as PhoneIcon, Mail as MailIcon } from '@material-ui/icons'
import BrandConfig from 'BrandConfig'
import { getLocalizedPartnerName } from '@dg-util'

const useStyles = makeStyles((theme) =>
  createStyles({
    // Standard Bottom margin for elements
    mb: {
      marginBottom: theme.spacing(4),
    },
    mb_sm: {
      marginBottom: theme.spacing(1),
    },
    link: {
      color: theme.brand.colors.linkMain,
      display: 'flex',
      gap: theme.spacing(1),
    },
    ctaOverride: {
      ...theme.brand.assets.layout?.ctaOverride,
    },
    icon: {
      color: theme.brand.colors.main,
      ...theme.brand.assets.layout?.iconCustomColor,
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: '40px',
      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
    mainBgWrapper: {
      height: 400,
      [theme.breakpoints.down('md')]: {
        height: 200,
      },
      [theme.breakpoints.down('sm')]: {
        height: 130,
      },
    },
    mainBg: {
      height: 400,
      width: '100%',
      marginBottom: theme.spacing(1),
      backgroundSize: 'cover',
      position: 'absolute',
      left: 0,
      top: 0,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        height: 200,
      },
      [theme.breakpoints.down('sm')]: {
        height: 130,
      },
      ...theme.brand.assets.images?.landingMainBg,
    },
    mainTitle: {
      fontSize: 48,
      padding: theme.spacing(3),
      width: '100%',
      margin: '0 auto',
      maxWidth: '978px',
      minWidth: '340px',
      [theme.breakpoints.down('md')]: {
        fontSize: 20,
      },
      ...theme.brand.typography.overrides?.landingTitle,
    },
  })
)

const LandingAboutBodyVehicle = () => {
  const { mb, link, icon, flexRow, mainBg, mainBgWrapper, mainTitle } = useStyles()
  const { t } = useTranslate(T.LANDING)
  const brandName = getLocalizedPartnerName()
  const phone = (t('phone') || '') as string
  const brandEmail = `forsikring@${PARTNER_NAME}.no`

  return (
    <>
      <div className={mainBgWrapper}>
        <div className={mainBg}>
          <Typography
            className={mainTitle}
            modifiers={['preFormatted', 'bold']}
            html={true}
            component={'h1'}
          >
            {t('mainTitle', { brandName })}
          </Typography>
        </div>
      </div>
      <Typography type='h3' className={mb} modifiers={['bold']}>
        Ingen kjenner bilen din helt som vi gjør. Med {brandName} Forsikring er bilen din i de
        tryggeste hender - også hvis uhellet skulle være ute.
      </Typography>
      <div className={flexRow}>
        <Typography className={mb}>
          For oss er det viktig at du føler deg trygg i bilene våre. Og bilforsikringen er en liten,
          men viktig del av akkurat det. Derfor har vi laget en bilforsikring som passer akkurat din
          bil og ditt bilhold. Kall det skreddersøm til en rimelig penge.
        </Typography>
        <Typography className={mb}>
          Med {brandName} Forsikring er du for eksempel garantert reparasjon på et merkeverksted
          dersom noe skulle skje. Vi tror det ligger en ekstra trygghet i det - at du alltid kan
          være trygg på at det er {brandName}-hender som hjelper deg hvis uhellet er ute.
        </Typography>
        <Typography className={mb}>
          Det er tross alt ingen som kjenner bilen din bedre. Vi kjenner hver eneste funksjon og
          lille særegenhet. Alle egenskaper og vanlige småskader. Uhell som er sjeldne for deg er
          hverdag for oss, og fordi vi har sett alt, er vi forberedt på alt.
        </Typography>
      </div>
      <LandingAboutImage alt={brandName} className={mb} />
      <Typography type='h3' modifiers={['bold']}>
        Hvorfor velge {brandName} Forsikring?
      </Typography>
      <Typography className={mb}>
        Bilforsikring handler selvsagt om å tilby mest mulig trygghet for en lavest mulig pris. Men
        det handler om god service også. Med {brandName} Forsikring har vi gjort noen grep som gir
        deg en bedre opplevelse dersom du skulle være uheldig. For eksempel kan du nyte godt av vår
        fleksible ordning for erstatningsbil. Velg mellom Hyre og vanlig leiebil - eller
        kompensasjon hvis du ikke trenger leiebil den dagen uhellet skulle være ute. Og så har vi
        naturligvis bunnsolide dekninger - bare sjekk her:
      </Typography>
      <CompareCoversTable className={mb} />
      <Typography type='h3' modifiers={['bold']}>
        Om {brandName} Forsikring
      </Typography>
      <Typography className={mb}>
        {brandName} Forsikring er levert av Digisure AS. Digisure AS, org nr. 920 553 435,
        Munkedamsveien 53B, 0250 Oslo, er godkjent forsikringsagent og er underlagt Lov om
        Forsikringsformidling med tilhørende forskrift. Virksomheten er under kontroll av
        Finanstilsynet og er registrert i Finanstilsynets register over godkjente
        forsikringsagentforetak. Se ytterligere info på:
        <Typography className={mb}>
          <a
            className={link}
            href='https://www.digisure.no/informasjonsplikt'
            target='_blank'
            rel='noreferrer'
          >
            https://www.digisure.no/informasjonsplikt
          </a>
        </Typography>
        Info om hvordan vi behandler personopplysninger finnes her:
        <Typography>
          <a
            className={link}
            href='https://www.digisure.no/personopplysninger'
            target='_blank'
            rel='noreferrer'
          >
            https://www.digisure.no/personopplysninger
          </a>
        </Typography>
      </Typography>
      <InfoBox className={mb}>
        <Typography type='h3' modifiers={['bold']}>
          Kontakt oss
        </Typography>
        <Typography type='h3' modifiers={['unDecorated']} color={'textMain'}>
          <a href={`tel: +47${phone.replace(/ /gi, '')}`} className={link}>
            <PhoneIcon className={icon} />
            {phone}
          </a>
        </Typography>
        <Typography modifiers={['unDecorated']} color={'textMain'}>
          <a href={`mailto: ${brandEmail}`} className={link}>
            <MailIcon className={icon} />
            {brandEmail}
          </a>
        </Typography>
      </InfoBox>
      <Typography type='h3' modifiers={['bold']}>
        Annet
      </Typography>
      <Typography>
        Nedenfor kan du laste ned Produktsammendrag (IPID) og fullstendige vilkår
      </Typography>
      <a className={link} target='_blank' href={BrandConfig.DOCUMENTS.LP.IPID} rel='noreferrer'>
        <Typography type='h3' color={'secondary'}>
          IPID
        </Typography>
      </a>
      <a className={link} target='_blank' href={BrandConfig.DOCUMENTS.LP.ANSVAR} rel='noreferrer'>
        <Typography type='h3' color={'secondary'}>
          Vilkår Ansvar
        </Typography>
      </a>
      <a className={link} target='_blank' href={BrandConfig.DOCUMENTS.LP.DELKASKO} rel='noreferrer'>
        <Typography type='h3' color={'secondary'}>
          Vilkår Delkasko
        </Typography>
      </a>
      <a className={link} target='_blank' href={BrandConfig.DOCUMENTS.LP.KASKO} rel='noreferrer'>
        <Typography type='h3' color={'secondary'}>
          Vilkår Kasko
        </Typography>
      </a>
      <a className={link} target='_blank' href={BrandConfig.DOCUMENTS.LP.SUPER} rel='noreferrer'>
        <Typography type='h3' color={'secondary'}>
          Vilkår Super
        </Typography>
      </a>
    </>
  )
}

export default LandingAboutBodyVehicle
