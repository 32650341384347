import React, { FC, useEffect } from 'react'
import { get } from 'lodash'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import BrandConfig from 'BrandConfig'
import { TRANSLATIONS as T } from '../../../../constants'
import { CTA_TYPE_PURCHASED_DONE } from '../../../../constants/cta-constants'
import { FSMBtnNext, CTA, Typography } from '@dg-shared'
import { useTranslate } from '../../../../hooks/shared'
import { getDataComponentName, getProductName } from '@dg-util'
import { FlowServiceType } from '../../../../types/interface/fsm.interface'
import { Events } from '../../../../FSM/shared/constants'
import { getSessionStorageAuthData } from '../../../../service/util.service'
import { SessionData } from '../../../../types/interface/session.interface'
import CONFIG, { FlowName } from '../../../../config'
import { PartnerName } from '../../../../types/interface/quote.interface'
import { switchFSM } from '../../../../util/productHandler'
import Loading from '../../Loading/Page'
import { FSMService } from '../../../../FSM'

declare global {
  interface Window {
    requestAuthRedirect: boolean
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    annotation: {
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(3),
      },
    },
    footer: {
      justifyContent: 'center',
      margin: '0 !important',
    },
    button: {
      ...theme.brand.assets.layout?.ctaOverride,
    },
  })
)

interface DoneProps {
  signOut(): Promise<void>
  flowService: FlowServiceType
  loading: boolean
}

const Done: FC<DoneProps> = ({ signOut, flowService, loading }) => {
  const { t } = useTranslate(T.OFFER_PURCHASED)
  const classes = useStyles()
  const { send } = flowService
  const parsedUserInfo: SessionData = getSessionStorageAuthData(CONFIG.USER_INFO_KEY)
  const prevConfig = flowService.state?.context?.prevConfig

  const clickHandler = async () => {
    const isPartnerWithLink = [
      PartnerName.circlek,
      PartnerName.instabank,
      PartnerName.nord,
    ].includes(PARTNER_NAME as PartnerName)

    if (prevConfig && prevConfig.flow === FlowName.MY_PAGES) {
      // Don't drop session and don't clear the cache for my pages flow.
      // Navigate back to the previous FSM state
      switchFSM({
        targetStateValue: prevConfig.stateValue,
        targetFlow: prevConfig.flow,
        targetProduct: prevConfig.product,
        FSMService: FSMService,
      })
    } else {
      await signOut()
      if (parsedUserInfo.agentId) {
        // Hide app state after signOut() - clear page before redirect
        document.querySelector('body').remove()
        // Back to Agent portal for Agents
        window.location.href = CONFIG.AGENT_PORTAL_HOST
      } else if (isPartnerWithLink) {
        // Hide app state after signOut() - clear page before redirect
        document.querySelector('body').remove()
        // The user navigates to the Partner MAIN_LINK
        window.location.href = get(BrandConfig.MAIN_LINK, getProductName())
      } else {
        // Back to LANDING_PAGE if partner has no MAIN_LINK
        send(Events.LANDING_PAGE)
      }
    }
  }

  useEffect(() => {
    // Fix for Safari when the user navigates back from the external
    // site the app should getting back the Auth page instead of getting its cached
    // version and show the Done page
    window.onpageshow = function (event) {
      if (event.persisted) {
        // Drop session and clear the cache
        ;(async () => {
          await signOut()
          send(Events.AUTH)
          window.location.reload()
        })()
      }
    }
  }, [send, signOut])

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Typography
        testId='done-annotation'
        className={classes.annotation}
        data-componentname={getDataComponentName(__filename)}
      >
        {t('done.annotation')}
      </Typography>
      <CTA context={CTA_TYPE_PURCHASED_DONE} containerClass={classes.footer} />
      <FSMBtnNext
        customClassName={classes.button}
        buttonText={t(`finished`)}
        handleClick={clickHandler}
      />
    </>
  )
}

export default Done
