import { AuthBankId, PageMyPage } from '../../../../pages/mypages'
import { StateRoute } from '../../../../types/interface/fsm.interface'

import { FSMCommonTransitions } from '../../constants'

const { MY_PAGES, AUTH_BANK_ID_START, AUTH_BANK_ID } = FSMCommonTransitions

const FSMBankIdNO: Array<StateRoute> = [
  {
    is: { [AUTH_BANK_ID]: AUTH_BANK_ID_START },
    page: AuthBankId,
  },
  {
    is: MY_PAGES,
    page: PageMyPage,
  },
]

export default FSMBankIdNO
