import { useQuery } from '@tanstack/react-query'
import apiV2 from '../../service/apiV2.service'
import { getProductName, reactQueryErrorHandler } from '@dg-util'
import { FSMService } from '../../FSM'
import { ResponseErrorV2Type } from '../../types/ApiV2'

const useReactQueryDatasource = () => {
  // Get Datasource ProductProps Query
  const productName = getProductName()
  const {
    data: productProps,
    refetch: refetchProductProps,
    isInitialLoading: isGetProductPropsLoading,
  } = useQuery(['productProps', productName], () => apiV2.getDatasourceProductProps(), {
    select: (response) => response.data,
    onError: (error: ResponseErrorV2Type) => reactQueryErrorHandler(error, FSMService.flowService),
  })

  // Get Datasource InsuranceCompanies Query
  const {
    data: insuranceCompanies,
    refetch: refetchInsuranceCompanies,
    isInitialLoading: isGetInsuranceCompaniesLoading,
  } = useQuery(['insuranceCompanies'], () => apiV2.getDatasourceInsuranceCompanies(), {
    select: (response) => response.data,
    onError: (error: ResponseErrorV2Type) => reactQueryErrorHandler(error, FSMService.flowService),
  })

  return {
    isLoading: isGetProductPropsLoading || isGetInsuranceCompaniesLoading,
    productProps,
    refetchProductProps,
    insuranceCompanies,
    refetchInsuranceCompanies,
  }
}

export default useReactQueryDatasource
