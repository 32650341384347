import React, { FC } from 'react'
import { Typography } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { CustomClassName } from '../../../types/interface/styles.interface'
import { getDataComponentName, getLocalizedPartnerName } from '@dg-util'
import BrandConfig from 'BrandConfig'

export const useAuthStartegyText = makeStyles(
  () =>
    createStyles({
      disclaimer: {
        marginTop: 5,
        textAlign: 'center',
        '& a': {
          textDecoration: 'underline',
          color: 'inherit',
        },
      },
    }),
  { index: 1 }
)

const AuthDecisionText: FC<CustomClassName> = ({}) => {
  const classes = useAuthStartegyText({
    height: window.innerHeight,
  })
  const { t } = useTranslate(T.AUTH)
  const link = BrandConfig.PRIVACY_POLICY_LINK
  const partnerLink = BrandConfig.PARTNER_PRIVACY_POLICY_LINK
  const partnerName = getLocalizedPartnerName()

  return (
    <Typography
      component='span'
      data-componentname={getDataComponentName(__filename)}
      size='small'
      html={true}
      color='textDisclaimer'
      modifiers={['preFormatted']}
      className={classes.disclaimer}
    >
      {t(`description`, { partnerLink, link, partnerName })}
    </Typography>
  )
}

export default AuthDecisionText
