import React, { FC, useEffect } from 'react'

import { Typography, FNOLButton, FNOLPageWrapper } from '@dg-shared'
import { useTranslate, useClaim } from '../../../hooks/shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import { ClaimEventName, FNOL_COLLISION_QUESTIONS, TRANSLATIONS as T } from '../../../constants'
import { useFNOLStyles } from '../shared/styles/useStyles'

const PageResponsible: FC<CommonPageProps> = ({ service, currentState }) => {
  const { t } = useTranslate(`${T.COLLISION}.responsible`)
  const FNOLClasses = useFNOLStyles({})
  const { handleAnswerClick, clearAnswer } = useClaim(service, currentState)

  useEffect(() => {
    clearAnswer(FNOL_COLLISION_QUESTIONS.WHO_IS_BLAME)
  }, [clearAnswer])

  const itemSelect = ({ eventName, label, value }: ClaimEventName) => (
    <FNOLButton
      key={label}
      testId={label}
      handleClick={() => handleAnswerClick(FNOL_COLLISION_QUESTIONS.WHO_IS_BLAME, value, eventName)}
      buttonText={t(label)}
    />
  )

  const eventItems = [
    { eventName: Events.FNOL_COLLISION_INJURED, label: 'buttons.yes', value: 1 },
    { eventName: Events.FNOL_COLLISION_INJURED, label: 'buttons.no', value: 2 },
  ]

  const ItemsList = () => <>{eventItems.map((item) => itemSelect(item))}</>

  const Content = () => (
    <>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <Typography testId='subtitle' html={true} className={FNOLClasses.description}>
        {t('subtitle')}
      </Typography>
      <ItemsList />
    </>
  )

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`back`)} breadcrumbsStepPercentage={30}>
      <Content />
    </FNOLPageWrapper>
  )
}

export default PageResponsible
