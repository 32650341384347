import { useCallback, useContext, useEffect, useState } from 'react'
import {
  InsurelyEventType,
  InsurelyResultType,
  SafeInsurelyResultType,
} from '../../../types/insurelyTypes'
import { filterVehicleInsurelyResults, getCoverageData } from '@dg-util'
import { Events } from '../../../FSM/shared/constants'
import { CoverageMapping } from '@dg-util/getCoverageData'
import { FlowServiceType } from '../../../types/interface/fsm.interface'
import apiV2 from '../../../service/apiV2.service'
import { Context } from '@dg-shared/ContextProvider'
import {
  INSURELY_COLLECTION_ID,
  INSURELY_RESULTS,
  SELECTED_INSURELY_QUOTE,
} from '../../../constants/insurely-constants'
import errorLoggerService from '../../../service/errorLogger.service'
import useReactQueryQuote from '../../../hooks/shared/useReactQueryQuote'
import { ProductName } from '../../../types/interface/quote.interface'
import { QuoteV2ResponseType } from '../../../types/ApiV2'

const useLogic = (service: FlowServiceType) => {
  const [offerDifference, setOfferDifference] = useState<number>(0)
  const [coverageData, setCoverageData] = useState<CoverageMapping | null>(null)
  const { context, setContext } = useContext(Context)
  const insurelyResults = context[INSURELY_RESULTS] as InsurelyResultType[]
  const selectedInsurelyQuote = context[SELECTED_INSURELY_QUOTE] as InsurelyResultType
  const insurelyCollectionId = context[INSURELY_COLLECTION_ID] as string
  const { quote, updateQuote, isLoading } = useReactQueryQuote()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const setQuoteCompetitorPrice = useCallback(
    async (InsurelyPremium: number): Promise<QuoteV2ResponseType> => {
      return await updateQuote({ InsurelyPremium })
    },
    [updateQuote]
  )

  const isInsurelyQuoteValid = (quote: SafeInsurelyResultType) => {
    let isValid = false

    // Check if quote is exist and has a premiumAmountYearRounded
    if (quote && quote?.insurance?.premiumAmountYearRounded) {
      isValid = true
    }

    return isValid
  }

  const cancelInsurely = async () => {
    const email = 'tommy.lindberg+admin@insurely.com'
    const insurances = [{ externalId: selectedInsurelyQuote.insurance.externalId }]
    const { cancelStatus } = await apiV2.cancelInsurelyInsurances(
      insurelyCollectionId,
      insurances,
      email
    )

    // Redirect to the Sign Document Service page
    if (cancelStatus.documentUrl) {
      document.location.href = cancelStatus.documentUrl
    }
  }

  useEffect(() => {
    if (quote) {
      // Handling of Insurely Iframe messages data
      const listener = async ({ data }: MessageEvent) => {
        const { name, value } = data

        if (name === InsurelyEventType.COLLECTION_ID) {
          setContext({
            [INSURELY_COLLECTION_ID]: value,
          })
        }

        if (name === InsurelyEventType.RESULTS) {
          // Filtering only for vehicle quotes
          if (quote.productName === ProductName.VEHICLE) {
            try {
              const coverageData = await getCoverageData(quote)

              setCoverageData(coverageData)

              const results: SafeInsurelyResultType[] =
                filterVehicleInsurelyResults(value, quote) || []

              if (results.length) {
                setContext({
                  [INSURELY_RESULTS]: results,
                })

                // Only one offer to compare is available. Select it immediately
                if (results.length === 1) {
                  const selectedQuote = results[0]

                  // Check if the selectedQuote is valid
                  if (isInsurelyQuoteValid(selectedQuote)) {
                    setContext({
                      [SELECTED_INSURELY_QUOTE]: selectedQuote,
                    })
                  } else {
                    // No Insurance results after validation
                    service.send(Events.INSURELY_NO_OFFERS_FOUND)
                  }
                }
              } else {
                // No Insurance results after filtering
                service.send(Events.INSURELY_NO_OFFERS_FOUND)
              }
            } catch (error) {
              errorLoggerService.captureException(error, {
                integration: 'fe:insurely',
              })
            }
          }
        }
      }

      // Subscribe to the Insurely Iframe messages
      window.addEventListener('message', listener)

      return () => {
        // Remove subscriptions
        window.removeEventListener('message', listener)
      }
    }
  }, [quote, service, setContext])

  useEffect(() => {
    if (quote && selectedInsurelyQuote) {
      const offerDifference =
        selectedInsurelyQuote.insurance.premiumAmountYearRounded -
        quote.CalculatedPrices.priceAnnual
      setOfferDifference(offerDifference)
    }
  }, [quote, selectedInsurelyQuote])

  useEffect(() => {
    ;(async () => {
      // Filtering only for vehicle quotes
      if (quote && quote.productName === ProductName.VEHICLE) {
        const coverageData = await getCoverageData(quote)

        setCoverageData(coverageData)
      }
    })()
  }, [quote])

  return {
    loading: isLoading,
    insurelyResults,
    quote,
    offerDifference,
    selectedInsurelyQuote,
    coverageData,
    setQuoteCompetitorPrice,
    insurelyCollectionId,
    cancelInsurely,
  }
}

export default useLogic
