import { useEffect, useState } from 'react'
import { Events } from '../../../FSM/shared/constants'
import { ServiceSend } from '../../../types/interface/fsm.interface'
import { OwnerFormData } from '../../../types/interface/form.interface'
import { QuoteV2Type } from '../../../types/ApiV2'
import useReactQueryQuote from '../../../hooks/shared/useReactQueryQuote'

interface HookOwnership {
  quote: Partial<QuoteV2Type> | null
  loading: boolean
  btnDisabled: boolean
  setBtnDisabled: (value: ((prevState: boolean) => boolean) | boolean) => void
  handleSetOwnership: (values: OwnerFormData) => void
}

type SetOwnershipArgsType = {
  NewOwnerRegistrationNumber?: string
  NewOwnerName?: string
}

const useLogic = (send: ServiceSend): HookOwnership => {
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true)
  const { quote, isLoading, setOwnershipQuote } = useReactQueryQuote()

  const SHOULD_SET_NEW_OWNER = '2'

  const setOwnership = async ({
    NewOwnerRegistrationNumber,
    NewOwnerName,
  }: SetOwnershipArgsType) => {
    await setOwnershipQuote({ NewOwnerRegistrationNumber, NewOwnerName })
    send(Events.NEXT)
  }

  const handleSetOwnership = async (values: OwnerFormData) => {
    //FIXME: This object should be changed in case the values are changing
    const shouldBeNewOwner = values.owner === SHOULD_SET_NEW_OWNER

    if (shouldBeNewOwner) {
      await setOwnership({ NewOwnerRegistrationNumber: values.ssn, NewOwnerName: values.fullName })
    } else {
      await setOwnership({})
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return {
    quote,
    loading: isLoading,
    btnDisabled,
    setBtnDisabled,
    handleSetOwnership,
  }
}

export default useLogic
