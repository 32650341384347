import {
  FNOLTheftMoreInfo,
  FNOLTheftStolenInCar,
  FNOLTheftStolenFromCar,
  FNOLTheftStolenAgeValue,
  FNOLTheftStolenWhenWhere,
  FNOLTheftPoliceCase,
  FNOLTheftPoliceReport,
  FNOLAdditionalInformation,
  FNOLTimeline,
} from '../../../../pages/FNOL'
import { FSMCommonTransitions } from '../../constants'
const {
  FNOL_THEFT_FLOW,
  FNOL_THEFT_MORE_INFO,
  FNOL_THEFT_STOLEN_IN_CAR,
  FNOL_THEFT_STOLEN_FROM_CAR,
  FNOL_THEFT_STOLEN_AGE_VALUE,
  FNOL_THEFT_STOLEN_WHEN_WHERE,
  FNOL_THEFT_POLICE_CASE,
  FNOL_THEFT_POLICE_REPORT,
  FNOL_ADDITIONAL_INFORMATION,
  FNOL_TIME_LINE,
} = FSMCommonTransitions

export default [
  {
    is: { [FNOL_THEFT_FLOW]: FNOL_THEFT_MORE_INFO },
    page: FNOLTheftMoreInfo,
  },
  {
    is: { [FNOL_THEFT_FLOW]: FNOL_THEFT_STOLEN_IN_CAR },
    page: FNOLTheftStolenInCar,
  },
  {
    is: { [FNOL_THEFT_FLOW]: FNOL_THEFT_STOLEN_FROM_CAR },
    page: FNOLTheftStolenFromCar,
  },
  {
    is: { [FNOL_THEFT_FLOW]: FNOL_THEFT_STOLEN_AGE_VALUE },
    page: FNOLTheftStolenAgeValue,
  },
  {
    is: { [FNOL_THEFT_FLOW]: FNOL_THEFT_STOLEN_WHEN_WHERE },
    page: FNOLTheftStolenWhenWhere,
  },
  {
    is: { [FNOL_THEFT_FLOW]: FNOL_THEFT_POLICE_REPORT },
    page: FNOLTheftPoliceReport,
  },
  {
    is: { [FNOL_THEFT_FLOW]: FNOL_THEFT_POLICE_CASE },
    page: FNOLTheftPoliceCase,
  },
  {
    is: { [FNOL_THEFT_FLOW]: FNOL_ADDITIONAL_INFORMATION },
    page: FNOLAdditionalInformation,
  },
  {
    is: { [FNOL_THEFT_FLOW]: FNOL_TIME_LINE },
    page: FNOLTimeline,
  },
]
