import { FSMCommonTransitions, Events } from '../../constants'
import { get, has } from 'lodash'
import { BaseActionObject, StatesConfig } from 'xstate'
import { ContextType, StateSchemaType } from '../../config'

const {
  AUTH_BANK_ID,
  POLICIES_LIST,
  POLICIES,
  POLICY_REVIEW,
  POLICY_CHANGE,
  POLICY_VERIFY,
  POLICY_DONE,
  MY_PAGES,
  LANDING_PAGE,
  LANDING_PAGE_ABOUT,
  ERROR,
  LANDING_PAGE_QUESTIONS_AND_ANSWERS,
  AUTH,
} = FSMCommonTransitions

export default (
  FLOW_MACHINE_ID: string
): StatesConfig<ContextType, StateSchemaType, BaseActionObject> => ({
  [POLICIES]: {
    initial: POLICIES_LIST,
    states: {
      [POLICIES_LIST]: {
        on: {
          [Events.BACK]: { target: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`, actions: 'signOut' },
          [Events.NEXT]: POLICY_REVIEW,
          [Events.NO_DATA]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
          [Events.MY_PAGES]: `#${FLOW_MACHINE_ID}.${MY_PAGES}`,
        },
      },
      [POLICY_REVIEW]: {
        on: {
          [Events.BACK]: POLICIES_LIST,
          [Events.CHANGE]: POLICY_CHANGE,
          [Events.NO_DATA]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
          [Events.LANDING_PAGE]: {
            target: `#${FLOW_MACHINE_ID}.${LANDING_PAGE}`,
            actions: 'setBackPath',
          },
        },
      },
      [POLICY_VERIFY]: {
        on: {
          [Events.BACK]: POLICY_CHANGE,
          [Events.NEXT]: POLICY_DONE,
          [Events.LANDING_PAGE]: {
            target: `#${FLOW_MACHINE_ID}.${LANDING_PAGE}`,
            actions: 'setBackPath',
          },
        },
      },
      [POLICY_CHANGE]: {
        on: {
          [Events.BACK]: POLICY_REVIEW,
          [Events.NEXT]: POLICY_VERIFY,
          [Events.LANDING_PAGE]: {
            target: `#${FLOW_MACHINE_ID}.${LANDING_PAGE}`,
            actions: 'setBackPath',
          },
        },
      },
      [POLICY_DONE]: {
        on: {
          [Events.BACK]: `#${FLOW_MACHINE_ID}.${POLICIES}`,
          [Events.NEXT]: `#${FLOW_MACHINE_ID}.${POLICIES}.${POLICIES_LIST}`,
        },
      },
    },
  },
  [LANDING_PAGE]: {
    initial: LANDING_PAGE_ABOUT,
    states: {
      [LANDING_PAGE_ABOUT]: {
        on: {
          [Events.BACK]: [
            {
              target: `#${FLOW_MACHINE_ID}.${POLICIES}.${POLICY_REVIEW}`,
              cond: (ctx) =>
                has(ctx.backPath, POLICIES) && get(ctx.backPath, POLICIES) == POLICY_REVIEW,
            },
            {
              target: `#${FLOW_MACHINE_ID}.${POLICIES}.${POLICY_VERIFY}`,
              cond: (ctx) =>
                has(ctx.backPath, POLICIES) && get(ctx.backPath, POLICIES) == POLICY_VERIFY,
            },
            {
              target: `#${FLOW_MACHINE_ID}.${POLICIES}.${POLICY_CHANGE}`,
              cond: (ctx) =>
                has(ctx.backPath, POLICIES) && get(ctx.backPath, POLICIES) === POLICY_CHANGE,
            },
          ],
        },
      },
      [LANDING_PAGE_QUESTIONS_AND_ANSWERS]: {
        on: {
          [Events.LANDING_PAGE_ABOUT]: LANDING_PAGE_ABOUT,
          [Events.AUTH]: `#${FLOW_MACHINE_ID}.${AUTH}`,
          [Events.BACK]: LANDING_PAGE_ABOUT,
        },
      },
    },
  },
  [ERROR]: {
    on: {
      [Events.BACK]: [
        {
          target: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
          cond: (ctx) =>
            has(ctx.backPath, POLICIES) && get(ctx.backPath, POLICIES) === POLICIES_LIST,
        },
        {
          target: `#${FLOW_MACHINE_ID}.${POLICIES}.${POLICY_REVIEW}`,
          cond: (ctx, event) => event.backState === POLICY_REVIEW,
        },
        {
          target: `#${FLOW_MACHINE_ID}.${POLICIES}.${POLICIES_LIST}`,
          cond: (ctx) =>
            has(ctx.backPath, POLICIES) && get(ctx.backPath, POLICIES) === POLICY_REVIEW,
        },
        {
          target: `#${FLOW_MACHINE_ID}.${POLICIES}.${POLICY_CHANGE}`,
          cond: (ctx) =>
            has(ctx.backPath, POLICIES) && get(ctx.backPath, POLICIES) === POLICY_CHANGE,
        },
        {
          target: `#${FLOW_MACHINE_ID}.${POLICIES}.${POLICY_VERIFY}`,
          cond: (ctx) =>
            has(ctx.backPath, POLICIES) && get(ctx.backPath, POLICIES) === POLICY_VERIFY,
        },
      ],
    },
  },
})
