import React from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import { BrandIconShared } from '@dg-shared'
import { IconType } from '../../../types/interface/icon.interface'
import { ThemeBrandColorsType } from 'BrandTheme/types'
import { StylesProps } from '../../../types/interface/styles.interface'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconStyle: (props: StylesProps) => ({
      verticalAlign: 'sub',
      marginLeft: 5,
      cursor: 'pointer',
      color: theme.brand.colors[props.iconColor],
    }),
    closeIcon: {
      color: theme.brand.colors.headingMain,
      position: 'absolute',
      top: 10,
      right: 10,
      cursor: 'pointer',
      fontWeight: 100,
    },
    dialogContentStyle: {
      padding: theme.spacing(4),
    },
  })
)

type InfoIconPropsType = {
  iconColor?: keyof ThemeBrandColorsType
  icon?: string
  children?: React.ReactNode
}

/**
 * Renders an informational icon that shows Informational popup
 *
 * @param icon {string} - Material UI Icon font icon name
 * @param iconColor {keyof ThemeBrandColorsType} - optional ThemeBrandColorsType color name
 * @param children {React.ReactNode} inner content
 * @constructor
 */
const InfoIcon: React.FC<InfoIconPropsType> = ({
  icon = 'info_outlined',
  iconColor = 'textSecondary',
  children,
}) => {
  const { iconStyle, closeIcon, dialogContentStyle } = useStyles({ iconColor })
  const [open, setOpen] = React.useState(false)

  const showPopup = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(!open)
    event.preventDefault()
  }

  const close = () => setOpen(false)

  return (
    <>
      <Dialog onClose={close} open={open} maxWidth='xs'>
        <DialogContent className={dialogContentStyle}>
          <BrandIconShared type={IconType.ERROR} customClass={closeIcon} onClick={close} action />
          {children}
        </DialogContent>
      </Dialog>
      <Icon fontSize='small' onClick={showPopup} className={iconStyle}>
        {icon}
      </Icon>
    </>
  )
}

export default InfoIcon
