import React, { ComponentType } from 'react'

function withDialog<T>(Component: ComponentType<T>) {
  // eslint-disable-next-line react/display-name
  return (props: T) => (
    <>
      <div>This is the text</div>
      <Component {...props} />
    </>
  )
}

export default withDialog
