import React, { FC, useEffect } from 'react'
import CONFIG from '../../config'
import { DEFAULT_INSURELY_TEST_COMPANY } from '../../constants'

export type InsurelyIframePropsType = {
  style?: Partial<React.CSSProperties>
  companyId: string
}

declare global {
  interface Window {
    insurely: {
      config: Record<string, string>
      prefill: {
        dataAggregation: {
          company: string
          hideResultsView: boolean
        }
      }
    }
  }
}

const InsurelyIframe: FC<InsurelyIframePropsType> = ({ style, companyId }) => {
  const insurelyIframeSrc = `https://blocks.insurely.com/`
  const insurelyCustomerId = CONFIG.INSURELY_CUSTOMER_ID
  const insurelyConfigName = CONFIG.INSURELY_CONFIG_NAME

  useEffect(() => {
    window.insurely = {
      config: {
        customerId: insurelyCustomerId,
        configName: insurelyConfigName,
        language: CONFIG.LANGUAGE_CODE,
      },
      prefill: {
        dataAggregation: {
          // Auto select default environment / brand test company or use the real one for production
          company: DEFAULT_INSURELY_TEST_COMPANY || companyId,
          hideResultsView: true,
        },
      },
    }
  })

  return (
    <iframe
      style={style}
      frameBorder='0'
      title='Insurely Iframe'
      src={insurelyIframeSrc}
      id='insurely-iframe'
      data-testid='insurely-iframe'
      sandbox='allow-scripts
        allow-same-origin
        allow-popups
        allow-forms
        allow-popups-to-escape-sandbox
        allow-top-navigation'
    />
  )
}

export default InsurelyIframe
