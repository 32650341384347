import React, { FC, useEffect } from 'react'
import { Typography, FNOLPageWrapper, FNOLButton } from '@dg-shared'
import { useTranslate, useClaim } from '../../../hooks/shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import {
  FLOW_THEFT_POLICE_REPORT_CLAIM_TYPE_IDS,
  FLOW_THEFT_QUESTIONS,
  TRANSLATIONS as T,
} from '../../../constants'
import { useFNOLStyles } from '../shared/styles/useStyles'

const PagePoliceReport: FC<CommonPageProps> = ({ service, currentState }) => {
  const { t } = useTranslate(`${T.FNOL_THEFT}.policeReport`)
  const FNOLClasses = useFNOLStyles({ externalLink: true })
  const { handleAnswerClick, clearAnswer } = useClaim(service, currentState)

  useEffect(() => {
    clearAnswer(FLOW_THEFT_QUESTIONS.REPORTED_TO_POLICE)
  }, [clearAnswer])

  const itemSelect = ({ event, label }: { event: Events; label: string }) => (
    <FNOLButton
      testId={label}
      key={label}
      handleClick={() =>
        handleAnswerClick(
          FLOW_THEFT_QUESTIONS.REPORTED_TO_POLICE,
          FLOW_THEFT_POLICE_REPORT_CLAIM_TYPE_IDS[event],
          event
        )
      }
      buttonText={t(label)}
    />
  )

  const eventItems = [
    { event: Events.FNOL_THEFT_POLICE_REPORT, label: 'button.yes' },
    { event: Events.NEXT, label: 'button.no' },
  ]

  const ItemsList = () => <>{eventItems.map((item) => itemSelect(item))}</>

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t('back')} breadcrumbsStepPercentage={90}>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <Typography testId='subtitle' className={FNOLClasses.description}>
        {t('subtitle')}
      </Typography>
      <ItemsList />
    </FNOLPageWrapper>
  )
}

export default PagePoliceReport
