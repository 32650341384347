import React from 'react'
import { CoverageInfoList, Typography } from '@dg-shared'
import { useTranslate, TranslatedTextType } from '../../../hooks/shared'
import { CoverageType } from '../../../types/interface/quote.interface'
import { ThemeBrandColorsType } from 'BrandTheme/types'
import InfoIcon from './InfoIcon'

type InfoIconPropsType = {
  color?: keyof ThemeBrandColorsType
  label?: TranslatedTextType
  icon?: string
  coverageType?: CoverageType
  children?: React.ReactNode
}

/**
 * Renders an informational label with an icon that shows an info popup by clicking on it
 * Informational popup text and label name is based on a translation name prefix
 * example: name = 'SuperCover', label is t('SuperCoverLabel'),
 * popupInfoText is t('SuperCoverInfoText') + t('SuperCoverInfoListText')
 *
 * @param coverageType {string} - coverage type name
 * @param label {string} - optional label name override
 * @constructor
 */
const CoverageInfoicon: React.FC<InfoIconPropsType> = ({ coverageType, label }) => {
  const { t } = useTranslate()
  const labelText = label || null
  const titleText = coverageType && t(coverageType.name)
  const infoText = coverageType && t(coverageType.description)
  const infoListText = coverageType && t(coverageType.list)

  const infoTextDetails = Array.isArray(infoListText) ? (
    <CoverageInfoList list={infoListText} />
  ) : (
    infoListText
  )

  return (
    <InfoIcon>
      {labelText}
      <Typography type='h2'>{titleText}</Typography>
      <Typography>{infoText}</Typography>
      {infoTextDetails}
    </InfoIcon>
  )
}

export default CoverageInfoicon
