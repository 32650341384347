import { ReactNode } from 'react'

import { QuoteV2Type } from '../ApiV2'

export interface QuoteAccepted {
  id: number
  policyNumber: number
  startDate: string
}

export interface OfferItemProps {
  itemData: Partial<QuoteV2Type>
  colLeft?: ReactNode
  colRight?: ReactNode
  isImage?: boolean
  showDivider?: boolean
}

export type CoverageType = {
  name: string
  description: string
  list?: string
  additionalInfo?: string
}

export enum CoverageTypeKeyType {
  trafik = 'Trafik',
  halv = 'Halv',
  hel = 'Hel',
  ansvar = '1',
  delkasko = '2',
  kasko = '3',
  super = '4',
}

export type CoverageTypesMapping = Record<
  PartnerName,
  Partial<Record<CoverageTypeKeyType, CoverageType>>
>

export enum PartnerName {
  instabank = 'instabank',
  nord = 'nord',
  circlek = 'circlek',
  volkswagen = 'volkswagen',
  skoda = 'skoda',
  audi = 'audi',
}

export enum ProductName {
  VEHICLE = 'vehicle',
  TRAVEL = 'travel',
  CONTENT = 'content',
}
