import React, { FC, useContext } from 'react'
import { Button, Grid } from '@material-ui/core'

import { TRANSLATIONS as T } from '../../constants/translations-constants'
import { Events } from '../../FSM/shared/constants'
import PolicyCardV2 from '../../components/Claims/PolicyCardV2'
import { Typography } from '@dg-shared'
import { useTranslate } from '../../hooks/shared'
import { PolicyV2Type } from '../../types/ApiV2'
import { SELECTED_POLICY_NUMBER } from '../../constants'
import { Context } from '@dg-shared/ContextProvider'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import { FlowServiceType } from '../../types/interface/fsm.interface'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    newQuoteButton: {
      display: 'flex',
      width: '100%',
      height: 230,
      padding: 40,
      borderRadius: 10,
      flexDirection: 'column',
      gap: theme.spacing(2),
      '& > span': {
        flexDirection: 'column',
        justifyContent: 'space-around',
      },
      '&:hover': {
        backgroundColor: `${theme.brand.colors.main}10`,
      },
      [theme.breakpoints.down('sm')]: {
        background: theme.brand.colors.main,
        padding: '5px 15px',
        height: 48,
        ...theme.brand.assets.layout?.mobileButton,
      },
      [theme.breakpoints.up('md')]: {
        '& .MuiTouchRipple-root': {
          color: theme.brand.colors.main,
        },
      },
      '& p': {
        marginTop: '0.35rem',
      },
    },
    icon: {
      color: theme.brand.colors.main,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    plusButtonContainer: {
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        padding: '12px 16px !important',
      },
      ...theme.brand.assets.layout?.policiesListCardsPlusButtonContainer,
    },
    cardsWrapper: {
      position: 'relative',
      marginBottom: 56,
    },
    policyListPlusButton: {
      textTransform: 'uppercase',
      ...theme.brand.assets.layout?.policyListPlusButton,
    },
  })
)

interface PoliciesList {
  policies: Partial<PolicyV2Type>[]
  service: FlowServiceType
  plusButtonHandler?: () => void
  additionalOffers?: React.ReactNode
}

const PoliciesListCards: FC<PoliciesList> = ({
  service,
  plusButtonHandler,
  policies,
  additionalOffers,
}) => {
  const { t } = useTranslate(T.POLICIES)
  const classes = useStyles({})
  const { setContext } = useContext(Context)

  const handleClaimCreation = async (policyNumber: string) => {
    setContext({ [SELECTED_POLICY_NUMBER]: policyNumber })
    service.send({ type: Events.NEXT, policyId: policyNumber })
  }

  return (
    <Grid
      container
      spacing={3}
      justifyContent={'center'}
      data-testid={'policy-list'}
      className={classes.cardsWrapper}
    >
      {policies.map((policy: PolicyV2Type, key) => (
        <Grid key={`${policy.PolicyNumber}-${key}`} item xs={12} md={4}>
          <PolicyCardV2
            data={policy}
            priceText={t(`card.priceYearly`)}
            handleClick={() => handleClaimCreation(policy.InsuranceNumber.toString())}
          />
        </Grid>
      ))}
      {additionalOffers}
      {plusButtonHandler && (
        <Grid key='plus_button' item xs={12} md={4} className={classes.plusButtonContainer}>
          <Button onClick={plusButtonHandler} className={classes.newQuoteButton}>
            <Icon className={classes.icon} fontSize='large'>
              add_circle
            </Icon>
            <Typography
              testId='new-quote-button'
              modifiers={['bold']}
              className={classes.policyListPlusButton}
            >
              {t('newQuoteButton')}
            </Typography>
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default PoliciesListCards
