/* eslint-disable max-len */
import React, { FC } from 'react'

import { IconType } from '../../types/interface/icon.interface'

interface CircleKSVGIconProps {
  type: string
  width?: number
  height?: number
  color?: string
  className?: string
}

const CircleKSVGIcon: FC<CircleKSVGIconProps> = ({
  type,
  color = '#FB6620',
  width = 24,
  height = 24,
  className,
}) => {
  switch (type) {
    case IconType.LOADING:
      return (
        <svg
          id='af95e1c5-77d8-482c-89ed-d5bb7b6758aa'
          data-name='Layer 1'
          xmlns='http://www.w3.org/2000/svg'
          x='0px'
          y='0px'
          viewBox='0 0 100 100'
          className={className}
          style={{
            width: `${width}px`,
            height: `${height}px`,
          }}
        >
          <path
            fill={color}
            d='M91.34,43.42a40,40,0,0,1,.93,6.17,56.84,56.84,0,0,1,0,6.26,43.93,43.93,0,0,1-2.92,12.23A41.61,41.61,0,0,1,82.8,78.91a39.73,39.73,0,0,1-9.56,8.43A23.24,23.24,0,0,1,70.48,89c-1,.48-1.9,1-2.89,1.44l-3,1.18c-1,.39-2.06.62-3.1.93a40.86,40.86,0,0,1-12.85,1.39,41.35,41.35,0,0,1-12.79-2.57A44.22,44.22,0,0,1,24.37,85a46.09,46.09,0,0,1-9.08-9.62,43.51,43.51,0,0,1-5.77-12A42.58,42.58,0,0,1,7.64,50.07,42.1,42.1,0,0,1,9.86,36.75l.55-1.62.27-.8.35-.78,1.42-3.11a47.56,47.56,0,0,1,3.61-5.8,43.78,43.78,0,0,1,9.62-9.74A46.85,46.85,0,0,1,37.9,8.46,49.16,49.16,0,0,1,51.58,6,6,6,0,0,1,52,18h-.45A37.8,37.8,0,0,0,41.33,19.3a35.69,35.69,0,0,0-9.44,4.38,33.11,33.11,0,0,0-7.76,7.09,39,39,0,0,0-3.05,4.35l-1.22,2.36-.31.59-.24.62-.5,1.24a33.49,33.49,0,0,0-2.29,10.45,34.2,34.2,0,0,0,1,10.75,35.37,35.37,0,0,0,4.2,10,38.69,38.69,0,0,0,7.12,8.37,37.8,37.8,0,0,0,9.41,5.86,37,37,0,0,0,22.08,2.12c.91-.23,1.86-.37,2.76-.68l2.7-.9c.89-.33,1.74-.77,2.61-1.14a21.9,21.9,0,0,0,2.54-1.33,35.88,35.88,0,0,0,9-7.13,38.36,38.36,0,0,0,6.47-9.58,40.75,40.75,0,0,0,3.27-11.18,54.88,54.88,0,0,0,.32-5.88,38.65,38.65,0,0,0-.58-5.87,1,1,0,0,1,2-.41Z'
          >
            <animateTransform
              attributeName='transform'
              attributeType='XML'
              type='rotate'
              dur='1s'
              from='0 50 50'
              to='360 50 50'
              repeatCount='indefinite'
            />
          </path>
        </svg>
      )
    case IconType.CHECK:
      return (
        <svg
          style={{
            width: `${width}px`,
            height: `${height}px`,
          }}
          className={className}
          id='a1f9432c-68f6-4c8c-b90e-e13df7c6210d'
          data-name='Layer 1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 100 100'
        >
          <path
            d='M33.72,78.65a1.49,1.49,0,0,1-1.06-.44L10.93,56.48a1.5,1.5,0,0,1,2.12-2.12L33.72,75,87,21.79a1.51,1.51,0,0,1,2.12,0,1.49,1.49,0,0,1,0,2.12L34.78,78.21A1.51,1.51,0,0,1,33.72,78.65Z'
            fill={color}
          />
        </svg>
      )
    case IconType.ERROR:
      return (
        <svg
          style={{
            width: `${width}px`,
            height: `${height}px`,
          }}
          className={className}
          id='aac2afb7-d6ae-4c42-bc65-5e07d8b1e6e7'
          data-name='Layer 1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 100 100'
        >
          <path
            d='M20.44,81.06a1.51,1.51,0,0,1-1.06-.44,1.49,1.49,0,0,1,0-2.12L78.5,19.38a1.5,1.5,0,1,1,2.12,2.12L21.5,80.62A1.51,1.51,0,0,1,20.44,81.06Z'
            fill={color}
          />
          <path
            d='M79.56,81.06a1.51,1.51,0,0,1-1.06-.44L19.38,21.5a1.5,1.5,0,1,1,2.12-2.12L80.62,78.5a1.49,1.49,0,0,1,0,2.12A1.51,1.51,0,0,1,79.56,81.06Z'
            fill={color}
          />
        </svg>
      )
    case IconType.CALENDAR:
      return (
        <svg
          id='a219f9bb-10b9-42a9-99cd-d3a24b161f81'
          data-name='Layer 1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 100 100'
          className={className}
          style={{
            width: `${width}px`,
            height: `${height}px`,
          }}
        >
          <path
            d='M86.67,91.16H13.33a5.69,5.69,0,0,1-5.69-5.69V20.76a5.69,5.69,0,0,1,5.69-5.69h8.08a1.5,1.5,0,1,1,0,3H13.33a2.69,2.69,0,0,0-2.69,2.69V85.47a2.7,2.7,0,0,0,2.69,2.69H86.67a2.69,2.69,0,0,0,2.69-2.69V20.76a2.68,2.68,0,0,0-2.69-2.69H79.54a1.5,1.5,0,0,1,0-3h7.13a5.69,5.69,0,0,1,5.69,5.69V85.47A5.69,5.69,0,0,1,86.67,91.16Z'
            fill={color}
          />
          <path d='M62.39,18.07H39a1.5,1.5,0,0,1,0-3H62.39a1.5,1.5,0,0,1,0,3Z' fill={color} />
          <path
            d='M33.85,28.41h-9a1.5,1.5,0,0,1-1.5-1.5V10.34a1.5,1.5,0,0,1,1.5-1.5h9a1.5,1.5,0,0,1,1.5,1.5V26.91A1.5,1.5,0,0,1,33.85,28.41Zm-7.49-3h6V11.84h-6Z'
            fill={color}
          />
          <path
            d='M75.14,28.41h-9a1.5,1.5,0,0,1-1.5-1.5V10.34a1.5,1.5,0,0,1,1.5-1.5h9a1.5,1.5,0,0,1,1.5,1.5V26.91A1.5,1.5,0,0,1,75.14,28.41Zm-7.49-3h6V11.84h-6Z'
            fill={color}
          />
          <path d='M90.12,38.89h-81a1.5,1.5,0,0,1,0-3h81a1.5,1.5,0,0,1,0,3Z' fill={color} />
          <path
            d='M32.27,58.76H24a1.5,1.5,0,0,1-1.5-1.5V49a1.5,1.5,0,0,1,1.5-1.5h8.3a1.5,1.5,0,0,1,1.5,1.5v8.3A1.5,1.5,0,0,1,32.27,58.76Zm-6.8-3h5.3v-5.3h-5.3Z'
            fill={color}
          />
          <path
            d='M54.15,58.76h-8.3a1.5,1.5,0,0,1-1.5-1.5V49a1.5,1.5,0,0,1,1.5-1.5h8.3a1.5,1.5,0,0,1,1.5,1.5v8.3A1.5,1.5,0,0,1,54.15,58.76Zm-6.8-3h5.3v-5.3h-5.3Z'
            fill={color}
          />
          <path
            d='M76,58.76h-8.3a1.5,1.5,0,0,1-1.5-1.5V49a1.5,1.5,0,0,1,1.5-1.5H76a1.5,1.5,0,0,1,1.5,1.5v8.3A1.5,1.5,0,0,1,76,58.76Zm-6.8-3h5.3v-5.3h-5.3Z'
            fill={color}
          />
          <path
            d='M32.27,75.57H24a1.5,1.5,0,0,1-1.5-1.5v-8.3a1.5,1.5,0,0,1,1.5-1.5h8.3a1.5,1.5,0,0,1,1.5,1.5v8.3A1.5,1.5,0,0,1,32.27,75.57Zm-6.8-3h5.3v-5.3h-5.3Z'
            fill={color}
          />
          <path
            d='M54.15,75.57h-8.3a1.5,1.5,0,0,1-1.5-1.5v-8.3a1.5,1.5,0,0,1,1.5-1.5h8.3a1.5,1.5,0,0,1,1.5,1.5v8.3A1.5,1.5,0,0,1,54.15,75.57Zm-6.8-3h5.3v-5.3h-5.3Z'
            fill={color}
          />
          <path
            d='M76,75.57h-8.3a1.5,1.5,0,0,1-1.5-1.5v-8.3a1.5,1.5,0,0,1,1.5-1.5H76a1.5,1.5,0,0,1,1.5,1.5v8.3A1.5,1.5,0,0,1,76,75.57Zm-6.8-3h5.3v-5.3h-5.3Z'
            fill={color}
          />
        </svg>
      )
    case IconType.ARROW_DOWN:
      return (
        <svg
          id='a1f9432c-68f6-4c8c-b90e-e13df7c621L9'
          data-name='Layer 2'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          className={className}
          style={{
            width: `${width}px`,
            height: `${height}px`,
          }}
        >
          <path d='M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z' fill={color} />
        </svg>
      )
    case IconType.DOT:
      return (
        <svg
          id='a1f9432c-68f6-4c8c-b90e-e13df7c6113L'
          data-name='Layer 2'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          className={className}
          style={{
            width: `${width}px`,
            height: `${height}px`,
            transform: 'scale(0.75)',
          }}
        >
          <circle cx='12' cy='12' r='3'></circle>
        </svg>
      )
    default:
      return null
  }
}

export default CircleKSVGIcon
