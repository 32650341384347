import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { useSelector } from '@xstate/react'
import { Events } from '../../../FSM/shared/constants'
import { getBackButtonText, normalizeMotorRegistrationNumber } from '@dg-util'
import { FlowServiceType } from '../../../types/interface/fsm.interface'
import { AddressValues } from '../../../types/interface/map.interface'
import { MachineConfigType } from '../../../FSM/shared/config'
import { AuthMethod } from '../../../constants/auth-constants'
import { Context } from '@dg-shared/ContextProvider'
import {
  QUOTE_ID,
  SELECTED_QUOTE_ADDRESS,
  SELECTED_QUOTE_MOTOR_REGISTRATION_NUMBER,
} from '../../../constants'

export interface FormFindValues {
  MotorRegistrationNumber?: string
  address?: AddressValues
}

interface HookFind {
  showErrorMessage: boolean
  title: string
  btnBackText: string
  backPath?: string
  isVippsAuth?: boolean
  handleSubmit(values: FormFindValues): Promise<void>
  setShowErrorMessage: Dispatch<SetStateAction<boolean>>
}

// TODO: Define the type
const useLogic = (service: FlowServiceType): HookFind => {
  const eventType = useSelector(service, (state) => state.event.type)
  const btnBackText = useSelector(service, ({ value }) =>
    getBackButtonText(service.machine.config as MachineConfigType, value)
  )
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false)
  const backPath = useSelector(service, (state) => state.context.backPath)
  const isVippsAuth = useSelector(service, (state) => state.context.authMethod === AuthMethod.VIPPS)
  const { setContext } = useContext(Context)

  const handleProductSearch = async (values: FormFindValues): Promise<void> => {
    const selectedValues = {
      [SELECTED_QUOTE_MOTOR_REGISTRATION_NUMBER]: values.MotorRegistrationNumber
        ? normalizeMotorRegistrationNumber(values.MotorRegistrationNumber)
        : undefined,
      [SELECTED_QUOTE_ADDRESS]: values.address,
    }

    setContext(selectedValues)

    service.send(Events.NEXT)
  }

  // Reset previously selected values
  useEffect(() => {
    const resetedValues = {
      [SELECTED_QUOTE_MOTOR_REGISTRATION_NUMBER]: '',
      [SELECTED_QUOTE_ADDRESS]: '',
      [QUOTE_ID]: '',
    }
    setContext(resetedValues)
  }, [setContext])

  useEffect(() => {
    const errorMessage = eventType === Events.WRONG_PRODUCT_ID
    setShowErrorMessage(errorMessage)
  }, [eventType])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return {
    //TODO: Display error if user came to this page by WRONG_PRODUCT_ID event
    showErrorMessage,
    setShowErrorMessage,
    // title: {
    //   [REDIRECT_PATH_FIND_CUSTOM]: 'title.custom',
    //   [REDIRECT_PATH_FIND_EMPTY]: 'title.empty',
    //   DEFAULT: 'title.empty'
    // }[location.pathname || 'DEFAULT'],
    title: 'title.empty',
    backPath,
    isVippsAuth,
    btnBackText: btnBackText,
    handleSubmit: handleProductSearch,
  }
}

export default useLogic
