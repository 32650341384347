import React, { FC, useEffect } from 'react'

import { Typography, FNOLPageWrapper, FNOLButton } from '@dg-shared'
import { useTranslate, useClaim } from '../../../hooks/shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { useFNOLStyles } from '../shared/styles/useStyles'
import {
  TRANSLATIONS as T,
  FNOL_COLLISION_QUESTIONS,
  AccidentCauseTypes,
  FLOW_COLLISION_CASE_OF_ACCIDENT_CAUSE_TYPE_IDS,
} from '../../../constants'
import { Events } from '../../../FSM/shared/constants'

const PageCaseOfAccident: FC<CommonPageProps> = ({ service, currentState }) => {
  const FNOLClasses = useFNOLStyles({})
  const { t } = useTranslate(`${T.COLLISION}.caseOfAccident`)
  const { handleAnswerClick, clearAnswer } = useClaim(service, currentState)

  useEffect(() => {
    clearAnswer(FNOL_COLLISION_QUESTIONS.HOW_DID_ACCIDENT_HAPPEN)
  }, [clearAnswer])

  const causeTypes: AccidentCauseTypes[] = [
    AccidentCauseTypes.NOT_GIVE_UP,
    AccidentCauseTypes.REAR_END_COLLISION,
    AccidentCauseTypes.BACKING,
    AccidentCauseTypes.OVERTAKING,
    AccidentCauseTypes.PARKING,
    AccidentCauseTypes.ONCOMING,
    AccidentCauseTypes.LEFT_TURN,
    AccidentCauseTypes.FIELD_CHANGE,
    AccidentCauseTypes.CHAIN_COLLISION,
    AccidentCauseTypes.ROUNDABOUT,
    AccidentCauseTypes.OTHER,
  ]

  const itemSelect = (causeType: AccidentCauseTypes) => (
    <FNOLButton
      key={causeType}
      testId={causeType}
      handleClick={() =>
        handleAnswerClick(
          FNOL_COLLISION_QUESTIONS.HOW_DID_ACCIDENT_HAPPEN,
          FLOW_COLLISION_CASE_OF_ACCIDENT_CAUSE_TYPE_IDS[causeType],
          Events.FNOL_COLLISION_REGISTRATION_NUM
        )
      }
      buttonText={t(causeType)}
    />
  )

  const ItemsList = () => <>{causeTypes.map((causeType) => itemSelect(causeType))}</>

  const Content = () => (
    <>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <Typography testId='subtitle' html={true} className={FNOLClasses.description}>
        {t('subtitle')}
      </Typography>
      <ItemsList />
    </>
  )

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`back`)} breadcrumbsStepPercentage={50}>
      <Content />
    </FNOLPageWrapper>
  )
}

export default PageCaseOfAccident
