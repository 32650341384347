import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Events } from '../../../FSM/shared/constants'
import { FlowServiceType } from '../../../types/interface/fsm.interface'
import { FSMBtnNext, Typography, VippsBtn } from '@dg-shared'
import { useTranslate, TranslatedTextType } from '../../../hooks/shared'
import AcceptCookies from '../../../components/shared/AcceptCookies'
import AuthDecisionText from 'components/AuthDecisionText'
import { getDataComponentName, redirect } from '@dg-util'
import { TRANSLATIONS as T } from '../../../constants'
import { FlowName } from '../../../config'

export const useAuthDecisionStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttons: {
      display: 'flex',
      flexDirection: 'column',
    },
    nextBtn: {
      width: '230px',
      height: '48px',
      margin: '0 auto',
      ...theme.brand.assets.layout?.nextBtn,
      ...theme.brand.assets.layout?.secondaryFontFamily,
    },
    myInsurances: {
      margin: `${theme.spacing(2)}px auto`,
      marginTop: theme.spacing(2.5),
      ...theme.brand.assets.layout?.myInsurances,
      ...theme.brand.assets.layout?.secondaryFontFamily,
    },
  })
)

interface AuthDecisionProps {
  textBtnAuthRegular: TranslatedTextType
  linkVipps: string
  errorVipps: boolean
  service?: FlowServiceType
}

const AuthDecision: FC<AuthDecisionProps> = ({
  textBtnAuthRegular,
  linkVipps,
  errorVipps,
  ...props
}) => {
  const classes = useAuthDecisionStyles()
  const { t } = useTranslate(T.AUTH)

  return (
    <>
      <div className={classes.buttons} data-componentname={getDataComponentName(__filename)}>
        <VippsBtn url={linkVipps} disabled={errorVipps} />
        <FSMBtnNext
          testId='button-next'
          handleClick={() => props.service.send(Events.NEXT)}
          buttonText={textBtnAuthRegular}
          customClassName={classes.nextBtn}
          outlined
        />

        <Typography
          type='h4'
          color='textMain'
          modifiers={['bold', 'withHover', 'decorated']}
          className={classes.myInsurances}
          onClick={() => {
            // Switch to MyPages
            redirect(`/?flow=${FlowName.MY_PAGES}`)
          }}
        >
          {t('myInsurances')}
        </Typography>
      </div>
      <section data-testid='next-buttons' className={classes.buttons}>
        <AuthDecisionText />
      </section>
      <AcceptCookies />
    </>
  )
}

export default AuthDecision
