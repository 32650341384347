import React, { FC, ReactNode } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      minHeight: theme.spacing(37),
      padding: theme.spacing(3),
    },
  })
)

const NoDataCard: FC<{ children?: ReactNode }> = ({ children }) => {
  const classes = useStyles()

  return (
    <Grid className={classes.wrapper} data-testid={'no-data'}>
      {children}
    </Grid>
  )
}

export default NoDataCard
