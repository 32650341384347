import { useMutation, useQuery } from '@tanstack/react-query'
import apiV2 from '../../service/apiV2.service'
import { reactQueryErrorHandler } from '@dg-util'
import { FSMService } from '../../FSM'
import { PartyV2Type, ResponseErrorV2Type } from '../../types/ApiV2'
import queryClient from '../../queryClient'

type UseReactQueryPartyArgsType = {
  // Default Get Own Party fetching enabled value
  partyQueryEnabled?: boolean
}

const useReactQueryParty = (options: UseReactQueryPartyArgsType = {}) => {
  const { partyQueryEnabled = true } = options

  // Update Mutation
  const {
    mutateAsync: updateParty,
    isLoading: isUpdatePartyLoading,
    data: updatedPartyData,
    isError: isUpdatePartyError,
    error: updatePartyError,
  } = useMutation((data: Partial<PartyV2Type>) => apiV2.updateParty(data), {
    async onSuccess(response) {
      // Set party data in query cache
      queryClient.setQueryData(['party'], (party: Partial<PartyV2Type>) => ({
        ...party,
        ...response,
      }))
    },
    onError: (error: ResponseErrorV2Type) => reactQueryErrorHandler(error, FSMService.flowService),
  })

  // Get Own Party Query
  const {
    data: party,
    isInitialLoading: isGetPartyLoading,
    isError: isPartyError,
    error: partyError,
  } = useQuery(['party'], () => apiV2.getPartyOwn(), {
    select: (response) => response.data,
    enabled: partyQueryEnabled,
    onError: (error: ResponseErrorV2Type) => reactQueryErrorHandler(error, FSMService.flowService),
  })

  return {
    party,
    isLoading: isUpdatePartyLoading || isGetPartyLoading,
    updateParty,
    updatedPartyData,
    isUpdatePartyError,
    updatePartyError,
    isPartyError,
    partyError,
  }
}

export default useReactQueryParty
