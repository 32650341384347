import React, { FC, Fragment } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'

import { IconType } from '../../types/interface/icon.interface'
import { Typography, BrandIconShared } from '@dg-shared'
import { TranslatedTextType } from '../../types'
import BrandConfig from 'BrandConfig'

const detailedInfo = makeStyles((theme: Theme) =>
  createStyles({
    coverHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(1),
    },
    coverCloseBtn: {
      marginBottom: theme.spacing(1),
      padding: '0',
      height: '1.5rem',
      color: theme.palette.primary.main,
    },
    privacy: {
      marginBottom: 0,
    },
  })
)

interface DetailedInfoShared {
  hideDetails: () => void
  text: { title?: TranslatedTextType; content: TranslatedTextType; link: TranslatedTextType }
}

const DetailedInfoShared: FC<DetailedInfoShared> = ({
  hideDetails,
  text: { title, content, link },
}) => {
  const classes = detailedInfo()

  return (
    <Fragment>
      <section className={classes.coverHeader}>
        {title && <Typography type='h2'>{title}</Typography>}

        {/* //TODO: Translate this */}
        <IconButton
          className={classes.coverCloseBtn}
          aria-label='click to know coverage'
          onClick={hideDetails}
        >
          <BrandIconShared color='headingMain' type={IconType.ERROR} />
        </IconButton>
      </section>

      <Typography>{content}</Typography>
      <a href={BrandConfig.PRIVACY_POLICY_LINK} target='_blank' rel='noreferrer'>
        <Typography
          className={classes.privacy}
          color={'secondary'}
          modifiers={['bold', 'decorated']}
        >
          {link}
        </Typography>
      </a>
    </Fragment>
  )
}

export default DetailedInfoShared
