import { get } from 'lodash'

import {
  VEHICLE_COVERAGE_TYPE_MAP as VCTM,
  VEHICLE_COVERAGE_TYPE_TRANSLATION_KEYS,
  VEHICLE_COVERAGE_TYPE_TRANSLATION_KEYS as VCTTK,
} from '../constants'
import { QuoteV2Type } from '../types/ApiV2'

export type CoverageType = {
  name: string
  description: string
  list: string
}

export interface CoverageMapping {
  type: CoverageType | null
  name: string | null
}

const getCoverageData = (quote?: Partial<QuoteV2Type>): CoverageMapping => {
  return {
    name: get(VCTM, [PARTNER_NAME, quote.CarPackage], null),
    type: get(VCTTK, [PARTNER_NAME, quote.CarPackage], null),
  }
}

export const getCoverageText = (coverageName: string) => {
  return get(VEHICLE_COVERAGE_TYPE_TRANSLATION_KEYS, [PARTNER_NAME, coverageName], null)
}

export default getCoverageData
