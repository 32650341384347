import React, { FC } from 'react'
import { Typography } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { getDataComponentName, getLocalizedPartnerName } from '@dg-util'
import { FSMMatch, FSMSwitch } from '../../../FSM'
import BrandConfig from 'BrandConfig'
import { MATCH_COMPOUND } from '../../../FSM/shared/constants'
import { AUTH_ERROR_TRANSLATION_KEYS } from '../../../constants'
import { has } from 'lodash'
import { FSMStateType } from '../../../types/interface/fsm.interface'

export const useAuthDisclaimerText = makeStyles(
  (theme: Theme) =>
    createStyles({
      paragraph: {
        marginBottom: theme.spacing(1),
        textAlign: 'start',
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
      },
      error: {
        marginBottom: theme.spacing(1),
      },
      disclaimer: {
        marginBottom: theme.spacing(1),
        color: theme.brand.colors.textMain,
        ...theme.brand.assets.layout?.AuthStrategyDisclaimer,
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
        '& a': {
          textDecoration: 'underline',
          color: 'inherit',
        },
      },
    }),
  { index: 1 }
)

type AuthDisclaimerText = {
  errorEventType: string
  fsmState?: FSMStateType
}

const AuthStrategyDisclaimer: FC<AuthDisclaimerText> = ({ errorEventType }) => {
  const classes = useAuthDisclaimerText({
    height: window.innerHeight,
  })
  const { t } = useTranslate(T.AUTH)

  const errorMessage = has(AUTH_ERROR_TRANSLATION_KEYS, errorEventType) && (
    <Typography
      testId='auth-strategy-error-message'
      color='textError'
      size={'small'}
      modifiers={['bold']}
      className={classes.error}
    >
      {t(AUTH_ERROR_TRANSLATION_KEYS[errorEventType])}
    </Typography>
  )

  const partnerName = getLocalizedPartnerName()

  return (
    <div data-componentname={getDataComponentName(__filename)}>
      <FSMSwitch>
        <FSMMatch is={MATCH_COMPOUND.AUTH_CHOICE}>
          <Typography color='textDisclaimer' size='small' className={classes.disclaimer}>
            {`${t('disclaimer.0')} `}
            <a href={BrandConfig.PRIVACY_POLICY_LINK} target='_blank' rel='noreferrer'>
              {t('disclaimer.1', { partnerName })}
            </a>
          </Typography>
          {errorMessage}
        </FSMMatch>
        <FSMMatch is={MATCH_COMPOUND.AUTH_REGULAR}>
          {errorMessage}
          <Typography
            size='small'
            testId='auth-typography-description'
            className={classes.disclaimer}
          >
            {t('description')}
          </Typography>
        </FSMMatch>
        <FSMMatch is={MATCH_COMPOUND.AUTH_AGENT}>
          {errorMessage}
          <Typography
            size='small'
            testId='auth-typography-description'
            className={classes.disclaimer}
          >
            {t('description')}
          </Typography>
        </FSMMatch>
      </FSMSwitch>
    </div>
  )
}

export default AuthStrategyDisclaimer
