import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { switchFSM } from '../../util/productHandler'
import DebugWrapper from './DebugWrapper'
import { SwitchFSMParamsType } from '../../types/interface/fsm.interface'
import { FSMCommonTransitions } from '../../FSM/shared/constants'
import { FlowName, IS_ACCEPTANCE, IS_PRODUCTION } from '../../config'
import { ProductName } from '../../types/interface/quote.interface'
import Icon from '@material-ui/core/Icon'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnDebug: {
      position: 'absolute',
      top: 14,
      right: 0,
      color: theme.palette.primary.main,
      zIndex: 1000,
    },
    icon: {
      color: theme.brand.colors.main,
    },
  })
)

// Navigates to selected product flow page for DEBUG QA needs on DEV
const SwitchFlowDEVButton = (params: SwitchFSMParamsType) => {
  const classes = useStyles()
  const { targetProduct, targetFlow, label = `Switch to ${targetFlow}/${targetProduct}` } = params

  const switchClickHandler = () => {
    const targetStateValue =
      (prompt(
        'Select targetState (see FSMCommonTransitions). Examples: "quoteFind", "policies":',
        params.targetStateValue
      ) as FSMCommonTransitions) || FSMCommonTransitions.AUTH
    const targetFlow =
      (prompt(
        'Select targetFlow (see FlowName). Examples: "qb", "mypages":',
        params.targetFlow
      ) as FlowName) || FlowName.QUOTE_AND_BUY
    const targetProduct =
      (prompt(
        'Select targetProduct (see ProductName). Examples: "vehicle", "travel", "content":',
        params.targetProduct
      ) as ProductName) || ProductName.VEHICLE

    switchFSM({ ...params, targetStateValue, targetFlow, targetProduct })
  }

  return !IS_PRODUCTION && !IS_ACCEPTANCE ? (
    <DebugWrapper>
      <Button className={classes.btnDebug} onClick={switchClickHandler}>
        <Icon className={classes.icon} fontSize='large' title={label}>
          logo_dev
        </Icon>
        <Icon className={classes.icon} fontSize='large' title={label}>
          switch_access_shortcut
        </Icon>
      </Button>
    </DebugWrapper>
  ) : null
}

export default SwitchFlowDEVButton
