import { useContext, useEffect } from 'react'
import format from 'date-fns/format'
import {
  ErrorCode,
  FORMAT_DAY_MONTH_YEAR_DOT,
  FORMAT_YEAR_MONTH_DAY_HYPHEN,
  TRANSLATIONS as T,
} from '../../../constants'
import { Events, FSMCommonTransitions as FCT } from '../../../FSM/shared/constants'
import { MileageFormValues } from '../../../types/interface/form.interface'
import { removeAllSpaces } from '../../../service/util.service'
import { clearSellingChannel, setPageError } from '@dg-util'
import { TranslatedTextType, useTranslate } from '../../../hooks/shared'
import { PartnerName } from '../../../types/interface/quote.interface'
import { FSMStateType, ServiceSend } from '../../../types/interface/fsm.interface'
import { useFullStorageCleanup } from '@dg-util/storageClear'
import useReactQueryAuth from '../../../hooks/shared/useReactQueryAuth'
import useReactQueryQuote from '../../../hooks/shared/useReactQueryQuote'
import useReactQueryPolicy from '../../../hooks/shared/useReactQueryPolicy'
import { Context } from '@dg-shared/ContextProvider'

interface HookDone {
  loading: boolean
  signOut(): Promise<void>
  mileageSubmit(value: MileageFormValues): void
  getText(): {
    title: TranslatedTextType
    subtitle: TranslatedTextType
    description: TranslatedTextType | null
  }
}

//TODO: Define the type
const useLogic = (send: ServiceSend, currentState: FSMStateType): HookDone => {
  const { t } = useTranslate(T.OFFER_PURCHASED)
  const cleanup = useFullStorageCleanup()
  const { signOut, isSignOutError, isLoading: isSignOutLoading } = useReactQueryAuth()
  const { quote, isLoading: isQuoteLoading } = useReactQueryQuote()
  const { updateMileagePolicy, isLoading } = useReactQueryPolicy({ policiesQueryEnabled: false })
  const { context } = useContext(Context)
  const isContextLoading = !!context.isLoading

  const signOutHandler = async () => {
    clearSellingChannel()
    await signOut()
    await cleanup()
  }

  useEffect(() => {
    if (isSignOutError) {
      setPageError(send, ErrorCode.ERROR_CODE_QUOTE_GENERAL, currentState)
      return
    }
  }, [currentState, isSignOutError, send])

  const mileageSubmit = async ({ mileage, readDate }: MileageFormValues) => {
    const mileageFormatted = parseInt(removeAllSpaces(mileage), 10)
    const dateFormatted = format(new Date(readDate), FORMAT_YEAR_MONTH_DAY_HYPHEN)

    // Update Mileage OdometerReading and DateOdometerReading
    // this is a different from the policy update since it is
    // not returning the full policy data in response (more secured for the regular auth case)
    await updateMileagePolicy({
      OdometerReading: mileageFormatted,
      DateOdometerReading: dateFormatted,
    })
    send(Events.DONE)
  }

  const getText = () => {
    // CircleK has different date format
    const dateFormat =
      PARTNER_NAME === PartnerName.circlek
        ? FORMAT_YEAR_MONTH_DAY_HYPHEN
        : FORMAT_DAY_MONTH_YEAR_DOT
    const startDate: string = quote?.InsuredFrom
      ? format(new Date(quote.InsuredFrom), dateFormat)
      : ''
    const donePage = currentState[FCT.QB_PURCHASED as keyof FSMStateType] === FCT.QB_PURCHASED_DONE
    const failurePage =
      currentState[FCT.QB_PURCHASED as keyof FSMStateType] === FCT.QB_PURCHASED_FAILURE

    return {
      title: failurePage ? t('fail.title') : t('title'),
      subtitle: failurePage ? null : t('subtitle', { startDate }),
      description: donePage ? t('done.description') : null,
    }
  }

  return {
    loading: isLoading || isQuoteLoading || isSignOutLoading || isContextLoading,
    signOut: signOutHandler,
    mileageSubmit,
    getText,
  }
}

export default useLogic
