import React, { FC, useState } from 'react'
import { Card, CardContent, CardMedia, Divider, Hidden } from '@material-ui/core'
import { get } from 'lodash'

import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { FORM_ID } from '../../../constants'
import { OfferItemProps, PartnerName, ProductName } from '../../../types/interface/quote.interface'
import { CoverageDetailedInfo, CustomCardHeader, ProductWrapper, Typography } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import { exampleCarImg } from 'BrandedImages'
import useContentStyles from '../../../hooks/shared/useContentStyles'
import { getProductName } from '@dg-util'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FSMService } from '../../../FSM'
import { QuoteV2Type } from '../../../types/ApiV2'
import { UpdateFormStateDataType } from './useLogic'

interface ChangeItemProps extends OfferItemProps {
  updateFormState(data: UpdateFormStateDataType): void
  updateOfferHandler(values: Partial<QuoteV2Type>): void
  initialFormValues: Record<string, unknown>
  formOptionsValues: Record<string, Record<string, unknown>[]>
  submitDisabled: boolean
}

const useChangePageStyles = makeStyles((theme: Theme) =>
  createStyles({
    backgroundImage: {
      [theme.breakpoints.up('md')]: {
        ...theme.brand.assets.pageBackgrounds.review,
      },
    },
    divider: {
      width: '46%',
      opacity: 0.4,
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(0),
        width: '100%',
      },
    },
    cardHeadingWrapper: {
      padding: theme.spacing(3, 3, 0, 3),
    },
  })
)

const ChangeItem: FC<ChangeItemProps> = ({
  updateFormState,
  updateOfferHandler,
  itemData,
  initialFormValues,
  formOptionsValues,
  submitDisabled,
}) => {
  const { t } = useTranslate(T.OFFER_CHANGE)
  const productName = getProductName()
  const { send } = FSMService.flowService
  const addressText = [itemData.Address1, itemData.City].filter((str) => str).join(', ')

  const contentStylesPropsMap: Record<ProductName, { isImage: boolean }> = {
    [ProductName.TRAVEL]: { isImage: true },
    [ProductName.CONTENT]: { isImage: true },
    [ProductName.VEHICLE]: { isImage: false },
  }

  const { backgroundImage, divider, cardHeadingWrapper } = useChangePageStyles()

  const classes = useContentStyles(contentStylesPropsMap[productName])
  const [coverageInfoOpened, setCoverageInfoState] = useState<boolean>(false)

  const handleOfferUpdate = (values: Partial<QuoteV2Type>): void => {
    updateOfferHandler(values)
  }

  const coverageDetailedInfo =
    itemData.productName === ProductName.VEHICLE && itemData.CarPackage ? (
      <CoverageDetailedInfo
        send={send}
        state={FSMService.flowService.state.value}
        coverageType={itemData.CarPackage}
        onClose={() => setCoverageInfoState(false)}
        translationCondition={get(itemData, 'SuperCoverage', false) ? 'isSuper' : 'isNotSuper'}
      />
    ) : null

  const priceDetails = itemData.CalculatedPrices.taxAnnual || itemData.CalculatedPrices.priceAnnual

  return (
    <Card elevation={3} className={backgroundImage}>
      <CustomCardHeader
        title={t(`offer`)}
        priceMonthly={itemData.CalculatedPrices.priceMonthly}
        priceMonthlyText={t(`card.priceMonthly`)}
        priceDetailsText={t(`card.priceDetails`, { value: priceDetails })}
      />
      {itemData.productName === ProductName.VEHICLE && (
        <div className={cardHeadingWrapper}>
          <Typography testId='change-vehicle-model' type='h2' className={classes.cardTitle}>
            {`${itemData.ManufacuringYear} ${itemData.CarMake} ${itemData.CarModel}`}
          </Typography>
          <Typography testId='change-vehicle-reg-num' type='h3' className={classes.cardSubTitle}>
            {t(`registrationNum`, { regNum: itemData.MotorRegistrationNumber })}
          </Typography>
          <Divider className={divider} />
        </div>
      )}
      <CardContent className={classes.twoCol}>
        <section className={classes.colLeft}>
          <ProductWrapper
            products={[ProductName.TRAVEL]}
            partners={[PartnerName.instabank, PartnerName.nord]}
          >
            <Typography type='h2' className={classes.cardSubTitle}>
              {t('card.title')}
            </Typography>
            <Divider className={classes.divider} />
          </ProductWrapper>
          <ProductWrapper
            products={[ProductName.CONTENT]}
            partners={[PartnerName.instabank, PartnerName.nord]}
          >
            <Typography type='h2'>{t('card.title')}</Typography>
            <Typography testId='change-heading' type='h3'>
              {addressText}
            </Typography>
            <Divider className={classes.divider} />
          </ProductWrapper>
          {itemData.productName === ProductName.VEHICLE && (
            <>
              <ProductWrapper
                products={[ProductName.VEHICLE]}
                partners={[PartnerName.instabank, PartnerName.circlek]}
              >
                <section>
                  <CardMedia
                    component='img'
                    alt='Example Car'
                    image={exampleCarImg}
                    className={classes.itemImage}
                  />
                </section>
              </ProductWrapper>
              {coverageInfoOpened && coverageDetailedInfo}
            </>
          )}
          <div className={classes.changeFormWrapper}>
            <FormBuilder
              id={FORM_ID.CHANGE}
              isFormControllable
              submitHandler={handleOfferUpdate}
              setFormState={updateFormState}
              customLabelFunc={setCoverageInfoState}
              optional={!submitDisabled}
              initialValues={initialFormValues}
              options={formOptionsValues}
              submitText={t('form.submitText')}
              submitClass={classes.buttonSubmit}
            />
          </div>
        </section>

        <Hidden smDown>
          <div className={classes.colRight}>{coverageDetailedInfo}</div>
        </Hidden>
      </CardContent>
    </Card>
  )
}

export default ChangeItem
