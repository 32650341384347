import React, { FC, ChangeEvent } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { CircularProgress, CircularProgressProps } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      '&$checked': {
        color: theme.palette.primary.main,
      },
      display: 'flex',
      alignSelf: 'center',
    },
  })
)

interface MuiCustomSpinner extends CircularProgressProps {
  changeHandler?(event: ChangeEvent): void
}

const LoadingSpinner: FC<MuiCustomSpinner> = ({ ...props }) => {
  const classes = useStyles()

  return <CircularProgress classes={{ root: classes.root }} {...props} />
}

export default LoadingSpinner
