import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useCommonStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainerMainMobile: {
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'left',
        paddingBottom: theme.spacing(1),
      },
      [theme.breakpoints.up('md')]: {},
    },
    gridContainerMainDesktop: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
        paddingBottom: '6rem',
      },
    },
    gridItemMobile: {
      paddingRight: '10px',
      paddingLeft: '10px',
      [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {},
    },
    gridItemHeader: {
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 1,
    },
    gridItemBody: {
      paddingRight: '20px',
      paddingLeft: '20px',
      height: 'calc(100% - 285px)', //FIXME: Something is wrong with this - change it
      [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {},
    },
    gridItemOneColumn: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(4),
        marginLeft: theme.spacing(4),
        flexDirection: 'column',
        minWidth: '700px',
      },
    },
    typographyPageTitle: {
      fontSize: '26px',
      lineHeight: '32px',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
      },
    },
    pageTitleOneColumnLayout: {
      fontSize: '26px',
      fontWeight: 500,
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(5),
        fontSize: '24px',
      },
    },
    typographyMobileBodyTitle: {
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
    },
    actionWrapper: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {},
      [theme.breakpoints.up('sm')]: {
        flexGrow: 1,
        justifyContent: 'flex-end',
      },
    },
    typographyActionLinkAbbreviation: {
      textAlign: 'center',
      fontSize: '0.9rem',
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(4),
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(8),
      },
    },
    typographyTitleAbbreviation: {
      fontSize: '0.9rem',
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
      },
    },
    buttonAdditionalActionLink: {
      display: 'flex',
      justifyContent: 'center',
    },
    logoWrapper: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    logoImage: {
      [theme.breakpoints.up('sm')]: {
        alignSelf: 'center',
      },
    },
    customForm: {
      [theme.breakpoints.down('xs')]: {},
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        minWidth: '100%',
      },
      '&.auth': {
        [theme.breakpoints.up('md')]: {
          '& > button': {
            marginTop: theme.spacing(2),
          },
        },
        [theme.breakpoints.down('sm')]: {
          '& > button': {
            marginTop: theme.spacing(1),
          },
        },
      },
    },
    errorMessage: {
      marginBottom: theme.spacing(3),
    },
    inlineProgressBar: {
      marginTop: theme.spacing(3),
    },
    headerStyle: {
      [theme.breakpoints.up('md')]: {
        width: '100%',
        '& > div': {
          paddingLeft: 0,
          paddingRight: theme.spacing(1),
        },
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(3),
      },
    },
    compareContentContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    iframeStyle: {
      border: 'none',
      [theme.breakpoints.up('md')]: {
        width: '50%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '500px',
      },
    },
  })
)
