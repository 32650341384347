import React, { FC, useContext, useEffect, useState } from 'react'
import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import Loading from '../../qb/Loading/Page'
import { LDContainerV2FSM, LMBasicV3FSM, Typography, withHidden } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import useReactQueryPolicy from '../../../hooks/shared/useReactQueryPolicy'
import {
  filterPersonVehiclesByPartner,
  filterVehiclesWithoutPolicies,
  getProductName,
  normalizeMotorRegistrationNumber,
} from '@dg-util'
import FNOLMyPagesDEVButton from '@dg-shared/FNOLMyPagesDEVButton'
import { FlowName } from '../../../config'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import PoliciesListCards from '../../../components/shared/PoliciesListCards'
import { PolicyV2Type, VehicleV2Type } from '../../../types/ApiV2'
import { FSMCommonTransitions } from '../../../FSM/shared/constants'
import useReactQueryPerson from '../../../hooks/shared/useReactQueryPerson'
import { PartnerName, ProductName } from '../../../types/interface/quote.interface'
import { Context } from '@dg-shared/ContextProvider'
import {
  QUOTE_ID,
  SELECTED_POLICY_NUMBER,
  SELECTED_POLICY_VERSION,
  SELECTED_QUOTE_MOTOR_REGISTRATION_NUMBER,
} from '../../../constants'
import ProductSelect from '@dg-shared/ProductSelect'
import { endOfDay } from 'date-fns'
import { Grid } from '@material-ui/core'
import OfferItem from '../../qb/Offers/OfferItem'
import { switchFSM } from '@dg-util/productHandler'
import { FSMService } from '../../../FSM'
import NoPolicies from '@dg-shared/NoPolicies'

const MobileScreen = withHidden(LMBasicV3FSM, true)
const DesktopScreen = withHidden(LDContainerV2FSM, false)

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(2),
    },
    cardItem: {
      minHeight: theme.spacing(29),
    },
    mobileContentWrapper: {
      justifyContent: 'flex-start !important',
    },
  })
)

const PageList: FC<CommonPageProps> = ({ service }) => {
  const { t } = useTranslate(T.POLICIES)
  const classes = useStyles({})
  const { policies, isLoading } = useReactQueryPolicy()
  const { personAllVehicles, isLoading: isPersonVehiclesLoading } = useReactQueryPerson()
  const vehicles = filterPersonVehiclesByPartner(PARTNER_NAME as PartnerName, personAllVehicles)
  const vehiclesWithoutPolicies = filterVehiclesWithoutPolicies(vehicles, policies)
  const { setContext } = useContext(Context)
  const [productFilter, setProductFilter] = useState<ProductName>(getProductName())
  const [dateFilter] = useState<number>(endOfDay(new Date()).getTime())

  useEffect(() => {
    // Remove the current policy number, version, quoteId and registration number to avoid the wrong version fetching (Fetch current active policy without version)
    setContext({
      [SELECTED_POLICY_VERSION]: null,
      [SELECTED_POLICY_NUMBER]: null,
      [SELECTED_QUOTE_MOTOR_REGISTRATION_NUMBER]: null,
      [QUOTE_ID]: null,
    })
  }, [setContext])

  const title = t('title')
  const backBtnText = t(`logout`, {}, 'string')
  const subtitle = policies?.length > 1 && t('subtitle')

  const filteredPolicies = policies
    .filter((policy: PolicyV2Type) => policy.productName === productFilter)
    .filter((policy: PolicyV2Type) => {
      return new Date(policy.InsuredTo) > new Date(dateFilter)
    })

  const additionalOffersPerProduct: Record<ProductName, React.ReactNode> = {
    [ProductName.VEHICLE]: vehiclesWithoutPolicies.map((vehicle: VehicleV2Type) => (
      <Grid key={vehicle.MotorRegistrationNumber} item xs={12} md={4}>
        <OfferItem
          customClassName={classes.cardItem}
          vehicle={vehicle}
          translations={{
            regNum: t(`registrationNum`, { regNum: null }),
            vehicleCardTitle: t('vehicleCardTitle'),
            seePrice: t('seePrice'),
          }}
          showImage={false}
          handleClick={() => {
            const selectedValues = {
              [SELECTED_QUOTE_MOTOR_REGISTRATION_NUMBER]: normalizeMotorRegistrationNumber(
                vehicle.MotorRegistrationNumber
              ),
            }

            setContext(selectedValues)

            // Switch FSM to the selected product name QB flow Ownership state
            switchFSM({
              targetStateValue: FSMCommonTransitions.QB_OWNERSHIP,
              targetFlow: FlowName.QUOTE_AND_BUY,
              targetProduct: productFilter,
              FSMService: FSMService,
            })
          }}
        />
      </Grid>
    )),
    [ProductName.TRAVEL]: null,
    [ProductName.CONTENT]: null,
  }

  const additionalOffers = additionalOffersPerProduct[productFilter]

  const body = (
    <>
      <ProductSelect
        handleProductChange={(product: ProductName) => {
          setProductFilter(product)

          // Switch FSM to the selected product name
          switchFSM({
            targetStateValue: FSMCommonTransitions.POLICIES,
            targetFlow: FlowName.MY_PAGES,
            targetProduct: product,
            FSMService: FSMService,
          })
        }}
        selectedProduct={productFilter}
      />
      <NoPolicies policies={filteredPolicies} additionalOffers={additionalOffers} />
      <PoliciesListCards
        policies={filteredPolicies}
        service={service}
        additionalOffers={additionalOffers}
        plusButtonHandler={() => {
          const targetStateValueMap: Record<ProductName, FSMCommonTransitions> = {
            [ProductName.VEHICLE]: FSMCommonTransitions.QB_FIND,
            [ProductName.CONTENT]: FSMCommonTransitions.QB_FIND,
            [ProductName.TRAVEL]: FSMCommonTransitions.QB_REVIEW,
          }

          // Switch FSM to the selected product name and QB flow
          switchFSM({
            targetStateValue: targetStateValueMap[productFilter],
            targetFlow: FlowName.QUOTE_AND_BUY,
            targetProduct: productFilter,
            FSMService: FSMService,
          })
        }}
      />
    </>
  )

  if (isLoading || isPersonVehiclesLoading) {
    return <Loading />
  }

  return (
    <>
      <MobileScreen
        titleHeader={title}
        paragraphSubTitle={subtitle}
        breadcrumbs
        breadcrumbsBtnText={backBtnText}
        send={FSMService.flowService.send}
        customClass={classes.mobileContentWrapper}
      >
        <FNOLMyPagesDEVButton />
        {body}
      </MobileScreen>
      <DesktopScreen
        breadcrumbs
        breadcrumbsBtnText={backBtnText}
        send={FSMService.flowService.send}
      >
        <FNOLMyPagesDEVButton />
        <Typography type={'h1'} className={classes.title}>
          {title}
        </Typography>
        {subtitle && <Typography>{subtitle}</Typography>}
        {body}
      </DesktopScreen>
    </>
  )
}

export default PageList
