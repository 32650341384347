import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { TranslatedTextType } from '../../hooks/shared'
import { ThemeBrandColorsType } from 'BrandTheme/types'

type BrandColorType = keyof ThemeBrandColorsType
interface ButtonNext {
  buttonText: TranslatedTextType
  customClassName?: string
  disabled?: boolean
  outlined?: boolean
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  handleClick?(values?: unknown): void
  testId?: string
  color?: BrandColorType
  style?: React.CSSProperties
}

interface ButtonNextStyles {
  outlined: boolean
  color?: BrandColorType
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonNext: (props: ButtonNextStyles) => ({
      fontSize: '1rem',
      border: props.outlined ? `1px solid ${theme.palette.primary.main}` : 'none',
      boxShadow: props.outlined && 'none',
      [theme.breakpoints.up('md')]: {
        maxWidth: '345px',
      },
      textTransform: 'none',
    }),
    button: {
      justifyContent: 'left',
      margin: theme.spacing(2, 0, 2, 0),
      padding: theme.spacing(2),
      color: theme.brand.colors.textMain,
      backgroundColor: theme.palette.background.paper,
      ...theme.brand.assets.layout?.customFnolButtonStyle,
      '&:hover': {
        color: theme.brand.colors.clean,
        backgroundColor: theme.brand.colors.main,
        opacity: 1,
        ...theme.brand.assets.layout?.customFnolButtonStyle,
      },
    },
    buttonColor: (props: ButtonNextStyles) => ({
      color: theme.brand.colors[props.color],
    }),
  })
)

const FNOLButton: FC<ButtonNext> = ({
  buttonText,
  customClassName,
  disabled = false,
  outlined = false,
  startIcon,
  endIcon,
  handleClick,
  testId = 'FNOLButton',
  color,
  style,
}) => {
  const classes = useStyles({ outlined, color })

  return (
    <Button
      data-testid={testId}
      disabled={disabled}
      onClick={handleClick}
      variant='contained'
      size='medium'
      className={`${classes.buttonNext} ${classes.button} ${
        color ? classes.buttonColor : ''
      } ${customClassName}`}
      fullWidth
      startIcon={startIcon}
      endIcon={endIcon}
      style={{ textAlign: 'left', ...style }}
    >
      {buttonText}
    </Button>
  )
}

export default FNOLButton
