import React, { FC, FunctionComponent, ReactNode } from 'react'

interface FSMMatchProps {
  is: string | Record<string, string>
  component?: FunctionComponent
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FSMMatch: FC<FSMMatchProps> = ({ is, component, children, ...props }) => {
  if (children) {
    const childrenWithProps = React.Children.map(children, (child) => {
      // Checking isValidElement is the safe way and avoids a typescript
      // error too.
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { ...props }) as ReactNode
      }

      return child
    })

    return <>{childrenWithProps}</>
  }

  return component ? React.createElement(component, { ...props }) : null
}

export default FSMMatch
