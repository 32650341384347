import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Card } from '@material-ui/core'

import { TRANSLATIONS as T } from '../../../../constants/translations-constants'
import { InsurelyResultType } from '../../../../types/insurelyTypes'
import { Typography } from '@dg-shared'
import { useTranslate } from '../../../../hooks/shared'
import ComparePolicyCardComponent from 'components/ComparePolicyCardComponent'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        maxWidth: '345px',
      },
      ...theme.brand.assets.layout.card,
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3),
    },
  })
)

interface ComparePolicyCardProps {
  chooseOffer(offerId: string): void
  item: InsurelyResultType
}

const ComparePolicyCard: FC<ComparePolicyCardProps> = ({ chooseOffer, item }) => {
  const { t } = useTranslate(T.OFFER_COMPARE)
  const classes = useStyles()

  return (
    <Card
      onClick={() => {
        chooseOffer(item.insurance.externalId)
      }}
      className={classes.card}
      data-testid={`test-compare-policy-card-${item.insurance.externalId}`}
    >
      <section className={classes.header}>
        <ComparePolicyCardComponent item={item} />
      </section>
      {item.insurance.renewalDate ? (
        <Typography>{t('date', { paidUntil: item.insurance.renewalDate })}</Typography>
      ) : null}
    </Card>
  )
}

export default ComparePolicyCard
