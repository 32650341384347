import React, { FC, useContext, useState } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, Dialog, DialogContent, Grid, Switch } from '@material-ui/core'
import FSMBtnNext from './FSMBtnNext'
import { Typography } from '@dg-shared'
import { useTranslate } from '../../hooks/shared'
import BrandIconShared from './BrandIconShared'
import { IconType } from '../../types/interface/icon.interface'
import { Context } from './ContextProvider'
import { TRANSLATIONS as T } from '../../constants'
import { getLocalizedPartnerName } from '@dg-util'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignContent: 'start',
      position: 'fixed',
      bottom: 20,
      left: 0,
      right: 0,
      margin: 20,
      width: 'auto',
      zIndex: 11,
    },
    content: {
      padding: theme.spacing(3),
    },
    dialogContent: {
      padding: theme.spacing(3, 0),
      display: 'flex',
      alignItems: 'center',
      gap: '36px',
      borderBottom: `1px solid ${theme.brand.colors.shadow}14`,
      [theme.breakpoints.down('sm')]: {
        gap: '8px',
        padding: theme.spacing(1.5, 0),
        position: 'relative',
      },
    },
    dialogContainer: {
      padding: theme.spacing(2.5, 2.5),
      overflowX: 'hidden',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
      },
    },
    title: {
      textAlign: 'center',
      fontSize: '26px',
      fontWeight: 500,
    },
    backBtn: {
      alignSelf: 'start',
      marginBottom: theme.spacing(4),
    },
    nextBtn: {
      maxWidth: '311px',
      height: '48px',
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
      ...theme.brand.assets.layout?.nextBtn,
      ...theme.brand.assets.layout?.cookiesCancelBtn,
    },
    button: {
      margin: 0,
      maxWidth: '311px',
      height: '48px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
      ...theme.brand.assets.layout?.ctaOverride,
      ...theme.brand.assets.layout?.cookiesAcceptBtn,
    },
    buttonsWrapper: {
      display: 'flex',
      justifyContent: 'end',
      marginTop: 24,
      gap: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    closeIcon: {
      color: theme.brand.colors.secondary,
      position: 'absolute',
      top: 10,
      right: 10,
      cursor: 'pointer',
      fontWeight: 100,
    },
    switch: {
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        top: 5,
        right: 0,
      },
    },
    bannerBg: {
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 10,
      background: `${theme.brand.colors.shadow}75`,
      backdropFilter: 'blur(2px)',
    },
  })
)

export type GDPRDataType = {
  marketing: boolean
  cookies: boolean
  statistic: boolean
}

type PartialContextType = {
  acceptCookies?: GDPRDataType
}

const defaultSettings = {
  marketing: true,
  cookies: false,
  statistic: true,
}

const AcceptCookies: FC = ({}) => {
  const { t } = useTranslate(T.COOKIES_BANNER)
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { context, setContext } = useContext(Context)

  const { acceptCookies = defaultSettings }: PartialContextType = context
  const [marketingState, setMarketingState] = useState<boolean>(acceptCookies.marketing)

  const showPopup = () => setOpen(true)
  const close = () => setOpen(false)

  const handleChange = (marketingState: boolean) => {
    setMarketingState(marketingState)
  }

  const handleAccept = () => {
    setContext({ acceptCookies: { ...acceptCookies, marketing: marketingState, cookies: true } })
  }
  const isAccept = acceptCookies.cookies
  const partnerName = getLocalizedPartnerName()

  return (
    <>
      {!isAccept && (
        <>
          <div className={classes.bannerBg}></div>
          <Grid container className={classes.container}>
            <Card>
              <CardContent id='banner' className={classes.content}>
                <Typography type='h1'>{t('mainTitle')}</Typography>
                <Typography>{t('decription', { partnerName })}</Typography>
                <div className={classes.buttonsWrapper}>
                  <FSMBtnNext
                    testId='show-popup-gtm'
                    handleClick={showPopup}
                    buttonText={t('buttons.description')}
                    customClassName={classes.nextBtn}
                    outlined
                  />
                  <FSMBtnNext
                    buttonText={t('buttons.accept')}
                    handleClick={() => {
                      handleAccept()
                      window.location.reload()
                    }}
                    customClassName={classes.button}
                    testId='accept-cookies'
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
      <Dialog onClose={close} open={open} maxWidth='lg'>
        <DialogContent id='banner-settings' className={classes.dialogContainer}>
          <BrandIconShared
            type={IconType.ERROR}
            customClass={classes.closeIcon}
            onClick={close}
            action
          />
          <Typography type='h3'>{t('details.title')}</Typography>
          <div className={classes.dialogContent}>
            <div>
              <Typography type='h3'>{t('details.cookiesTitle')}</Typography>
              <Typography>{t('details.cookiesDescription')}</Typography>
            </div>
            <Switch
              className={classes.switch}
              //this switch is not changing and it should be true by Design
              checked={true}
              disabled={true}
              id='accept-cookies-switch'
            />
          </div>
          <div className={classes.dialogContent}>
            <div>
              <Typography type='h3'>{t('details.statisticTitle')}</Typography>
              <Typography>{t('details.statisticDescription', { partnerName })}</Typography>
            </div>
            <Switch
              className={classes.switch}
              id='accept-statistic-switch'
              //this switch is not changing and it should be true by Design
              checked={true}
              disabled={true}
            />
          </div>
          <div className={classes.dialogContent}>
            <div>
              <Typography type='h3'>{t('details.marketingTitle')}</Typography>
              <Typography>{t('details.marketingDescription', { partnerName })}</Typography>
            </div>
            <Switch
              className={classes.switch}
              checked={marketingState}
              onChange={() => handleChange(!marketingState)}
              id='accept-marketing-switch'
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
          <div className={classes.buttonsWrapper}>
            <FSMBtnNext
              handleClick={() => {
                close()
              }}
              buttonText={t('popupButtons.cancel')}
              customClassName={classes.nextBtn}
              outlined
              testId='cancel-cookies-popup'
            />
            <FSMBtnNext
              buttonText={t('popupButtons.accept')}
              handleClick={() => {
                handleAccept()
                window.location.reload()
              }}
              customClassName={classes.button}
              testId='accept-cookies-popup'
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default AcceptCookies
