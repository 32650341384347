import React, { FC } from 'react'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import LandingAboutBodyVehicle from 'components/LandingAboutBody.vehicle'
import LandingPageWrapper from '@dg-shared/LandingPageWrapper'
import { getDataComponentName } from '@dg-util'

const AboutPage: FC<CommonPageProps> = ({}) => {
  return (
    <LandingPageWrapper data-componentname={getDataComponentName(__filename)}>
      <LandingAboutBodyVehicle />
    </LandingPageWrapper>
  )
}

export default AboutPage
