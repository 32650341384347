import { Events, FSMCommonTransitions as FSMT } from '../FSM/shared/constants'

export const FNOL_COLLISION_ANSWERS_MAP = {
  howMany: 1,
  animal: 2,
  object: 3,
  building: 4,
  pedestrianOrCyclist: 5,
  other: 6,
}

export const FNOL_COLLISION_START_PAGE = [
  {
    eventName: Events.FNOL_COLLISION_HOW_MANY,
    label: 'mainPageButtons.howMany',
    value: FNOL_COLLISION_ANSWERS_MAP.howMany,
  },
  {
    eventName: Events.FNOL_COLLISION_ANIMAL,
    label: 'mainPageButtons.animal',
    value: FNOL_COLLISION_ANSWERS_MAP.animal,
  },
  {
    eventName: Events.FNOL_COLLISION_OBJECT,
    label: 'mainPageButtons.object',
    value: FNOL_COLLISION_ANSWERS_MAP.object,
  },
  {
    eventName: Events.FNOL_COLLISION_INJURED,
    label: 'mainPageButtons.building',
    value: FNOL_COLLISION_ANSWERS_MAP.building,
  },
  {
    eventName: Events.FNOL_COLLISION_INJURED,
    label: 'mainPageButtons.pedestrianOrCyclist',
    value: FNOL_COLLISION_ANSWERS_MAP.pedestrianOrCyclist,
  },
  {
    eventName: Events.FNOL_COLLISION_INJURED,
    label: 'mainPageButtons.other',
    value: FNOL_COLLISION_ANSWERS_MAP.other,
  },
]

export const FNOL_OTHER_CAUSE_TYPE = [
  {
    name: FSMT.FNOL_OTHER_VANDALISM,
    translationKey: 'vandalism',
    claimTypeID: 10,
    causeTypeID: 1,
  },
  {
    name: FSMT.FNOL_OTHER_MACHINE_DAMAGE,
    translationKey: 'machineDamage',
    claimTypeID: 92,
    causeTypeID: 2,
  },
  {
    name: FSMT.FNOL_OTHER_ERROR_FILLING,
    translationKey: 'errorFilling',
    claimTypeID: 13,
    causeTypeID: 3,
  },
  {
    name: FSMT.FNOL_OTHER_LOST_KEY,
    translationKey: 'lostKey',
    claimTypeID: 19,
    causeTypeID: 4,
  },
  {
    name: FSMT.FNOL_OTHER_LEGAL_AID,
    translationKey: 'legalAid',
    claimTypeID: 164,
    causeTypeID: 5,
  },
  {
    name: FSMT.FNOL_OTHER_FIRE,
    translationKey: 'fire',
    claimTypeID: 4,
    causeTypeID: 6,
  },
]
