import React, { FC, useEffect } from 'react'

import {
  FNOL_OTHER_REASON_FOR_FIRE_CAUSE_TYPE_IDS,
  QUESTIONS,
  TRANSLATIONS as T,
} from '../../../constants'
import { Events } from '../../../FSM/shared/constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Typography, FNOLPageWrapper, FNOLButton } from '@dg-shared'
import { useTranslate, useClaim } from '../../../hooks/shared'
import { useFNOLStyles } from '../shared/styles/useStyles'

const PageFireCause: FC<CommonPageProps> = ({ service, currentState }) => {
  const { t } = useTranslate(T.FNOL_OTHER_FIRE)
  const FNOLClasses = useFNOLStyles({})
  const { handleAnswerClick, clearAnswer } = useClaim(service, currentState)

  useEffect(() => {
    clearAnswer(QUESTIONS.REASON_FOR_FIRE)
  }, [clearAnswer])

  const causeTypeSelect = (translationKey: string, causeTypeID: number) => (
    <FNOLButton
      testId={translationKey}
      key={translationKey}
      handleClick={() => handleAnswerClick(QUESTIONS.REASON_FOR_FIRE, causeTypeID, Events.NEXT)}
      buttonText={t(`button.${translationKey}`)}
    />
  )

  const CauseTypesList = () => (
    <>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <Typography testId='subtitle' html={true} className={FNOLClasses.description}>
        {t('subtitle')}
      </Typography>
      {Object.entries(FNOL_OTHER_REASON_FOR_FIRE_CAUSE_TYPE_IDS).map(
        ([translationKey, causeTypeId]) => causeTypeSelect(translationKey, causeTypeId)
      )}
    </>
  )

  return (
    <FNOLPageWrapper breadcrumbsStepPercentage={50} breadcrumbsBtnText={t(`back`)}>
      <CauseTypesList />
    </FNOLPageWrapper>
  )
}

export default PageFireCause
