import { useState, useEffect, useCallback, useContext } from 'react'
import { useSelector } from '@xstate/react'

import CONFIG from '../../../config'
import { Events } from '../../../FSM/shared/constants'
import { QUOTE_ID, SELECTED_QUOTE_MOTOR_REGISTRATION_NUMBER } from '../../../constants'
import { FlowServiceType } from '../../../types/interface/fsm.interface'
import { normalizeMotorRegistrationNumber } from '@dg-util'
import BrandConfig from 'BrandConfig'
import useReactQueryAuth from '../../../hooks/shared/useReactQueryAuth'
import { AuthRegularV2InputType } from '../../../types/ApiV2'
import apiV2 from '../../../service/apiV2.service'
import { Context } from '@dg-shared/ContextProvider'
import { UserAuthInput } from '../../../types'
import messagesQueue from '../../../service/messagesQueue'
import { ProductName } from '../../../types/interface/quote.interface'

interface HookAuth {
  loading: boolean
  errorType: string
  errorVipps: boolean
  vippsLink: string
  authHandler(data: UserAuthInput): void
  isReCaptchaReady: boolean
}

const useLogic = (service: FlowServiceType): HookAuth => {
  const [loading, setLoading] = useState<boolean>(true)
  const [errorVipps, setErrorVipps] = useState<boolean>(false)
  const [vippsLink, setVippsLink] = useState<string>('')
  const stateEventType = useSelector(service, (state) => state.event.type)
  const [errorType, setErrorType] = useState<string>(null)
  const [reCaptchaToken, setReCaptchaToken] = useState<string>('')

  // Check current FSM state event type for errorType
  useEffect(() => {
    // Check error events in messages queue
    const messageQueueAuthError = messagesQueue.getMessage(Events.AUTH_ERROR)
    const messageQueueSessionError = messagesQueue.getMessage(Events.SESSION_EXPIRED)

    if (messageQueueAuthError || messageQueueSessionError) {
      // Update eventType for correct error handling
      setErrorType(messageQueueAuthError?.id || messageQueueSessionError?.id)
    }

    // Check error events in stateEventType
    if (
      [Events.WRONG_PRODUCT_ID, Events.AUTH_ERROR, Events.SESSION_EXPIRED].includes(
        stateEventType as Events
      )
    ) {
      setErrorType(stateEventType)
    }

    // Cleanup on navigation
    if ([Events.NEXT, Events.BACK].includes(stateEventType as Events)) {
      setErrorType(null)
    }
  }, [stateEventType])

  const { VIPPS_AUTH } = BrandConfig
  const { authRegular, isAuthRegularLoading } = useReactQueryAuth()
  const { setContext } = useContext(Context)

  useEffect(() => {
    setLoading(isAuthRegularLoading)
  }, [isAuthRegularLoading])

  // Recaptcha token handling
  useEffect(() => {
    const executeRecaptcha = async () => {
      if (window.grecaptcha?.ready) {
        clearInterval(intervalId)

        window.grecaptcha.ready(async () => {
          try {
            const reCaptchaToken = (await window.grecaptcha.execute(CONFIG.RECAPTCHA_SITE_KEY, {
              action: 'submit',
            })) as string
            setReCaptchaToken(reCaptchaToken)
          } catch (error) {
            console.error('ReCaptcha execution error', error)

            // Reset execution Recaptcha
            intervalId = setInterval(executeRecaptcha, 100)
          }
        }, undefined)
      }
    }

    let intervalId = setInterval(executeRecaptcha, 100)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const authHandler = useCallback(
    async (userInput: AuthRegularV2InputType) => {
      setLoading(true)

      await authRegular({ ...userInput, reCaptchaToken })

      if (userInput.regNum) {
        setContext({
          [SELECTED_QUOTE_MOTOR_REGISTRATION_NUMBER]: normalizeMotorRegistrationNumber(
            userInput.regNum
          ),
        })
      }

      setLoading(false)
      service.send(Events.NEXT)
    },
    [authRegular, service, setContext, reCaptchaToken]
  )

  const getVipps = async () => {
    const { vLink, errVipps } = await apiV2.getVippsLink()
    const error = !!errVipps

    return error ? setErrorVipps(error) : setVippsLink(vLink)
  }

  useEffect(() => {
    if (VIPPS_AUTH) {
      getVipps()
    }
  }, [VIPPS_AUTH])

  // Reset previously selected values
  useEffect(() => {
    if (ProductName.TRAVEL) {
      const resetedValues = {
        [SELECTED_QUOTE_MOTOR_REGISTRATION_NUMBER]: '',
        [QUOTE_ID]: '',
      }
      setContext(resetedValues)
    }
  }, [setContext])

  return {
    errorType,
    loading,
    errorVipps,
    vippsLink,
    authHandler,
    isReCaptchaReady: !!reCaptchaToken,
  }
}

export default useLogic
