import React, { FC } from 'react'
import { endOfDay, startOfDecade } from 'date-fns'

import { FORM_ID, TRANSLATIONS as T } from '../../../../../constants'
import { Typography, FNOLPageWrapper } from '@dg-shared'
import { useTranslate } from '../../../../../hooks/shared'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import { CommonPageProps } from '../../../../../types/interface/fsm.interface'
import useLogic, { DateFormValues } from './useLogic'
import useCommonLegalAidStyles from '../commonStyles'

const Page: FC<CommonPageProps> = ({ service, currentState }) => {
  const classes = useCommonLegalAidStyles()
  const { t } = useTranslate(`${T.FNOL_OTHER_LEGAL_AID}.date`)
  const { selectedDataFields, handleSubmit, updateForm } = useLogic(service, currentState)

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`back`)} breadcrumbsStepPercentage={50}>
      <Typography type='h1'>{t('title')}</Typography>
      <Typography>{t('subtitle')}</Typography>
      <section className={classes.formContainer}>
        <FormBuilder
          id={FORM_ID.DATE}
          submitHandler={handleSubmit}
          submitClass={classes.submitBtn}
          classes={classes.form}
          filledFieldsData={selectedDataFields}
          isFormControllable
          validationArgs={{
            min: startOfDecade(new Date()),
            max: endOfDay(new Date()),
          }}
          setFormState={({ values }) => updateForm(values as DateFormValues)}
        />
      </section>
    </FNOLPageWrapper>
  )
}

export default Page
