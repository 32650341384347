import { Events, FSMCommonTransitions } from '../../constants'
import { get, has } from 'lodash'
import { ErrorCode, ERROR_QUOTE_CONSTRAINTS, ERROR_QUOTE_VALIDATION } from '../../../../constants'
import { BaseActionObject, StatesConfig } from 'xstate'
import sharedFSMconfig, { ContextType, MachineConfigType, StateSchemaType } from '../../config'
import { mergeDeep } from '../../../../service/util.service'
import guards from '../../guards'
import actions from '../../actions'
import { AuthMethod } from '../../../../constants/auth-constants'
import { isCriticalError } from '@dg-util'

const {
  AUTH,
  AUTH_CHOICE,
  AUTH_REGULAR,
  AUTH_AGENT,
  QB_EXTRA_OFFERS,
  QB_EXTRA_OFFERS_LIST,
  QB_EXTRA_FIND_CUSTOM,
  QB_EXTRA_FIND_EMPTY,
  QB_REVIEW,
  QB_VERIFY,
  QB_OWNER,
  QB_FIND,
  INSURELY,
  INSURELY_COMPARARISON_LIST,
  INSURELY_COMPARE,
  INSURELY_COMPARE_INIT,
  QB_OWNERSHIP,
  QB_CHANGE,
  QB_PURCHASED,
  QB_PURCHASED_FAILURE,
  QB_PURCHASED_MILEAGE,
  QB_PURCHASED_DONE,
  HISTORY,
  ERROR,
  LANDING_PAGE,
  LANDING_PAGE_QUESTIONS_AND_ANSWERS,
  LANDING_PAGE_ABOUT,
} = FSMCommonTransitions

export const fsmQBAuthFlow = (FLOW_MACHINE_ID: string) => ({
  [AUTH]: {
    initial: AUTH_CHOICE,
    states: {
      [AUTH_CHOICE]: {
        on: {
          //TODO: We don't have offers page with FSM
          [Events.CUSTOM_AUTH_SUCCESS]: {
            target: `#${FLOW_MACHINE_ID}.${QB_EXTRA_OFFERS}`,
            actions: ['setUserAuthorized', 'setVippsAuth'],
          },
          [Events.CUSTOM_AUTH_FAILURE]: {},
          [Events.NEXT]: AUTH_REGULAR,
          [Events.AGENT_AUTH]: AUTH_AGENT,
          [Events.LANDING_PAGE]: {
            target: `#${FLOW_MACHINE_ID}.${LANDING_PAGE}`,
            actions: 'setBackPath',
          },
        },
      },
      [AUTH_REGULAR]: {
        on: {
          [Events.NEXT]: {
            target: `#${FLOW_MACHINE_ID}.${QB_FIND}`,
            actions: ['setUserAuthorized', 'setRegularAuth'],
          },
          [Events.BACK]: AUTH_CHOICE,
          [Events.AUTH_ERROR]: AUTH_REGULAR,
        },
      },
      [AUTH_AGENT]: {
        on: {
          [Events.NEXT]: {
            target: `#${FLOW_MACHINE_ID}.${QB_FIND}`,
            actions: ['setUserAuthorized', 'setAgentAuth'],
          },
          [Events.BACK]: {
            actions: ['redirectToAgentPortal'],
          },
          [Events.AUTH_ERROR]: AUTH_AGENT,
        },
      },
    },
  },
})

export const fsmQBFlow = (
  FLOW_MACHINE_ID: string
): StatesConfig<ContextType, StateSchemaType, BaseActionObject> => ({
  [LANDING_PAGE]: {
    initial: LANDING_PAGE_ABOUT,
    states: {
      [LANDING_PAGE_ABOUT]: {
        on: {
          [Events.LANDING_PAGE_QUESTIONS_AND_ANSWERS]: LANDING_PAGE_QUESTIONS_AND_ANSWERS,
          [Events.AUTH]: `#${FLOW_MACHINE_ID}.${AUTH}`,
          [Events.BACK]: [
            {
              target: `#${FLOW_MACHINE_ID}.${QB_OWNERSHIP}.${QB_REVIEW}`,
              cond: (ctx) =>
                has(ctx.backPath, QB_OWNERSHIP) && get(ctx.backPath, QB_OWNERSHIP) == QB_REVIEW,
            },
            {
              target: `#${FLOW_MACHINE_ID}.${QB_CHANGE}`,
              cond: (ctx) => ctx.backPath == QB_CHANGE,
            },
            {
              target: `#${FLOW_MACHINE_ID}.${AUTH}`,
            },
          ],
        },
      },
      [LANDING_PAGE_QUESTIONS_AND_ANSWERS]: {
        on: {
          [Events.LANDING_PAGE_ABOUT]: LANDING_PAGE_ABOUT,
          [Events.AUTH]: `#${FLOW_MACHINE_ID}.${AUTH}`,
        },
      },
    },
  },
  [QB_FIND]: {
    on: {
      [Events.NEXT]: {
        target: `#${FLOW_MACHINE_ID}.${QB_OWNERSHIP}`,
        cond: 'isAuthorized',
      },
      [Events.BACK]: [
        { cond: 'isAuthAgent', actions: ['signOut'] },
        { target: `#${FLOW_MACHINE_ID}.${AUTH}`, actions: 'signOut' },
      ],
    },
  },
  [QB_EXTRA_OFFERS]: {
    initial: QB_EXTRA_OFFERS_LIST,
    states: {
      [QB_EXTRA_OFFERS_LIST]: {
        on: {
          [Events.NEXT]: `#${FLOW_MACHINE_ID}.${QB_OWNERSHIP}`,
          [Events.BACK]: { target: `#${FLOW_MACHINE_ID}.${AUTH}`, actions: 'signOut' },
          [Events.FIND_CUSTOM]: QB_EXTRA_FIND_CUSTOM,
          [Events.EMPTY]: QB_EXTRA_FIND_EMPTY,
        },
      },
      [QB_EXTRA_FIND_CUSTOM]: {
        on: {
          [Events.NEXT]: `#${FLOW_MACHINE_ID}.${QB_OWNERSHIP}`,
          [Events.BACK]: QB_EXTRA_OFFERS_LIST,
        },
      },
      [QB_EXTRA_FIND_EMPTY]: {
        on: {
          [Events.NEXT]: `#${FLOW_MACHINE_ID}.${QB_OWNERSHIP}`,
          [Events.BACK]: { target: `#${FLOW_MACHINE_ID}.${AUTH}`, actions: 'signOut' },
        },
      },
      [HISTORY]: {
        type: 'history',
      },
    },
  },
  [QB_OWNERSHIP]: {
    initial: QB_REVIEW,
    states: {
      [QB_REVIEW]: {
        on: {
          [Events.BACK]: [
            {
              target: `#${FLOW_MACHINE_ID}.${QB_EXTRA_OFFERS}.${HISTORY}`,
              cond: 'isVippsAuth',
            },
            {
              target: `#${FLOW_MACHINE_ID}.${QB_FIND}`,
            },
          ],
          [Events.NEXT]: [
            {
              target: `#${FLOW_MACHINE_ID}.${QB_VERIFY}`,
              cond: 'isVerify',
            },
            {
              target: QB_OWNER,
            },
          ],
          [Events.CHANGE]: `#${FLOW_MACHINE_ID}.${QB_CHANGE}`,
          [Events.WRONG_PRODUCT_ID]: [
            // For error from EXTRA_OFFERS_LIST page
            {
              target: `#${FLOW_MACHINE_ID}.${QB_EXTRA_OFFERS}.${HISTORY}`,
              cond: (ctx) =>
                has(ctx.backPath, QB_EXTRA_OFFERS) &&
                get(ctx.backPath, QB_EXTRA_OFFERS) === QB_EXTRA_OFFERS_LIST,
            },
            // For error from Find page
            {
              target: `#${FLOW_MACHINE_ID}.${QB_FIND}`,
            },
          ],
          [Events.START_COMPARE]: `#${FLOW_MACHINE_ID}.${INSURELY}`,
          [Events.LANDING_PAGE]: {
            target: `#${FLOW_MACHINE_ID}.${LANDING_PAGE}`,
            actions: 'setBackPath',
          },
        },
      },
      [QB_OWNER]: {
        on: {
          [Events.NEXT]: `#${FLOW_MACHINE_ID}.${QB_VERIFY}`,
          [Events.BACK]: QB_REVIEW,
        },
      },
      [HISTORY]: {
        type: 'history',
      },
    },
  },
  [QB_CHANGE]: {
    on: {
      [Events.BACK]: QB_OWNERSHIP,
      [Events.NEXT]: QB_OWNERSHIP,
      [Events.LANDING_PAGE]: {
        target: `#${FLOW_MACHINE_ID}.${LANDING_PAGE}`,
        actions: 'setBackPath',
      },
    },
  },
  [QB_VERIFY]: {
    on: {
      [Events.BACK]: `${QB_OWNERSHIP}.${HISTORY}`,
      [Events.PAYMENT_SUCCESS]: QB_PURCHASED,
      [Events.PAYMENT_FAILURE]: `${QB_PURCHASED}.${QB_PURCHASED_FAILURE}`,
      [Events.PAYMENT_CANCEL]: QB_VERIFY,
    },
  },
  [QB_PURCHASED]: {
    initial: QB_PURCHASED_MILEAGE,
    states: {
      [QB_PURCHASED_MILEAGE]: {
        on: {
          [Events.DONE]: QB_PURCHASED_DONE,
        },
      },
      [QB_PURCHASED_DONE]: {
        type: 'final',
        on: {
          [Events.BACK]: {
            target: `#${FLOW_MACHINE_ID}.${AUTH}`,
            actions: ['signOut'],
          },
          [Events.LANDING_PAGE]: {
            target: `#${FLOW_MACHINE_ID}.${LANDING_PAGE}`,
            actions: 'setBackPath',
          },
        },
      },
      [QB_PURCHASED_FAILURE]: {
        type: 'final',
        on: {
          [Events.BACK]: {
            target: `#${FLOW_MACHINE_ID}.${AUTH}`,
            actions: ['signOut'],
          },
        },
      },
      [HISTORY]: {
        type: 'history',
      },
    },
  },
  [INSURELY]: {
    initial: INSURELY_COMPARE_INIT,
    states: {
      [INSURELY_COMPARE_INIT]: {
        on: {
          [Events.BACK]: `#${FLOW_MACHINE_ID}.${QB_OWNERSHIP}`,
          [Events.COMPARE]: INSURELY_COMPARE,
          [Events.COMPARE_LIST]: INSURELY_COMPARARISON_LIST,
          [Events.INSURELY_NO_OFFERS_FOUND]: `#${FLOW_MACHINE_ID}.${QB_OWNERSHIP}`,
        },
      },
      [INSURELY_COMPARARISON_LIST]: {
        on: {
          [Events.NEXT]: INSURELY_COMPARE,
          [Events.BACK]: `#${FLOW_MACHINE_ID}.${QB_OWNERSHIP}.${HISTORY}`,
          [Events.INSURELY_NO_OFFERS_FOUND]: `#${FLOW_MACHINE_ID}.${QB_OWNERSHIP}`,
        },
      },
      [INSURELY_COMPARE]: {
        on: {
          [Events.NEXT]: `#${FLOW_MACHINE_ID}.${QB_VERIFY}`,
          [Events.BACK]: `#${FLOW_MACHINE_ID}.${QB_OWNERSHIP}.${HISTORY}`,
          [Events.INSURELY_NO_OFFERS_FOUND]: `#${FLOW_MACHINE_ID}.${QB_OWNERSHIP}`,
          [Events.LANDING_PAGE]: {
            target: `#${FLOW_MACHINE_ID}.${LANDING_PAGE}`,
            actions: 'setBackPath',
          },
        },
      },
    },
  },
  [ERROR]: {
    on: {
      [Events.BACK]: [
        {
          target: `#${FLOW_MACHINE_ID}.${AUTH}`,
          actions: ['signOut'],
          cond: (ctx, type, state) => {
            const errorType = state.state.event?.errorType

            const isCriticalErrorType = [
              ERROR_QUOTE_CONSTRAINTS,
              ERROR_QUOTE_VALIDATION,
              ErrorCode.ERROR_CODE_NO_QUOTE,
            ].includes(errorType)
            const isCriticalErrorCode = isCriticalError(errorType?.code)

            return isCriticalErrorType || isCriticalErrorCode
          },
        },
        {
          target: `${QB_FIND}`,
          cond: (ctx) =>
            (has(ctx.backPath, QB_EXTRA_OFFERS) &&
              get(ctx.backPath, QB_EXTRA_OFFERS) === QB_EXTRA_OFFERS_LIST) ||
            (has(ctx.backPath, QB_OWNERSHIP) &&
              get(ctx.backPath, QB_OWNERSHIP) === QB_REVIEW &&
              ctx.authMethod !== AuthMethod.VIPPS),
        },
        {
          target: QB_CHANGE,
          cond: (ctx) => has(ctx.backPath, QB_CHANGE) || ctx.backPath === QB_CHANGE,
        },
        {
          target: `${QB_EXTRA_OFFERS}.${HISTORY}`,
          cond: (ctx) =>
            has(ctx.backPath, QB_OWNERSHIP) && get(ctx.backPath, QB_OWNERSHIP) === QB_REVIEW,
        },
        {
          target: `${QB_OWNERSHIP}.${HISTORY}`,
          cond: (ctx) => has(ctx.backPath, QB_OWNERSHIP),
        },
        {
          target: `${QB_VERIFY}`,
          cond: (ctx) => ctx.backPath === QB_VERIFY,
        },
        {
          target: `${QB_PURCHASED}.${HISTORY}`,
          cond: (ctx) => has(ctx.backPath, QB_PURCHASED),
        },
        {
          target: `${QB_OWNERSHIP}.${QB_REVIEW}`,
          cond: (ctx) => has(ctx.backPath, INSURELY),
        },
        // Default fallback back navigation to the Auth and Sign-out
        // It has higher priority in MyPages than MyPages Error transition since
        // It is merged deeply first
        {
          target: `#${FLOW_MACHINE_ID}.${AUTH}`,
          actions: ['signOut'],
        },
      ],
    },
  },
})

const FLOW_MACHINE_ID = 'QBFlowMachineVehicle'

const FSMConfig: MachineConfigType = {
  id: FLOW_MACHINE_ID,
  initial: AUTH,
  context: {
    authorized: false,
    authMethod: '',
  },
  states: {
    ...fsmQBAuthFlow(FLOW_MACHINE_ID),
    ...fsmQBFlow(FLOW_MACHINE_ID),
  },
}

const FSMConfigVehicleNO = mergeDeep(FSMConfig, sharedFSMconfig)
const FSMOptionsVehicleNO = { guards, actions }

export { FSMConfigVehicleNO, FSMOptionsVehicleNO }
