import React, { FC, useEffect } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import { FSMBtnNext, LDCardTwoCol, LMBgHeader, Typography, withHidden } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import { logoMax, logoMin } from 'BrandedImages'
import StandardsButton from '@dg-shared/StandardsButton'
import FNOLDevButton from '@dg-shared/FNOLDevButton'
import useLogic from '../Review/useLogic'
import Loading from '../Loading/Page'
import ContactCustomerSwitch from '@dg-shared/ContactCustomerSwitch'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      justifyContent: 'space-between',
      height: '55%',
    },
    title: {
      lineHeight: '1.25rem',
      marginBottom: theme.spacing(2),
    },
    subtitle: {
      marginBottom: theme.spacing(1),
    },
    disclaimer: {
      marginBottom: theme.spacing(0.5),
      textAlign: 'center',
      '& a': {
        textDecoration: 'underline',
        color: 'inherit',
      },
    },

    buttonsWithText: {},
    contactCustomerSwitchWrapperClass: {
      textAlign: 'left',
    },
  })
)

const MobileScreen = withHidden(LMBgHeader, true)
const DesktopScreen = withHidden(LDCardTwoCol, false)

window.grecaptcha = (window as Window).grecaptcha || {}

const CompareDecision: FC<CommonPageProps> = ({ service, currentState }) => {
  const { t } = useTranslate(T.ISURELY_COMPARE_DECISION)
  const classes = useStyles()
  const { loading, quote } = useLogic(service)

  const canComparePrice = quote?.canComparePrice

  useEffect(() => {
    // if quote is loaded and can't compare price do an immediate redirect to Review page
    if (!loading && !canComparePrice) {
      service.send(Events.REVIEW)
    }
  }, [loading, canComparePrice, service, currentState])

  const disclaimer = (
    <Typography size='small' modifiers={['preFormatted']}>
      {t('disclaimer')}
    </Typography>
  )

  const Buttons = () => (
    <div className={classes.wrapper}>
      <Typography color='textSecondary'>{t('description')}</Typography>
      <FSMBtnNext
        testId='start-compare-button'
        handleClick={() => service.send({ type: Events.START_COMPARE, state: currentState })}
        buttonText={t('buttons.startCompare')}
      />
      <FSMBtnNext
        testId='review-button'
        handleClick={() => service.send({ type: Events.REVIEW, state: currentState })}
        buttonText={t('buttons.review')}
      />
      <Typography
        color='textDisclaimer'
        size='small'
        modifiers={['preFormatted']}
        className={classes.disclaimer}
      >
        {t('additionalInfo.text')}
      </Typography>
      <ContactCustomerSwitch />
    </div>
  )

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <MobileScreen
        headerTitle={t('title')}
        headerSubTitle={t('subtitle')}
        textLogoAlt={t('title')}
        imageLogo={logoMin}
        brandPageBackground='mainMobile'
      >
        {disclaimer}
        <Buttons />
      </MobileScreen>

      <DesktopScreen
        imageLogo={logoMax}
        textLogoAlt={t('title')}
        brandPageBackground='main'
        brandPageBackgroundCard='mainCard'
      >
        <StandardsButton />
        <FNOLDevButton />
        <section>
          <Typography type='h1' className={classes.title}>
            {t('title')}
          </Typography>
          <Typography type='h3' className={classes.subtitle}>
            {t('subtitle')}
          </Typography>
          {disclaimer}
        </section>
        <Buttons />
      </DesktopScreen>
    </>
  )
}

export default CompareDecision
