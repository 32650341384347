import React, { Attributes, FC, ReactNode } from 'react'
import { Field, Form, FormSpy } from 'react-final-form'
import { has } from 'lodash'

import { FieldDependency, FormFieldV2, FormState } from '../../../types/interface/form.interface'
import { useCommonStyles } from '../../../styles/common_instabank'
import useFormConfig from '../../../hooks/shared/useFormConfig'
import RFFCustomSubmitBtn from './RFFCustomSubmitBtn'
import { Typography, RFFConditional } from '@dg-shared'
import { TranslatedTextType } from '../../../types'
import { FormApi } from 'final-form'
import LoadingSpinner from './MUICustomSpinner'

interface FormBuilderProps {
  id: string
  errorText?: TranslatedTextType
  validationArgs?: Record<string, unknown>
  externalDependency?: { [field: string]: boolean }
  customSubmit?: boolean
  initialValues?: Record<string, unknown>
  debug?: boolean
  // eslint-disable-next-line
  submitHandler(values?: any): void
  submitText?: TranslatedTextType
  submitClass?: string
  setFormState?(data: FormState<unknown>): void
  changeHandler?(values?: unknown): void
  // TODO: consider to make this prop a mandatory to avoid optional form defining confusing with formBuilder `optional`
  // prop and validation form JOI optional() configuration
  optional?: boolean
  isFormControllable?: boolean
  // eslint-disable-next-line
  filledFieldsData?: any
  customLabelFunc?(value: unknown): unknown
  handleBtnDisabled?(value: unknown): void
  classes?: string
  loadingSpinner?: boolean
  options?: Record<string, Record<string, unknown>[]>
}

const FormBuilder: FC<FormBuilderProps> = ({
  id,
  errorText,
  validationArgs,
  externalDependency,
  initialValues,
  submitHandler,
  customSubmit,
  debug,
  submitClass,
  submitText,
  setFormState,
  changeHandler,
  optional,
  isFormControllable,
  customLabelFunc,
  handleBtnDisabled,
  filledFieldsData,
  classes,
  children,
  loadingSpinner,
  options,
}) => {
  const { fields, initialStaticValues, validation } = useFormConfig(id, validationArgs)
  const commonClasses = useCommonStyles()

  // In case the field will be disabled / enabled the field value in form should be set to null / initialValue
  // this should fix the form update request and set appropriate value instead of just ignoring it
  const updateFormFieldValue = (field: FormFieldV2, form: FormApi, isEnabled: boolean) => {
    const value = isEnabled ? field.initialValue : null
    const oldValue = form.getFieldState(field.name)?.value

    // Update form only in case of changing value to / from null (switch the field)
    // this avoids infinite form update re-renders
    if (value === null || oldValue === null) {
      form.change(field.name, value)
    }
  }

  if (!fields) {
    return null
  }

  return (
    <Form
      onSubmit={(values) => submitHandler(values)}
      validate={validation}
      initialValues={initialValues || initialStaticValues}
      keepDirtyOnReinitialize={!!filledFieldsData}
    >
      {({ handleSubmit, values, submitting, pristine, valid, invalid, form }) => {
        if (typeof filledFieldsData === 'object' && !!filledFieldsData) {
          Object.keys(filledFieldsData).map((el) => form.change(el, filledFieldsData[el]))
        }
        !!handleBtnDisabled && handleBtnDisabled(invalid)
        pristine = optional ? false : pristine

        return (
          <form
            data-testid={`form-${id}`}
            onSubmit={handleSubmit}
            className={`${commonClasses.customForm} ${classes}`}
            noValidate
            onChange={changeHandler}
            id={id}
          >
            {Object.keys(fields).map((key) => {
              const field = fields[key]
              const fieldOptions = options ? options[field.name] : null
              const fieldBody = (
                <React.Fragment key={field.name + key}>
                  <Field
                    name={field.name}
                    defaultValue={field.defaultValue}
                    initialValue={field.initialValue}
                    type={field.type}
                    format={field.format}
                    parse={field.parse}
                    formatOnBlur={field.formatOnBlur || true}
                    render={({ input, meta }) =>
                      React.createElement(field.component, {
                        input,
                        meta,
                        validationArgs,
                        type: field.type,
                        fieldLabel: !field.customLabel && field.label,
                        fieldPlaceholder: field.placeholder,
                        customLabel:
                          !!field.customLabel &&
                          React.createElement(
                            field.customLabel,
                            field.customLabelProps ||
                              ({
                                field,
                                invalid: (meta.error || meta.submitError) && !meta.pristine,
                                toggler: customLabelFunc,
                                value: input.value,
                              } as Attributes)
                          ),
                        labelPlacement: field.labelPlacement,
                        options: field.options,
                        fieldOptions,
                        errorMsg: field.errorMsg,
                        ...field.componentProps,
                      })
                    }
                  />
                  {!!errorText && (
                    <Typography color='textError' className={commonClasses.errorMessage}>
                      {errorText}
                    </Typography>
                  )}
                </React.Fragment>
              )

              if (field.dependency) {
                return field.dependency.reduceRight(
                  (prev: ReactNode, cur: FieldDependency) => (
                    <RFFConditional
                      key={field.name}
                      when={cur.fieldName}
                      is={cur.value}
                      // Field enabled / disabled switching callback
                      updateCallBack={(isEnabled) => updateFormFieldValue(field, form, isEnabled)}
                    >
                      {prev}
                    </RFFConditional>
                  ),
                  fieldBody
                )
              }

              if (has(externalDependency, [field.name])) {
                return externalDependency[field.name] ? fieldBody : null
              }

              return fieldBody
            })}
            {children}
            {!customSubmit &&
              (loadingSpinner === true ? (
                <LoadingSpinner />
              ) : (
                <RFFCustomSubmitBtn
                  {...{ submitting, pristine, valid, buttonText: submitText }}
                  classesCustom={submitClass ? submitClass : ''}
                  dataTestid={`form-${id}-submit-btn`}
                />
              ))}
            {debug && (
              <pre style={{ width: '300px', wordWrap: 'break-word' }}>
                {JSON.stringify(values, null, 2)}
              </pre>
            )}
            {isFormControllable && (
              <FormSpy
                subscription={{ values: true, valid: true, pristine: true }}
                onChange={setFormState}
              />
            )}
          </form>
        )
      }}
    </Form>
  )
}

export default FormBuilder
