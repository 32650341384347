import React, { FC, useEffect } from 'react'

import { Typography, FNOLPageWrapper, FNOLButton } from '@dg-shared'
import { useTranslate, useClaim } from '../../../hooks/shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { TRANSLATIONS as T, FNOL_COLLISION_QUESTIONS, ClaimEventName } from '../../../constants'
import { useFNOLStyles } from '../shared/styles/useStyles'
import { FNOL_COLLISION_START_PAGE } from '../../../constants/fnol-constants'

const PageCollision: FC<CommonPageProps> = ({ service, currentState }) => {
  const { t } = useTranslate(T.COLLISION)
  const FNOLClasses = useFNOLStyles({ externalLink: true })
  const { handleAnswerClick, clearAnswer } = useClaim(service, currentState)

  useEffect(() => {
    clearAnswer(FNOL_COLLISION_QUESTIONS.WHAT_HAPPENED)
  }, [clearAnswer])

  const collisionTypeSelect = ({ eventName, label, value }: ClaimEventName) => (
    <FNOLButton
      testId={label}
      key={value as number}
      handleClick={() =>
        handleAnswerClick(FNOL_COLLISION_QUESTIONS.WHAT_HAPPENED, value, eventName)
      }
      buttonText={t(label)}
    />
  )

  const Content = () => (
    <>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <Typography testId='subtitle' html={true} className={FNOLClasses.description}>
        {t('subtitle')}
      </Typography>
      {FNOL_COLLISION_START_PAGE.map((item) => collisionTypeSelect(item))}
    </>
  )

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`back`)} breadcrumbsStepPercentage={30}>
      <Content />
    </FNOLPageWrapper>
  )
}

export default PageCollision
