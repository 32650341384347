import React, { FC } from 'react'
import { get, mapValues } from 'lodash'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Hidden, Dialog, DialogContent } from '@material-ui/core'

import { IconType } from '../../types/interface/icon.interface'
import { VEHICLE_COVERAGE_TYPE_TRANSLATION_KEYS } from '../../constants'

import CoverageInfoList, { LocalizedListItemType } from './CoverageInfoList'
import { filterTranslationByCondition } from '../../service/util.service'
import { Typography, BrandIconShared, ProductWrapper } from '@dg-shared'
import { useTranslate } from '../../hooks/shared'
import { PartnerName, ProductName } from '../../types/interface/quote.interface'
import FSMBtnNext from './FSMBtnNext'
import { Events } from '../../FSM/shared/constants'
import { FSMStateType, ServiceSend } from '../../types/interface/fsm.interface'
import { StateValue } from 'xstate'
import { getLocalizedPartnerName } from '@dg-util'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      color: theme.palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
      },
    },
    title: {
      marginBottom: theme.spacing(1),
    },
    coverText: {
      marginBottom: theme.spacing(1),
    },
    closeBtn: {
      padding: 0,
      height: '2rem',
      color: theme.palette.primary.main,
    },
    dialogContentStyle: {
      padding: 16,
    },
    closeIcon: {
      color: theme.brand.colors.headingMain,
      position: 'absolute',
      top: 10,
      right: 10,
      cursor: 'pointer',
      fontWeight: 100,
    },
    disclaimer: {
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'start',
      },
      '& a': {
        textDecoration: 'underline',
        color: 'inherit',
      },
      '& button': {
        color: 'inherit',
      },
    },
    link: {
      ...theme.brand.assets.layout?.buttonLink,
    },
  })
)

interface CoverageDetailedInfoProps {
  coverageType: string | number
  onClose?: () => void
  showDialogMobile?: boolean
  translationCondition?: string
  send?: ServiceSend
  state?: StateValue | FSMStateType
}

const CoverageDetailedInfo: FC<CoverageDetailedInfoProps> = ({
  coverageType,
  translationCondition,
  showDialogMobile = true,
  onClose,
  send,
  state,
}) => {
  const classes = useStyles()
  const { t } = useTranslate()

  const coverageTranslationKeys = get(
    VEHICLE_COVERAGE_TYPE_TRANSLATION_KEYS,
    [PARTNER_NAME, coverageType],
    null
  )

  const partnerName = getLocalizedPartnerName()

  const {
    name,
    description,
    list,
    additionalInfo = null,
  } = mapValues(coverageTranslationKeys, (el) => t(el))

  const infoList = (
    <CoverageInfoList
      list={filterTranslationByCondition(
        list as Array<LocalizedListItemType>,
        translationCondition
      )}
    />
  )

  const disclaimerLinks = (
    <ProductWrapper
      partners={[PartnerName.skoda, PartnerName.audi, PartnerName.volkswagen]}
      products={[ProductName.VEHICLE]}
    >
      <Typography color='textDisclaimer' size='small' className={classes.disclaimer}>
        {`${t(`coverageDetails.disclaimer.0`)}`}
        <FSMBtnNext
          buttonText={t(`coverageDetails.disclaimer.1`, { partnerName })}
          handleClick={() => {
            send({ type: Events.LANDING_PAGE, state: state })
          }}
          customClassName={classes.link}
        />
      </Typography>
    </ProductWrapper>
  )

  return (
    <>
      <Hidden smDown={showDialogMobile}>
        <section id='review-coverage-wrapper' className={classes.container}>
          <section className={classes.header}>
            {name && (
              <Typography testId='review-coverage-title' type='h2' className={classes.title}>
                {name}
              </Typography>
            )}
          </section>
          {disclaimerLinks}
          <Typography
            testId='review-coverage-description'
            className={classes.coverText}
            color='textMain'
          >
            {description}
          </Typography>
          {infoList}
          <Typography>{additionalInfo}</Typography>
        </section>
      </Hidden>

      <Hidden mdUp smDown={!showDialogMobile}>
        <Dialog onClose={() => onClose()} open={true} maxWidth='xs'>
          <DialogContent className={classes.dialogContentStyle}>
            <BrandIconShared
              type={IconType.ERROR}
              customClass={classes.closeIcon}
              onClick={() => {
                onClose()
              }}
              action
            />
            <Typography type='h2'>{name}</Typography>
            {disclaimerLinks}
            <Typography>{description}</Typography>
            {infoList}
            <Typography>{additionalInfo}</Typography>
          </DialogContent>
        </Dialog>
      </Hidden>
    </>
  )
}

export default CoverageDetailedInfo
