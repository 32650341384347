import React, { FC, useEffect } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { LMBasicV3FSM, LDContainerV2FSM, Typography, FSMBtnNext, BrandIconShared } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import { FlowServiceType } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import useLogic from '../Insurely/useLogic'
import PageWrapper from '@dg-shared/PageWrapper'
import { Card } from '@material-ui/core'
import { IconType } from '../../../types/interface/icon.interface'

import { formatWithThousandSpace } from '../../../service/util.service'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nextButton: {
      margin: 0,
      [theme.breakpoints.up('md')]: {
        maxWidth: '345px',
        marginTop: theme.spacing(5),
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(3),
      },
    },
    container: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(5),
      },
    },
    footerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      gap: theme.spacing(3),
    },
    logoDesktopHeader: {
      ...theme.brand.assets.images.logoDesktopHeader,
      height: 41,
      width: 200,
      margin: '0 auto',
    },
    table: {
      '& td': {
        textAlign: 'center',
        verticalAlign: 'middle',
      },
      '& td:first-child': {
        textAlign: 'left',
        width: '35%',
      },
    },
    priceCell: {
      verticalAlign: 'top !important',
    },
    buyButton: {
      marginTop: theme.spacing(3),
    },
  })
)

export type PagePropsType = {
  service: FlowServiceType
}

// TODO: Finish this page after clarification of design/requirements
const QBCompareBenefitsPage: FC<PagePropsType> = (props) => {
  const { service } = props
  const { t } = useTranslate(T.OFFER_COMPARE_PRICE)
  const { quote, selectedInsurelyQuote, setQuoteCompetitorPrice } = useLogic(service)
  const classes = useStyles()

  useEffect(() => {
    if (!selectedInsurelyQuote) {
      service.send(Events.INSURELY_NO_OFFERS_FOUND)
    }
    // TODO: FIx with using correct deps in [] and useCallback. Left here to not break anything
    // eslint-disable-next-line
  }, [selectedInsurelyQuote])

  useEffect(() => {
    if (quote && selectedInsurelyQuote) {
      // Set Quote Competitor Price
      setQuoteCompetitorPrice(selectedInsurelyQuote.insurance.premiumAmountYearRounded).catch(
        (error) => {
          throw new Error(error)
        }
      )
    }
  }, [quote, selectedInsurelyQuote, setQuoteCompetitorPrice])

  const renderCheckbox = (option: boolean) =>
    option ? <BrandIconShared type={IconType.CHECK} /> : <BrandIconShared type={IconType.ERROR} />

  const content =
    quote && selectedInsurelyQuote ? (
      <Card elevation={3} className={classes.container}>
        <table width='100%' className={classes.table}>
          <tr>
            <td />
            <td>
              <div className={classes.logoDesktopHeader} />
            </td>
            <td>{t(`yourCurrentInsurance`)}</td>
          </tr>
          <tr>
            <td>
              <Typography type='h4'>{t(`coverage`)}</Typography>
            </td>
            <td>
              <Typography size='xLarge' modifiers={['bold']} color='textMain' testId='price'>
                {renderCheckbox(!!quote.CarPackage)}
              </Typography>
            </td>
            <td>
              <Typography size='xLarge' modifiers={['bold']} color='textMain' testId='price'>
                {renderCheckbox(!!selectedInsurelyQuote.insurance.coverage)}
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography type='h4'>{t(`deductible.title`)}</Typography>
            </td>
            <td>
              <Typography size='xLarge' modifiers={['bold']} color='textMain' testId='price'>
                {renderCheckbox(!!quote.CarComprehensiveDeductible)}
              </Typography>
            </td>
            <td>
              <Typography size='xLarge' modifiers={['bold']} color='textMain' testId='price'>
                {renderCheckbox(!!selectedInsurelyQuote.deductibles.length)}
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography type='h4'>{t(`yearlyDrivingDistance.title`)}:</Typography>
            </td>
            <td>
              <Typography size='xLarge' modifiers={['bold']} color='textMain' testId='price'>
                {renderCheckbox(!!quote.AnnualMileage)}
              </Typography>
            </td>
            <td>
              <Typography size='xLarge' modifiers={['bold']} color='textMain' testId='price'>
                {renderCheckbox(!!selectedInsurelyQuote.insurance.maxMileage)}
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography type='h4'>{t(`prevDriversAge`)}</Typography>
            </td>
            <td>
              <Typography size='xLarge' modifiers={['bold']} color='textMain' testId='price'>
                {renderCheckbox(!!quote.YoungDrivers)}
              </Typography>
            </td>
            <td>
              <Typography size='xLarge' modifiers={['bold']} color='textMain' testId='price'>
                {renderCheckbox(!!selectedInsurelyQuote.insurance.youngDriver)}
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography type='h3' color='main'>
                Pris
              </Typography>
            </td>
            <td className={classes.priceCell}>
              <Typography
                size='xLarge'
                modifiers={['bold']}
                color='textMain'
                testId='price'
                component='span'
              >
                {formatWithThousandSpace(quote.CalculatedPrices.priceAnnual.toString())}
              </Typography>
              <Typography testId='priceText'>{t(`card.priceText`)}</Typography>
              <FSMBtnNext
                customClassName={classes.buyButton}
                handleClick={() => service.send(Events.NEXT)}
                buttonText='Byt nu'
                testId='button-next'
              />
            </td>
            <td className={classes.priceCell}>
              <Typography
                size='xLarge'
                modifiers={['bold']}
                color='textMain'
                testId='price'
                component='span'
              >
                {formatWithThousandSpace(
                  selectedInsurelyQuote.insurance.premiumAmountYearRounded.toString()
                )}
              </Typography>
              <Typography testId='priceText'>{t(`card.priceText`)}</Typography>
            </td>
          </tr>
        </table>
      </Card>
    ) : null

  return (
    <PageWrapper
      mobile={LMBasicV3FSM}
      desktop={LDContainerV2FSM}
      breadcrumbsBtnText={t(`back`)}
      breadcrumbsStepPercentage={70}
    >
      <Typography type='h1'>{t('comparePricesBtn')}</Typography>
      {content}
      <div className={classes.footerWrapper}>
        <FSMBtnNext
          handleClick={() => service.send(Events.BACK)}
          buttonText={t(`back`)}
          customClassName={classes.nextButton}
          outlined
        />
        <FSMBtnNext
          handleClick={() => service.send(Events.NEXT)}
          buttonText={t('submitBtn')}
          customClassName={classes.nextButton}
        />
      </div>
    </PageWrapper>
  )
}

export default QBCompareBenefitsPage
