import { capitalize } from 'lodash'

export default (value: string, trim = true) => {
  value &&
    value
      .replace(/\s\s+/g, ' ')
      .split(' ')
      .map((i: string) => capitalize(i.trim()))
      .join(' ')

  return trim ? value.trim() : value
}
