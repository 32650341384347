import React, { FC, useEffect } from 'react'

import { Typography, FNOLPageWrapper } from '@dg-shared'
import { useTranslate, useClaim } from '../../../hooks/shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import { useFNOLStyles } from '../shared/styles/useStyles'
import { FORM_ID, TRANSLATIONS as T, FNOL_COLLISION_QUESTIONS } from '../../../constants'
import FormBuilder from '@dg-shared/forms/FormBuilder'

interface RegistrationNumberFormValues {
  registrationNum?: string
}

const PageRegistrationNumber: FC<CommonPageProps> = ({ service, currentState }) => {
  const FNOLClasses = useFNOLStyles({})
  const { t } = useTranslate(`${T.COLLISION}`)
  const { handleAnswerClick, clearAnswer } = useClaim(service, currentState)

  useEffect(() => {
    clearAnswer(FNOL_COLLISION_QUESTIONS.LICENSE_PLATE_NUMBER)
  }, [clearAnswer])

  const handleSubmit = async ({ registrationNum }: RegistrationNumberFormValues) => {
    await handleAnswerClick(
      FNOL_COLLISION_QUESTIONS.LICENSE_PLATE_NUMBER,
      registrationNum,
      Events.NEXT
    )
  }

  const Content = () => (
    <>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('registrationNum.title')}
      </Typography>
      <Typography testId='subtitle' html={true} className={FNOLClasses.description}>
        {t('registrationNum.subtitle')}
      </Typography>
      <FormBuilder
        id={FORM_ID.REGISTRATION_NUMBER}
        classes={FNOLClasses.formContainer}
        submitHandler={handleSubmit}
      />
    </>
  )

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`back`)} breadcrumbsStepPercentage={60}>
      <Content />
    </FNOLPageWrapper>
  )
}

export default PageRegistrationNumber
