import { Events, FSMCommonTransitions } from '../../constants'
import { has, get } from 'lodash'
import { ContextType } from '../../config'

const {
  FNOL_CLAIMS,
  FNOL_CLAIMS_LIST,
  FNOL_THEFT_FLOW,
  FNOL_THEFT_MORE_INFO,
  FNOL_THEFT_STOLEN_IN_CAR,
  FNOL_THEFT_STOLEN_FROM_CAR,
  FNOL_THEFT_STOLEN_AGE_VALUE,
  FNOL_THEFT_STOLEN_WHEN_WHERE,
  FNOL_THEFT_POLICE_CASE,
  FNOL_THEFT_POLICE_REPORT,
  FNOL_ADDITIONAL_INFORMATION,
  FNOL_TIME_LINE,
  MY_PAGES,
} = FSMCommonTransitions

export default (FLOW_MACHINE_ID: string) => ({
  [FNOL_THEFT_FLOW]: {
    initial: FNOL_THEFT_MORE_INFO,
    states: {
      [FNOL_THEFT_MORE_INFO]: {
        on: {
          [Events.BACK]: `#${FLOW_MACHINE_ID}.${FNOL_CLAIMS}.${FNOL_CLAIMS_LIST}`,
          [Events.FNOL_THEFT_STOLEN_CAR]: {
            target: FNOL_THEFT_STOLEN_WHEN_WHERE,
            actions: 'setBackPath',
          },
          [Events.FNOL_THEFT_STOLEN_IN_CAR]: FNOL_THEFT_STOLEN_IN_CAR,
          [Events.FNOL_THEFT_STOLEN_FROM_CAR]: FNOL_THEFT_STOLEN_FROM_CAR,
        },
      },
      [FNOL_THEFT_STOLEN_IN_CAR]: {
        on: {
          [Events.BACK]: FNOL_THEFT_MORE_INFO,
          [Events.NEXT]: { target: FNOL_THEFT_STOLEN_AGE_VALUE, actions: 'setBackPath' },
        },
      },
      [FNOL_THEFT_STOLEN_FROM_CAR]: {
        on: {
          [Events.BACK]: FNOL_THEFT_MORE_INFO,
          [Events.NEXT]: { target: FNOL_THEFT_STOLEN_AGE_VALUE, actions: 'setBackPath' },
        },
      },
      [FNOL_THEFT_STOLEN_AGE_VALUE]: {
        on: {
          [Events.BACK]: [
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_THEFT_FLOW}.${FNOL_THEFT_STOLEN_IN_CAR}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_THEFT_FLOW) &&
                get(ctx.backPath, FNOL_THEFT_FLOW) === FNOL_THEFT_STOLEN_IN_CAR,
            },
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_THEFT_FLOW}.${FNOL_THEFT_STOLEN_FROM_CAR}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_THEFT_FLOW) &&
                get(ctx.backPath, FNOL_THEFT_FLOW) === FNOL_THEFT_STOLEN_FROM_CAR,
            },
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_THEFT_FLOW}.${FNOL_THEFT_MORE_INFO}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_THEFT_FLOW) &&
                get(ctx.backPath, FNOL_THEFT_FLOW) === FNOL_THEFT_MORE_INFO,
            },
          ],
          [Events.NEXT]: { target: FNOL_THEFT_STOLEN_WHEN_WHERE },
        },
      },
      [FNOL_THEFT_STOLEN_WHEN_WHERE]: {
        on: {
          [Events.BACK]: [
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_THEFT_FLOW}.${FNOL_THEFT_MORE_INFO}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_THEFT_FLOW) &&
                get(ctx.backPath, FNOL_THEFT_FLOW) === FNOL_THEFT_MORE_INFO,
            },
            { target: FNOL_THEFT_STOLEN_AGE_VALUE },
          ],
          [Events.NEXT]: { target: FNOL_THEFT_POLICE_REPORT },
        },
      },
      [FNOL_THEFT_POLICE_REPORT]: {
        on: {
          [Events.BACK]: FNOL_THEFT_STOLEN_WHEN_WHERE,
          [Events.NEXT]: FNOL_THEFT_POLICE_CASE,
          [Events.FNOL_THEFT_POLICE_REPORT]: FNOL_THEFT_POLICE_CASE,
        },
      },
      [FNOL_THEFT_POLICE_CASE]: {
        on: {
          [Events.BACK]: FNOL_THEFT_POLICE_REPORT,
          [Events.NEXT]: FNOL_ADDITIONAL_INFORMATION,
        },
      },
      [FNOL_ADDITIONAL_INFORMATION]: {
        on: {
          [Events.BACK]: FNOL_THEFT_POLICE_CASE,
          [Events.NEXT]: FNOL_TIME_LINE,
        },
      },
      [FNOL_TIME_LINE]: {
        on: {
          [Events.BACK]: `#${FLOW_MACHINE_ID}.${MY_PAGES}`,
          [Events.NEXT]: `#${FLOW_MACHINE_ID}.${MY_PAGES}`,
        },
      },
    },
  },
})
