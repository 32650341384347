import React, { FC } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { IconButton, useTheme } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { BrandColorType } from '@dg-shared/typography/Typography'

const useStyles = makeStyles(() =>
  createStyles({
    iconQuestion: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    iconButtonQuestion: {
      padding: 0,
      paddingLeft: '5px',
    },
  })
)

interface DetailedInfoButtonShared {
  showDetails: () => void
  color?: BrandColorType
}

const DetailedInfoButtonShared: FC<DetailedInfoButtonShared> = ({
  color = 'textSecondary',
  showDetails,
  children,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const brandColor = theme.brand.colors[color]

  return (
    <section className={classes.iconQuestion}>
      {children}
      {/* //TODO: Translate aria-label */}
      <IconButton
        aria-label='click to know why'
        className={classes.iconButtonQuestion}
        onClick={showDetails}
      >
        <InfoOutlined htmlColor={brandColor} fontSize='small' />
      </IconButton>
    </section>
  )
}

export default DetailedInfoButtonShared
