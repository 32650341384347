import { FSMCommonTransitions } from './constants'
import { StateRoute } from '../../types/interface/fsm.interface'
import AuthStrategy from '../../pages/qb/Auth/AuthStrategy'
import { QBChange, QBPurchased, QBVerify } from '../../pages/qb'
import { StandardsPage, PlaygroundPage } from '@dg-shared'
import ErrorPage from '../../pages/shared/ErrorPage'
import { IS_PRODUCTION, IS_ACCEPTANCE } from '../../config'
import AboutPage from '../../pages/qb/Landing/About'
import QuestionsAndAnswersPage from '../../pages/qb/Landing/QuestionsAndAnswers'

const {
  AUTH,
  QB_CHANGE,
  QB_VERIFY,
  QB_PURCHASED,
  ERROR,
  LANDING_PAGE,
  LANDING_PAGE_ABOUT,
  LANDING_PAGE_QUESTIONS_AND_ANSWERS,
  STANDARDS,
  PLAYGROUND,
} = FSMCommonTransitions

export const FSMCommonStatePaths: Array<StateRoute> = [
  {
    is: AUTH,
    page: AuthStrategy,
  },
  {
    is: QB_CHANGE,
    page: QBChange,
  },
  {
    is: QB_VERIFY,
    page: QBVerify,
  },
  {
    is: QB_PURCHASED,
    page: QBPurchased,
  },
  {
    is: ERROR,
    page: ErrorPage,
  },

  {
    is: { [LANDING_PAGE]: LANDING_PAGE_ABOUT },
    page: AboutPage,
  },
  {
    is: {
      [LANDING_PAGE]: LANDING_PAGE_QUESTIONS_AND_ANSWERS,
    },
    page: QuestionsAndAnswersPage,
  },
]

// FSM state paths that available only on DEV and LOCAL environments
if (!IS_PRODUCTION && !IS_ACCEPTANCE) {
  // UI Standards path
  FSMCommonStatePaths.push({
    is: STANDARDS,
    page: StandardsPage,
  })

  // UI Playground path
  FSMCommonStatePaths.push({
    is: PLAYGROUND,
    page: PlaygroundPage,
  })
}
