import React, { ErrorInfo } from 'react'
import { removePersistedState } from '../../FSM/util'

type ErrorBoundaryStateType = { error: Error; errorInfo: ErrorInfo }

const isStateError = (error: string) =>
  error.search(/Error: Child state '(.+)' does not exist on '(.+)'/i) !== -1

class ErrorBoundary extends React.Component {
  state: ErrorBoundaryStateType = {
    error: null,
    errorInfo: null,
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    })

    // FSM missing state error case
    if (isStateError(error.toString())) {
      // Remove FSM cache and reload page
      removePersistedState()
      window.location.reload()
    }
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
