import { Events, FSMCommonTransitions } from '../../constants'
import { ContextType } from '../../config'
import { get, has } from 'lodash'

interface TransitionEvent {
  flowName: string
}

const {
  MY_PAGES,
  FNOL_CLAIMS,
  FNOL_CLAIMS_LIST,
  FNOL_OTHER_FLOW,
  FNOL_OTHER_START,
  FNOL_OTHER_DAMAGE_LOCATION_AND_TIME,
  FNOL_ADDITIONAL_INFORMATION,
  FNOL_OTHER_TIMELINE,
  FNOL_OTHER_LEGAL_DATE,
  FNOL_OTHER_LEGAL_AMOUNT,
  FNOL_OTHER_FIRE_CAUSE,
  FNOL_OTHER_VANDALISM,
  FNOL_OTHER_MACHINE_DAMAGE,
  FNOL_OTHER_ERROR_FILLING,
  FNOL_OTHER_LOST_KEY,
  FNOL_OTHER_LEGAL_AID,
  FNOL_OTHER_FIRE,
} = FSMCommonTransitions

export default (FLOW_MACHINE_ID: string) => {
  const commonFlow = {
    [FNOL_OTHER_DAMAGE_LOCATION_AND_TIME]: {
      on: {
        [Events.BACK]: `#${FLOW_MACHINE_ID}.${FNOL_OTHER_FLOW}.${FNOL_OTHER_START}`,
        [Events.NEXT]: [
          {
            target: FNOL_ADDITIONAL_INFORMATION,
            actions: 'setBackPath',
          },
        ],
      },
    },
    [FNOL_ADDITIONAL_INFORMATION]: {
      on: {
        [Events.BACK]: [
          {
            target: `#${FLOW_MACHINE_ID}.${FNOL_OTHER_FLOW}.${FNOL_OTHER_LEGAL_AID}.${FNOL_OTHER_LEGAL_AMOUNT}`,
            cond: (ctx: ContextType) =>
              has(ctx.backPath, FNOL_OTHER_FLOW) &&
              get(ctx.backPath, `${FNOL_OTHER_FLOW}.${FNOL_OTHER_LEGAL_AID}`) ===
                FNOL_OTHER_LEGAL_AMOUNT,
          },
          {
            target: FNOL_OTHER_DAMAGE_LOCATION_AND_TIME,
          },
        ],
        [Events.NEXT]: FNOL_OTHER_TIMELINE,
      },
    },
    [FNOL_OTHER_TIMELINE]: {
      on: {
        [Events.BACK]: `#${FLOW_MACHINE_ID}.${MY_PAGES}`,
        [Events.NEXT]: `#${FLOW_MACHINE_ID}.${MY_PAGES}`,
      },
    },
  }

  return {
    [FNOL_OTHER_FLOW]: {
      initial: FNOL_OTHER_START,
      states: {
        [FNOL_OTHER_START]: {
          on: {
            [Events.BACK]: `#${FLOW_MACHINE_ID}.${FNOL_CLAIMS}.${FNOL_CLAIMS_LIST}`,
            [Events.NEXT]: [
              {
                target: FNOL_OTHER_VANDALISM,
                cond: (ctx: ContextType, event: TransitionEvent) =>
                  event.flowName === FNOL_OTHER_VANDALISM,
              },
              {
                target: FNOL_OTHER_MACHINE_DAMAGE,
                cond: (ctx: ContextType, event: TransitionEvent) =>
                  event.flowName === FNOL_OTHER_MACHINE_DAMAGE,
              },
              {
                target: FNOL_OTHER_ERROR_FILLING,
                cond: (ctx: ContextType, event: TransitionEvent) =>
                  event.flowName === FNOL_OTHER_ERROR_FILLING,
              },
              {
                target: FNOL_OTHER_LOST_KEY,
                cond: (ctx: ContextType, event: TransitionEvent) =>
                  event.flowName === FNOL_OTHER_LOST_KEY,
              },
              {
                target: FNOL_OTHER_LEGAL_AID,
                cond: (ctx: ContextType, event: TransitionEvent) =>
                  event.flowName === FNOL_OTHER_LEGAL_AID,
              },
              {
                target: FNOL_OTHER_FIRE,
                cond: (ctx: ContextType, event: TransitionEvent) =>
                  event.flowName === FNOL_OTHER_FIRE,
              },
            ],
          },
        },
        [FNOL_OTHER_VANDALISM]: {
          initial: FNOL_OTHER_DAMAGE_LOCATION_AND_TIME,
          states: commonFlow,
        },
        [FNOL_OTHER_MACHINE_DAMAGE]: {
          initial: FNOL_OTHER_DAMAGE_LOCATION_AND_TIME,
          states: commonFlow,
        },
        [FNOL_OTHER_ERROR_FILLING]: {
          initial: FNOL_OTHER_DAMAGE_LOCATION_AND_TIME,
          states: commonFlow,
        },
        [FNOL_OTHER_LOST_KEY]: {
          initial: FNOL_OTHER_DAMAGE_LOCATION_AND_TIME,
          states: commonFlow,
        },
        [FNOL_OTHER_LEGAL_AID]: {
          initial: FNOL_OTHER_LEGAL_DATE,
          states: {
            [FNOL_OTHER_LEGAL_DATE]: {
              on: {
                [Events.BACK]: `#${FLOW_MACHINE_ID}.${FNOL_OTHER_FLOW}.${FNOL_OTHER_START}`,
                [Events.NEXT]: [
                  {
                    target: FNOL_OTHER_LEGAL_AMOUNT,
                    actions: 'setBackPath',
                  },
                ],
              },
            },
            [FNOL_OTHER_LEGAL_AMOUNT]: {
              on: {
                [Events.BACK]: FNOL_OTHER_LEGAL_DATE,
                [Events.NEXT]: [
                  {
                    target: FNOL_ADDITIONAL_INFORMATION,
                    actions: 'setBackPath',
                  },
                ],
              },
            },
            ...commonFlow,
            [FNOL_OTHER_DAMAGE_LOCATION_AND_TIME]: {
              on: {
                [Events.BACK]: `#${FLOW_MACHINE_ID}.${FNOL_CLAIMS}.${FNOL_CLAIMS_LIST}`,
                [Events.NEXT]: [
                  {
                    target: FNOL_ADDITIONAL_INFORMATION,
                    actions: 'setBackPath',
                  },
                ],
              },
            },
          },
        },
        [FNOL_OTHER_FIRE]: {
          initial: FNOL_OTHER_FIRE_CAUSE,
          states: {
            [FNOL_OTHER_FIRE_CAUSE]: {
              on: {
                [Events.BACK]: `#${FLOW_MACHINE_ID}.${FNOL_OTHER_FLOW}.${FNOL_OTHER_START}`,
                [Events.NEXT]: FNOL_OTHER_DAMAGE_LOCATION_AND_TIME,
              },
            },
            ...commonFlow,
            [FNOL_OTHER_DAMAGE_LOCATION_AND_TIME]: {
              on: {
                [Events.BACK]: FNOL_OTHER_FIRE_CAUSE,
                [Events.NEXT]: [
                  {
                    target: FNOL_ADDITIONAL_INFORMATION,
                    actions: 'setBackPath',
                  },
                ],
              },
            },
          },
        },
      },
    },
  }
}
