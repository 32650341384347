import React, { FC } from 'react'

import { TRANSLATIONS as T, FORM_ID } from '../../../../../constants'
import { CommonPageProps } from '../../../../../types/interface/fsm.interface'
import { FNOLPageWrapper, Typography } from '@dg-shared'
import { useTranslate } from '../../../../../hooks/shared'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import useLogic from './useLogic'
import useCommonLegalAidStyles from '../commonStyles'

const Page: FC<CommonPageProps> = ({ service, currentState }) => {
  const { t } = useTranslate(`${T.FNOL_OTHER_LEGAL_AID}.amount`)
  const classes = useCommonLegalAidStyles()

  const { selectedDataFields, handleSubmit, updateForm } = useLogic(service, currentState)

  const content = (
    <section className={classes.formContainer}>
      <FormBuilder
        id={FORM_ID.COMPENSATION}
        submitHandler={handleSubmit}
        classes={classes.form}
        submitClass={classes.submitBtn}
        filledFieldsData={selectedDataFields}
        isFormControllable
        setFormState={({ values }) => updateForm(values)}
      />
    </section>
  )

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`back`)} breadcrumbsStepPercentage={70}>
      <Typography type='h1'>{t('title')}</Typography>
      {content}
    </FNOLPageWrapper>
  )
}

export default Page
