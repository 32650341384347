import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Typography } from '@dg-shared'
import { TranslatedTextType } from '../../types'
import { TypographyType } from '@dg-shared/typography/Typography'
import { ThemeBrandColorsType } from '../../brand/theme/types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(0.8)}px`,
      backgroundColor: theme.brand.colors.cardHeader,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.spacing(0.5),
    },
    title: {
      alignSelf: 'center',
      marginBottom: 0,
    },
    priceText: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '1 !important',
      marginBottom: 0,
    },
    detailsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    priceDisclaimer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(0.5),
    },
    detailsMeta: {
      alignSelf: 'flex-end',
      margin: 0,
      marginTop: '-5px',
    },
    disabled: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(0.8)}px`,
      backgroundColor: theme.brand.colors.textInactive,
    },
  })
)

interface CustomCardHeaderProps {
  priceMonthly?: number
  priceMonthlyText?: TranslatedTextType
  priceDetailsText?: TranslatedTextType
  title: TranslatedTextType
  withPrice?: boolean
  isDisabled?: boolean
  containerClass?: string
  titleTextType?: TypographyType
  titleColor?: keyof ThemeBrandColorsType
}

const CustomCardHeader: FC<CustomCardHeaderProps> = ({
  title,
  titleColor = 'textCardHeader',
  priceMonthly,
  priceMonthlyText,
  priceDetailsText,
  isDisabled,
  withPrice = true,
  containerClass,
  titleTextType = 'h2',
}) => {
  const classes = useStyles()

  const containerClassName = [isDisabled ? classes.disabled : classes.container, containerClass]
    .filter((item) => item)
    .join(' ')

  return (
    <div className={containerClassName} data-testid={'card-price-element'}>
      <div className={classes.row}>
        <Typography
          type={titleTextType}
          testId='card-header'
          className={classes.title}
          color={titleColor}
        >
          {title}
        </Typography>
        {withPrice && (
          <div className={classes.detailsContainer}>
            <Typography color='textCardHeader' type='h2' className={classes.priceText}>
              {String(priceMonthly)}
            </Typography>
            <Typography color='textCardHeader' size='small' className={classes.detailsMeta}>
              {priceMonthlyText}
            </Typography>
          </div>
        )}
      </div>
      <section className={classes.priceDisclaimer}>
        <Typography color='textCardHeader' size='small'>
          {priceDetailsText}
        </Typography>
      </section>
    </div>
  )
}

export default CustomCardHeader
