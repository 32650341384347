const FSMStorageKey = 'app-state'
export const AUTH_PORTAL_REQUEST_REDIRECT_KEY = 'AUTH_PORTAL_REQUEST_REDIRECT_KEY'

enum FSMCommonTransitions {
  // Auth
  AUTH = 'auth',
  AUTH_CHOICE = 'buy',
  AUTH_AGENT = 'auth-agent',
  AUTH_REGULAR = 'regular',
  AUTH_BANK_ID = 'bankId',
  AUTH_BANK_ID_START = 'authBankIdStart',

  ERROR = 'error',
  STANDARDS = 'standards',
  PLAYGROUND = 'playground',
  HISTORY = 'history',

  // MyPages & FNOL
  MY_PAGES = 'myPage',
  FNOL_CLAIMS = 'claims',
  FNOL_CLAIMS_LIST = 'claimsList',
  FNOL_WINDSHIELD_FLOW = 'windshield',
  FNOL_WINDSHIELD_START = 'windshieldStart',
  FNOL_OTHER_FLOW = 'other',
  FNOL_OTHER_START = 'otherStart',
  FNOL_OTHER_DAMAGE_LOCATION_AND_TIME = 'otherDamageLocationAndTime',
  FNOL_OTHER_TIMELINE = 'otherTimeline',
  FNOL_OTHER_LEGAL_DATE = 'otherLegalDate',
  FNOL_OTHER_LEGAL_AMOUNT = 'otherLegalAmount',
  FNOL_OTHER_FIRE_CAUSE = 'otherFireCause',
  FNOL_OTHER_VANDALISM = 'otherVandalism',
  FNOL_OTHER_MACHINE_DAMAGE = 'otherMachineDamage',
  FNOL_OTHER_ERROR_FILLING = 'otherErrorFilling',
  FNOL_OTHER_LOST_KEY = 'otherLostKey',
  FNOL_OTHER_LEGAL_AID = 'otherLegalAid',
  FNOL_OTHER_FIRE = 'otherFire',
  FNOL_COLLISION_FLOW = 'collision',
  FNOL_COLLISION_WHAT_HAPPENED = 'collisionWhatHappened',
  FNOL_COLLISION_HOW_MANY = 'collisionHowMany',
  FNOL_COLLISION_ANIMAL = 'collisionAnimal',
  FNOL_COLLISION_OBJECT = 'collisionObject',
  FNOL_COLLISION_RESPONSIBLE = 'collisionResponsible',
  FNOL_COLLISION_INJURED = 'collisionInjured',
  FNOL_COLLISION_CASE_OF_ACCIDENT = 'collisionCaseOfAccident',
  FNOL_COLLISION_REGISTRATION_NUM = 'collisionRegistrationNumber',
  FNOL_COLLISION_WHEN_WHERE = 'collisionWhenWhere',
  FNOL_COLLISION_ACCIDENT_FORM = 'collisionAccidentForm',
  FNOL_COLLISION_DAMAGE_REPORT = 'collisionDamageReport',
  FNOL_COLLISION_MORE_DETAILS = 'collisionMoreDetails',
  FNOL_THEFT_FLOW = 'theft',
  FNOL_THEFT_MORE_INFO = 'theftMoreInfo',
  FNOL_THEFT_STOLEN_IN_CAR = 'theftStolenInCar',
  FNOL_THEFT_STOLEN_FROM_CAR = 'theftStolenFromCar',
  FNOL_THEFT_STOLEN_AGE_VALUE = 'theftStolenAgeValue',
  FNOL_THEFT_STOLEN_WHEN_WHERE = 'theftStolenWhenWhere',
  FNOL_THEFT_POLICE_CASE = 'theftPoliceCase',
  FNOL_THEFT_POLICE_REPORT = 'theftPoliceReport',
  FNOL_ADDITIONAL_INFORMATION = 'additionalInfo',
  FNOL_TIME_LINE = 'timeLine',
  FNOL_ROADSIDE_ASSISTANCE_FLOW = 'roadsideAssistance',
  FNOL_ROADSIDE_ASSISTANCE_START = 'roadsideAssistanceStart',

  // Policies
  POLICIES = 'policies',
  POLICIES_LIST = 'policiesList',
  POLICY_DONE = 'policyDone',
  POLICY_REVIEW = 'policyReview',
  POLICY_CHANGE = 'policyChange',
  POLICY_VERIFY = 'policyVerify',

  // Landing page
  LANDING_PAGE = 'om-index',
  LANDING_PAGE_ABOUT = 'om',
  LANDING_PAGE_QUESTIONS_AND_ANSWERS = 'om-qa',

  // QB
  QB_EXTRA_OFFERS = 'quoteExtraOffers',
  QB_EXTRA_OFFERS_LIST = 'quoteExtraOffersList',
  QB_EXTRA_FIND_EMPTY = 'quoteExtraOffersFindEmpty',
  QB_EXTRA_FIND_CUSTOM = 'quoteExtraOffersFindCustom',
  QB_FIND = 'quoteFind',
  QB_OWNER = 'quoteOwner',
  QB_OWNERSHIP = 'quoteOwnership',
  QB_PURCHASED = 'purchased',
  QB_PURCHASED_DONE = 'done',
  QB_PURCHASED_FAILURE = 'failure',
  QB_PURCHASED_MILEAGE = 'mileage',
  QB_REVIEW = 'quoteReview',
  QB_CHANGE = 'quoteChange',
  QB_VERIFY = 'quoteVerify',
  QB_CHOOSE_ADDRESS = 'quoteChooseAddress',

  // Insurely
  INSURELY = 'insurely',
  INSURELY_COMPARE_INIT = 'insurelyCompareInit',
  INSURELY_COMPARE = 'insurelyCompare',
  INSURELY_COMPARARISON_LIST = 'insurelyComparisonList',
  INSURELY_COMPARE_DECISION = 'insurelyCompareDecision',
  INSURELY_COMPARE_BENEFITS = 'insurelyCompareBenefits',
}

enum Events {
  NEXT = 'NEXT',
  BACK = 'BACK',
  EMPTY = 'EMPTY',
  ERROR = 'ERROR',
  FIND_CUSTOM = 'FIND_CUSTOM',
  DONE = 'DONE',
  PAYMENT_FAILURE = 'PAYMENT_FAILURE',
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  PAYMENT_CANCEL = 'PAYMENT_CANCEL',
  CUSTOM_AUTH_START = 'CUSTOM_AUTH_START',
  CUSTOM_AUTH_SWITCHER = 'CUSTOM_AUTH_SWITCHER',
  CUSTOM_AUTH_SUCCESS = 'CUSTOM_AUTH_SUCCESS',
  CUSTOM_AUTH_FAILURE = 'CUSTOM_AUTH_FAILURE',
  CHANGE = 'CHANGE',
  REVIEW = 'REVIEW',
  WRONG_PRODUCT_ID = 'WRONG_PRODUCT_ID',
  START_COMPARE = 'START_COMPARE',
  COMPARE = 'COMPARE',
  COMPARE_LIST = 'COMPARE_LIST',
  AUTH_ERROR = 'AUTH_ERROR',
  AUTH = 'AUTH',
  AGENT_AUTH = 'AGENT_AUTH',
  INSURELY_FAILURE_BANK_ID = 'failedBankId',
  INSURELY_NO_OFFERS_FOUND = 'failedCalculation',
  STANDARDS = 'STANDARDS',
  PLAYGROUND = 'PLAYGROUND',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  MY_PAGES = 'MY_PAGES',
  POLICIES = 'POLICIES',
  FIND = 'FIND',
  OWNERSHIP = 'OWNERSHIP',

  // FNOL
  NO_DATA = 'NO_DATA',
  FNOL_CLAIMS = 'FNOL_CLAIMS',
  FNOL_WINDSHIELD = 'FNOL_WINDSHIELD',
  FNOL_COLLISION = 'FNOL_COLLISION',
  FNOL_COLLISION_HOW_MANY = 'FNOL_COLLISION_HOW_MANY',
  FNOL_COLLISION_ANIMAL = 'FNOL_COLLISION_ANIMAL',
  FNOL_COLLISION_OBJECT = 'FNOL_COLLISION_OBJECT',
  FNOL_COLLISION_RESPONSIBLE = 'FNOL_COLLISION_RESPONSIBLE',
  FNOL_COLLISION_INJURED = 'FNOL_COLLISION_INJURED',
  FNOL_COLLISION_CASE_OF_ACCIDENT = 'FNOL_COLLISION_CASE_OF_ACCIDENT',
  FNOL_COLLISION_REGISTRATION_NUM = 'FNOL_COLLISION_REGISTRATION_NUM',
  FNOL_COLLISION_WHEN_WHERE = 'FNOL_COLLISION_WHEN_WHERE',
  FNOL_COLLISION_MORE_DETAILS = 'FNOL_COLLISION_MORE_DETAILS',
  FNOL_THEFT = 'FNOL_THEFT',
  FNOL_ROADSIDE_ASSISTANCE = 'FNOL_ROADSIDE_ASSISTANCE',
  FNOL_OTHER = 'FNOL_OTHER',
  FNOL_OTHER_DAMAGE_LOCATION_AND_TIME = 'FNOL_OTHER_DAMAGE_LOCATION_AND_TIME',
  FNOL_OTHER_TIMELINE = 'FNOL_OTHER_TIMELINE',
  FNOL_OTHER_LEGAL_DATE = 'FNOL_OTHER_LEGAL_DATE',
  FNOL_OTHER_LEGAL_AMOUNT = 'FNOL_OTHER_LEGAL_AMOUNT',
  FNOL_OTHER_FIRE_CAUSE = 'FNOL_OTHER_FIRE_CAUSE',
  FNOL_THEFT_STOLEN_CAR = 'FNOL_THEFT_STOLEN_CAR',
  FNOL_THEFT_POLICE_REPORT = 'FNOL_THEFT_POLICE_REPORT',
  FNOL_THEFT_STOLEN_IN_CAR = 'FNOL_THEFT_STOLEN_IN_CAR',
  FNOL_THEFT_STOLEN_FROM_CAR = 'FNOL_THEFT_STOLEN_FROM_CAR',
  FNOL_ADDITIONAL_INFORMATION = 'FNOL_ADDITIONAL_INFORMATION',

  // Landing page
  LANDING_PAGE = 'LANDING_PAGE',
  LANDING_PAGE_ABOUT = 'LANDING_PAGE_ABOUT',
  LANDING_PAGE_QUESTIONS_AND_ANSWERS = 'LANDING_PAGE_QUESTIONS_AND_ANSWERS',
}

export enum FNOLClaimTypes {
  FNOL_WINDSHIELD = 'FNOL_WINDSHIELD',
  FNOL_COLLISION = 'FNOL_COLLISION',
  FNOL_THEFT = 'FNOL_THEFT',
  FNOL_ROADSIDE_ASSISTANCE = 'FNOL_ROADSIDE_ASSISTANCE',
  FNOL_OTHER = 'FNOL_OTHER',
}

const MATCH_COMPOUND = {
  AUTH_CHOICE: { auth: FSMCommonTransitions.AUTH_CHOICE },
  AUTH_REGULAR: { auth: FSMCommonTransitions.AUTH_REGULAR },
  AUTH_AGENT: { auth: FSMCommonTransitions.AUTH_AGENT },
  PURCHASED_MILEAGE: { purchased: FSMCommonTransitions.QB_PURCHASED_MILEAGE },
  PURCHASED_DONE: { purchased: FSMCommonTransitions.QB_PURCHASED_DONE },
  PURCHASED_FAIL: { purchased: FSMCommonTransitions.QB_PURCHASED_FAILURE },
}

export enum PolicyStatus {
  ACCEPTED = 1,
  VALID = 2,
}

export const FSM_PRESERVED_DATA_KEY = 'FSM_PRESERVED_DATA'
export { FSMCommonTransitions, Events, MATCH_COMPOUND, FSMStorageKey }
