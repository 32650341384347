import { useMutation, useQuery } from '@tanstack/react-query'
import { AuthRegularV2InputType, ResponseErrorV2Type } from '../../types/ApiV2'
import apiV2 from '../../service/apiV2.service'
import { reactQueryErrorHandler, setSessionStorageAuthItem } from '@dg-util'
import { FSMService } from '../../FSM'

const useReactQueryAuth = () => {
  // Regular Auth Mutation
  const {
    mutateAsync: authRegular,
    isLoading: isAuthRegularLoading,
    data: authRegularResult,
    isError: isAuthRegularError,
    error: authRegularError,
  } = useMutation((authInput: AuthRegularV2InputType) => apiV2.authRegular(authInput), {
    async onSuccess(response) {
      setSessionStorageAuthItem(response.data.token, response.data.kid)
    },
    onError: (error: ResponseErrorV2Type) => reactQueryErrorHandler(error, FSMService.flowService),
    cacheTime: 0, // Do not use cache for this request response
  })

  // Sign Out Query
  const {
    data: signOutResult,
    isError: isSignOutError,
    error: signOutError,
    refetch: signOut,
    isFetching: isSignOutFetching,
    isInitialLoading: isSignOutLoading,
  } = useQuery(['signOut'], () => apiV2.authSignOut(), {
    select: (response) => response.data,
    enabled: false,
    // We do not handling SignOut error since we don't need an error message in failure
    // on session expired or other sign-out errors (we just clear app cache and navigate to the
    // start flow state in any case)
    cacheTime: 0, // Do not use cache for this request response
  })

  return {
    isLoading: isSignOutFetching || isSignOutLoading || isAuthRegularLoading,
    authRegular,
    isAuthRegularLoading,
    authRegularResult,
    isAuthRegularError,
    authRegularError,
    signOutResult,
    isSignOutError,
    signOutError,
    signOut,
    isSignOutFetching,
    isSignOutLoading,
  }
}

export default useReactQueryAuth
