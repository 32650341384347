import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core'

import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { IconType } from '../../../types/interface/icon.interface'
import { Typography, BrandIconShared } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import BrandConfig from 'BrandConfig'
import { getLocalizedPartnerName } from '@dg-util'

const useDetailsStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      alignItems: 'flex-start',
    },
    listItemText: {
      fontSize: '16px',
      lineHeight: '22px',
      color: theme.brand.colors.textMain,
      '& a': {
        color: theme.brand.colors.textMain,
      },
    },
    listIcon: {
      marginTop: '4px',
      minWidth: '7px',
      color: theme.brand.colors.main,
    },
    listIconSVG: {
      transform: 'scale(0.25)',
      ...theme.brand.assets.layout?.iconCustomColor,
    },
  })
)

const Details: FC = () => {
  const classes = useDetailsStyles()
  const { t } = useTranslate(`${T.OFFER_VERIFY}.details`)

  const link = BrandConfig.PARTNER_PRIVACY_POLICY_LINK
  const pasteLink = (text: string) => text.replace(/{link}/gi, link)
  const partnerName = getLocalizedPartnerName()

  return (
    <>
      <Typography modifiers={['bold']}>{t('title')}</Typography>
      <List>
        {t(`text`, { partnerName }, 'obj')?.map((el) => (
          <ListItem className={classes.listItem} key={el}>
            <ListItemIcon className={classes.listIcon}>
              <BrandIconShared type={IconType.DOT} customClass={classes.listIconSVG} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ className: classes.listItemText }}>
              <Typography html={true} component='span'>
                {pasteLink(el)}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </>
  )
}

export default Details
