import { Typography } from '@dg-shared/index'
import { useTranslate } from '../../hooks/shared'
import { getProductPropValue } from '@dg-util'
import React, { FC } from 'react'
import useContentStyles from '../../hooks/shared/useContentStyles'
import { PolicyReviewCardProps } from '../../types'

const PolicyReviewTravelDetails: FC<PolicyReviewCardProps> = ({ policy, productProps }) => {
  const classes = useContentStyles({ isImage: true })
  const coverageName = getProductPropValue(productProps.coverage, policy.TravelCoverage)
  const { t } = useTranslate()

  return (
    <div
      className={classes.column}
      data-testid={'coverage-data'}
      style={{ marginTop: 0, marginBottom: 0 }}
    >
      <div className={classes.row}>
        <Typography testId='review-coverage-extent'>{t(`TravelCoverage`)}</Typography>
        <Typography testId='review-coverage-extent-value' className={classes.rightColItem}>
          {coverageName}
        </Typography>
      </div>
      <div className={classes.row}>
        <Typography testId='review-coverage-TravelCoverArea'>{t(`TravelCoverArea`)}</Typography>
        <Typography testId='review-coverage-TravelCoverArea-value' className={classes.rightColItem}>
          {getProductPropValue(productProps.coverArea, policy.TravelCoverArea)}
        </Typography>
      </div>
      <div className={classes.row}>
        <Typography testId='review-coverage-TravelPreviousClaims'>
          {t(`TravelPreviousClaims`)}
        </Typography>
        <Typography
          testId='review-coverage-TravelPreviousClaims-value'
          className={classes.rightColItem}
        >
          {getProductPropValue(productProps.claimsPrev, policy.TravelPreviousClaims)}
        </Typography>
      </div>
    </div>
  )
}

export default PolicyReviewTravelDetails
