import { CoverageDetailedInfo, Typography } from '@dg-shared/index'
import { useTranslate } from '../../hooks/shared'
import { formatWithThousandSpace } from '../../service/util.service'
import { getProductPropValue } from '@dg-util'
import React, { FC, useState } from 'react'
import useContentStyles from '../../hooks/shared/useContentStyles'
import { Hidden } from '@material-ui/core'
import DetailedInfoButtonShared from '@dg-shared/DetailedInfoButtonShared'
import { PartnerName } from '../../types/interface/quote.interface'
import { FSMService } from '../../FSM'
import { PolicyReviewCardProps } from '../../types'

const PolicyReviewVehicleDetails: FC<PolicyReviewCardProps> = ({ policy, productProps }) => {
  const classes = useContentStyles({ isImage: true })
  const [coverageInfoOpened, setCoverageInfoOpened] = useState<boolean>(false)
  const coverageName = getProductPropValue(productProps.coverageType, policy.CarPackage)
  const { t } = useTranslate()

  const coverage = (
    <>
      <Hidden mdUp>
        <DetailedInfoButtonShared
          showDetails={() => setCoverageInfoOpened(true)}
          color={PartnerName.skoda ? 'textSecondary' : 'main'}
        >
          <Typography testId='review-coverage-extent-value' className={classes.rightColItem}>
            {coverageName}
          </Typography>
        </DetailedInfoButtonShared>
      </Hidden>
      <Hidden smDown>
        <Typography testId='review-coverage-extent-value' className={classes.rightColItem}>
          {coverageName}
        </Typography>
      </Hidden>
    </>
  )

  const coverageInfo = (
    <CoverageDetailedInfo
      send={FSMService.flowService.send}
      coverageType={policy.CarPackage}
      state={FSMService.flowService.state.value}
      onClose={() => setCoverageInfoOpened(false)}
      translationCondition={policy.SuperCoverage ? 'isSuper' : 'isNotSuper'}
    />
  )

  return (
    <div
      className={classes.column}
      data-testid={'coverage-data'}
      style={{ marginTop: 0, marginBottom: 0 }}
    >
      <div className={classes.row}>
        <Typography testId='review-coverage-extent'>{t(`coverage`)}</Typography>
        {coverage}
        {coverageInfoOpened && coverageInfo}
      </div>
      {!!policy.CarComprehensiveDeductible && (
        <div className={classes.row}>
          <Typography testId='review-coverage-deductible'>{t(`deductible.title`)}</Typography>
          <Typography testId='review-coverage-deductible-value' className={classes.rightColItem}>
            {t(`deductible.value`, {
              deductible: formatWithThousandSpace(policy.CarComprehensiveDeductible),
            })}
          </Typography>
        </div>
      )}
      <div className={classes.row}>
        <Typography testId='review-coverage-bonus'>{t(`bonus`)}</Typography>
        <Typography testId='review-coverage-bonus-value' className={classes.rightColItem}>
          {getProductPropValue(productProps.bonus, policy.CarBonus)}
        </Typography>
      </div>
      <div className={classes.row}>
        <Typography testId='review-coverage-drivingDistance'>
          {t(`yearlyDrivingDistance.title`)}:
        </Typography>
        <Typography testId='review-coverage-drivingDistance-value' className={classes.rightColItem}>
          {t(`yearlyDrivingDistance.value`, {
            annualMileage: formatWithThousandSpace(policy.AnnualMileage),
          })}
        </Typography>
      </div>
      <div className={classes.row}>
        <Typography testId='review-coverage-driversAge'>{t(`driversAge`)}:</Typography>
        <Typography testId='review-coverage-driversAge-value' className={classes.rightColItem}>
          {policy.YoungDrivers ? t(`yes`) : t(`no`)}
        </Typography>
      </div>
      <div className={classes.row}>
        <Typography testId='review-coverage-PreviousClaims'>{t(`PreviousClaims`)}:</Typography>
        <Typography testId='review-coverage-PreviousClaims-value' className={classes.rightColItem}>
          {getProductPropValue(productProps.previousClaims, policy.PreviousClaims || 0)}
        </Typography>
      </div>
    </div>
  )
}

export default PolicyReviewVehicleDetails
