import React, { FC, ReactNode } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Select, InputLabel, FormControl, FormHelperText, MenuItem } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import { FieldOptions } from '../../../types/interface/form.interface'

const useRFFCustomSelectStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      paddingTop: '15px', //TODO: make conditional in case the select has label
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        marginBottom: theme.spacing(3),
      },
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
      color: theme.brand.colors.main,
    },
    selectLabel: {
      fontSize: '16px',
      fontWeight: 400,
      color: theme.palette.info.dark,
    },
  })
)

interface RFFCustomSelectProps extends FieldRenderProps<string | number | Date, HTMLElement> {
  options: Array<FieldOptions>
  defaultItem?: FieldOptions
  fieldLabel?: string
  customLabel?: ReactNode
  fieldOptions?: Array<FieldOptions>
  isDisabled?: boolean
}

const RFFCustomSelect: FC<RFFCustomSelectProps> = ({
  input,
  meta,
  defaultItem,
  fieldLabel,
  customLabel,
  fieldOptions,
  isDisabled,
  ...props
}) => {
  const classes = useRFFCustomSelectStyles()
  const options = fieldOptions
  const isError: boolean = (meta.error || meta.submitError) && !meta.pristine
  const defaultSelectItem: boolean | ReactNode = defaultItem ? (
    <MenuItem key={`default-${input.name}`} value={defaultItem.value}>
      <em>{defaultItem.text}</em>
    </MenuItem>
  ) : null
  const selectLabel = fieldLabel ? (
    <InputLabel shrink id={input.name} className={classes.selectLabel}>
      {fieldLabel}
    </InputLabel>
  ) : (
    customLabel
  )
  return options ? (
    <FormControl error={isError} className={classes.formControl}>
      {selectLabel}
      <Select
        disabled={isDisabled}
        name={input.name}
        value={input.value}
        onChange={input.onChange}
        IconComponent={KeyboardArrowDown}
        displayEmpty
        variant='outlined'
        className={classes.selectEmpty}
        inputProps={{
          'data-testid': props.selectTestId ? props.selectTestId : `select-${input.name}`,
        }}
      >
        {defaultSelectItem}
        {!!options &&
          options.map((selectOption: FieldOptions, idx: number) => {
            return (
              <MenuItem key={`${props.fieldName}-${idx}`} value={selectOption.name}>
                {selectOption.value}
              </MenuItem>
            )
          })}
      </Select>
      <FormHelperText>{meta.error}</FormHelperText>
    </FormControl>
  ) : null
}

export default RFFCustomSelect
