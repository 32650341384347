import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    twoCol: () => ({
      display: 'flex',
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
    }),
    colLeft: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(2),
        justifyContent: 'center',
      },
      [theme.breakpoints.up('lg')]: {
        marginRight: theme.spacing(4),
      },
    },
    colRight: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(2),
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(4),
      },
    },
    itemImage: {
      width: '100%',
      maxWidth: '220px',
      margin: '24px auto 12px auto',
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2),
      },
    },
    cardTitle: {
      marginBottom: theme.spacing(1),
      lineHeight: '1 !important',
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2),
      },
    },
    cardSubTitle: {
      marginBottom: theme.spacing(2.5),
    },
    //CONTENTS Styles
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      paddingTop: '10px',
      paddingBottom: '10px',
      '& > *': {
        margin: '0',
      },
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
    },
    changeFormWrapper: {
      marginTop: theme.spacing(2),
    },
    rightColItem: {
      fontWeight: 700,
      textAlign: 'right',
      color: theme.brand.colors.main,
      ...theme.brand.typography.overrides?.reviewRightColItem,
      marginBottom: '0',
    },
    // TRAVEL
    divider: {
      backgroundColor: theme.palette.primary.dark,
      marginBottom: theme.spacing(2),
    },
    buttonSubmit: {
      maxWidth: '345px',
      margin: 'auto',
    },
    inactivePolicyText: {
      display: 'flex',
      gap: theme.spacing(1),
      marginTop: theme.spacing(4),
      margin: `0 auto`,
    },
    infoIconStyle: {
      verticalAlign: 'sub',
      marginLeft: 5,
      color: theme.brand.colors.textSecondary,
    },
  })
)
