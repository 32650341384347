import React, { FC, useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { FlowName } from './config'
import { StateRoute } from './types/interface/fsm.interface'
import { FSMService, FSMSwitch, FSMMatch } from './FSM'
import FSMProtectedRoute from './routes/FSMProtectedRoute'
import { QBCustomAuth, AcceptPage } from './pages/qb'
import AppWrapper from './AppWrapper'
import Sentry from './service/sentry.service'
import AgentLoginPage from './pages/qb/AgentLogin/AgentLoginPage'
import LandingPage from './pages/qb/Landing/LandingPage'
import { signOutOnDemand } from '@dg-util/productHandler'
import SwitchFlowDEVButton from '@dg-shared/SwitchFlowDEVButton'
import { FSMCommonTransitions } from './FSM/shared/constants'
import { ProductName } from './types/interface/quote.interface'

const App: FC = () => {
  const [routes, setRoutes] = useState<StateRoute[]>(FSMService.routes)

  useEffect(() => {
    // Do a signOut action if it was requested by the productHandler on app initialization
    // This should clean the previous redis auth key in case the flow/product was changed in the URL directly
    ;(async () => signOutOnDemand())()

    // Register routes update on FSM re-initialization
    FSMService.setInitMachineCallback((fsmService) => {
      setRoutes(fsmService.routes)
    })
  }, [])

  const content = (
    <>
      <FSMSwitch>
        {!isEmpty(routes) &&
          routes.map((route: StateRoute, idx: number) => (
            <FSMMatch key={`${idx}-route`} is={route.is} component={route.page} />
          ))}
      </FSMSwitch>
      <Switch>
        <FSMProtectedRoute path='/accept' component={AcceptPage} />
        <FSMProtectedRoute path='/custom-auth' component={QBCustomAuth} />
        {/* Agent login handling */}
        <Route path='/agent' component={AgentLoginPage} />
        {/* Landing pages */}
        <Route path='/om' component={LandingPage} />
        <SwitchFlowDEVButton
          targetStateValue={FSMCommonTransitions.QB_FIND}
          targetFlow={FlowName.QUOTE_AND_BUY}
          targetProduct={ProductName.VEHICLE}
          FSMService={FSMService}
          label='Switch to other flow state'
        />
      </Switch>
    </>
  )

  return <AppWrapper>{content}</AppWrapper>
}

export default Sentry.withProfiler(App)
