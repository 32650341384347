import React, { FC } from 'react'

import { Typography, FNOLPageWrapper } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import WhenWhereForm from '../../../components/Claims/WhenWhereForm'
import { useFNOLStyles } from '../shared/styles/useStyles'
import { TRANSLATIONS as T } from '../../../constants'

const PageCollisionWhenWhere: FC<CommonPageProps> = ({ service, currentState }) => {
  const FNOLClasses = useFNOLStyles({})
  const { t } = useTranslate(`${T.COLLISION}.when&Where`)

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`back`)} breadcrumbsStepPercentage={70}>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <Typography testId='subtitle' html={true} className={FNOLClasses.description}>
        {t('subtitle')}
      </Typography>
      <WhenWhereForm service={service} currentState={currentState} />
    </FNOLPageWrapper>
  )
}

export default PageCollisionWhenWhere
