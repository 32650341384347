import React, { FC, ReactElement } from 'react'

import { withHidden, LMBasicV3FSM, LDContentCardFSM } from '@dg-shared'
import { TranslatedTextType } from '../../types'
import { FSMService } from '../../FSM'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const MobileScreen = withHidden(LMBasicV3FSM, true)
const DesktopScreen = withHidden(LDContentCardFSM, false)

export type FNOLPageWrapper = {
  children: ReactElement | ReactElement[]
  breadcrumbsBtnText: TranslatedTextType
  breadcrumbsStepPercentage?: number
  mobileWrapperStyle?: React.CSSProperties
  desktopWrapperStyle?: React.CSSProperties
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      alignItems: 'center',
      flexDirection: 'column',
      display: 'flex',
      margin: '0 auto',
      maxWidth: 425,
      [theme.breakpoints.down('sm')]: {
        margin: '0',
        height: '100%',
        alignItems: 'start',
      },
    },
  })
)

// This page wrapper combines both Desktop & Mobile view page view for FNOL
const FNOLPageWrapper: FC<FNOLPageWrapper> = ({
  children,
  breadcrumbsBtnText,
  breadcrumbsStepPercentage,
  mobileWrapperStyle,
  desktopWrapperStyle,
}) => {
  const { send } = FSMService.flowService
  const classes = useStyles()

  return (
    <>
      <MobileScreen
        breadcrumbsBtnText={breadcrumbsBtnText}
        send={send}
        breadcrumbs={!!breadcrumbsBtnText}
        breadcrumbsStepPercentage={breadcrumbsStepPercentage}
      >
        <div className={classes.wrapper} style={mobileWrapperStyle}>
          {children}
        </div>
      </MobileScreen>

      <DesktopScreen
        breadcrumbsBtnText={breadcrumbsBtnText}
        send={send}
        breadcrumbs={!!breadcrumbsBtnText}
        breadcrumbsStepPercentage={breadcrumbsStepPercentage}
      >
        <div className={classes.wrapper} style={desktopWrapperStyle}>
          {children}
        </div>
      </DesktopScreen>
    </>
  )
}

export default FNOLPageWrapper
