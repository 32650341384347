import React from 'react'
import { Typography } from '@dg-shared'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      border: `5px solid ${theme.palette.primary.main}`,
      background: theme.palette.primary.light,
      padding: theme.spacing(2),
      margin: theme.spacing(3),
    },
  })
)

const TestComponent2 = () => {
  const { wrapper } = useStyles()
  return (
    <div className={wrapper}>
      <Typography type='h1' color='main'>
        Default Test Component (Not Branded) and resolved by webpack modules resolver
      </Typography>
      <Typography>It has a default content for all brands</Typography>
      <Typography color='textError'>Path to this component is:</Typography>
      <Typography color='main' size='xSmall'>
        src/brand/default/components/testComponent2.tsx
      </Typography>
    </div>
  )
}

export default TestComponent2
