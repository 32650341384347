import { FNOLClaimsList } from '../../../../pages/FNOL'
import PageFNOLPoliciesList from '../../../../pages/FNOL/Policies/PageFNOLPoliciesList'

import routesClaimsWindshield from '../windshield/routesClaimsWindshield'
import routesClaimsCollision from '../collision/routesClaimsCollision'
import routesClaimsRoadsideAssistance from '../roadsideAssistance/routesClaimsRoadsideAssistance'
import routesClaimsTheft from '../theft/routesClaimsTheft'
import routesClaimsOther from '../other/routesClaimsOther'
import { FSMCommonTransitions } from '../../constants'

const { POLICIES_LIST, FNOL_CLAIMS, FNOL_CLAIMS_LIST } = FSMCommonTransitions

export default [
  {
    is: { [FNOL_CLAIMS]: POLICIES_LIST },
    page: PageFNOLPoliciesList,
  },
  {
    is: { [FNOL_CLAIMS]: FNOL_CLAIMS_LIST },
    page: FNOLClaimsList,
  },
  ...routesClaimsWindshield,
  ...routesClaimsCollision,
  ...routesClaimsRoadsideAssistance,
  ...routesClaimsTheft,
  ...routesClaimsOther,
]
