import React, { FC, useMemo, useState } from 'react'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import PageWrapper from '@dg-shared/PageWrapper'
import { ExternalSubmitBtn, LDContainerV2FSM, LMBasicV3FSM } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import { FORM_ID, TRANSLATIONS as T } from '../../../constants'
import { formatPolicyDate, getDataComponentName } from '@dg-util'
import { Card, Divider } from '@material-ui/core'
import Loading from '../../qb/Loading/Page'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import PolicyReviewCardHeader from '@dg-shared/PolicyReviewCardHeader'
import useLogic from './useLogic'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import PolicyReviewVehicleDetails from '@dg-shared/PolicyReviewVehicleDetails'
import PolicyVerifyCard from '@dg-shared/PolicyVerifyCard'
import { FormState } from 'final-form'
import { VerifyFormValues } from '../../../types/interface/form.interface'
import { Events } from '../../../FSM/shared/constants'
import useReactQueryParty from '../../../hooks/shared/useReactQueryParty'
import {
  definePolicyChangeStartDate,
  definePolicyNotChangeDate,
} from '../../../util/defineOfferDate'
import { ProductName } from '../../../types/interface/quote.interface'
import { PolicyReviewCardProps } from '../../../types'
import PolicyReviewTravelDetails from '@dg-shared/PolicyReviewTravelDetails'
import PolicyReviewContentDetails from '@dg-shared/PolicyReviewContentDetails'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backgroundImage: {
      [theme.breakpoints.up('md')]: {
        ...theme.brand.assets.pageBackgrounds.verify,
      },
    },
    columns: {
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(3),
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      gap: theme.spacing(3),
      '&>div': {
        flex: '2 1 auto',
      },
    },
    actionButtons: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        '& > button:not(:last-child)': {
          marginBottom: theme.spacing(3),
        },
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyItems: 'center',
        justifyContent: 'center',
        '& > button:not(:last-child)': {
          marginRight: theme.spacing(2),
        },
      },
    },
    headerDate: {
      display: 'none',
    },
    cardWrapper: {
      marginTop: 14,
    },
    headerContainer: {
      margin: theme.spacing(3),
      padding: 0,
      paddingBottom: theme.spacing(1),
      marginBottom: 0,
    },
    externalButton: {
      marginTop: theme.spacing(3),
    },
    divider: {
      opacity: 0.4,
      margin: `0 ${theme.spacing(3)}px 0  ${theme.spacing(3)}px`,
    },
  })
)

const PagePolicyVerify: FC<CommonPageProps> = ({ service }) => {
  const { t } = useTranslate(T.POLICY_VERIFY)
  const classes = useStyles()
  const { backBtn, isLoading, policy, productProps, changePolicy } = useLogic()
  const { updateParty, party, isLoading: isPartyLoading } = useReactQueryParty()
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false)

  // const definedDate = defineDate(quote, Number(formValue.insuranceCompany))
  // TODO: FIX this part. definedDate should be calculated per Partner and Product for policy (not Quote)
  const definedDate = useMemo(() => {
    if (new Date(policy.InsuredFrom) < new Date()) {
      return definePolicyNotChangeDate(1)
    } else {
      return definePolicyChangeStartDate(policy)
    }
  }, [policy])

  const [formValue, setFormValue] = useState<VerifyFormValues>({})

  if (isLoading || isPartyLoading) {
    return <Loading />
  }

  const externalSubmitBtn = (
    <ExternalSubmitBtn
      customClassName={classes.externalButton}
      text={t('submit')}
      targetId={FORM_ID.POLICY_VERIFY}
      disabled={btnDisabled}
    />
  )

  const submitFormHandler = async (values: VerifyFormValues) => {
    await updateParty({
      Email: values.email,
      MobilePhone: values.phone,
    })

    const changeDate = formatPolicyDate(values.policyDate)
    await changePolicy({ changeDate, ...policy })

    service.send(Events.NEXT)
  }

  const policyReviewCardDetailsPerProduct: Record<ProductName, React.FC<PolicyReviewCardProps>> = {
    [ProductName.VEHICLE]: PolicyReviewVehicleDetails,
    [ProductName.TRAVEL]: PolicyReviewTravelDetails,
    [ProductName.CONTENT]: PolicyReviewContentDetails,
  }

  const PolicyDetails = policyReviewCardDetailsPerProduct[policy.productName]

  const content = policy && party && (
    <div data-componentname={getDataComponentName(__filename)} className={classes.cardWrapper}>
      <Card data-testid='verify-policy-card' elevation={3} className={classes.backgroundImage}>
        <PolicyReviewCardHeader
          policy={policy}
          classDate={classes.headerDate}
          classContainer={classes.headerContainer}
        />
        <Divider className={classes.divider} />
        <div className={classes.columns}>
          <div>
            <FormBuilder
              id={FORM_ID.POLICY_VERIFY}
              filledFieldsData={formValue}
              handleBtnDisabled={setBtnDisabled}
              initialValues={{
                policyDate: definedDate.selected,
                email: party.Email,
                phone: party.MobilePhone?.slice(3), // remove first 3 numbers since it is a code (ex. +47)
              }}
              setFormState={({ values }: FormState<never>): void => setFormValue(values)}
              validationArgs={{ ...definedDate }}
              submitHandler={submitFormHandler}
              isFormControllable
              customSubmit
            />
            <PolicyDetails policy={policy} productProps={productProps} />
          </div>
          <div>
            <PolicyVerifyCard policy={policy} />
          </div>
        </div>
      </Card>
      <section className={classes.actionButtons}>{externalSubmitBtn}</section>
    </div>
  )

  return (
    <PageWrapper
      title={t('title')}
      titleHeader={t('title')}
      mobile={LMBasicV3FSM}
      desktop={LDContainerV2FSM}
      breadcrumbsBtnText={t(backBtn.text)}
    >
      {content}
    </PageWrapper>
  )
}

export default PagePolicyVerify
