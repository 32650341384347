import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Card } from '@material-ui/core'
import { VEHICLE_COVERAGE_TYPE_MAP } from '../../../../constants'
import { formatWithThousandSpace } from '../../../../service/util.service'
import { Events } from '../../../../FSM/shared/constants'
import { Typography, FSMBtnNext } from '@dg-shared'
import { useTranslate, TranslatedTextType } from '../../../../hooks/shared'
import { TFunction } from '../../../../hooks/shared/useTranslate'
import { ServiceSend } from '../../../../types/interface/fsm.interface'
import { PartnerName } from 'src/types/interface/quote.interface'

const useCardPriceStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: 'flex',
      flexDirection: 'column',
      width: '300px',
      alignItems: 'center',
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
      '&:first-child': {
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
    price: {
      marginBottom: theme.spacing(0),
      marginTop: theme.spacing(3),
    },
    btnNext: {
      maxWidth: '240px', // From Figma layout
      alignSelf: 'center',
      padding: '5px',
      height: theme.spacing(4),
      ...theme.brand.assets.layout?.ctaOverride,
    },
    priceSubtitle: {
      marginBottom: theme.spacing(3),
    },
  })
)

type RegNumMapQuoteAndOffer = {
  quote: string
  offer: string | null
}

interface CardPriceProps {
  annotation: TranslatedTextType
  price: string
  priceText: TranslatedTextType
  send?: ServiceSend
  btnText?: TranslatedTextType
  regNumMap?: RegNumMapQuoteAndOffer
  coverageName?: string
  testId?: string
}

const getCardText = (t: TFunction, coverageName: string | null, regNum: RegNumMapQuoteAndOffer) => {
  let coverageText = ''
  let regNumText = ''

  if (regNum.offer && regNum.offer === regNum.quote) {
    regNumText = t(
      `registrationNum`,
      {
        regNum: regNum.offer,
      },
      'string'
    )
  }

  if (
    Object.values(VEHICLE_COVERAGE_TYPE_MAP[PARTNER_NAME as PartnerName]).includes(coverageName)
  ) {
    coverageText = regNumText ? `- ${coverageName}` : coverageName
  }

  return `${regNumText} ${coverageText}`
}

const CardPrice: FC<CardPriceProps> = ({
  annotation,
  btnText,
  send,
  price,
  priceText,
  regNumMap,
  coverageName,
  testId,
}) => {
  const classes = useCardPriceStyles()
  const { t } = useTranslate()

  return (
    <Card className={classes.card} data-testid={testId}>
      <Typography testId='annotation'>{annotation}</Typography>
      <Typography
        size='xLarge'
        modifiers={['bold']}
        className={classes.price}
        color='textMain'
        testId='price'
      >
        {formatWithThousandSpace(price)}
      </Typography>
      <Typography className={classes.priceSubtitle} testId='priceText'>
        {priceText}
      </Typography>
      {!!regNumMap && (
        <Typography testId='regNum'>{getCardText(t, coverageName, regNumMap)}</Typography>
      )}
      {btnText && (
        <FSMBtnNext
          handleClick={() => send(Events.NEXT)}
          buttonText={btnText}
          customClassName={classes.btnNext}
          testId='button-next'
        />
      )}
    </Card>
  )
}

export default CardPrice
