import { FSMCommonTransitions } from '../../constants'
import { QBFind, QBReview, QBOffers, QBOwnership, QBChooseAddress } from '../../../../pages/qb'
import { QBCompareInit } from '../../../../pages/qb/Insurely'
import QBComparePage from '../../../../pages/qb/Insurely/Compare/QBComparePage'
import QBCompareListPage from '../../../../pages/qb/Insurely/CompareList/QBCompareListPage'
import { StateRoute } from '../../../../types/interface/fsm.interface'
import { FSMCommonStatePaths } from '../../routes'

const {
  QB_FIND,
  QB_REVIEW,
  QB_OWNER,
  QB_OWNERSHIP,
  QB_EXTRA_OFFERS,
  QB_EXTRA_OFFERS_LIST,
  QB_EXTRA_FIND_EMPTY,
  QB_EXTRA_FIND_CUSTOM,
  QB_CHOOSE_ADDRESS,
  INSURELY,
  INSURELY_COMPARE_INIT,
  INSURELY_COMPARE,
  INSURELY_COMPARARISON_LIST,
} = FSMCommonTransitions

export const FSMQBVehicleStatePathsNO = [
  {
    is: QB_FIND,
    page: QBFind,
  },
  {
    is: { [QB_OWNERSHIP]: QB_REVIEW },
    page: QBReview,
  },
  {
    is: { [QB_OWNERSHIP]: QB_OWNER },
    page: QBOwnership,
  },
  {
    is: { [QB_EXTRA_OFFERS]: QB_EXTRA_OFFERS_LIST },
    page: QBOffers,
  },
  {
    is: { [QB_EXTRA_OFFERS]: QB_EXTRA_FIND_EMPTY },
    page: QBFind,
  },
  {
    is: { [QB_EXTRA_OFFERS]: QB_EXTRA_FIND_CUSTOM },
    page: QBFind,
  },
  {
    is: { [INSURELY]: INSURELY_COMPARE_INIT },
    page: QBCompareInit,
  },
  {
    is: { [INSURELY]: INSURELY_COMPARE },
    page: QBComparePage,
  },
  {
    is: { [INSURELY]: INSURELY_COMPARARISON_LIST },
    page: QBCompareListPage,
  },
]

export const FSMVehicleStatePathsNO: Array<StateRoute> = [
  ...FSMCommonStatePaths,
  ...FSMQBVehicleStatePathsNO,
]

export const FSMQBTravelStatePathsNO = [
  {
    is: QB_REVIEW,
    page: QBReview,
  },
]

export const FSMTravelStatePathsNO: Array<StateRoute> = [
  ...FSMCommonStatePaths,
  ...FSMQBTravelStatePathsNO,
]

export const FSMQBContentStatePathsNO = [
  {
    is: QB_REVIEW,
    page: QBReview,
  },
  {
    is: QB_FIND,
    page: QBFind,
  },
  {
    is: QB_CHOOSE_ADDRESS,
    page: QBChooseAddress,
  },
]

export const FSMContentStatePathsNO: Array<StateRoute> = [
  ...FSMCommonStatePaths,
  ...FSMQBContentStatePathsNO,
]
