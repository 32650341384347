import { PartnerName } from '../types/interface/quote.interface'

export { BUILD_CONSTANTS } from './build-constants'
export * from './scenarios/claim-scenario'
export * from './forms-constants'
export * from './insurance-constants'
export * from './route-constants'
export * from './translations-constants'
export * from './offer-constants'
export * from './error-constants'
export * from './icon-constants'

// This mapping caused by the fact that i2i product props names and Quote/Policy field names are not the same
// should be unified on a backend
export const productPropsFieldNamesMap: Record<string, string> = {
  CarBonus: 'bonus',
  CarComprehensiveDeductible: 'deductible',
  CarPackage: 'coverageType',
  AnnualMileage: 'mileageAnnual',
  SumInsuredContents: 'sumInsuredContents',
  DeductibleContents: 'deductibleContents',
  Residents: 'residents',
  TravelPreviousClaims: 'claimsPrev',
  PreviousClaims: 'previousClaims',
  TravelCoverArea: 'coverArea',
  TravelCoverage: 'coverage',
}

export const localizedPartnerName: Record<PartnerName, string> = {
  [PartnerName.skoda]: 'Škoda',
  [PartnerName.audi]: 'Audi',
  [PartnerName.volkswagen]: 'Volkswagen',
  [PartnerName.nord]: 'Nord',
  [PartnerName.instabank]: 'Instabank',
  [PartnerName.circlek]: 'CircleK',
}
