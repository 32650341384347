import React, { FC, useEffect, useState } from 'react'
import { Typography, FNOLPageWrapper } from '@dg-shared'
import { useTranslate, useClaim } from '../../../hooks/shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import { FLOW_THEFT_QUESTIONS, FORM_ID, TRANSLATIONS as T } from '../../../constants'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import { useFNOLStyles } from '../shared/styles/useStyles'

interface PoliceCaseFormValues {
  caseNumber?: number
}

const PagePoliceCase: FC<CommonPageProps> = ({ service, currentState }) => {
  const { t } = useTranslate(`${T.FNOL_THEFT}.policeCase`)
  const [selectedDataFields, setSelectedDataFields] = useState<PoliceCaseFormValues>({})
  const FNOLClasses = useFNOLStyles({ externalLink: true })
  const { handleAnswerClick, clearAnswer } = useClaim(service, currentState)

  useEffect(() => {
    clearAnswer(FLOW_THEFT_QUESTIONS.POLICE_CASE)
  }, [clearAnswer])

  const handleSubmit = async ({ caseNumber }: PoliceCaseFormValues) => {
    await handleAnswerClick(FLOW_THEFT_QUESTIONS.POLICE_CASE, caseNumber, Events.NEXT)
  }

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t('back')} breadcrumbsStepPercentage={90}>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <Typography testId='subtitle' className={FNOLClasses.description}>
        {t('subtitle')}
      </Typography>
      <FormBuilder
        id={FORM_ID.POLICE_CASE}
        classes={FNOLClasses.formContainer}
        setFormState={({ values }) => setSelectedDataFields(values)}
        submitHandler={handleSubmit}
        filledFieldsData={selectedDataFields}
        isFormControllable
        optional
      />
    </FNOLPageWrapper>
  )
}

export default PagePoliceCase
