import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Card, CardMedia, CardContent, CardActionArea } from '@material-ui/core'

import { OfferSearchItemProps } from '../../../types/interface/offer.interface'
import { ProductWrapper, Typography } from '@dg-shared'
import searchCarImg from '@dg-images/search-car.png'
import { PartnerName, ProductName } from '../../../types/interface/quote.interface'

const useOffersStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: '100%',
      maxWidth: '340px',
      '&:hover': {
        boxShadow: `0 0 10px ${theme.palette.primary.main}`,
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    },
    button: {
      height: '100%',
      display: 'flex',
      padding: theme.spacing(2),
      flexDirection: 'column',
    },
    image: {
      height: '88px',
      width: '111px',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
    content: {
      width: '100%',
      padding: '0',
      display: 'flex',
      flexDirection: 'column',
    },
  })
)

const OfferSearchLink: FC<OfferSearchItemProps> = ({ translations, handleClick }) => {
  const classes = useOffersStyles()
  const IMG_TITLE = 'Example Car'

  return (
    <Card elevation={3} className={classes.card}>
      <CardActionArea onClick={handleClick} className={classes.button}>
        <ProductWrapper
          partners={[PartnerName.circlek, PartnerName.instabank]}
          products={[ProductName.VEHICLE]}
        >
          <CardMedia
            component='img'
            alt={IMG_TITLE}
            image={searchCarImg}
            title={IMG_TITLE}
            className={classes.image}
          />
        </ProductWrapper>
        <CardContent className={classes.content}>
          <Typography type='h2'>{translations.CTAQuestion}</Typography>
          <Typography>{translations.CTALink}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default OfferSearchLink
