import React, { FC } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { ThemeBrandColorsType } from 'BrandTheme/types'

import { partnersIcons } from '../../constants'
import { PartnerName } from '../../types/interface/quote.interface'

interface BrandIconSharedProps {
  type: string
  customClass?: string
  size?: number
  weight?: number
  color?: keyof ThemeBrandColorsType
  onClick?: () => void
  action?: boolean
}

const BrandIconShared: FC<BrandIconSharedProps> = ({
  type,
  customClass,
  color = 'main',
  onClick,
  action = false,
}) => {
  const theme = useTheme()
  const brandColor = theme.brand.colors[color]
  const iconComponent = partnersIcons[PARTNER_NAME as PartnerName].components[type]
  const iconProps = partnersIcons[PARTNER_NAME as PartnerName].props[type]
  const createdComponent = React.createElement(iconComponent, {
    ...iconProps,
    // This is a color set for the custom icon component CircleKSVGIcon (used as a CircleK custom icon)
    color: brandColor,
    // This is a color set for the material ui SvgIcon component (used as a default partner icon)
    htmlColor: brandColor,
    className: customClass ? customClass : null,
  })

  if (action) {
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    return <div onClick={onClick}>{createdComponent}</div>
  }

  return createdComponent
}

export default BrandIconShared
