import { Events, FSMCommonTransitions } from '../../constants'
import { has, get } from 'lodash'
import { ContextType } from '../../config'

const {
  FNOL_CLAIMS,
  FNOL_CLAIMS_LIST,
  FNOL_COLLISION_FLOW,
  FNOL_COLLISION_WHAT_HAPPENED,
  FNOL_COLLISION_HOW_MANY,
  FNOL_COLLISION_ANIMAL,
  FNOL_COLLISION_OBJECT,
  FNOL_COLLISION_RESPONSIBLE,
  FNOL_COLLISION_INJURED,
  FNOL_COLLISION_CASE_OF_ACCIDENT,
  FNOL_COLLISION_REGISTRATION_NUM,
  FNOL_COLLISION_WHEN_WHERE,
  FNOL_COLLISION_ACCIDENT_FORM,
  FNOL_COLLISION_DAMAGE_REPORT,
  FNOL_ADDITIONAL_INFORMATION,
  FNOL_COLLISION_MORE_DETAILS,
  FNOL_TIME_LINE,
  MY_PAGES,
  HISTORY,
} = FSMCommonTransitions

export default (FLOW_MACHINE_ID: string) => ({
  [FNOL_COLLISION_FLOW]: {
    initial: FNOL_COLLISION_WHAT_HAPPENED,
    states: {
      [FNOL_COLLISION_WHAT_HAPPENED]: {
        on: {
          [Events.BACK]: `#${FLOW_MACHINE_ID}.${FNOL_CLAIMS}.${FNOL_CLAIMS_LIST}`,
          [Events.FNOL_COLLISION_HOW_MANY]: FNOL_COLLISION_HOW_MANY,
          [Events.FNOL_COLLISION_ANIMAL]: FNOL_COLLISION_ANIMAL,
          [Events.FNOL_COLLISION_OBJECT]: FNOL_COLLISION_OBJECT,
          [Events.FNOL_COLLISION_INJURED]: {
            target: FNOL_COLLISION_INJURED,
            actions: 'setBackPath',
          },
        },
      },
      [FNOL_COLLISION_HOW_MANY]: {
        on: {
          [Events.BACK]: FNOL_COLLISION_WHAT_HAPPENED,
          [Events.FNOL_COLLISION_RESPONSIBLE]: FNOL_COLLISION_RESPONSIBLE,
        },
      },
      [FNOL_COLLISION_OBJECT]: {
        on: {
          [Events.BACK]: FNOL_COLLISION_WHAT_HAPPENED,
          [Events.NEXT]: { target: FNOL_COLLISION_INJURED, actions: 'setBackPath' },
        },
      },
      [FNOL_COLLISION_ANIMAL]: {
        on: {
          [Events.BACK]: FNOL_COLLISION_WHAT_HAPPENED,
          [Events.NEXT]: { target: FNOL_COLLISION_INJURED, actions: 'setBackPath' },
        },
      },
      [FNOL_COLLISION_RESPONSIBLE]: {
        on: {
          [Events.BACK]: FNOL_COLLISION_HOW_MANY,
          [Events.FNOL_COLLISION_INJURED]: {
            target: FNOL_COLLISION_INJURED,
            actions: 'setBackPath',
          },
        },
      },
      [FNOL_COLLISION_INJURED]: {
        on: {
          [Events.BACK]: [
            {
              target: FNOL_COLLISION_WHAT_HAPPENED,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_COLLISION_FLOW) &&
                get(ctx.backPath, FNOL_COLLISION_FLOW) === FNOL_COLLISION_WHAT_HAPPENED,
            },
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_ANIMAL}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_COLLISION_FLOW) &&
                get(ctx.backPath, FNOL_COLLISION_FLOW) === FNOL_COLLISION_ANIMAL,
            },
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_RESPONSIBLE}`,
              cond: (ctx: ContextType) =>
                [
                  FNOL_COLLISION_RESPONSIBLE,
                  FNOL_COLLISION_CASE_OF_ACCIDENT,
                  FNOL_COLLISION_ACCIDENT_FORM,
                  FNOL_COLLISION_INJURED,
                ].includes(get(ctx.backPath, FNOL_COLLISION_FLOW)),
            },
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_OBJECT}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_COLLISION_FLOW) &&
                get(ctx.backPath, FNOL_COLLISION_FLOW) === FNOL_COLLISION_OBJECT,
            },
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_RESPONSIBLE}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_COLLISION_FLOW) &&
                get(ctx.backPath, FNOL_COLLISION_FLOW) === FNOL_COLLISION_DAMAGE_REPORT,
            },
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${HISTORY}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_COLLISION_FLOW) &&
                get(ctx.backPath, FNOL_COLLISION_FLOW) === FNOL_COLLISION_MORE_DETAILS,
            },
            // Default back to FNOL_COLLISION_WHAT_HAPPENED state
            {
              target: FNOL_COLLISION_WHAT_HAPPENED,
            },
          ],
          [Events.NEXT]: [
            {
              target: FNOL_COLLISION_CASE_OF_ACCIDENT,
              cond: (ctx: ContextType) =>
                [
                  FNOL_COLLISION_RESPONSIBLE,
                  FNOL_COLLISION_CASE_OF_ACCIDENT,
                  FNOL_COLLISION_INJURED,
                  FNOL_COLLISION_DAMAGE_REPORT,
                ].includes(get(ctx.backPath, FNOL_COLLISION_FLOW)),
              actions: 'setBackPath',
            },
            {
              target: FNOL_COLLISION_MORE_DETAILS,
              action: 'setBackPath',
            },
          ],
        },
      },
      [FNOL_COLLISION_CASE_OF_ACCIDENT]: {
        on: {
          [Events.BACK]: {
            target: FNOL_COLLISION_INJURED,
          },
          [Events.FNOL_COLLISION_REGISTRATION_NUM]: {
            target: FNOL_COLLISION_REGISTRATION_NUM,
            actions: 'setBackPath',
          },
        },
      },
      [FNOL_COLLISION_REGISTRATION_NUM]: {
        on: {
          [Events.BACK]: FNOL_COLLISION_CASE_OF_ACCIDENT,
          [Events.NEXT]: { target: FNOL_COLLISION_WHEN_WHERE, action: 'setBackPath' },
        },
      },
      [FNOL_COLLISION_WHEN_WHERE]: {
        on: {
          [Events.BACK]: [
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_REGISTRATION_NUM}`,
              cond: (ctx: ContextType) =>
                (has(ctx.backPath, FNOL_COLLISION_FLOW) &&
                  get(ctx.backPath, FNOL_COLLISION_FLOW) === FNOL_COLLISION_CASE_OF_ACCIDENT) ||
                get(ctx.backPath, FNOL_COLLISION_FLOW) === FNOL_COLLISION_WHEN_WHERE,
            },
            // Case when user navigates back from FNOL_COLLISION_ACCIDENT_FORM
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_MORE_DETAILS}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_COLLISION_FLOW) &&
                get(ctx.backPath, FNOL_COLLISION_FLOW) === FNOL_COLLISION_WHAT_HAPPENED,
            },
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_REGISTRATION_NUM}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_COLLISION_FLOW) &&
                get(ctx.backPath, FNOL_COLLISION_FLOW) === FNOL_COLLISION_ACCIDENT_FORM,
            },
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_REGISTRATION_NUM}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_COLLISION_FLOW) &&
                get(ctx.backPath, FNOL_COLLISION_FLOW) === FNOL_COLLISION_DAMAGE_REPORT,
            },
            // Default Back
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_MORE_DETAILS}`,
              actions: 'setBackPath',
            },
          ],
          [Events.NEXT]: [
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_ACCIDENT_FORM}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_COLLISION_FLOW) &&
                get(ctx.backPath, FNOL_COLLISION_FLOW) === FNOL_COLLISION_CASE_OF_ACCIDENT,
            },
            // Default Next
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_ADDITIONAL_INFORMATION}`,
              actions: 'setBackPath',
            },
          ],
        },
      },
      [FNOL_COLLISION_ACCIDENT_FORM]: {
        on: {
          [Events.BACK]: FNOL_COLLISION_WHEN_WHERE,
          [Events.NEXT]: { target: FNOL_COLLISION_DAMAGE_REPORT, actions: 'setBackPath' },
          [Events.FNOL_ADDITIONAL_INFORMATION]: {
            target: FNOL_ADDITIONAL_INFORMATION,
            actions: 'setBackPath',
          },
        },
      },
      [FNOL_COLLISION_DAMAGE_REPORT]: {
        on: {
          [Events.BACK]: FNOL_COLLISION_ACCIDENT_FORM,
          [Events.NEXT]: { target: FNOL_ADDITIONAL_INFORMATION, actions: 'setBackPath' },
        },
      },
      [FNOL_ADDITIONAL_INFORMATION]: {
        on: {
          [Events.BACK]: [
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_DAMAGE_REPORT}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_COLLISION_FLOW) &&
                get(ctx.backPath, FNOL_COLLISION_FLOW) === FNOL_COLLISION_DAMAGE_REPORT,
            },
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_ACCIDENT_FORM}`,
              cond: (ctx: ContextType) =>
                has(ctx.backPath, FNOL_COLLISION_FLOW) &&
                get(ctx.backPath, FNOL_COLLISION_FLOW) === FNOL_COLLISION_ACCIDENT_FORM,
            },
            // Default Back
            {
              target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_WHEN_WHERE}`,
            },
          ],
          [Events.NEXT]: FNOL_TIME_LINE,
        },
      },
      [FNOL_COLLISION_MORE_DETAILS]: {
        on: {
          [Events.BACK]: {
            target: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}.${FNOL_COLLISION_INJURED}`,
          },
          [Events.NEXT]: FNOL_COLLISION_WHEN_WHERE,
        },
      },
      [FNOL_TIME_LINE]: {
        on: {
          [Events.BACK]: `#${FLOW_MACHINE_ID}.${MY_PAGES}`,
          [Events.NEXT]: `#${FLOW_MACHINE_ID}.${MY_PAGES}`,
        },
      },
      [HISTORY]: {
        type: 'history',
        history: 'deep',
      },
    },
  },
})
