import { createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) =>
  createStyles({
    infoBox: {
      background: theme.brand.colors.clean,
      padding: theme.spacing(4),
      borderRadius: theme.spacing(1),
      marginLeft: -theme.spacing(4),
      marginRight: -theme.spacing(4),
    },
  })
)

const InfoBox = ({ children, className }: { children: React.ReactNode; className: string }) => {
  const { infoBox } = useStyles()

  return <div className={[className, infoBox].join(' ')}>{children}</div>
}

export default InfoBox
