import React, { FC, ReactNode } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'
import { TRANSLATIONS as T } from '../../constants'
import { PolicyV2Type, CorrespondenceV2Type } from '../../types/ApiV2'

import { NoDataCard } from '@dg-shared'
import { useTranslate } from '../../hooks/shared'
import PolicyDocumentCard from './PolicyDocumentCard'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(2),
    },
    documentList: {
      width: '100%',
    },
  })
)

const PolicyDocumentsList: FC<{
  handleClick: (correspondenceId: number) => Promise<void>
  className?: string
  policy: Partial<PolicyV2Type>
  children?: ReactNode
}> = ({ handleClick, className, policy, children }) => {
  const { t } = useTranslate(T.DOCUMENTS)
  const classes = useStyles()

  return !isEmpty(policy.Correspondence) ? (
    <div className={[classes.documentList, className].join(' ')} data-testid={'document-list'}>
      {children}
      {policy.Correspondence.map((item: CorrespondenceV2Type, index: number) => {
        return (
          <PolicyDocumentCard
            key={`Card-${index}`}
            data={item}
            openFile={handleClick}
            docType={t('card.productType')}
          />
        )
      })}
    </div>
  ) : (
    <NoDataCard>{children}</NoDataCard>
  )
}

export default PolicyDocumentsList
