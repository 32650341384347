// Mock Request Body for testting & debugging
import { IS_PRODUCTION } from '../config'

const DEBUG_REQUEST_BODY_STORAGE_KEY = 'DEBUG_REQUEST_BODY_STORAGE_KEY'

export const getDebugRequestBody = () => {
  // Disable for PROD
  if (IS_PRODUCTION) return undefined

  return JSON.parse(localStorage.getItem(DEBUG_REQUEST_BODY_STORAGE_KEY)) || undefined
}
