import React, { FC, useState } from 'react'
import ImageUploading, { ErrorsType } from 'react-images-uploading'
import { FormControl, Button } from '@material-ui/core'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../../constants'
import { IconType } from '../../../types/interface/icon.interface'
import { BrandIconShared, Typography } from '@dg-shared'
import { useTranslate, TranslatedTextType } from '../../../hooks/shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 5,
    },
    formControl: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: '345px',
      },
      textAlign: 'left',
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(5),
    },
    image: {
      marginBottom: theme.spacing(5),
    },
    selectedImage: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(5),
      cursor: 'pointer',
      '& img': {
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
    uploadBtn: {
      [theme.breakpoints.up('md')]: {
        maxWidth: '345px',
      },
      height: theme.spacing(6),
      border: `1px dashed ${theme.palette.action.disabled}`,
      '&:hover': {
        backgroundColor: theme.palette.background.default,
        '& .MuiSvgIcon-root': {
          color: theme.brand.colors.textInactive,
        },
      },
      '& span': {
        textTransform: 'none',
        fontSize: theme.spacing(2),
        fontWeight: 400,
        color: theme.palette.text.disabled,
      },
    },
    deleteBtn: {
      '&:hover': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
    },
  })
)

export interface Image {
  data_url: string
  file: File
}

interface ImageUploadProps {
  images: Array<Image>
  setImages(imageList: Array<Image>): void
  maxUploadFileSize?: number
}

const maxUploadFileSizeBytes = 31457280 // 30mb

const RFFCustomImageUpload: FC<ImageUploadProps> = ({
  images,
  setImages,
  maxUploadFileSize = maxUploadFileSizeBytes,
}) => {
  const classes = useStyles()
  const { t } = useTranslate(`${T.FNOL_OTHER_ADDITIONAL_INFO}.attachImage`)

  const [selectedImage, setSelectedImage] = useState<number>()

  const onChange = async (imageList: Array<Image>) => {
    setImages(imageList)
  }

  const getErrorTranslations = (errors: ErrorsType | null): Array<TranslatedTextType> => {
    if (!errors) return []

    const errorNames = Object.entries(errors).map(([key, value]) => {
      return value ? key : null
    })

    return errorNames.map((name) => t(`error.${name}`))
  }

  return (
    <FormControl className={classes.formControl}>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={3}
        dataURLKey='data_url'
        maxFileSize={maxUploadFileSize}
        acceptType={['svg', 'png', 'jpg', 'jpeg', 'tiff', 'heif', 'heic']}
      >
        {({ imageList, onImageUpload, onImageRemove, dragProps, errors }) => (
          <section className={classes.wrapper}>
            {imageList.map((image, index) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <section
                key={index}
                className={selectedImage === index ? classes.selectedImage : classes.image}
                onClick={() => setSelectedImage(index)}
                data-testid={`image-${index}`}
              >
                {image.file.type !== 'image/heif' && image.file.type !== 'image/heic' ? (
                  <img src={image['data_url']} alt='' width='343' />
                ) : (
                  <Typography type='h5'>{image.file.name}</Typography>
                )}
                <Button
                  className={classes.deleteBtn}
                  onClick={() => onImageRemove(index)}
                  startIcon={<BrandIconShared type={IconType.REMOVE} />}
                  data-testid={`removeBtn-image-${index}`}
                >
                  {t('removeBtn', {}, 'string')}
                </Button>
              </section>
            ))}
            <Typography color={'textMain'}>{t('label')}</Typography>
            <Button
              fullWidth
              type={'button'}
              variant={'outlined'}
              size={'medium'}
              className={classes.uploadBtn}
              onClick={onImageUpload}
              startIcon={<BrandIconShared type={IconType.ADD} color={'textInactive'} />}
              disableRipple
              {...dragProps}
            >
              {t('placeholder', {}, 'string')}
            </Button>
            {getErrorTranslations(errors).map((error) => (
              <Typography key={error as string} type={'h5'} color={'textError'}>
                {error}
              </Typography>
            ))}
          </section>
        )}
      </ImageUploading>
      <Typography type={'h5'} color={'textDisclaimer'}>
        {t('message')}
      </Typography>
    </FormControl>
  )
}

export default RFFCustomImageUpload
