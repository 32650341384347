import React, { FC, useEffect, useState, ChangeEvent } from 'react'
import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api'
// NB: Documentation https://react-google-maps-api-docs.netlify.app/
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { pick, isEmpty } from 'lodash'

import { useTranslate } from '../../hooks/shared'
import { TRANSLATIONS as T } from '../../constants/translations-constants'
import {
  GOOGLE_MAP_API_KEY,
  GOOGLE_MAP_OPTIONS,
  GOOGLE_MAPS_LIBRARIES,
} from '../../constants/google-map-constants'
import {
  AddressInterface,
  CurrentPosition,
  MapComponentInterface,
  MapsMouseEvent,
  PositionInterface,
} from '../../types/interface/map.interface'
import ButtonNext from './ButtonNext'
import apiV2 from '../../service/apiV2.service'
import { reactQueryErrorHandler } from '@dg-util'
import { FSMService } from '../../FSM'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapContainer: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: '0',
      zIndex: 0,
      [theme.breakpoints.down('sm')]: {
        top: '0',
        height: '100%',
      },
    },
    buttonNext: {
      bottom: '160px',
      left: '0',
      right: '0',
      margin: 'auto',
      width: '325px',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    searchGroup: {
      top: '100px',
      left: '20px',
      right: '0',
      zIndex: 1,
      width: '100%',
      display: 'flex',
      maxWidth: `350px`,
      borderRadius: `3px`,
      position: 'absolute',
      alignItems: 'center',
      backgroundColor: theme.palette.secondary.contrastText,
      border: `1px solid transparent`,
      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
      [theme.breakpoints.down('sm')]: {
        left: '0',
        margin: 'auto',
        top: '0',
        maxWidth: '100%',
      },
    },
    search: {
      lineHeight: `35px`,
      padding: `0 20px 0 12px`,
      fontSize: `1rem`,
      border: `none`,
      outline: `none`,
      width: '95%',
      maxWidth: '95%',
      flex: '95%',
    },
    clearSearchBtn: {
      flex: '5%',
      width: '5%',
      maxWidth: '5%',
      borderRadius: 0,
      color: theme.palette.info.main,
      borderLeft: `1px solid ${theme.palette.info.main}`,
    },
  })
)

const MapComponent: FC<MapComponentInterface> = ({ handleClick }) => {
  const { t } = useTranslate(T.CLAIMS)
  const classes = useStyles()
  const [currentPosition, setCurrentPosition] = useState<CurrentPosition>({ lat: 0, lng: 0 })
  const [autoComplete, setAutoComplete] = useState<google.maps.places.Autocomplete | null>(null)
  const [currentPositionAddress, setCurrentPositionAddress] = useState<AddressInterface | null>(
    null
  )

  useEffect(() => {
    ;(async () => {
      navigator.geolocation.getCurrentPosition(onLoadNavigatorLocation)
      await onLoadGoogleGeolocation()
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (!!currentPosition.lat && !!currentPosition.lng) {
        try {
          const res = await apiV2.geocodeLatLngUrl(currentPosition.lat, currentPosition.lng)

          const firstLocationItem = res?.results[0]
          const actualLocation = pick(firstLocationItem, [
            'formatted_address',
            'address_components',
          ])

          if (!isEmpty(actualLocation)) {
            await setCurrentPositionAddress(actualLocation)
            return
          }
        } catch (error) {
          reactQueryErrorHandler(error, FSMService.flowService)
        }
      }
    })()
    // eslint-disable-next-line
  }, [currentPosition.lat, currentPosition.lng])

  const onLoadGoogleGeolocation = async () => {
    try {
      const res = await apiV2.geolocateUrl()

      if (res?.location) {
        await setCurrentPosition(res.location)
        return
      }
    } catch (error) {
      reactQueryErrorHandler(error, FSMService.flowService)
    }
  }

  const onLoadNavigatorLocation = (position: PositionInterface) => {
    setCurrentPosition({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    })
  }

  const onLoadAutocomplete = (autocomplete: google.maps.places.Autocomplete) => {
    setAutoComplete(autocomplete)
  }

  const handleChangedPlace = () => {
    const { geometry } = !!autoComplete && autoComplete.getPlace()

    if (!geometry) {
      return
    }

    setCurrentPosition({
      lat: geometry.location.lat(),
      lng: geometry.location.lng(),
    })
  }

  const handleClickOnMap = (mapsMouseEvent: MapsMouseEvent) => {
    const { latLng } = mapsMouseEvent

    if (!latLng) {
      return
    }

    setCurrentPosition({
      lat: latLng.lat(),
      lng: latLng.lng(),
    })
  }

  const handleClearSearch = () => {
    setCurrentPositionAddress({
      formatted_address: '',
      address_components: [],
    })
  }

  const handleSavePosition = () => {
    handleClick(currentPositionAddress)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentPositionAddress({
      formatted_address: event.target.value,
      address_components: [],
    })
  }

  const search = (
    <Autocomplete onLoad={onLoadAutocomplete} onPlaceChanged={handleChangedPlace}>
      <div className={classes.searchGroup}>
        <input
          value={currentPositionAddress?.formatted_address}
          placeholder={t(`other.accidentForm.locationBtn`, {}, 'string')}
          className={classes.search}
          onChange={handleInputChange}
        />
        <Button
          onClick={handleClearSearch}
          className={classes.clearSearchBtn}
          endIcon={<Close color='inherit' />}
          disableRipple
        />
      </div>
    </Autocomplete>
  )

  return (
    <>
      <div className={classes.mapContainer}>
        <LoadScript googleMapsApiKey={GOOGLE_MAP_API_KEY} libraries={GOOGLE_MAPS_LIBRARIES}>
          {search}
          <GoogleMap
            zoom={13}
            center={currentPosition}
            mapContainerClassName={classes.mapContainer}
            options={GOOGLE_MAP_OPTIONS}
            onClick={handleClickOnMap}
          >
            {!!currentPosition.lat && <Marker position={currentPosition} />}
          </GoogleMap>
        </LoadScript>
        <ButtonNext
          handleClick={handleSavePosition}
          customClassName={classes.buttonNext}
          buttonText={t(`select`)}
        />
      </div>
    </>
  )
}

export default MapComponent
