import React, { FC, lazy, Suspense } from 'react'
import { getProductName } from '@dg-util'
import { QuoteV2Type } from '../../../types/ApiV2'
import { IconType } from '../../../types/interface/icon.interface'

interface CardVehicleProps {
  offer: Partial<QuoteV2Type>
}

const VerifyCardContent: FC<CardVehicleProps> = ({ offer }) => {
  const VerifyCardProductContent = lazy(() => import(`./VerifyCardContent.${getProductName()}.tsx`))

  return (
    <Suspense fallback={IconType.LOADING}>
      <VerifyCardProductContent offer={offer} />
    </Suspense>
  )
}

export default VerifyCardContent
