import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, Grid, Hidden } from '@material-ui/core'

import { LDContainerV2FSMProps } from '../../../types/interface/layouts.interface'
import { BrandedHeaderShared, FSMBreadcrumbs } from '@dg-shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: () => ({
      minHeight: '100vh',
      backgroundColor: theme.brand.colors.background,
      justifyContent: 'center',
      alignContent: 'start',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        minWidth: '750px',
        paddingBottom: theme.spacing(5),
      },
    }),
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        maxWidth: '968px',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      height: '80px',
      justifyContent: 'space-around',
    },
    wrapper: {
      width: '100%',
      display: 'flex',
      textAlign: 'center',
      flexDirection: 'column',
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(2)}px`,
      [theme.breakpoints.up('md')]: {
        maxWidth: '920px',
        margin: `${theme.spacing(2)}px auto auto`,
      },
    },
    content: {
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
  })
)

const LDContentCardFSM: FC<LDContainerV2FSMProps> = ({
  send,
  children,
  breadcrumbsBtnText,
  breadcrumbsStepPercentage,
  breadcrumbs = false,
  bgColor = '#FFF', // FIXME: Remove this prop when Background color will be the same on all pages
}) => {
  const classes = useStyles({ backgroundColor: bgColor })

  return (
    <Grid container direction='row' component='article' className={classes.container}>
      <Hidden mdUp={false} smDown={true}>
        <Grid item xs={12} className={classes.header}>
          <BrandedHeaderShared />
        </Grid>
      </Hidden>

      <Grid item xs={12}>
        {/* //FIXME: Remove !!breadcrumbsBtnText from the logic expression in case
          // to simplify the logic. For now we need it to avoid regression */}
        {breadcrumbs && (
          <FSMBreadcrumbs
            send={send}
            buttonText={breadcrumbsBtnText}
            stepsPercentage={breadcrumbsStepPercentage}
          />
        )}
      </Grid>

      <Card className={classes.wrapper}>
        <CardContent className={classes.content}>{children}</CardContent>
      </Card>
    </Grid>
  )
}
export default LDContentCardFSM
