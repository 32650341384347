import { createMachine, interpret } from 'xstate'

import CONFIG from '../config'
import { getFlowName, getProductName } from '@dg-util'
import BrandFSMInput from 'BrandFSMInput'
import { get } from 'lodash'
import {
  FlowMachineType,
  FlowServiceType,
  FSMStateType,
  StateRoute,
} from '../types/interface/fsm.interface'
import { getPersistedState, persistState, setClientHistory } from './util'

export class FSMService {
  flowService: FlowServiceType
  flowMachine: FlowMachineType
  routes: StateRoute[]
  currentState: FSMStateType
  onTransitionCallback(newState: FSMStateType): void {
    this.currentState = newState
  }
  initMachineCallbacks: ((fsmService: FSMService) => void)[] = []
  setInitMachineCallback(callBack: (fsmService: FSMService) => void): void {
    this.initMachineCallbacks.push(callBack)
  }

  constructor() {
    this.initMachine()
  }
  initMachine() {
    const flowName = getFlowName()
    const productName = getProductName()
    const input = get(BrandFSMInput, [flowName, productName])

    const flowMachine = createMachine(input.config, input.options)

    this.flowService = interpret(flowMachine, {
      devTools: CONFIG.DEV_TOOLS,
    }) as unknown as FlowServiceType
    this.flowMachine = flowMachine
    this.routes = input.routes
    this.initMachineCallbacks.forEach((initMachineCallback) => {
      initMachineCallback(this)
    })
  }
  restart() {
    this.flowService.stop()

    const persistedState = getPersistedState(this.flowMachine)

    this.flowService.onTransition(async (newState: FSMStateType) => {
      setClientHistory(newState.value)

      // Cache state in storage
      persistState(newState)

      this.onTransitionCallback(newState)
      this.currentState = newState
    })

    this.flowService.start(persistedState)
  }
}

export default new FSMService()
