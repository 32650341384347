import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { useTranslate, TranslatedTextType } from '../../../hooks/shared'

interface RFFCustomSubmitBtnProps {
  pristine: boolean
  submitting: boolean
  valid: boolean
  buttonText?: TranslatedTextType
  classesCustom?: string
  dataTestid?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    //TODO: REUSED!
    buttonSubmit: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.contrastText,
      ...theme.brand.assets.layout?.ctaOverride,
      fontSize: '1rem',
    },
  })
)

const RFFCustomSubmitBtn: FC<RFFCustomSubmitBtnProps> = ({
  submitting,
  pristine,
  valid,
  buttonText,
  classesCustom,
  dataTestid,
}) => {
  const classes = useStyles()
  const { t } = useTranslate()

  return (
    <Button
      variant='contained'
      type='submit'
      fullWidth
      disableElevation
      className={
        classesCustom ? [classesCustom, classes.buttonSubmit].join(' ') : classes.buttonSubmit
      }
      disabled={submitting || pristine || !valid}
      data-testid={dataTestid}
    >
      {buttonText ? buttonText : t(`next`)}
    </Button>
  )
}

export default RFFCustomSubmitBtn
