import fsmConfigPolicies from '../policies/config'
import { Events, FSMCommonTransitions } from '../../constants'
import { MachineConfigType } from '../../config'
import fsmConfigBankId from '../bankId/config'
import fsmConfigClaims from '../claims/config'
import guards from '../../guards'
import actions from '../../actions'
import { mergeDeepArray } from '../../../../service/util.service'

const { AUTH_BANK_ID, MY_PAGES, FNOL_CLAIMS, POLICIES, ERROR, AUTH } = FSMCommonTransitions

const FLOW_MACHINE_ID = 'MyPagesFlowMachineNO'

const FSMConfigMyPagesNO: MachineConfigType = {
  id: FLOW_MACHINE_ID,
  initial: AUTH_BANK_ID,
  context: {
    authorized: false,
    authMethod: '',
  },
  // TODO: Fix TS error that caused by the `cond: (ctx: ContextType, event: TransitionEvent)` in `fsmConfigClaimsOther.ts`
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  states: {
    // This will redirect from QB AUTH to MyPages AUTH_BANK_ID immediately
    [AUTH]: {
      always: AUTH_BANK_ID,
    },
    [MY_PAGES]: {
      on: {
        [Events.BACK]: { target: AUTH_BANK_ID, actions: 'signOut' },
        [Events.FNOL_CLAIMS]: { target: FNOL_CLAIMS, actions: 'setBackPath' },
        [Events.POLICIES]: { target: POLICIES, actions: 'setBackPath' },
      },
    },
    ...mergeDeepArray([
      fsmConfigBankId(FLOW_MACHINE_ID),
      fsmConfigClaims(FLOW_MACHINE_ID),
      fsmConfigPolicies(FLOW_MACHINE_ID),
    ]),
  },
  on: {
    [Events.ERROR]: {
      target: ERROR,
      actions: 'setBackPath',
    },
    [Events.SESSION_EXPIRED]: {
      target: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
      actions: ['setMessageQueueEvent', 'signOut'],
    },
    [Events.AUTH_ERROR]: {
      target: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
      actions: ['setMessageQueueEvent', 'signOut'],
    },
  },
}

const FSMOptionsMyPagesNO = { guards, actions }

export { FSMConfigMyPagesNO, FSMOptionsMyPagesNO }
