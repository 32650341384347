import { useState, useEffect } from 'react'
import { get } from 'lodash'

import { QUESTIONS } from '../../../../../constants'
import { Events } from '../../../../../FSM/shared/constants'
import { FlowServiceType, FSMStateType } from '../../../../../types/interface/fsm.interface'
import { REGEX_NUMBER_EMPTY_SPACE } from '../../../../../constants/regex-constants'
import { useClaim } from '../../../../../hooks/shared'
import { formatWithThousandSpace } from '../../../../../service/util.service'

interface CompensationFormValue {
  compensation?: string
}

interface CompensationHook {
  selectedDataFields: CompensationFormValue
  handleSubmit(values: CompensationFormValue): Promise<void>
  updateForm(values: CompensationFormValue): void
}

const useLogic = (service: FlowServiceType, currentState: FSMStateType): CompensationHook => {
  const [selectedDataFields, setSelectedDataFields] = useState<CompensationFormValue | null>(null)
  const { handleAnswerClick, claimAnswers, clearAnswer } = useClaim(service, currentState)

  useEffect(() => {
    clearAnswer(QUESTIONS.WHAT_IS_THE_DISPUTED_AMOUNT)
  }, [clearAnswer])

  useEffect(() => {
    const valueFromStorage = get(claimAnswers, QUESTIONS.WHAT_IS_THE_DISPUTED_AMOUNT)

    if (valueFromStorage) {
      setSelectedDataFields({
        compensation: formatWithThousandSpace(valueFromStorage as string | number),
      })
    }
  }, [claimAnswers])

  const handleSubmit = async (values: { compensation: string }) => {
    const compensation = parseInt(values.compensation.replace(REGEX_NUMBER_EMPTY_SPACE, ''), 10)

    await handleAnswerClick(QUESTIONS.WHAT_IS_THE_DISPUTED_AMOUNT, compensation, Events.NEXT)
  }

  return {
    selectedDataFields,
    handleSubmit,
    updateForm: setSelectedDataFields,
  }
}

export default useLogic
