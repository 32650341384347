import React, { FC } from 'react'
import { Typography } from '@dg-shared'
import { useTranslate } from '../hooks/shared'
import { TRANSLATIONS as T } from '../constants'
import useContentStyles from '../pages/qb/Verify/Content/useContentStyles'
import { PolicyVerifyCardContentProps } from '../types'
import { getDataComponentName } from '@dg-util'

const PolicyVerifyCardContentTravel: FC<PolicyVerifyCardContentProps> = ({ policy }) => {
  const { t } = useTranslate(`${T.OFFER_VERIFY}.card`)
  const classes = useContentStyles()
  const { CalculatedPrices } = policy

  return (
    <div data-componentname={getDataComponentName(__filename)}>
      <Typography type='h3'>{t(`card.title.${policy.productName}`)}</Typography>
      <Typography className={classes.textDetails}>
        {t(`productProps.${policy.TravelCoverage}`)}
      </Typography>
      <Typography className={classes.textDetails}>
        {t(`productProps.${policy.TravelCoverArea}`)}
      </Typography>

      <section className={`${classes.row} ${classes.spaceTop}`}>
        <Typography type='h3'>{t(`total`)}</Typography>
        <section className={classes.priceRow}>
          <Typography testId='total-price-travel-no' type='h3' className={classes.priceText}>
            {`${CalculatedPrices.priceMonthly + CalculatedPrices.taxMonthly}`}
          </Typography>
          <Typography className={classes.currency}>
            {t('priceMonth', { priceMonthly: null })}
          </Typography>
        </section>
      </section>

      <Typography
        testId='verify-card-content-travel-disclaimer'
        color='textDisclaimer'
        size='small'
      >
        {t('priceTotal', { priceTotal: policy.CalculatedPrices.priceTotal })}
      </Typography>
    </div>
  )
}

export default PolicyVerifyCardContentTravel
