import { Events, FSMCommonTransitions } from '../../../../../FSM/shared/constants'
import { mergeDeep } from '../../../../../service/util.service'
import actions from '../../../../../FSM/shared/actions'
import guards from '../../../../../FSM/shared/guards'
import sharedFSMconfig, { MachineConfigType } from '../../../../../FSM/shared/config'

const {
  AUTH,
  AUTH_CHOICE,
  AUTH_REGULAR,
  QB_REVIEW,
  QB_CHANGE,
  QB_VERIFY,
  QB_PURCHASED,
  QB_PURCHASED_FAILURE,
  QB_PURCHASED_DONE,
  HISTORY,
  ERROR,
} = FSMCommonTransitions

const FLOW_MACHINE_ID = 'QBFlowMachineTravel'

const FSMConfig: MachineConfigType = {
  id: FLOW_MACHINE_ID,
  initial: AUTH,
  context: {
    authorized: false,
    authMethod: '',
  },
  states: {
    [AUTH]: {
      initial: AUTH_CHOICE,
      states: {
        [AUTH_CHOICE]: {
          on: {
            CUSTOM_AUTH_SUCCESS: {
              target: `#${FLOW_MACHINE_ID}.${QB_REVIEW}`,
              actions: ['setUserAuthorized', 'setVippsAuth'],
            },
            CUSTOM_AUTH_FAILURE: {},
            NEXT: AUTH_REGULAR,
          },
        },
        [AUTH_REGULAR]: {
          on: {
            [Events.NEXT]: `#${FLOW_MACHINE_ID}.${QB_REVIEW}`,
            [Events.BACK]: AUTH_CHOICE,
          },
          exit: ['setUserAuthorized', 'setRegularAuth'],
        },
      },
    },
    [QB_REVIEW]: {
      on: {
        BACK: { target: AUTH, actions: 'signOut' },
        NEXT: QB_VERIFY,
        CHANGE: QB_CHANGE,
      },
    },
    [QB_CHANGE]: {
      on: {
        BACK: QB_REVIEW,
        NEXT: QB_REVIEW,
      },
    },
    [QB_VERIFY]: {
      on: {
        BACK: QB_REVIEW,
        PAYMENT_SUCCESS: QB_PURCHASED,
        PAYMENT_FAILURE: `${QB_PURCHASED}.${QB_PURCHASED_FAILURE}`,
        PAYMENT_CANCEL: QB_VERIFY,
      },
    },
    [QB_PURCHASED]: {
      initial: QB_PURCHASED_DONE,
      states: {
        [QB_PURCHASED_DONE]: {
          type: 'final',
          on: {
            [Events.BACK]: {
              target: `#${FLOW_MACHINE_ID}.${AUTH}`,
              actions: ['signOut'],
            },
          },
        },
        [QB_PURCHASED_FAILURE]: {
          type: 'final',
          on: {
            BACK: {
              target: `#${FLOW_MACHINE_ID}.${AUTH}`,
              actions: ['signOut'],
            },
          },
        },
        [HISTORY]: {
          type: 'history',
        },
      },
    },
    [ERROR]: {
      on: {
        [Events.BACK]: [
          {
            target: AUTH,
            actions: ['signOut'],
            cond: (ctx) => ctx.backPath === QB_REVIEW,
          },
        ],
      },
    },
  },
}

const FSMConfigTravelNO = mergeDeep(FSMConfig, sharedFSMconfig)
const FSMOptionsTravelNO = { guards, actions }

export { FSMConfigTravelNO, FSMOptionsTravelNO }
