import { Typography } from '@dg-shared/index'
import { useTranslate } from '../../hooks/shared'
import React, { FC } from 'react'
import useContentStyles from '../../hooks/shared/useContentStyles'
import { formatWithThousandSpace } from '../../service/util.service'
import { PolicyReviewCardProps } from '../../types'
import { getProductPropValue } from '@dg-util'

const PolicyReviewContentDetails: FC<PolicyReviewCardProps> = ({ policy, productProps }) => {
  const classes = useContentStyles({ isImage: true })
  const { t } = useTranslate()

  return (
    <div
      className={classes.column}
      data-testid={'coverage-data'}
      style={{ marginTop: 0, marginBottom: 0 }}
    >
      <div className={classes.row}>
        <Typography testId='review-coverage-SumInsuredContents'>
          {t(`SumInsuredContents`)}
        </Typography>
        <Typography
          testId='review-coverage-SumInsuredContents-value'
          className={classes.rightColItem}
        >
          {t(`${'priceWithCurrency'}`, {
            price: formatWithThousandSpace(policy.SumInsuredContents),
          })}
        </Typography>
      </div>
      <div className={classes.row}>
        <Typography testId='review-coverage-Alarm'>{t(`Alarm`)}</Typography>
        <Typography testId='review-coverage-Alarm-value' className={classes.rightColItem}>
          {policy.Alarm ? 'Ja' : 'Nei'}
        </Typography>
      </div>
      <div className={classes.row}>
        <Typography testId='review-coverage-DeductibleContents'>
          {t(`DeductibleContents`)}
        </Typography>
        <Typography
          testId='review-coverage-DeductibleContents-value'
          className={classes.rightColItem}
        >
          {t(`${'priceWithCurrency'}`, {
            price: formatWithThousandSpace(policy.DeductibleContents),
          })}
        </Typography>
      </div>
      <div className={classes.row}>
        <Typography testId='review-coverage-Residents'>{t(`Residents`)}</Typography>
        <Typography testId='review-coverage-Residents-value' className={classes.rightColItem}>
          {policy.Residents}
        </Typography>
      </div>
      <div className={classes.row}>
        <Typography testId='review-coverage-PreviousClaims'>{t(`PreviousClaims`)}</Typography>
        <Typography testId='review-coverage-PreviousClaims-value' className={classes.rightColItem}>
          {getProductPropValue(productProps.previousClaims, policy.PreviousClaims || 0)}
        </Typography>
      </div>
    </div>
  )
}

export default PolicyReviewContentDetails
