import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import useLogic from './useLogic'
import { LColThreeBasic } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import OfferSearchLink from './OfferSearchLink'
import OfferItem from './OfferItem'
import Loading from '../Loading/Page'
import { VehicleV2Type } from '../../../types/ApiV2'
import { getLocalizedPartnerName } from '@dg-util'

const useOffersStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      justifyContent: 'center',
      '&:not(last-child)': {
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.up('md')]: {
        width: '100%',
        display: 'flex',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '&:not(last-child)': {
          marginBottom: theme.spacing(4),
        },
      },
    },
  })
)

const Offers: FC<CommonPageProps> = ({ service, currentState }) => {
  const { t } = useTranslate(T.OFFERS)
  const classes = useOffersStyles()
  const { isLoading, vehicles, handleClick } = useLogic(service.send, currentState)
  const brandName = getLocalizedPartnerName()

  if (isLoading) {
    return <Loading />
  }

  const content = (
    <>
      {vehicles.map((vehicle: VehicleV2Type, idx: number) => (
        <Grid key={idx} item xs={12} md={4} className={classes.card}>
          <OfferItem
            vehicle={vehicle}
            translations={{
              regNum: t(`registrationNum`, { regNum: null }),
            }}
            handleClick={() => handleClick(vehicle.MotorRegistrationNumber)}
          />
        </Grid>
      ))}
      <Grid item xs={12} md={4} className={classes.card}>
        <OfferSearchLink
          translations={{
            CTAQuestion: t('CTA.question', { brandName }),
            CTALink: t('CTA.link'),
          }}
          handleClick={() => handleClick()}
        />
      </Grid>
    </>
  )

  return (
    <LColThreeBasic
      send={service.send}
      titleHeader={t('title')}
      breadcrumbs
      breadcrumbsBtnText={t(`logout`)}
      breadcrumbsStepPercentage={30}
      alignContent={vehicles.length < 3}
      transProps={
        <Trans
          i18nKey={t(`subtitle`, { count: vehicles.length })}
          values={{ brandName: brandName }}
        />
      }
    >
      {content}
    </LColThreeBasic>
  )
}

export default Offers
