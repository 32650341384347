import { useEffect, useState } from 'react'
import { get } from 'lodash'
import { format } from 'date-fns'

import { FORMAT_YEAR_MONTH_DAY_HYPHEN, QUESTIONS } from '../../../../../constants'
import { Events } from '../../../../../FSM/shared/constants'
import { FlowServiceType, FSMStateType } from '../../../../../types/interface/fsm.interface'
import { useClaim } from '../../../../../hooks/shared'

export interface DateFormValues {
  date: Date
}

interface LegalAidHook {
  selectedDataFields: DateFormValues
  handleSubmit(values: DateFormValues): Promise<void>
  updateForm(values: DateFormValues): void
}

const useLogic = (service: FlowServiceType, currentState?: FSMStateType): LegalAidHook => {
  const [selectedDataFields, setSelectedDataFields] = useState<DateFormValues | null>(null)
  const { handleAnswerClick, claimAnswers, clearAnswer } = useClaim(service, currentState)

  useEffect(() => {
    clearAnswer(QUESTIONS.WHEN_DID_DISPUTE_OCCURED)
  }, [clearAnswer])

  useEffect(() => {
    const savedDate = get(claimAnswers, [QUESTIONS.WHEN_DID_DISPUTE_OCCURED])

    if (savedDate) {
      setSelectedDataFields({ date: new Date(savedDate as string | number) })
    }
  }, [claimAnswers])

  const handleSubmit = async (values: { date: Date }) => {
    const answer = format(new Date(values.date), FORMAT_YEAR_MONTH_DAY_HYPHEN)

    await handleAnswerClick(QUESTIONS.WHEN_DID_DISPUTE_OCCURED, answer, Events.NEXT)
  }

  return {
    selectedDataFields,
    handleSubmit,
    updateForm: setSelectedDataFields,
  }
}

export default useLogic
