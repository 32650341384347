import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Card, CardContent, CardHeader } from '@material-ui/core'

import { TRANSLATIONS as T } from '../../constants/translations-constants'
import { useTranslate } from '../../hooks/shared'
import PolicyVerifyCardContentVehicle from '../PolicyVerifyCardContent.vehicle'
import { PolicyV2Type } from '../../types/ApiV2'
import { ProductName } from '../../types/interface/quote.interface'
import { PolicyVerifyCardContentProps } from '../../types'
import PolicyVerifyCardContentTravel from '../PolicyVerifyCardContent.travel'
import PolicyVerifyCardContentContent from '../PolicyVerifyCardContent.content'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      margin: '0 auto',
      maxWidth: '345px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(2),
      },
    },
    header: {
      backgroundColor: theme.brand.colors.cardHeader,
    },
    title: {
      color: theme.brand.colors.textCardHeader,
      fontSize: theme.spacing(3),
      fontWeight: 500,
    },
  })
)

interface PolicyVerifyCardProps {
  policy: Partial<PolicyV2Type>
}

const PolicyVerifyCard: FC<PolicyVerifyCardProps> = ({ policy }) => {
  const { t } = useTranslate(`${T.OFFER_VERIFY}.card`)
  const classes = useStyles()

  const policyVerifyCardContentPerProduct: Record<
    ProductName,
    React.FC<PolicyVerifyCardContentProps>
  > = {
    [ProductName.VEHICLE]: PolicyVerifyCardContentVehicle,
    [ProductName.TRAVEL]: PolicyVerifyCardContentTravel,
    [ProductName.CONTENT]: PolicyVerifyCardContentContent,
  }
  const PolicyVerifyCardContent = policyVerifyCardContentPerProduct[policy.productName]

  return (
    <Card elevation={3} className={classes.card}>
      <CardHeader
        titleTypographyProps={{
          className: classes.title,
        }}
        className={classes.header}
        title={t('title')}
      />
      <CardContent>
        <PolicyVerifyCardContent policy={policy} />
      </CardContent>
    </Card>
  )
}

export default PolicyVerifyCard
