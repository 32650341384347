import Sentry from './sentry.service'

import { PartnerName } from '../types/interface/quote.interface'
import config from '../../package.json'
import { ApiV2Error } from './apiV2.service'

const errorLoggerService = {
  partner: '',
  setPartner: (partnerName: PartnerName) => {
    errorLoggerService.partner = partnerName
  },
  getPartnerName: () => errorLoggerService.partner || PARTNER_NAME || 'unknown',
  captureMessage: (message: string, extra: Record<string, unknown>) => {
    Sentry.withScope((scope) => {
      scope.setTag('partner', errorLoggerService.getPartnerName())
      scope.setTag('app:version', config.version)

      scope.setExtras(extra)
      if (extra?.integration) {
        scope.setTag('integration', extra.integration as string)
      }

      Sentry.captureMessage(message, scope)
    })
  },
  captureException: (exception: Error | ApiV2Error, extra: Record<string, unknown>) => {
    Sentry.withScope((scope) => {
      scope.setTag('partner', errorLoggerService.getPartnerName())
      scope.setTag('app:version', config.version)

      if (extra?.integration) {
        scope.setTag('integration', extra.integration as string)
      }

      scope.setTag(
        'error-status',
        (exception as ApiV2Error).status || (exception as ApiV2Error).response?.status || 'unknown'
      )
      scope.setTag(
        'error-type',
        (exception as ApiV2Error).statusText ||
          (exception as ApiV2Error).response?.statusText ||
          'unknown'
      )

      scope.setExtras({
        ...extra,
        message: 'message' in exception ? exception.message : 'NetworkError',
        level: 'error',
        response: (exception as ApiV2Error).response,
      })

      Sentry.captureException(exception, scope)
    })
  },
}

export default errorLoggerService
