import { Typography } from '@dg-shared'
import CoverageInfoIcon from '../../../components/shared/InfoIcon/CoverageInfoIcon'
import { getCoverageText } from '@dg-util/getCoverageData'
import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    cellItem: {
      display: 'flex',
      whiteSpace: 'nowrap',
      '&>span': {
        lineHeight: 1.2,
      },
    },
  })
)

type CoverTableHeadItemType = {
  label: string
  infoIcon?: string
}
const CoverTableHeadItem = ({ item }: { item: CoverTableHeadItemType }) => {
  const { cellItem } = useStyles()
  return (
    <Typography modifiers={['bold']} type='h4' className={cellItem}>
      {item.label}
      {item.infoIcon ? <CoverageInfoIcon coverageType={getCoverageText(item.infoIcon)} /> : null}
    </Typography>
  )
}

export default CoverTableHeadItem
