import authDesktopBg from './images/auth-desktop-bg.jpg'
import authMobileBg from './images/auth-mobile-bg.png'
import authTravelDsBg from '../../static/images/travel-desktop-bg.jpg'
import authContentDsBg from '../../static/images/content-desktop-bg.jpg'
import contentCardRightSide from './images/content-card-right-side.png'
import doneDesktopBg from './images/done-desktop-bg.jpg'
import doneMobileBg from './images/done-mobile-bg.png'
import loadingDesktopBg from './images/loading-desktop-bg.jpg'
import loadingMobileBg from './images/loading-mobile-bg.jpg'
import fnolTimelineBackground from './images/fnol-timeline-bg.jpg'
import fnolTimelineCard from './images/fnol-timeline-card.jpg'
import loadTravelMob from '../../static/images/loading-travel-mobile-bg.png'
import loadContentMob from '../../static/images/loading-content-mobile-bg.png'
import collisionMobileBackground from './images/collision-mobile-background.png'
import collisionDesktopBackground from '../../static/images/collision-desktop-background.jpg'
import roadsideAssistance from './images/roadside_assistance_mob.png'

import { ProductName } from '../../types/interface/quote.interface'
import { ThemeBrandAssetsType, ThemeBrandColorsType } from 'BrandTheme/types'
import brandTheme from '../theme/utils'
import { getProductName } from '@dg-util'
import logoMin from './images/logoMin.png'

const initCallback = () => {
  const productName: ProductName = getProductName()

  const brandColors: ThemeBrandColorsType = {
    main: '#00AD4B',
    secondary: '#4cad77',
    background: '#FAFAFA',
    headingMain: '#00534C',
    headingSecondary: '#00534C',
    textMain: '#333F48',
    textSecondary: '#768692',
    textError: '#F84971',
    textPlaceholder: '#C8CFD3',
    textDisclaimer: '#B6BABC',
    textInactive: '#C8CFD3',
    buttonActive: '#00AD4B',
    buttonInactive: '#4cad77',
    inputActive: '#4cad77',
    inputInactive: '#678f79',
    linkMain: '#00AD4B',
    linkSecondary: '#00AD4B',
    modal: '#C4C4C4',
    shadow: '#000000',
    clean: '#FFFFFF',
    cardHeader: '#00534C',
    textCardHeader: '#FFF',
    textCardFooter: '#00AD4B',
  }

  const theme = brandTheme.setup({
    productName,
    colors: brandColors,
  }).theme

  const productBackgroundsMap = {
    vehicle: {
      loadingMobile: `linear-gradient(to bottom, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0)), 
      url(${loadingMobileBg}) no-repeat center bottom`,
      loading: `url(${loadingDesktopBg}) no-repeat center center fixed`,
      authDesktop: `linear-gradient(to top, rgba(255, 255, 255, 1) 5%, rgba(255, 255, 255, 0)), 
      url(${authDesktopBg}) no-repeat center center fixed`,
      authMobileBg: `url(${authMobileBg}) no-repeat left top scroll`,
      reviewBg: '',
    },
    travel: {
      loadingMobile: `linear-gradient(to bottom, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0)), 
      url(${loadTravelMob}) no-repeat center bottom`,
      loading: `url(${authTravelDsBg}) no-repeat center top fixed`,
      authDesktop: `linear-gradient(to top, rgba(255, 255, 255, 1) 5%, rgba(255, 255, 255, 0)), 
      url(${authTravelDsBg}) no-repeat center top fixed`,
      authMobileBg: `url(${authTravelDsBg}) no-repeat left top scroll`,
      reviewBg: `linear-gradient(to right, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0)),
         url(${authTravelDsBg}) no-repeat right 76px scroll`,
    },
    content: {
      loadingMobile: `linear-gradient(to bottom, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0)) 60%, 
      url(${loadContentMob}) no-repeat center bottom`,
      loading: `linear-gradient(to right, rgba(255, 255, 255, 0.75) 5%, rgba(255, 255, 255, 0.75)), 
      url(${authContentDsBg}) no-repeat center center fixed`,
      authDesktop: `linear-gradient(to right, rgba(255, 255, 255, 1) 5%, rgba(255, 255, 255, 0)), 
      url(${authContentDsBg}) no-repeat center center fixed`,
      authMobileBg: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 5%, rgba(255, 255, 255, 0.5)), 
      url(${authContentDsBg}) no-repeat left top scroll`,
      reviewBg: `linear-gradient(to right, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0)),
         url(${authContentDsBg}) no-repeat right top scroll`,
    },
    fnol_vehicle: {
      loadingMobile: `linear-gradient(to bottom, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0)), 
      url(${loadingMobileBg}) no-repeat center bottom`,
      loading: `url(${loadingDesktopBg}) no-repeat center center fixed`,
      authDesktop: `linear-gradient(to top, rgba(255, 255, 255, 1) 5%, rgba(255, 255, 255, 0)), 
      url(${authDesktopBg}) no-repeat center center fixed`,
      authMobileBg: `url(${authMobileBg}) no-repeat left top scroll`,
      reviewBg: '',
    },
  }

  const styleProductMap = {
    vehicle: {
      LMBgHeaderHeader: {
        backgroundPositionY: 'bottom',
      },
    },
    travel: {
      LMBgHeaderHeader: {
        backgroundPositionY: 'top',
      },
    },
    content: {
      LMBgHeaderHeader: {
        backgroundPositionY: 'top',
      },
    },
  }

  const loadingBackground = productBackgroundsMap[productName].loading
  const loadingMobileBackground = productBackgroundsMap[productName].loadingMobile

  const mainBackgroung = productBackgroundsMap[productName].authDesktop
  const mainBackgroungMobile = productBackgroundsMap[productName].authMobileBg

  const reviewBackground = productBackgroundsMap[productName].reviewBg

  const overrides = {
    contained: {
      '&:hover': {
        color: brandColors.clean,
        opacity: 0.75,
        '& .MuiSvgIcon-root': {
          color: brandColors.clean,
        },
      },
      '&:disabled': {
        backgroundColor: brandColors.buttonInactive,
        color: brandColors.textInactive,
      },
    },
    MuiIconButton: {
      label: {
        color: brandColors.main,
      },
      root: {
        '&.Mui-disabled': {
          '& .MuiIconButton-label': {
            color: brandColors.textInactive,
          },
        },
      },
    },
    MuiSelect: {
      select: {
        '&.Mui-disabled': {
          color: brandColors.textSecondary,
        },
      },
    },
    MuiPickersDay: {
      daySelected: {
        '& .MuiIconButton-label': {
          color: brandColors.clean,
        },
      },
      dayDisabled: {
        '& .MuiIconButton-label': {
          color: brandColors.textInactive,
        },
      },
    },
    MuiInputBase: {
      input: {
        color: brandColors.textMain,
      },
    },
    MuiCardActionArea: {
      root: {
        '& .MuiTouchRipple-root': {
          color: brandColors.main,
        },
      },
      focusHighlight: {
        backgroundColor: brandColors.main,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: brandColors.textMain,
      },
    },
    MuiButton: {
      outlined: {
        height: 48,
        borderRadius: '8px',
        '&:hover': {
          backgroundColor: brandColors.main,
          color: brandColors.clean,
          '& .MuiSvgIcon-root': {
            color: brandColors.clean,
          },
        },
        '&:active': {
          backgroundColor: brandColors.main,
          color: brandColors.clean,
          '& .MuiSvgIcon-root': {
            color: brandColors.clean,
          },
        },
        '@media (hover:none)': {
          '&:hover': {
            color: brandColors.main,
            backgroundColor: brandColors.clean,
          },
          '&:active': {
            backgroundColor: brandColors.main,
            color: brandColors.clean,
          },
        },
      },
      contained: {
        height: 48,
        borderRadius: '8px',
        '&:hover': {
          color: brandColors.clean,
          backgroundColor: brandColors.main,
          opacity: 0.75,
          '& .MuiSvgIcon-root': {
            color: brandColors.clean,
          },
        },
        '&:active': {
          color: brandColors.clean,
          backgroundColor: brandColors.main,
          opacity: 0.75,
          '& .MuiSvgIcon-root': {
            color: brandColors.clean,
          },
        },
        '@media (hover:none)': {
          '&:hover': {
            color: brandColors.clean,
            backgroundColor: `${brandColors.main} !important`,
            opacity: 1,
          },
          '&:active': {
            backgroundColor: brandColors.main,
            color: brandColors.clean,
          },
        },
      },
    },
  }

  const brandAssets: ThemeBrandAssetsType = {
    pageBackgrounds: {
      main: {
        background: mainBackgroung,
        backgroundSize: 'cover',
      },
      mainMobile: {
        background: mainBackgroungMobile,
        backgroundSize: 'cover',
        backgroundPosition: 'top',
      },
      mainCard: {
        background: `linear-gradient(to top, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0))`,
        backgroundSize: 'cover',
      },
      secondary: {
        background: `linear-gradient(to top, rgba(255, 255, 255, 1) 5%, rgba(255, 255, 255, 0)), 
      url(${doneDesktopBg}) no-repeat center center fixed`,
        backgroundSize: 'cover',
      },
      secondaryMobile: {
        background: `url(${doneMobileBg}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      secondaryCard: {
        background: `linear-gradient(to top, rgba(255, 255, 255, 1) 5%, rgba(255, 255, 255, 0)), 
      url(${doneDesktopBg}) no-repeat center center`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      error: {
        background: `linear-gradient(to top, rgba(255, 255, 255, 1) 5%, rgba(255, 255, 255, 0)), 
      url(${collisionDesktopBackground}) no-repeat center center fixed`,
        backgroundSize: 'cover',
      },
      errorCardMain: {
        background: `url(${contentCardRightSide}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      errorCardMobileMain: {
        background: `url(${collisionMobileBackground}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      loading: {
        alignContent: 'center',
        background: loadingBackground,
        backgroundSize: 'cover',
      },
      loadingMobile: {
        background: loadingMobileBackground,
        backgroundSize: 'contain',
      },
      review: {
        background: reviewBackground,
        backgroundSize: 'cover',
      },
      verify: {
        background: reviewBackground,
        backgroundSize: 'cover',
      },
      roadsideAssistance: {
        background: `url(${roadsideAssistance}) no-repeat center`,
        backgroundSize: 'cover',
      },
      fnolTimeline: {
        background: `linear-gradient(to top, rgba(255, 255, 255, 1) 5%, rgba(255, 255, 255, 0)), 
      url(${fnolTimelineBackground}) no-repeat center center fixed`,
        backgroundSize: 'cover',
      },
      fnolTimelineCard: {
        background: `linear-gradient(to top, rgba(255, 255, 255, 1) 5%, rgba(255, 255, 255, 0)), 
      url(${fnolTimelineCard}) no-repeat center center`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
    },
    images: {
      changeVehicleOwnerPageCard: {
        background: `url(${contentCardRightSide}) no-repeat right top scroll`,
        backgroundSize: '50%, auto, cover',
      },
      logoDesktopHeader: {
        background: `url(${logoMin}) no-repeat left center scroll`,
        backgroundSize: 'auto, cover',
        display: 'flex',
        flex: 1,
      },
      logoMobileHeader: {
        background: `url(${logoMin}) no-repeat left center scroll`,
        backgroundSize: 'auto, cover',
        height: 60,
      },
    },
    layout: {
      card: {
        cursor: 'pointer',
        '&:hover p, &:visited p, &:active p': {
          color: brandColors.clean,
        },
        '&:hover, &:visited': {
          backgroundColor: brandColors.main,
        },
        '&:active': {
          backgroundColor: brandColors.main,
          opacity: 0.7,
        },
      },
      mobileButton: {
        borderRadius: 8,
        '& p': {
          color: brandColors.clean,
        },
        '&:hover': {
          opacity: '.75',
          background: brandColors.main,
        },
      },
      LMBgHeaderHeader: {
        [theme.breakpoints.down('sm')]: {
          ...styleProductMap[productName].LMBgHeaderHeader,
          height: 300,
        },
      },
      customHeaderTextWrapper: {
        position: 'absolute',
        padding: '0 16px',
        top: 140,
        zIndex: 10,
        color: brandColors.textMain,
      },
      iconCustomColor: {
        color: brandColors.secondary,
      },
      myInsurances: {
        marginTop: '68px',
      },
      customMobileSubmit: {
        [theme.breakpoints.down('sm')]: {
          position: 'absolute',
          bottom: 0,
        },
      },
      policiesListCardsPlusButtonContainer: {
        [theme.breakpoints.down('sm')]: {
          position: 'fixed',
          bottom: 0,
          width: '100%',
          padding: '30px 16px 12px !important',
          background: `linear-gradient(0deg, ${brandColors.background} 75%, rgba(255,255,255,0) 100%)`,
        },
      },
    },
  }

  // Use branded colors and default Typography
  return brandTheme.setup({
    productName,
    colors: brandColors,
    assets: brandAssets,
    overrides,
  })
}

export default brandTheme.init(initCallback)
