import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../../../constants'
import { CTA_TYPE_INSTABANK_LINK } from '../../../../constants/cta-constants'
import { CTA, Typography, FSMBtnNext } from '@dg-shared'
import { useTranslate } from '../../../../hooks/shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backBtn: {
      paddingTop: '20px',
      [theme.breakpoints.up('md')]: {
        marginLeft: '-10px',
      },
      justifyContent: 'flex-start',
    },
    bodyText: {
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0),
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
      },
    },
    bodyContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(6),
        marginTop: theme.spacing(6),
      },
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(0),
      },
    },
    buttonNext: {
      ...theme.brand.assets.layout?.ctaOverride,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
      },
    },
  })
)

interface FailProps {
  handleBack(): Promise<void>
}

const Fail: FC<FailProps> = ({ handleBack }) => {
  const { t } = useTranslate(T.OFFER_PURCHASED_FAIL)
  const classes = useStyles()

  return (
    <>
      <div className={classes.bodyContainer}>
        {t('annotation', {}, 'obj')?.map((el, key) => (
          <Typography key={key} className={classes.bodyText}>
            {el}
          </Typography>
        ))}
      </div>
      <FSMBtnNext
        customClassName={classes.buttonNext}
        handleClick={handleBack}
        buttonText={t(`back`)}
      />
      <CTA context={CTA_TYPE_INSTABANK_LINK} />
    </>
  )
}

export default Fail
