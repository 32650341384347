import React, { FC, useEffect } from 'react'

import { FNOLButton, FNOLPageWrapper, Typography } from '@dg-shared'
import { useTranslate, useClaim } from '../../../hooks/shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events, FSMCommonTransitions } from '../../../FSM/shared/constants'
import { FNOL_COLLISION_QUESTIONS, TRANSLATIONS as T } from '../../../constants'
import { useFNOLStyles } from '../shared/styles/useStyles'

const PageInjured: FC<CommonPageProps> = ({ service, currentState }) => {
  const FNOLClasses = useFNOLStyles({})
  const { t } = useTranslate(`${T.COLLISION}.injured`)
  const { handleAnswerClick, clearAnswer } = useClaim(service, currentState)

  const BP_ACCIDENT_FORM = FSMCommonTransitions.FNOL_COLLISION_ACCIDENT_FORM
  const BP_RESPONSIBLE = FSMCommonTransitions.FNOL_COLLISION_RESPONSIBLE
  const BP_INJURED = FSMCommonTransitions.FNOL_COLLISION_INJURED
  const BP_CASE_OF_ACCIDENT = FSMCommonTransitions.FNOL_COLLISION_CASE_OF_ACCIDENT
  const BP_DAMAGE_REPORT = FSMCommonTransitions.FNOL_COLLISION_DAMAGE_REPORT
  const COLLISION_RESPONSIBLE = FSMCommonTransitions.FNOL_COLLISION_RESPONSIBLE

  useEffect(() => {
    clearAnswer([
      FNOL_COLLISION_QUESTIONS.DID_ANYONE_GET_INJURED,
      FNOL_COLLISION_QUESTIONS.WAS_ANYONE_IN_YOUR_CAR_INJURED,
    ])
  }, [clearAnswer])

  const itemSelect = ({ label, answer }: { label: string; answer: number }) => (
    <FNOLButton
      key={label}
      handleClick={() =>
        handleAnswerClick(
          service.state?.context?.backPath?.collision === COLLISION_RESPONSIBLE
            ? FNOL_COLLISION_QUESTIONS.DID_ANYONE_GET_INJURED
            : FNOL_COLLISION_QUESTIONS.WAS_ANYONE_IN_YOUR_CAR_INJURED,
          answer,
          Events.NEXT
        )
      }
      buttonText={t(label)}
      testId={label}
    />
  )

  const eventItems = [
    { label: 'buttons.noOne', answer: 1 },
    { label: 'buttons.inMyCar', answer: 2 },
  ]

  const showThreeButtons = [
    BP_ACCIDENT_FORM,
    BP_RESPONSIBLE,
    BP_CASE_OF_ACCIDENT,
    BP_INJURED,
    BP_DAMAGE_REPORT,
  ].includes(service.state?.context?.backPath?.collision)

  if (showThreeButtons) {
    eventItems.push({ label: 'buttons.inAnotherCar', answer: 3 })
  }

  const ItemsList = () => <>{eventItems.map((item) => itemSelect(item))}</>

  const Content = () => (
    <>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <Typography testId='subtitle' html={true} className={FNOLClasses.description}>
        {t('subtitle')}
      </Typography>
      <ItemsList />
    </>
  )

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`back`)} breadcrumbsStepPercentage={40}>
      <Content />
    </FNOLPageWrapper>
  )
}

export default PageInjured
