import React, { FC, useEffect } from 'react'

import { Typography, FNOLPageWrapper, FNOLButton } from '@dg-shared'
import { useTranslate, useClaim } from '../../../hooks/shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import { TRANSLATIONS as T, FNOL_COLLISION_QUESTIONS } from '../../../constants'
import { useFNOLStyles } from '../shared/styles/useStyles'

const PageHowMany: FC<CommonPageProps> = ({ service }) => {
  const FNOLClasses = useFNOLStyles({})
  const { t } = useTranslate(`${T.COLLISION}.howMany`)
  const { handleAnswerClick, clearAnswer } = useClaim(service)

  useEffect(() => {
    clearAnswer(FNOL_COLLISION_QUESTIONS.HOW_MANY_CARS_INVOLVED)
  }, [clearAnswer])

  const collisionTypeSelect = ({ label, answer }: { label: string; answer: number }) => (
    <FNOLButton
      testId={`button-${label}`}
      key={`button-${label}`}
      handleClick={() =>
        handleAnswerClick(
          FNOL_COLLISION_QUESTIONS.HOW_MANY_CARS_INVOLVED,
          answer,
          Events.FNOL_COLLISION_RESPONSIBLE
        )
      }
      buttonText={t(`buttons.${label}`)}
    />
  )

  const collisionTypeEvents = [
    { label: 'oneCar', answer: 1 },
    { label: 'twoCars', answer: 2 },
    { label: 'moreThenTwoCars', answer: 3 },
  ]

  const Content = () => (
    <>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <Typography testId='subtitle' html={true} className={FNOLClasses.description}>
        {t('subtitle')}
      </Typography>
      {collisionTypeEvents.map((item) => collisionTypeSelect(item))}
    </>
  )

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`back`)} breadcrumbsStepPercentage={30}>
      <Content />
    </FNOLPageWrapper>
  )
}

export default PageHowMany
