import { FNOLRoadsideAssistance } from '../../../../pages/FNOL'
import { FSMCommonTransitions } from '../../constants'
const { FNOL_ROADSIDE_ASSISTANCE_FLOW, FNOL_ROADSIDE_ASSISTANCE_START } = FSMCommonTransitions

export default [
  {
    is: { [FNOL_ROADSIDE_ASSISTANCE_FLOW]: FNOL_ROADSIDE_ASSISTANCE_START },
    page: FNOLRoadsideAssistance,
  },
]
