import React, { FC } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../../../constants'
import { FormFieldV2 } from '../../../../types/interface/form.interface'
import { DetailedInfoButtonShared, Typography } from '@dg-shared'
import { useTranslate } from '../../../../hooks/shared'

const useStyles = makeStyles(() =>
  createStyles({
    colRight: {
      marginBottom: '0',
    },
  })
)

interface AuthCustomLabelProps {
  field: FormFieldV2
  invalid: boolean
  toggler: (state: boolean) => boolean
}

const AuthCustomLabel: FC<AuthCustomLabelProps> = ({ invalid, toggler }) => {
  const { t } = useTranslate(T.AUTH)
  const classes = useStyles()

  return (
    <DetailedInfoButtonShared showDetails={() => toggler(true)}>
      <Typography color={invalid ? 'textError' : 'textSecondary'} className={classes.colRight}>
        {t('form.ssn.label')}
      </Typography>
    </DetailedInfoButtonShared>
  )
}

export default AuthCustomLabel
