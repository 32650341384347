import { Events } from '../../../FSM/shared/constants'
import React, { useContext, useEffect } from 'react'
import { FSMService } from '../../../FSM'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Tab, Tabs, Hidden, Menu, MenuItem, IconButton, Theme } from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'
import { useTranslate } from '../../../hooks/shared'
import { TRANSLATIONS as T } from '../../../constants'
import { Context } from '@dg-shared/ContextProvider'
import FSMBtnNext from '../FSMBtnNext'
import { getLocalizedPartnerName } from '@dg-util'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
    },
    tabs: {
      '& [role="tablist"]': {
        height: '100%',
      },
    },
    icon: {
      color: theme.brand.colors.main,
    },
    mobileMenuButton: {
      height: '100%',
      display: 'flex',
    },
    ctaOverride: {
      height: 40,
      marginLeft: theme.spacing(1.5),
      fontSize: theme.spacing(1.75),
      ...theme.brand.assets.layout?.ctaOverride,
    },
    wrapper: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
      '& a': {
        textDecoration: 'none',
        textTransform: 'uppercase',
        fontSize: 14,
        color: `${theme.brand.colors.shadow}8A`,
        ...theme.brand.assets.layout?.contacts,
      },
      '& span': {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        alignItems: 'normal',
      },
      '& svg': {
        ...theme.brand.assets.layout?.iconCustomColor,
      },
    },
  })
)

const LANDING_PAGE_SELECTED_TAB = 'landingPageSelectedTab'

const LandingHeaderMenu = () => {
  const { t } = useTranslate(T.LANDING)
  const { context, setContext } = useContext(Context)
  const landingPageSelectedTab = context[LANDING_PAGE_SELECTED_TAB] as Record<string, string>
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  useEffect(() => {
    const hideMenu = () => setAnchorEl(null)

    // Subscribe for listening to click outside of the menu
    document.body.addEventListener('mousedown', hideMenu)

    return () => {
      // Cleanup the event listener
      document.body.removeEventListener('mousedown', hideMenu)
    }
  }, [])

  const handleChange = (event: React.SyntheticEvent, newValue: Events) => {
    setContext({
      [LANDING_PAGE_SELECTED_TAB]: { value: newValue },
    })
    FSMService.flowService.send(newValue)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (newValue: Events) => {
    setContext({
      [LANDING_PAGE_SELECTED_TAB]: { value: newValue },
    })
    FSMService.flowService.send(newValue)
    setAnchorEl(null)
  }

  const classes = useStyles()
  const partnerName = getLocalizedPartnerName()

  return (
    <div className={classes.container}>
      <Hidden lgUp={false} mdDown={true}>
        <Tabs
          textColor='primary'
          indicatorColor='primary'
          className={classes.tabs}
          value={landingPageSelectedTab?.value || Events.LANDING_PAGE_ABOUT}
          onChange={handleChange}
        >
          <Tab label={t('headerMenu.about', { partnerName })} value={Events.LANDING_PAGE_ABOUT} />
          <Tab
            label={t('headerMenu.questionsAndAnswers')}
            value={Events.LANDING_PAGE_QUESTIONS_AND_ANSWERS}
          />
          <div className={classes.wrapper}>
            <FSMBtnNext
              buttonText='Beregn din pris på 1 min'
              handleClick={() => {
                FSMService.flowService.send(Events.AUTH)
              }}
              customClassName={`${classes.ctaOverride}`}
            />
          </div>
        </Tabs>
      </Hidden>
      <Hidden lgUp={true} mdDown={false}>
        <div className={classes.mobileMenuButton}>
          <IconButton
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MenuIcon fontSize='inherit' />
          </IconButton>

          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => handleClose(Events.LANDING_PAGE_ABOUT)}>
              {t('headerMenu.about')}
            </MenuItem>
            <MenuItem onClick={() => handleClose(Events.LANDING_PAGE_QUESTIONS_AND_ANSWERS)}>
              {t('headerMenu.questionsAndAnswers')}
            </MenuItem>
          </Menu>
        </div>
      </Hidden>
    </div>
  )
}

export default LandingHeaderMenu
