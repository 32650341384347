import {
  CoverageTypesMapping,
  CoverageTypeKeyType,
  PartnerName,
} from '../types/interface/quote.interface'
import { ENV, EnvType } from '../config'

type CoverageMapping = Record<PartnerName, Partial<Record<CoverageTypeKeyType, string>>>

const VEHICLE_COVERAGE_TYPE_NO = {
  [CoverageTypeKeyType.ansvar]: 'Ansvar',
  [CoverageTypeKeyType.delkasko]: 'Delkasko',
  [CoverageTypeKeyType.kasko]: 'Kasko',
  [CoverageTypeKeyType.super]: 'Super',
}
const VEHICLE_COVERAGE_TYPE_SE = {
  [CoverageTypeKeyType.trafik]: 'Trafik',
  [CoverageTypeKeyType.halv]: 'Halvförsäkring',
  [CoverageTypeKeyType.hel]: 'Helförsäkring',
  [CoverageTypeKeyType.super]: 'Avställd',
}

export const VEHICLE_COVERAGE_TYPE_MAP: CoverageMapping = {
  [PartnerName.circlek]: VEHICLE_COVERAGE_TYPE_SE,
  [PartnerName.instabank]: VEHICLE_COVERAGE_TYPE_NO,
  [PartnerName.nord]: VEHICLE_COVERAGE_TYPE_NO,
  [PartnerName.volkswagen]: VEHICLE_COVERAGE_TYPE_NO,
  [PartnerName.skoda]: VEHICLE_COVERAGE_TYPE_NO,
  [PartnerName.audi]: VEHICLE_COVERAGE_TYPE_NO,
}

const VEHICLE_COVERAGE_TYPES_NO = {
  [CoverageTypeKeyType.ansvar]: {
    name: 'coverageDetails.ansvar.name',
    description: 'coverageDetails.ansvar.description',
    list: 'coverageDetails.ansvar.list',
  },
  [CoverageTypeKeyType.delkasko]: {
    name: 'coverageDetails.delkasko.name',
    description: 'coverageDetails.delkasko.description',
    list: 'coverageDetails.delkasko.list',
  },
  [CoverageTypeKeyType.kasko]: {
    name: 'coverageDetails.kasko.name',
    description: 'coverageDetails.kasko.description',
    list: 'coverageDetails.kasko.list',
  },
  [CoverageTypeKeyType.super]: {
    name: 'coverageDetails.super.name',
    description: 'coverageDetails.super.description',
    list: 'coverageDetails.super.list',
  },
}

const VEHICLE_COVERAGE_TYPES_SE = {
  [CoverageTypeKeyType.trafik]: {
    name: 'coverageDetails.trafik.name',
    description: 'coverageDetails.trafik.description',
    list: 'coverageDetails.trafik.list',
    additionalInfo: 'coverageDetails.trafik.additionalInfo',
  },
  [CoverageTypeKeyType.halv]: {
    name: 'coverageDetails.halvforsakring.name',
    description: 'coverageDetails.halvforsakring.description',
    list: 'coverageDetails.halvforsakring.list',
    additionalInfo: 'coverageDetails.halvforsakring.additionalInfo',
  },
  [CoverageTypeKeyType.hel]: {
    name: 'coverageDetails.helforsakring.name',
    description: 'coverageDetails.helforsakring.description',
    list: 'coverageDetails.helforsakring.list',
  },
  [CoverageTypeKeyType.super]: {
    name: 'coverageDetails.super.name',
    description: 'coverageDetails.super.description',
    list: 'coverageDetails.super.list',
  },
}

export const VEHICLE_COVERAGE_TYPE_TRANSLATION_KEYS: CoverageTypesMapping = {
  [PartnerName.circlek]: VEHICLE_COVERAGE_TYPES_SE,
  [PartnerName.instabank]: VEHICLE_COVERAGE_TYPES_NO,
  [PartnerName.nord]: VEHICLE_COVERAGE_TYPES_NO,
  [PartnerName.volkswagen]: VEHICLE_COVERAGE_TYPES_NO,
  [PartnerName.skoda]: VEHICLE_COVERAGE_TYPES_NO,
  [PartnerName.audi]: VEHICLE_COVERAGE_TYPES_NO,
}

const defaultNoInsurelyTestCompany = 'no-demo'
const defaultSeInsurelyTestCompany = 'se-demo'

const INSURELY_TEST_COMPANY_ID_MAP: Record<PartnerName, Record<EnvType, string | null>> = {
  [PartnerName.instabank]: {
    [EnvType.ENV_LOCAL]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_TEST]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_DEV]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_ACC]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_PROD]: null,
  },
  [PartnerName.nord]: {
    [EnvType.ENV_LOCAL]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_TEST]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_DEV]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_ACC]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_PROD]: null,
  },
  [PartnerName.circlek]: {
    [EnvType.ENV_LOCAL]: defaultSeInsurelyTestCompany,
    [EnvType.ENV_TEST]: defaultSeInsurelyTestCompany,
    [EnvType.ENV_DEV]: defaultSeInsurelyTestCompany,
    [EnvType.ENV_ACC]: defaultSeInsurelyTestCompany,
    [EnvType.ENV_PROD]: null,
  },
  [PartnerName.volkswagen]: {
    [EnvType.ENV_LOCAL]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_TEST]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_DEV]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_ACC]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_PROD]: null,
  },
  [PartnerName.skoda]: {
    [EnvType.ENV_LOCAL]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_TEST]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_DEV]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_ACC]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_PROD]: null,
  },
  [PartnerName.audi]: {
    [EnvType.ENV_LOCAL]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_TEST]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_DEV]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_ACC]: defaultNoInsurelyTestCompany,
    [EnvType.ENV_PROD]: null,
  },
}

export const DEFAULT_INSURELY_TEST_COMPANY =
  INSURELY_TEST_COMPANY_ID_MAP[PARTNER_NAME as PartnerName][ENV]

export const QUOTE_ID = 'quoteId'
export const CLAIM_ID = 'claimId'
export const CLAIM_ANSWERS = 'claimAnswers'
export const SELECTED_POLICY_NUMBER = 'selectedPolicyNumber'
export const SELECTED_POLICY_VERSION = 'selectedPolicyVersion'
export const SELECTED_QUOTE_MOTOR_REGISTRATION_NUMBER = 'selectedQuoteMotorRegistrationNumber'
export const SELECTED_QUOTE_ADDRESS = 'selectedQuoteAddress'
