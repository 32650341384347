import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    card: {
      margin: '0 auto',
      maxWidth: '345px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(2),
      },
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      '& section': {
        '& p': {
          fontSize: '12px',
        },
        borderBottom: `1px solid ${theme.brand.colors.textSecondary}`,
      },
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    textDetails: {
      fontSize: theme.spacing(1.5),
      maxWidth: '50%',
      marginBottom: 0,
    },
    priceText: {
      margin: 0,
    },
    priceRow: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: theme.spacing(1),
      alignItems: 'baseline',
    },
    spaceTop: {
      marginTop: theme.spacing(2),
    },
    currency: {
      color: theme.brand.colors.headingMain,
      paddingLeft: theme.spacing(0.5),
      margin: 0,
    },
    cardDetails: {
      fontSize: theme.spacing(1.5),
      maxWidth: '85%',
      marginBottom: 0,
    },
  })
)
