import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { StylesProps } from '../types/interface/styles.interface'

export const useMobileStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerWrapper: (props: StylesProps) => ({
      padding: '30px 20px',
      background: `center url(${props.backgroundImage}) no-repeat`,
      borderRadius: '0 0 30% 0',
    }),
    logoWrapper: {
      display: 'flex',
      flex: 0,
      marginTop: theme.spacing(3),
    },
    logoImage: (props: StylesProps) => ({
      alignSelf: 'start',
      height: props.logoImageHeight ? props.logoImageHeight : '28px',
    }),
  })
)
