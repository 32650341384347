import { StateRoute } from '../../../../types/interface/fsm.interface'
import { FSMCommonStatePaths } from '../../routes'
import routesBankId from '../bankId/routes'
import routesClaims from '../claims/routes'
import routesPolicies from '../policies/routes'
import { FSMVehicleStatePathsNO } from '../qb/routes'

const FSMMyPagesStatePathsNO: Array<StateRoute> = [
  ...FSMCommonStatePaths,
  ...routesBankId,
  ...routesPolicies,
  ...routesClaims,
  ...FSMVehicleStatePathsNO,
]

export { FSMMyPagesStatePathsNO }
