import React, { FC } from 'react'
import { Typography } from '@dg-shared'
import { useTranslate } from '../hooks/shared'
import { TRANSLATIONS as T, VEHICLE_COVERAGE_TYPE_MAP } from '../constants'
import { get } from 'lodash'
import useContentStyles from '../pages/qb/Verify/Content/useContentStyles'
import { PolicyVerifyCardContentProps } from '../types'
import { getDataComponentName } from '@dg-util'

const PolicyVerifyCardContentVehicle: FC<PolicyVerifyCardContentProps> = ({ policy }) => {
  const { t } = useTranslate(`${T.OFFER_VERIFY}.card`)
  const classes = useContentStyles()
  const {
    MotorRegistrationNumber,
    CalculatedPrices,
    CarPackage,
    CarMake,
    ManufacuringYear, // It is how it defined in i2i (with typo)
    CarModel,
  } = policy

  const coverageType = get(VEHICLE_COVERAGE_TYPE_MAP, [PARTNER_NAME, CarPackage], null)
  return (
    <div data-componentname={getDataComponentName(__filename)}>
      <Typography type='h3'>{t(`carCoverage`, { coverageType })}</Typography>
      <Typography className={classes.textDetails}>
        {[ManufacuringYear, CarMake, CarModel].join(' ')}
      </Typography>
      <Typography className={classes.textDetails}>
        {t(`registrationNum`, { regNum: MotorRegistrationNumber })}
      </Typography>
      <section className={classes.body}>
        <section className={`${classes.row} ${classes.spaceTop}`}>
          <Typography>{coverageType}</Typography>
          <Typography testId='price-month-vehicle-no'>
            {t('priceMonth', { priceMonthly: CalculatedPrices.priceMonthly })}
          </Typography>
        </section>
        {CalculatedPrices.taxMonthly && (
          <section className={`${classes.row} ${classes.spaceTop}`}>
            <Typography>{t('insuranceStateFee')}</Typography>
            <Typography testId='tax-price-month-vehicle-no'>
              {t('priceMonth', { priceMonthly: CalculatedPrices.taxMonthly })}
            </Typography>
          </section>
        )}
      </section>
      <section className={`${classes.row} ${classes.spaceTop}`}>
        <Typography type='h3'>{t(`total`)}</Typography>
        <section className={classes.priceRow}>
          <Typography testId='total-price-vehicle-no' type='h3' className={classes.priceText}>
            {`${CalculatedPrices.priceMonthly + CalculatedPrices.taxMonthly}`}
          </Typography>
          <Typography className={classes.currency}>
            {t('priceMonth', { priceMonthly: null })}
          </Typography>
        </section>
      </section>
      <Typography
        testId='verify-card-content-vehicle-desclaimer'
        color='textDisclaimer'
        size='small'
      >
        {t('priceTotal', { priceTotal: policy.CalculatedPrices.priceTotal })}
      </Typography>
    </div>
  )
}

export default PolicyVerifyCardContentVehicle
