import { isEmpty } from 'lodash'
import { HookDocuments, backBtn } from '../../../types/interface/policy.interface'
import { base64ToArrayBuffer, reactQueryErrorHandler, saveByteArray } from '@dg-util'
import useReactQueryPolicy from '../../../hooks/shared/useReactQueryPolicy'
import { PolicyDocumentV2ResponseType } from '../../../types/ApiV2'
import apiV2 from '../../../service/apiV2.service'
import { useEffect, useState } from 'react'
import useReactQueryDatasource from '../../../hooks/shared/useReactQueryDatasource'
import { FSMCommonTransitions } from '../../../FSM/shared/constants'
import { FSMService } from '../../../FSM'

const { POLICY_REVIEW } = FSMCommonTransitions

const useLogic = (): HookDocuments => {
  const [isPolicyDocumentLoading, setIsPolicyDocumentLoading] = useState<boolean>(false)
  const {
    policies,
    isLoading,
    policy,
    updatePolicy,
    calculatePolicy,
    changePolicy,
    refetchPolicy,
  } = useReactQueryPolicy()

  useEffect(() => {
    if (!policy) {
      ;(async () => await refetchPolicy())()
    }
  }, [refetchPolicy, policy])

  const { productProps, isLoading: isProductPropsLoading } = useReactQueryDatasource()

  const downloadFile = (fileObj: PolicyDocumentV2ResponseType['data']): void => {
    const sampleBytes = base64ToArrayBuffer(fileObj.Content)
    saveByteArray([sampleBytes], fileObj.Name)
  }

  const handleClick = async (correspondenceId: number): Promise<void> => {
    setIsPolicyDocumentLoading(true)

    try {
      const policyDocument = await apiV2.getPolicyDocument(correspondenceId)
      downloadFile(policyDocument.data)
    } catch (error) {
      reactQueryErrorHandler(error, FSMService.flowService, POLICY_REVIEW)
    }

    setIsPolicyDocumentLoading(false)
  }

  const backBtn: backBtn = {
    text: !isEmpty(policies) ? `back` : `logout`,
    path: !isEmpty(policies) ? 'TODO: DELETED CONSTANT CLEANUP: PROFILE_MENU' : '',
  }

  return {
    backBtn,
    handleClick,
    policies,
    policy,
    updatePolicy,
    calculatePolicy,
    changePolicy,
    productProps,
    refetchPolicy,
    isLoading: isLoading || isPolicyDocumentLoading || isProductPropsLoading,
  }
}

export default useLogic
