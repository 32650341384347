import React, { FC } from 'react'
import { FieldRenderProps } from 'react-final-form'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import { TimePickerProps } from '@material-ui/pickers/TimePicker/TimePicker'
import { Timer } from '@material-ui/icons'

const useRFFCustomTimePickerStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageInput: {
      display: 'flex',
    },
    formControl: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        marginBottom: theme.spacing(3),
      },
    },
    timePickerFieldLabel: {
      position: 'relative',
    },
    datePicker: {
      display: 'flex',
      '& > div': {
        color: theme.palette.primary.dark,
      },
      marginTop: theme.spacing(2),
      marginBottom: 0,
    },
    inputLabel: {
      position: 'relative',
      marginBottom: theme.spacing(2),
      color: theme.palette.info.dark,
      fontSize: theme.spacing(2),
      lineHeight: 1.4, // 22
    },
  })
)

const RFFCustomTimePicker: FC<FieldRenderProps<TimePickerProps>> = ({ input, meta, ...props }) => {
  const classes = useRFFCustomTimePickerStyles()

  const isError: boolean = (meta.error || meta.submitError) && !meta.pristine

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <FormControl error={isError} className={classes.formControl}>
        {!!props.fieldLabel && (
          <InputLabel id={props.fieldLabel} className={classes.inputLabel} shrink>
            {props.fieldLabel}
          </InputLabel>
        )}
        <KeyboardTimePicker
          disableToolbar
          variant='inline'
          inputVariant={props.fieldInputVariant || 'standard'}
          format='HH:mm'
          ampm={false}
          value={input.value || null}
          onChange={input.onChange}
          className={props.customClass || null}
          autoOk
          placeholder={props.fieldPlaceholder}
          InputLabelProps={{
            shrink: true,
            variant: 'standard',
            classes: {
              root: classes.timePickerFieldLabel,
            },
          }}
          keyboardIcon={<Timer />}
          helperText={''} // Need to disable component validation
          invalidDateMessage={'Invalid time format. Please enter a valid time.'}
        />
        <FormHelperText>{meta.error}</FormHelperText>
      </FormControl>
    </MuiPickersUtilsProvider>
  )
}

export default RFFCustomTimePicker
