import { Events } from '../../FSM/shared/constants'
import { FlowServiceType, FSMStateType } from '../../types/interface/fsm.interface'
import { TranslatedTextType } from '../../types'
import { useCallback, useContext, useRef } from 'react'
import { Context } from '@dg-shared/ContextProvider'
import { CLAIM_ANSWERS, CLAIM_ID } from '../../constants'

const useClaim = (service: FlowServiceType, currentState?: FSMStateType) => {
  const { context, setContext } = useContext(Context)
  const claimId = context[CLAIM_ID] as string
  const claimAnswers = (context[CLAIM_ANSWERS] || {}) as Record<string, unknown>

  const setClaimAnswer = async (
    question: string,
    answer: TranslatedTextType | number | boolean
  ) => {
    setContext({
      [CLAIM_ANSWERS]: {
        ...claimAnswers,
        [question]: answer,
      },
    })
  }

  const setClaimMultipleAnswers = async (
    questionsWithAnswers: Record<string, TranslatedTextType | number | boolean>
  ) => {
    setContext({
      [CLAIM_ANSWERS]: {
        ...claimAnswers,
        ...questionsWithAnswers,
      },
    })
  }

  const handleAnswerClick = async (
    question: string,
    answer: TranslatedTextType | number | boolean,
    event: Events
  ) => {
    await setClaimAnswer(question, answer)
    service.send({ type: event, state: currentState })
  }

  const clearClaimFull = useCallback(() => {
    setContext({
      [CLAIM_ANSWERS]: undefined,
      [CLAIM_ID]: undefined,
    })
  }, [setContext])

  // create a ref to store the initial version of the function
  // ref will maintain a stable reference to the initial version of the function, even if it changes over time
  // and it avoid re-trigger useEffect where clearAnswer() usually called
  // Support clear of one or multiple questions
  const clearAnswer = useRef((questionsToRemove: string | string[]) => {
    const filteredAnswers: Record<string, unknown> = {}

    // Ensure questionsToRemove is always an array
    if (typeof questionsToRemove === 'string') {
      questionsToRemove = [questionsToRemove]
    }

    // Iterate through the original answers object
    for (const key in claimAnswers) {
      // If the key is not in the questionsToRemove array, add it to the filteredAnswers object
      if (!questionsToRemove.includes(key)) {
        filteredAnswers[key] = claimAnswers[key]
      }
    }

    setContext({
      [CLAIM_ANSWERS]: filteredAnswers,
    })
  })

  return {
    handleAnswerClick,
    setClaimAnswer,
    setClaimMultipleAnswers,
    clearAnswer: clearAnswer.current,
    clearClaimFull,
    claimAnswers,
    claimId,
  }
}

export default useClaim
