import React, { FC, useEffect } from 'react'

import { Typography, FNOLButton, FNOLPageWrapper } from '@dg-shared'
import { useTranslate, useClaim } from '../../../hooks/shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import {
  FLOW_COLLISION_ANIMAL_CAUSE_TYPE_IDS,
  FNOL_COLLISION_QUESTIONS,
  TRANSLATIONS as T,
} from '../../../constants'
import { useFNOLStyles } from '../shared/styles/useStyles'

const PageAnimal: FC<CommonPageProps> = ({ service, currentState }) => {
  const FNOLClasses = useFNOLStyles({})
  const { t } = useTranslate(`${T.COLLISION}.animal`)
  const { handleAnswerClick, clearAnswer } = useClaim(service, currentState)

  useEffect(() => {
    clearAnswer(FNOL_COLLISION_QUESTIONS.WHAT_KIND_OF_ANIMAL)
  }, [clearAnswer])

  const itemSelect = (animal: string) => (
    <FNOLButton
      key={`buttons.${animal}`}
      testId={`buttons.${animal}`}
      handleClick={() =>
        handleAnswerClick(
          FNOL_COLLISION_QUESTIONS.WHAT_KIND_OF_ANIMAL,
          FLOW_COLLISION_ANIMAL_CAUSE_TYPE_IDS[animal],
          Events.NEXT
        )
      }
      buttonText={t(`buttons.${animal}`)}
    />
  )

  const animalItems = ['moose', 'deer', 'babyDeer', 'badger', 'fox', 'pets']

  const ItemsList = () => <>{animalItems.map((item) => itemSelect(item))}</>

  const Content = () => (
    <>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <Typography testId='subtitle' html={true} className={FNOLClasses.description}>
        {t('subtitle')}
      </Typography>
      <ItemsList />
    </>
  )

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`back`)} breadcrumbsStepPercentage={35}>
      <Content />
    </FNOLPageWrapper>
  )
}

export default PageAnimal
