// Use a 20% of events capturing/tracing for all environments
import { IS_DEVELOPMENT, IS_LOCAL } from '../config'
import * as SentryTracing from '@sentry/tracing'
import * as Sentry from '@sentry/react'

const tracesSampleRate = 0.2

// Tracing initialization
SentryTracing.addExtensionMethods()
// Sentry initialisation
Sentry.init({
  dsn: 'https://37a2461ea03542bda1f1a85d860df61e@o1129041.ingest.sentry.io/6172544',
  environment: process.env.ENV,
  debug: IS_LOCAL || IS_DEVELOPMENT,
  // To set a uniform sample rate
  tracesSampleRate,
})

export default Sentry
