import React, { FC, useEffect } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Card } from '@material-ui/core'

import { TRANSLATIONS as T } from '../../../../constants/translations-constants'
import { LDContainerV2FSM, LMBasicV3FSM } from '@dg-shared'
import { useTranslate } from '../../../../hooks/shared'
import InsurelyIframe from '@dg-shared/InsurelyIframe'
import { FlowServiceType } from '../../../../types/interface/fsm.interface'
import { Events } from '../../../../FSM/shared/constants'
import useLogic from '../useLogic'
import PageWrapper from '@dg-shared/PageWrapper'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(5),
      },
    },
  })
)

export type InsurelyInitPagePropsType = {
  service: FlowServiceType
}

const InsurelyInitPage: FC<InsurelyInitPagePropsType> = (props) => {
  const { service } = props
  const { t } = useTranslate(T.OFFER_COMPARE_PRICE)
  const classes = useStyles()
  const { insurelyResults, quote, selectedInsurelyQuote } = useLogic(service)

  useEffect(() => {
    const showOffersList = insurelyResults?.length > 1 && !selectedInsurelyQuote
    const showCompareQuotes = quote && selectedInsurelyQuote

    if (showOffersList) {
      // There are multiple Insurely Results to compare. Show list
      service.send(Events.COMPARE_LIST)
    } else if (showCompareQuotes) {
      // Compare Insurely Offer is selected. Show compare page
      service.send(Events.COMPARE)
    }
  }, [insurelyResults, quote, selectedInsurelyQuote, service])

  const content = quote ? (
    <Card elevation={3} className={classes.container}>
      <InsurelyIframe companyId={quote.prevInsuranceCompanyId} />
    </Card>
  ) : null

  return (
    <PageWrapper
      mobile={LMBasicV3FSM}
      desktop={LDContainerV2FSM}
      breadcrumbsBtnText={t(`back`)}
      breadcrumbsStepPercentage={60}
    >
      {content}
    </PageWrapper>
  )
}

export default InsurelyInitPage
