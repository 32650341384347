import React, { FC } from 'react'
import { Typography } from '@dg-shared/index'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'
import { PolicyV2Type } from '../../types/ApiV2'
import { useTranslate } from '../../hooks/shared'
import { TRANSLATIONS as T } from '../../constants'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noPoliciesText: {
      textAlign: 'center',
      maxWidth: 320,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    noPoliciesTextWrapper: {
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(3),
    },
  })
)

const NoPolicies: FC<{
  policies: Partial<PolicyV2Type>[]
  additionalOffers: React.ReactNode
}> = ({ policies, additionalOffers }) => {
  const classes = useStyles()
  const { t } = useTranslate(T.POLICIES)

  const noPoliciesText = isEmpty(policies) ? t('noPoliciesText') : ''
  const noAdditionalOffersText = isEmpty(additionalOffers)
    ? t('clickToBuyNewPolicyText', { productInsurance: t('insurance') })
    : ''

  return noPoliciesText ? (
    <div className={classes.noPoliciesTextWrapper}>
      <Typography className={classes.noPoliciesText}>
        {noPoliciesText} {noAdditionalOffersText}
      </Typography>
    </div>
  ) : null
}

export default NoPolicies
