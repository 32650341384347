import { InsurelyResultType, SafeInsurelyResultType } from '../types/insurelyTypes'
import { QuoteV2Type } from '../types/ApiV2'

// Remove sensitive data from the insurances data
const filterSensitiveInsurelyFields = (items: InsurelyResultType[]): SafeInsurelyResultType[] =>
  items.map((item) => {
    return {
      insurance: {
        premiumAmountYearRounded: item.insurance.premiumAmountYearRounded,
        insuranceName: item.insurance.insuranceName,
        registrationNo: item.insurance.registrationNo,
        coverage: item.insurance.coverage,
        maxMileage: item.insurance.maxMileage,
        youngDriver: item.insurance.youngDriver,
        renewalDate: item.insurance.renewalDate,
      },
      deductibles: item.deductibles,
    }
  })

// Filter results with the corresponding registrationNo and insuranceType
// Include the insurances for vehicles without registrationNo in case if there are no
// match with the same registrationNo like in quote
export const filterVehicleInsurelyResults = (
  results: InsurelyResultType[],
  quote: Partial<QuoteV2Type>
) => {
  // Filter only vehicle insurances
  const vehicleInsurances = results.filter(
    (result) => result.insurance.insuranceType === 'vehicleInsurance'
  )

  // Check if we have insurance offers with the same registrationNo that we have in quote
  const insurancesWithTheSameRegistrationNo = vehicleInsurances.filter(
    (result) => result.insurance.registrationNo === quote.MotorRegistrationNumber
  )

  // return them immediately if found at least one
  if (insurancesWithTheSameRegistrationNo.length > 0) {
    // Return only the first found match
    return filterSensitiveInsurelyFields(insurancesWithTheSameRegistrationNo.slice(0, 1))
  } else {
    // Return all offers without registration number if no insurancesWithTheSameRegistrationNo match found
    return filterSensitiveInsurelyFields(
      vehicleInsurances.filter((result) => !result.insurance.registrationNo)
    )
  }
}
