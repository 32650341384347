import { Events, FSMCommonTransitions } from '../../constants'

import fsmConfigClaimsCollision from '../collision/fsmConfigClaimsCollision'
import fsmConfigClaimsWindshield from '../windshield/fsmConfigClaimsWindshield'
import fsmConfigClaimsTheft from '../theft/fsmConfigClaimsTheft'
import fsmConfigClaimsRoadsideAssistance from '../roadsideAssistance/fsmConfigClaimsRoadsideAssistance'
import fsmConfigClaimsOther from '../other/fsmConfigClaimsOther'

const {
  AUTH_BANK_ID,
  MY_PAGES,
  FNOL_CLAIMS,
  FNOL_CLAIMS_LIST,
  POLICIES_LIST,
  FNOL_WINDSHIELD_FLOW,
  FNOL_COLLISION_FLOW,
  FNOL_ROADSIDE_ASSISTANCE_FLOW,
  FNOL_THEFT_FLOW,
  FNOL_OTHER_FLOW,
} = FSMCommonTransitions

export default (FLOW_MACHINE_ID: string) => ({
  [FNOL_CLAIMS]: {
    initial: POLICIES_LIST,
    states: {
      [POLICIES_LIST]: {
        on: {
          [Events.BACK]: `#${FLOW_MACHINE_ID}.${MY_PAGES}`,
          [Events.NEXT]: FNOL_CLAIMS_LIST,
          [Events.NO_DATA]: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
        },
      },
      [FNOL_CLAIMS_LIST]: {
        on: {
          [Events.BACK]: POLICIES_LIST,
          [Events.FNOL_WINDSHIELD]: `#${FLOW_MACHINE_ID}.${FNOL_WINDSHIELD_FLOW}`,
          [Events.FNOL_COLLISION]: `#${FLOW_MACHINE_ID}.${FNOL_COLLISION_FLOW}`,
          [Events.FNOL_THEFT]: `#${FLOW_MACHINE_ID}.${FNOL_THEFT_FLOW}`,
          [Events.FNOL_ROADSIDE_ASSISTANCE]: `#${FLOW_MACHINE_ID}.${FNOL_ROADSIDE_ASSISTANCE_FLOW}`,
          [Events.FNOL_OTHER]: `#${FLOW_MACHINE_ID}.${FNOL_OTHER_FLOW}`,
        },
      },
    },
  },
  ...fsmConfigClaimsWindshield(FLOW_MACHINE_ID),
  ...fsmConfigClaimsCollision(FLOW_MACHINE_ID),
  ...fsmConfigClaimsRoadsideAssistance(FLOW_MACHINE_ID),
  ...fsmConfigClaimsTheft(FLOW_MACHINE_ID),
  ...fsmConfigClaimsOther(FLOW_MACHINE_ID),
})
