import React, { FC } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import ExpandableItem from '../../../components/shared/landing/ExpandableItem'
import { Card } from '@material-ui/core'
import { Typography } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import questionsAndAnswers from './landingText/questionsAndAnswers.json'
import { TRANSLATIONS as T } from '../../../constants'
import { getLocalizedPartnerName } from '@dg-util'

const useStyles = makeStyles((theme) =>
  createStyles({
    // Standard Bottom margin for elements
    mb: {
      marginBottom: theme.spacing(4),
    },
    cardWrapper: {
      padding: '32px',
    },
    border: {
      boxShadow: `0px 1px 0px ${theme.brand.colors.buttonInactive}`,
      padding: '16px 32px 16px 40px',
    },
    gap: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
  })
)

const LandingQuestoinsAnswersBodyVehicle: FC = ({}) => {
  const classes = useStyles()
  const QA = questionsAndAnswers
  const { t } = useTranslate(T.LANDING)
  const brandName = getLocalizedPartnerName()
  const brandNameSanitized = brandName.replace('Š', 's').toLocaleLowerCase()
  const phone = (t('phone') || '') as string
  const localize = (text: string) =>
    text
      .replace(/{brandName}/gi, brandName)
      .replace(/{phone}/gi, phone)
      .replace(/{brandNameSanitized}/gi, brandNameSanitized)

  return (
    <Card className={classes.cardWrapper}>
      <Typography type='h1' className={classes.mb}>
        Ofte stilte spørsmål om {brandName} Forsikring
      </Typography>

      {QA.content.map((el, i) => {
        return (
          <ExpandableItem
            customDetailsClass={classes.gap}
            key={i}
            customClass={classes.border}
            title={localize(el.question)}
          >
            {el.answer &&
              el.answer.map((item, i) => {
                return (
                  <Typography html={true} key={i} type='h5' className={classes.gap}>
                    {localize(item)}
                  </Typography>
                )
              })}
          </ExpandableItem>
        )
      })}
    </Card>
  )
}

export default LandingQuestoinsAnswersBodyVehicle
