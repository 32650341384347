import Sentry from './sentry.service'
import { getProductName } from '@dg-util'

export const getSentryPerformanceTransaction = (name: string) => {
  const productName = getProductName()
  const transaction = Sentry.startTransaction({ name: `${name} [${PARTNER_NAME}][${productName}]` })
  Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(transaction))

  return transaction
}
