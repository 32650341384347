import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import RoomIcon from '@material-ui/icons/Room'

import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { useTranslate } from '../../../hooks/shared'

const useRFFCustomLocationButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.info.dark,
      fontWeight: 400,
      fontSize: '16px',
      border: 'none',
      height: '50px',
      width: '100%',
      borderRadius: '8px',
      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
      marginBottom: '20px',
      textTransform: 'none',
      '& > span': {
        justifyContent: 'space-between',
      },
      '&:hover': {
        border: 'none',
      },
    },
  })
)

interface LocationButtonProps {
  clickHandler(): void
}

const RFFCustomLocationButton: FC<LocationButtonProps> = ({ clickHandler }) => {
  const { t } = useTranslate(T.FNOL_OTHER_WHEN_WHERE)
  const classes = useRFFCustomLocationButtonStyles()

  return (
    <Button
      onClick={() => clickHandler()}
      color='primary'
      className={classes.button}
      endIcon={<RoomIcon color='primary' />}
      disableRipple
    >
      {t('locationBtn')}
    </Button>
  )
}

export default RFFCustomLocationButton
