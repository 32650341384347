import { Events, FNOLClaimTypes } from '../../FSM/shared/constants'

export interface ClaimEventName {
  eventName: Events
  label?: string
  value?: string | number | boolean
  claimTypeID?: number
  causeTypeID?: number
}

export const QUESTIONS = {
  CHOOSE_CORRECT_CLAIM: 'QuestionChooseCorrectClaim', // fnol/other
  HAPPENED_OTHER_REASONS: 'QuestionWhatHasHappenedOtherReasons', // damage-list
  REASON_FOR_FIRE: 'QuestionReasonForFire', // damage-brann
  WHEN_DID_IT_HAPPENED: 'QuestionWhenDidItHappened', // damage-accident-form
  WHERE_DID_IT_HAPPENED: 'QuestionWhereDidItHappened', // damage-accident-form
  WHEN_DID_DISPUTE_OCCURED: 'QuestionWhenDidDisputeOccurred', // damage-legal-aid-date
  WHAT_IS_THE_DISPUTED_AMOUNT: 'QuestionWhatIsTheDisputedAmount', // damage-legal-aid-compensation
  ANY_OTHER_INFORMATION_WE_SHOULD_KNOW_OF: 'QuestionAnyOtherInformationWeShouldKnowOf', // damage-legal-aid-comments
}

export const FNOL_COLLISION_QUESTIONS = {
  WHAT_HAPPENED: 'QuestionWhatHappened',
  HOW_MANY_CARS_INVOLVED: 'QuestionHowManyCarsWereInvolved',
  WHO_IS_BLAME: 'QuestionInYourOpinionWhatIsMostCorrect',
  DID_ANYONE_GET_INJURED: 'QuestionDidAnyoneGetInjured',
  HOW_DID_ACCIDENT_HAPPEN: 'QuestionHowDidTheAccidentHappen',
  LICENSE_PLATE_NUMBER: 'QuestionTheOtherPartsLicensPlateNumber',
  HAVE_YOU_FILLED_FORM_PAPER: 'QuestionHaveYouFilledOutClaimFormOnPaper',
  WHAT_KIND_OF_ANIMAL: 'QuestionWhatKindOfAnimalDidYouHit',
  DESCRIBE_WHAT_HAPPENED: 'QuestionDiescribeWhatHappened',
  WHAT_DID_YOU_HIT: 'QuestionWhatDidYouHit',
  WAS_ANYONE_IN_YOUR_CAR_INJURED: 'QuestionWasAnyoneInYourCarInjured',
}

export const FLOW_THEFT_QUESTIONS = {
  WHAT_HAS_HAPPENED: 'QuestionWhatHasHappened',
  WHAT_WAS_STOLEN: 'QuestionWhatWasStolen',
  WHAT_WAS_STOLEN_FROM_THE_CAR: 'QuestionWhatWasStolenFromTheCar',
  HOW_OLD_IS_THE_ITEM: 'QuestionHowOldIsTheItem',
  ESTIMATED_VALUE: 'QuestionEstimatedValue',
  REPORTED_TO_POLICE: 'QuestionIsItReportedToThePolice',
  POLICE_CASE: 'QuestionWhatIsTheReportNumber',
}

export const FLOW_THEFT_CLAIM_TYPE_IDS: { [key in Events]?: number } = {
  [Events.FNOL_THEFT_STOLEN_CAR]: 1,
  [Events.FNOL_THEFT_STOLEN_IN_CAR]: 2,
  [Events.FNOL_THEFT_STOLEN_FROM_CAR]: 3,
}

export const FLOW_THEFT_POLICE_REPORT_CLAIM_TYPE_IDS: { [key in Events]?: number } = {
  [Events.FNOL_THEFT_POLICE_REPORT]: 1,
  [Events.NEXT]: 2,
}

export const FLOW_COLLISION_ANIMAL_CAUSE_TYPE_IDS: Record<string, number> = {
  moose: 1,
  deer: 2,
  babyDeer: 3,
  badger: 4,
  fox: 5,
  pets: 6,
}

export enum AccidentCauseTypes {
  NOT_GIVE_UP = 'buttons.notGiveUp',
  REAR_END_COLLISION = 'buttons.rearEndCollision',
  BACKING = 'buttons.backing',
  OVERTAKING = 'buttons.overtaking',
  PARKING = 'buttons.parking',
  ONCOMING = 'buttons.oncoming',
  LEFT_TURN = 'buttons.leftTurn',
  FIELD_CHANGE = 'buttons.fieldChange',
  CHAIN_COLLISION = 'buttons.chainCollision',
  ROUNDABOUT = 'buttons.roundabout',
  OTHER = 'buttons.other',
}

export const FLOW_COLLISION_CASE_OF_ACCIDENT_CAUSE_TYPE_IDS: {
  [key in AccidentCauseTypes]?: number
} = {
  [AccidentCauseTypes.NOT_GIVE_UP]: 1,
  [AccidentCauseTypes.REAR_END_COLLISION]: 2,
  [AccidentCauseTypes.BACKING]: 3,
  [AccidentCauseTypes.OVERTAKING]: 4,
  [AccidentCauseTypes.PARKING]: 5,
  [AccidentCauseTypes.ONCOMING]: 6,
  [AccidentCauseTypes.LEFT_TURN]: 7,
  [AccidentCauseTypes.FIELD_CHANGE]: 8,
  [AccidentCauseTypes.CHAIN_COLLISION]: 9,
  [AccidentCauseTypes.ROUNDABOUT]: 10,
  [AccidentCauseTypes.OTHER]: 11,
}

export const CLAIM_TYPE_IDS: { [key in FNOLClaimTypes]?: number } = {
  [FNOLClaimTypes.FNOL_WINDSHIELD]: 1,
  [FNOLClaimTypes.FNOL_COLLISION]: 2,
  [FNOLClaimTypes.FNOL_THEFT]: 3,
  [FNOLClaimTypes.FNOL_ROADSIDE_ASSISTANCE]: 4,
  [FNOLClaimTypes.FNOL_OTHER]: 5,
}

export enum FireCauseTypes {
  FUEL_LEAK = 'fuelLeak',
  ELECTRIC = 'electric',
  LIT = 'lit',
  OPEN_FIRE = 'openFire',
  OTHER_CAUSE = 'otherCause',
}

export const FNOL_OTHER_REASON_FOR_FIRE_CAUSE_TYPE_IDS: { [key in FireCauseTypes]: number } = {
  [FireCauseTypes.FUEL_LEAK]: 1,
  [FireCauseTypes.ELECTRIC]: 2,
  [FireCauseTypes.LIT]: 3,
  [FireCauseTypes.OPEN_FIRE]: 4,
  [FireCauseTypes.OTHER_CAUSE]: 5,
}
