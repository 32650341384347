import React, { FC, ReactElement } from 'react'
import { FSMRouterConsumer } from '../FSMContext'
import { get, isObject } from 'lodash'
import { FSMStateType } from '../../types/interface/fsm.interface'

// FYI: Deep comparison of two objects. In two words, this function omits inner
// key and returns true if 1st layer key and 2nd layer value are the same.
const deepStateMatch = (currentState: FSMStateType, query: string): boolean => {
  if (currentState.matches(query)) return true

  const state = currentState.value

  if (!isObject(state)) return false

  const outerKey = Object.keys(state)[0]
  const innerStateObject = get(state, outerKey)
  const innerKey = get(Object.keys(innerStateObject), 0)

  if (!innerKey) return false

  const stateValue = get(state, [outerKey, innerKey])

  return stateValue === get(query, outerKey)
}

const FSMSwitch: FC = ({ children }) => {
  return (
    <FSMRouterConsumer>
      {(context) => {
        let element: React.ReactNode

        if (!context.currentState) return

        //TODO: Define type for child
        React.Children.forEach(children, (child: React.ReactNode) => {
          if (React.isValidElement(child)) {
            if (element) return

            if (deepStateMatch(context.currentState, child.props.is)) {
              element = child
            }
          }
        })

        return (
          !!element &&
          React.cloneElement(element as ReactElement, {
            service: context.service,
            currentState: context.currentState.value,
          })
        )
      }}
    </FSMRouterConsumer>
  )
}

export default FSMSwitch
