import { isObject, isEqual } from 'lodash'
import { State, StateValueMap } from 'xstate'

import { FSMStorageKey, FSM_PRESERVED_DATA_KEY } from './shared/constants'
import { FlowMachineType, FSMStateType } from '../types/interface/fsm.interface'
import { getProductName } from '@dg-util'

const removePersistedState = (): void => localStorage.removeItem(FSMStorageKey)

const getPersistedState = (machine: FlowMachineType) => {
  // Check for FSM state manipulation (switch flow / product without logout)
  const fsm_preserved_data = localStorage.getItem(FSM_PRESERVED_DATA_KEY)

  // FSM manipulation
  if (fsm_preserved_data) {
    // Get current flow/product initial state
    const initialState = State.create(machine.initialState)

    // Manipulate FSM state value and context from preserved data
    const fsm_preserved = JSON.parse(fsm_preserved_data)

    // Force navigate to the specific FSM state immediately
    initialState.value = fsm_preserved.value

    // Preserve FSM context data (like auth)
    initialState.context = fsm_preserved.context

    // Persist initial state
    persistState(initialState)

    // Remove preserved data to avoid conflicts after page reload
    localStorage.removeItem(FSM_PRESERVED_DATA_KEY)

    // Resolve FSM manipulated state and return its persisted cache
    return machine.resolveState(initialState)
  }

  const stateFetched = JSON.parse(localStorage.getItem(FSMStorageKey))

  if (!stateFetched) return machine.resolveState(State.create(machine.initialState))

  const isEqualProductName = getProductName() === stateFetched.productName

  if (!isEqualProductName) {
    removePersistedState()
    return
  }
  if (isEqualProductName) return machine.resolveState(State.create(stateFetched))
}

const persistState = (stateFSM: FSMStateType) => {
  const productName = getProductName()
  const jsonState = JSON.stringify({ ...stateFSM, productName })

  try {
    localStorage.setItem(FSMStorageKey, jsonState)
  } catch (e) {
    throw new Error('Inability to save the state')
  }
}

const getObjectValue = (value: StateValueMap | string): string => {
  return isObject(value) ? getObjectValue(value[Object.keys(value)[0]]) : value
}

const setClientHistory = (value: string | StateValueMap): void => {
  const title = getObjectValue(value)
  // "history.state" equals to "value" only in case user press browser's back button
  // and browser's history state already updated. In this case there is no need to update history.
  if (!isEqual(history.state, value)) {
    history.pushState(value, title as string, title as string)
  }
}

export { getPersistedState, removePersistedState, persistState, setClientHistory }
