import React, { FC } from 'react'
import { FlowServiceType } from '../../types/interface/fsm.interface'
import { useSelector } from '@xstate/react'
import { AuthMethod } from '../../constants/auth-constants'
import { SESSION_ID_KEY } from '../../config'

type AgentStatusPropsType = {
  flowService: FlowServiceType
}

const AgentStatus: FC<AgentStatusPropsType> = ({ flowService }) => {
  const isAgent =
    useSelector(flowService, (state) => state.context.authMethod === AuthMethod.AGENT) ||
    !!sessionStorage.getItem(SESSION_ID_KEY)

  return isAgent ? <p hidden>Agent</p> : null
}

export default AgentStatus
