import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { CardActionArea, CardContent, Card } from '@material-ui/core'

import { Typography, CustomCardHeader } from '@dg-shared'
import { useTranslate } from '../../hooks/shared'
import { addSpaceRegNum } from '@dg-util'
import { PolicyV2Type } from '../../types/ApiV2'
import { TRANSLATIONS as T } from '../../constants'
import { ProductName } from '../../types/interface/quote.interface'
import PolicyInsuranceDatesText from '@dg-shared/PolicyInsuranceDatesText'

const usePolicyCardStyles = makeStyles((theme) =>
  createStyles({
    cardTextContainer: {
      width: '100%',
      height: '100%',
    },
    vehicleInformationText: {
      fontSize: '1.25rem',
      wordBreak: 'break-word',
      fontWeight: 500,
    },
    date: {
      position: 'absolute',
      bottom: theme.spacing(2),
    },
    card: {
      height: '100%',
      minHeight: theme.spacing(29),
      transition: '0.2s',
      '&:hover': {
        boxShadow: `0 0 10px ${theme.palette.primary.main}`,
      },
    },
    vehicleInfo: {
      marginBottom: theme.spacing(1),
    },
    cardHover: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'start',
    },
    policyNumber: {
      position: 'absolute',
      bottom: 0,
      paddingBottom: theme.spacing(1),
    },
    cardHeaderContainerStyle: {
      '& > div': {
        marginTop: 0,
      },
      ...theme.brand.assets.layout?.policyCardV2CardHeaderContainer,
    },
  })
)

interface PolicyCardProps {
  data: PolicyV2Type
  handleClick(): void
  priceText?: string | string[]
}

const PolicyCardV2: FC<PolicyCardProps> = ({ data, handleClick }) => {
  const classes = usePolicyCardStyles()
  const { ManufacturingYear, CarMake, CarModel, MotorRegistrationNumber, InsuranceNumber } = data
  const vehicleInfo = [ManufacturingYear, CarMake, CarModel].join(' ')
  const { t } = useTranslate(T.POLICY_REVIEW)

  const cardContentPerProductMap: Record<ProductName, () => JSX.Element> = {
    [ProductName.VEHICLE]: () => (
      <CardContent className={classes.cardTextContainer}>
        {vehicleInfo ? (
          <Typography type={'h2'} className={classes.vehicleInfo} testId={'vehicle-info'}>
            {vehicleInfo}
          </Typography>
        ) : null}
        {!!MotorRegistrationNumber && (
          <Typography testId={'reg-num'}>
            <Trans i18nKey='regNum' values={{ regNum: MotorRegistrationNumber }} />
          </Typography>
        )}
        <Typography className={classes.policyNumber} testId={'policy-num'}>
          <Trans i18nKey='policyNum' values={{ policyNum: addSpaceRegNum(InsuranceNumber) }} />
        </Typography>
      </CardContent>
    ),
    [ProductName.TRAVEL]: () => (
      <CardContent className={classes.cardTextContainer}>
        <PolicyInsuranceDatesText policy={data} />
        <Typography className={classes.policyNumber} testId={'policy-num'}>
          <Trans i18nKey='policyNum' values={{ policyNum: addSpaceRegNum(InsuranceNumber) }} />
        </Typography>
      </CardContent>
    ),
    [ProductName.CONTENT]: () => (
      <CardContent className={classes.cardTextContainer}>
        <PolicyInsuranceDatesText policy={data} />
        <Typography className={classes.policyNumber} testId={'policy-num'}>
          <Trans i18nKey='policyNum' values={{ policyNum: addSpaceRegNum(InsuranceNumber) }} />
        </Typography>
      </CardContent>
    ),
  }

  return (
    <Card
      className={classes.card}
      elevation={3}
      onClick={() => handleClick()}
      data-testid={'policy-card'}
    >
      <CardActionArea className={classes.cardHover}>
        <CustomCardHeader
          titleTextType='h3'
          withPrice={false}
          title={t(`card.title.${data.productName}`)}
          containerClass={classes.cardHeaderContainerStyle}
        />
        {cardContentPerProductMap[data.productName]()}
      </CardActionArea>
    </Card>
  )
}

export default PolicyCardV2
