import { Events, FSMCommonTransitions } from '../../constants'

const { FNOL_CLAIMS, FNOL_CLAIMS_LIST, FNOL_WINDSHIELD_FLOW, FNOL_WINDSHIELD_START, MY_PAGES } =
  FSMCommonTransitions

export default (FLOW_MACHINE_ID: string) => ({
  [FNOL_WINDSHIELD_FLOW]: {
    initial: FNOL_WINDSHIELD_START,
    states: {
      [FNOL_WINDSHIELD_START]: {
        on: {
          [Events.BACK]: { target: `#${FLOW_MACHINE_ID}.${FNOL_CLAIMS}.${FNOL_CLAIMS_LIST}` },
          [Events.NEXT]: { target: `#${FLOW_MACHINE_ID}.${MY_PAGES}` },
        },
      },
    },
  },
})
