import React, { ReactNode } from 'react'

import { getProductName } from '@dg-util'
import { PartnerName, ProductName } from '../../types/interface/quote.interface'

type PidWrapperPropsType = {
  children: ReactNode
  products: Array<ProductName>
  partners: Array<PartnerName>
  excludeMode?: boolean
}

const ProductWrapper: React.FC<PidWrapperPropsType> = ({
  children,
  products,
  partners,
  excludeMode = false,
}) => {
  const productName = getProductName()
  const includesProduct = products.includes(productName)
  const includesPartner = partners.includes(PARTNER_NAME as PartnerName)

  const showForProduct = excludeMode ? !includesProduct : includesProduct
  const showForPartner = excludeMode ? !includesPartner : includesPartner

  return showForProduct && showForPartner ? <>{children}</> : null
}

export default ProductWrapper
