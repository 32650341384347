import React, { FC } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Switch, FormControlLabel } from '@material-ui/core'

interface RFFCustomSwitchProps extends FieldRenderProps<unknown, HTMLElement> {
  fieldLabel: string
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom'
  customLabel?: React.ReactNode
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    switch: {
      marginBottom: theme.spacing(3),
      '& > span': {
        color: theme.palette.info.dark,
        fontSize: '1rem',
      },
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      marginLeft: '3px',
    },
  })
)

const RFFCustomSwitch: FC<RFFCustomSwitchProps> = ({
  input,
  customLabel,
  fieldLabel,
  labelPlacement = 'end',
  customClass = '',
}) => {
  const classes = useStyles()

  return (
    <FormControlLabel
      control={
        <Switch
          onChange={input.onChange}
          name={input.name}
          value={input.value}
          checked={input.checked}
          edge='start'
        />
      }
      id={input.name}
      label={customLabel || fieldLabel}
      className={`${classes.switch} ${customClass}`}
      labelPlacement={labelPlacement}
    />
  )
}

export default RFFCustomSwitch
