import { getGDPRSettings } from '@dg-util'
import { AuthMethod } from '../../constants/auth-constants'

const isAuthorized = (context: { authorized: boolean }) => context.authorized
const gdpr_setting = getGDPRSettings()
const isGDPRAccept = () => gdpr_setting.cookies

const isVerify = (_context: Record<string, string>, event: { isVerify: boolean }) => event.isVerify

const isVippsAuth = (context: { authMethod: AuthMethod }) =>
  [AuthMethod.VIPPS].includes(context.authMethod)
const isAuthAgent = (context: { authMethod: AuthMethod }) =>
  [AuthMethod.AGENT].includes(context.authMethod)
const isNotVippsAuth = (context: { authMethod: AuthMethod }) =>
  ![AuthMethod.VIPPS].includes(context.authMethod)
const isBankIdAuth = (context: { authMethod: AuthMethod }) =>
  [AuthMethod.BANKID].includes(context.authMethod)

export default {
  isAuthorized,
  isGDPRAccept,
  isVerify,
  isVippsAuth,
  isNotVippsAuth,
  isBankIdAuth,
  isAuthAgent,
}
