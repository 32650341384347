import Table from '@dg-shared/landing/Table'
import React from 'react'
import CoverTableHeadItem from '@dg-shared/landing/CoverTableHeadItem'
import { CoverageTypeKeyType } from '../../../types/interface/quote.interface'
import ExpandableItem from '@dg-shared/landing/ExpandableItem'
import { BrandIconShared, Typography } from '@dg-shared'
import { IconType } from '../../../types/interface/icon.interface'

const tableData = {
  title: 'Få enkel oversikt over våre ulike forsikringer',
  thead: [
    <CoverTableHeadItem key={0} item={{ label: 'Dekning' }} />,
    <CoverTableHeadItem key={1} item={{ label: 'Ansvar', infoIcon: CoverageTypeKeyType.ansvar }} />,
    <CoverTableHeadItem
      key={2}
      item={{ label: 'Delkasko', infoIcon: CoverageTypeKeyType.delkasko }}
    />,
    <CoverTableHeadItem key={3} item={{ label: 'Kasko', infoIcon: CoverageTypeKeyType.kasko }} />,
    <CoverTableHeadItem key={4} item={{ label: 'Super', infoIcon: CoverageTypeKeyType.super }} />,
  ],
  tbody: [
    [
      <ExpandableItem key={0} title={'Ansvar'}>
        <Typography size={'small'} color={'textSecondary'}>
          Lovpålagt ansvarsdekning er inkludert i alle bilforsikringene våre. I tillegg til den
          lovpålagte ansvarsdekningen dekkes også fører- og passasjerulykke og utgifter til
          rettshjelp.
        </Typography>
        <Typography modifiers={['bold']} size={'small'} color={'textSecondary'}>
          Lovpålagt ansvarsdekning
        </Typography>
        <ul>
          <li>Inntil 100 000 000 kroner ved skade på andres bygninger/eiendeler</li>
          <li>Ubegrenset beløp for personskader for alle involverte i ulykken</li>
          <li>
            Fører- og passasjerulykke dekkes med inntil 100 000 kroner ved død, og inntil 200 000
            kroner ved medisinsk invaliditet. Summen er per person
          </li>
          <li>Ingen egenandel</li>
        </ul>
      </ExpandableItem>,
      <BrandIconShared key={1} type={IconType.CHECK} color={'secondary'} />,
      <BrandIconShared key={2} type={IconType.CHECK} color={'secondary'} />,
      <BrandIconShared key={3} type={IconType.CHECK} color={'secondary'} />,
      <BrandIconShared key={4} type={IconType.CHECK} color={'secondary'} />,
    ],
    [
      <ExpandableItem key={0} title='Rettshjelp'>
        <Typography size={'small'} color={'textSecondary'}>
          Du kan få dekket utgifter til rettshjelp med inntil 100 000 kroner. Egenandelen er 4 000
          kroner + 20 % av omkostningene.
        </Typography>
      </ExpandableItem>,
      <BrandIconShared key={1} type={IconType.CHECK} color={'secondary'} />,
      <BrandIconShared key={2} type={IconType.CHECK} color={'secondary'} />,
      <BrandIconShared key={3} type={IconType.CHECK} color={'secondary'} />,
      <BrandIconShared key={4} type={IconType.CHECK} color={'secondary'} />,
    ],
    [
      <ExpandableItem key={0} title='Brann og tyveri'>
        <Typography size={'small'} color={'textSecondary'}>
          Vi dekker kostnader til reparasjon av bilen din dersom den blir skadet i brann, eksplosjon
          eller lynnedslag.
        </Typography>
        <Typography size={'small'} color={'textSecondary'}>
          Du er også dekket hvis bilen din får skader som følge av naturskader som flom, storm,
          skred eller jordskjelv.
        </Typography>
        <Typography size={'small'} color={'textSecondary'}>
          Vi erstatter verdien av bilen din hvis den blir stjålet. Du får også dekket kostnader til
          reparasjon av skader påført i forbindelse med tyveri, samt skader forårsaket av hærverk.
        </Typography>
        <Typography size={'small'} color={'textSecondary'}>
          Tyveri av bilen din eller fysisk skade på bilen som følge av forsøk på tyveri.
        </Typography>
      </ExpandableItem>,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={2} type={IconType.CHECK} color={'secondary'} />,
      <BrandIconShared key={3} type={IconType.CHECK} color={'secondary'} />,
      <BrandIconShared key={4} type={IconType.CHECK} color={'secondary'} />,
    ],
    [
      <ExpandableItem key={0} title='Glasskade'>
        <Typography size={'small'} color={'textSecondary'}>
          Vi dekker skader på alle bilens vindusruter, samt glasstak og takluker av glass. Hvis
          ruten kan repareres, betaler du ingen egenandel. Hvis ruten må byttes, betaler du en
          egenandel på 2 500 kroner.
        </Typography>
        <Typography size={'small'} color={'textSecondary'}>
          Glasskader påvirker ikke bonusen. Forsikringen inkluderer ikke skader på lykter eller
          speil.
        </Typography>
      </ExpandableItem>,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={2} type={IconType.CHECK} color={'secondary'} />,
      <BrandIconShared key={3} type={IconType.CHECK} color={'secondary'} />,
      <BrandIconShared key={4} type={IconType.CHECK} color={'secondary'} />,
    ],
    [
      <ExpandableItem key={0} title='Ekstrautstyr og løsøre'>
        <Typography size={'small'} color={'textSecondary'}>
          Personlig løsøre dekkes med 5 000 kroner.
        </Typography>
        <Typography size={'small'} color={'textSecondary'}>
          Ekstrautstyr dekkes med 10 000 kroner.
        </Typography>
      </ExpandableItem>,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={2} type={IconType.CHECK} color={'secondary'} />,
      <BrandIconShared key={3} type={IconType.CHECK} color={'secondary'} />,
      <BrandIconShared key={4} type={IconType.CHECK} color={'secondary'} />,
    ],
    [
      <ExpandableItem key={0} title='Veihjelp'>
        <Typography size={'small'} color={'textSecondary'}>
          Veihjelp, selv hjemme på din egen gårdsplass. Egenandelen er 500 kroner.
        </Typography>
        <Typography size={'small'} color={'textSecondary'}>
          Les mer om veihjelp.
        </Typography>
      </ExpandableItem>,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={2} type={IconType.CHECK} color={'secondary'} />,
      <BrandIconShared key={3} type={IconType.CHECK} color={'secondary'} />,
      <BrandIconShared key={4} type={IconType.CHECK} color={'secondary'} />,
    ],
    [
      <ExpandableItem key={0} title='Kollisjon, utforkjøring og velting'>
        <Typography size={'small'} color={'textSecondary'}>
          Med denne dekningen er du sikret mot skader på egen bil som skyldes større uhell, også de
          du selv er skyld i. Kjører du av veien, kolliderer eller velter bilen, dekker vi
          kostnadene ved reparasjon.
        </Typography>
        <Typography size={'small'} color={'textSecondary'}>
          Vi dekker også skader påført av andre plutselige ytre årsaker, for eksempel at et tre
          faller over bilen.
        </Typography>
      </ExpandableItem>,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={3} type={IconType.CHECK} color={'secondary'} />,
      <BrandIconShared key={4} type={IconType.CHECK} color={'secondary'} />,
    ],
    [
      <ExpandableItem key={0} title='Nøkkelforsikring'>
        <Typography size={'small'} color={'textSecondary'}>
          Dekker tapt eller brukket bilnøkkel med sum på inntil 7500 kroner og inntil ett
          skadetilfelle per år. Egenandelen er 500 kroner.
        </Typography>
      </ExpandableItem>,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={3} type={IconType.CHECK} color={'secondary'} />,
      <BrandIconShared key={4} type={IconType.CHECK} color={'secondary'} />,
    ],
    [
      <ExpandableItem key={0} title='Leiebil'>
        <Typography size={'small'} color={'textSecondary'}>
          Leiebil tilsvarende din egen bil, inntil VW Passat-størrelse. Leiebilen har du
        </Typography>
        <ul>
          <li>
            i inntil 30 dager hvis skaden skjer i Norge og du lar oss ordne reparasjon hos et av
            våre avtaleverksteder. Ønsker du å velge verksted selv får du leiebil i inntil 10 dager.
          </li>
          <li>
            i inntil 30 dager når du er på bilferie utenfor Norden og reparasjonen tar mer enn 2
            virkedager – uansett teknisk problem.
          </li>
        </ul>
      </ExpandableItem>,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={3} type={IconType.CHECK} color={'secondary'} />,
      <BrandIconShared key={4} type={IconType.CHECK} color={'secondary'} />,
    ],
    [
      <ExpandableItem key={0} title='Maskinskade'>
        <Typography size={'small'} color={'textSecondary'}>
          Dekker skader på motor og girkasse, og tilhørende elektronikk inntil bilen er 10 år eller
          har kjørt inntil 200&nbsp;000&nbsp;km. Bilen må ha fulgt alle service­intervallene.
        </Typography>
        <Typography size={'small'} color={'textSecondary'}>
          Maskinskade: 8000 kroner. Egenandelen er fast uansett bilens alder og km stand.
          Maskinskade gir ikke bonustap.
        </Typography>
      </ExpandableItem>,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={3} type={IconType.CHECK} color={'secondary'} />,
      <BrandIconShared key={4} type={IconType.CHECK} color={'secondary'} />,
    ],
    [
      <ExpandableItem key={0} title='Utvidet leiebil'>
        <Typography size={'small'} color={'textSecondary'}>
          Leiebil tilsvarende din egen bil, inntil VW Passat/Skoda Octavia/Audi A4-størrelse.
          Leiebilen har du
        </Typography>
        <ul>
          <li>
            i hele reparasjons­tiden når skaden skjer i Norge og du lar oss ordne reparasjon hos et
            av våre avtaleverksteder. Ønsker du å velge verksted selv får du leiebil i inntil 30
            dager
          </li>
          <li>
            i inntil 30 dager når skaden skjer i Norden, uansett teknisk problem, så lenge bilen må
            på verksted
          </li>
          <li>
            i inntil 30 dager når du er på bilferie utenfor Norden og reparasjonen tar mer enn 2
            virkedager – uansett teknisk problem
          </li>
        </ul>
      </ExpandableItem>,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={4} type={IconType.CHECK} color={'secondary'} />,
    ],
    [
      <ExpandableItem key={0} title='Utvidet ekstrautstyr og løsøre'>
        <Typography size={'small'} color={'textSecondary'}>
          Personlig løsøre dekkes med 10 000 kroner.
        </Typography>
        <Typography size={'small'} color={'textSecondary'}>
          Ekstrautstyr dekkes med 50 000 kroner.
        </Typography>
      </ExpandableItem>,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={4} type={IconType.CHECK} color={'secondary'} />,
    ],
    [
      <ExpandableItem key={0} title='Totalskadegaranti'>
        <Typography size={'small'} color={'textSecondary'}>
          Hvis bilen din er under 1 år og ikke har kjørt mer enn 15&nbsp;000&nbsp;km, får du en helt
          ny bil hvis den blir totalskadet.
        </Typography>
        <Typography size={'small'} color={'textSecondary'}>
          Totalskadegarantien gjelder ikke for biler som er leaset.
        </Typography>
      </ExpandableItem>,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={4} type={IconType.CHECK} color={'secondary'} />,
    ],
    [
      <ExpandableItem key={0} title='Utvidet totalskadegaranti'>
        <Typography size={'small'} color={'textSecondary'}>
          Hvis bilen din er under 3 år og ikke har kjørt mer enn 60&nbsp;000&nbsp;km, får du en helt
          ny bil hvis den blir totalskadet.
        </Typography>
        <Typography size={'small'} color={'textSecondary'}>
          Totalskadegarantien gjelder ikke for leasingbiler.
        </Typography>
      </ExpandableItem>,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={4} type={IconType.CHECK} color={'secondary'} />,
    ],
    [
      <ExpandableItem key={0} title='Startleie for leasing'>
        <Typography size={'small'} color={'textSecondary'}>
          Har du leasingbil får du dekket gjenstående startleie hvis bilen blir stjålet eller
          totalskadet. Maksimal utbetaling er 150 000 kroner. I tillegg vil leasingselskapet ditt få
          erstattet bilens markedsverdi.
        </Typography>
      </ExpandableItem>,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={1} type={IconType.ERROR} color={'textError'} />,
      <BrandIconShared key={4} type={IconType.CHECK} color={'secondary'} />,
    ],
  ],
}

const CompareCoversTable = ({ className }: { className: string }) => {
  return <Table className={className} data={tableData} />
}

export default CompareCoversTable
