import React, { FC } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import { QUESTIONS, TRANSLATIONS as T } from '../../../constants'
import { Events, FSMCommonTransitions } from '../../../FSM/shared/constants'
import { FNOL_OTHER_CAUSE_TYPE } from '../../../constants/fnol-constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Typography, FNOLPageWrapper, FNOLButton } from '@dg-shared'
import { useTranslate, useClaim } from '../../../hooks/shared'
import { useFNOLStyles } from '../shared/styles/useStyles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnNext: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.dark,
      textAlign: 'left',
      textTransform: 'none',
      justifyContent: 'flex-start',
      fontWeight: 400,
    },
  })
)

const PageAnnet: FC<CommonPageProps> = ({ service }) => {
  const { t } = useTranslate(T.FNOL_OTHER_START)
  const classes = useStyles()
  const FNOLClasses = useFNOLStyles({})
  const { send } = service
  const { setClaimMultipleAnswers } = useClaim(service)

  const handleBtnClick = async (
    name: FSMCommonTransitions,
    claimTypeID: number,
    causeTypeID?: number
  ) => {
    await setClaimMultipleAnswers({
      [QUESTIONS.CHOOSE_CORRECT_CLAIM]: claimTypeID,
      [QUESTIONS.HAPPENED_OTHER_REASONS]: causeTypeID,
    })
    send({ type: Events.NEXT, flowName: name })
  }

  const content = (
    <>
      <Typography className={FNOLClasses.title} type='h1'>
        {t('title')}
      </Typography>
      <Typography className={FNOLClasses.description}>{t('subtitle')}</Typography>
      {FNOL_OTHER_CAUSE_TYPE.map((event) => (
        <FNOLButton
          key={`button-${event.translationKey}`}
          customClassName={classes.btnNext}
          handleClick={() => handleBtnClick(event.name, event.claimTypeID, event.causeTypeID)}
          buttonText={t(`button.${event.translationKey}`)}
        />
      ))}
    </>
  )

  return (
    <FNOLPageWrapper breadcrumbsStepPercentage={30} breadcrumbsBtnText={t(`back`)}>
      {content}
    </FNOLPageWrapper>
  )
}

export default PageAnnet
