import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../../../constants/translations-constants'
import { PartnerName, ProductName } from '../../../../types/interface/quote.interface'
import { Typography, ProductWrapper } from '@dg-shared'
import { useTranslate } from '../../../../hooks/shared'
import CardPrice from './CardPrice'
import { ServiceSend } from '../../../../types/interface/fsm.interface'
import { InsurelyResultType } from '../../../../types/insurelyTypes'
import { QuoteV2Type } from '../../../../types/ApiV2'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerCards: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
  })
)

interface PriceTwinCardsProps {
  quote: Partial<QuoteV2Type>
  offerToCompare: InsurelyResultType
  send?: ServiceSend
}

const PriceTwinCards: FC<PriceTwinCardsProps> = ({ offerToCompare, quote, send }) => {
  const { t } = useTranslate(T.OFFER_COMPARE_PRICE)
  const classes = useStyles()

  return (
    <section className={classes.containerCards} data-testid='price-twin-cards'>
      <CardPrice
        testId={'card-price-1'}
        send={send}
        annotation={t('card.title.0')}
        btnText={t('card.button')}
        price={quote.CalculatedPrices.priceAnnual.toString()}
        priceText={t(`card.priceText`)}
      />

      <CardPrice
        testId={'card-price-2'}
        price={offerToCompare.insurance.premiumAmountYearRounded?.toString()}
        annotation={t('card.title.1')}
        priceText={t(`card.priceText`)}
        coverageName={offerToCompare.insurance.insuranceName}
        regNumMap={{
          quote: quote.MotorRegistrationNumber,
          offer: offerToCompare.insurance.registrationNo,
        }}
      />

      <ProductWrapper
        partners={[
          PartnerName.instabank,
          PartnerName.volkswagen,
          PartnerName.skoda,
          PartnerName.audi,
          PartnerName.nord,
        ]}
        products={[ProductName.VEHICLE]}
      >
        <Typography color='textDisclaimer' size='small'>
          {t(`priceWarning`)}
        </Typography>
      </ProductWrapper>
    </section>
  )
}

export default PriceTwinCards
