// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import { Typography } from '@dg-shared'
import TestComponent from 'components/testComponent'
import TestComponent2 from 'components/testComponent2'

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: 30,
    },
    section: {
      padding: 15,
      margin: 5,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      borderRadius: 10,
      border: '1px solid #ddd',
    },
    row: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      flex: 1,
    },
  })
)

const PlaygroundPage: FC = () => {
  const classes = useStyles()

  return (
    <section className={classes.content}>
      <Typography type='h1'>Playground</Typography>
      <Typography type='h2' color='main'>
        WebPack per-brand module resolving
      </Typography>
      <div className={classes.row}>
        <TestComponent />
        <TestComponent2 />
      </div>
    </section>
  )
}

export default PlaygroundPage
