import { Typography } from '@dg-shared/index'
import { useTranslate } from '../../hooks/shared'
import React, { FC } from 'react'
import { PolicyV2Type } from '../../types/ApiV2'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import PolicyVersionSelect from '@dg-shared/PolicyVersionSelect'
import PolicyInsuranceDatesText from '@dg-shared/PolicyInsuranceDatesText'
import { ProductName } from '../../types/interface/quote.interface'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        display: 'grid',
      },
    },
    vehicleInfo: {
      marginRight: theme.spacing(2),
    },
  })
)

const PolicyReviewCardHeader: FC<{
  policy: Partial<PolicyV2Type>
  showSelectVersion?: boolean
  classDate?: string
  classContainer?: string
}> = ({ policy, classDate, classContainer, showSelectVersion }) => {
  const { t } = useTranslate()
  const classes = useStyles()

  if (!policy) {
    return null
  }

  const datesRange =
    policy.Versions.length > 1 && showSelectVersion ? (
      <PolicyVersionSelect policy={policy} />
    ) : (
      <PolicyInsuranceDatesText policy={policy} classDate={classDate} />
    )

  const CardHeaderPerProduct: Record<ProductName, () => React.ReactNode> = {
    [ProductName.VEHICLE]: () => (
      <>
        <Typography
          type='h2'
          testId={'vehicle-info'}
          component='span'
          className={classes.vehicleInfo}
        >
          {[policy.ManufacuringYear, policy.CarMake, policy.CarModel]
            .filter((item) => item)
            .join(' ')}
        </Typography>
        <Typography type='h3' testId={'regNum-info'} html component='span'>
          {t(`regNum`, { regNum: policy.MotorRegistrationNumber })}
        </Typography>
      </>
    ),
    [ProductName.TRAVEL]: () => (
      <>
        <Typography
          type='h2'
          testId={'vehicle-info'}
          component='span'
          className={classes.vehicleInfo}
        >
          {t(`productProps.${policy.TravelCoverage}`)}
        </Typography>
        <Typography type='h3' testId={'regNum-info'} html component='span'>
          {t(`productProps.${policy.TravelCoverArea}`)}
        </Typography>
      </>
    ),
    [ProductName.CONTENT]: () => (
      <>
        <Typography
          type='h2'
          testId={'vehicle-info'}
          component='span'
          className={classes.vehicleInfo}
        >
          {policy.Address1}
        </Typography>
        <Typography type='h3' testId={'regNum-info'} html component='span'>
          {[policy.PostNumber, policy.City].filter((item) => item).join(' ')}
        </Typography>
      </>
    ),
  }

  return (
    <div className={[classes.container, classContainer].join(' ')}>
      {CardHeaderPerProduct[policy.productName]()}
      {datesRange}
    </div>
  )
}

export default PolicyReviewCardHeader
