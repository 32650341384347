export const FORM_ID = {
  AUTH: 'auth',
  FIND: 'find',
  CHANGE: 'change',
  OWNERSHIP: 'ownership',
  COMPARE_AUTH: 'compareAuth',
  VERIFY: 'verify',
  MILEAGE: 'mileage',
  POLICY_VERIFY: 'policyVerify',
  POLICY_CHANGE: 'policyChange',

  // FNOL
  ACCIDENT: 'accident',
  COMPENSATION: 'compensation',
  ADDITIONAL_INFO: 'additionalInfo',
  DATE: 'date',
  STOLEN_AGE_VALUE: 'stolenAgeValue',
  POLICE_CASE: 'policeCase',
  REGISTRATION_NUMBER: 'registrationNum',
  MORE_DETAILS: 'moreDetails',
  OBJECT: 'collisionObject',
  DAMAGE_REPORT: 'collisionDamageReport',
}

export const FORMAT_DAY_MONTH = 'd MMMM'
export const FORMAT_TIME_DAY_MONTH = 'HH:mm, dd MMMM'
export const FORMAT_YEAR_MONTH_DAY = 'yyyy/MM/dd'
export const FORMAT_YEAR_MONTH_DAY_HYPHEN = 'yyyy-MM-dd'
export const FORMAT_DAY_MONTH_YEAR = 'dd/MM/yyyy'
export const FORMAT_DAY_MONTH_YEAR_DOT = 'dd.MM.yyyy'

export const BONUS_TEST_ID = 'select-bonus'
export const PREVIOUS_CLAIMS_TEST_ID = 'select-PreviousClaims'
export const MILEAGE_TEST_ID = 'select-annual-mileage'
export const PREVIOUS_INSURANCE_COMPANY_TEXT_LOCATOR = 'form-insurance-company'
export const POLICY_START_DATE_TEXT_LOCATOR = 'form-date-picker'

export const CUSTOM_DATE_PICKER = 'RFFCustomDatePicker'
export const CUSTOM_TEXT_FIELD = 'RFFCustomTextField'
