import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

import { IconType } from '../../types/interface/icon.interface'
import { FSMBtnNext, BrandIconShared } from '@dg-shared'
import { TranslatedTextType } from '../../types'

const useBtnEditStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(2),
      },
    },
    button: {
      maxWidth: '325px',
      ...theme.brand.assets.layout?.btnNext,
      [theme.breakpoints.up('md')]: {
        borderWidth: '1px',
      },
    },
    btnEdit: {
      marginRight: -6,
      marginTop: -4,
      ...theme.brand.assets.layout?.btnNext,
      border: 'none',
    },
  })
)

interface BtnEditProps {
  text: TranslatedTextType
  testId?: string
  handleClick(): void
  id?: string
}

const BtnEdit: FC<BtnEditProps> = ({ text, handleClick, testId, id }) => {
  const classes = useBtnEditStyles()

  return (
    <div className={classes.container}>
      {/* //NOTE: Need block container for Safari when use forms */}
      <FSMBtnNext
        id={id}
        testId={testId}
        handleClick={handleClick}
        buttonText={text}
        customClassName={classes.button}
        outlined
        startIcon={<BrandIconShared type={IconType.CREATE} customClass={classes.btnEdit} />}
      />
    </div>
  )
}

export default BtnEdit
