import { isString } from 'lodash'
import { Events } from '../../../FSM/shared/constants'
import { normalizeMotorRegistrationNumber, filterPersonVehiclesByPartner } from '@dg-util'
import { FSMStateType, ServiceSend } from '../../../types/interface/fsm.interface'
import useReactQueryPerson from '../../../hooks/shared/useReactQueryPerson'
import { VehicleV2Type } from '../../../types/ApiV2'
import { PartnerName } from '../../../types/interface/quote.interface'
import { useContext, useEffect } from 'react'
import { Context } from '@dg-shared/ContextProvider'
import { QUOTE_ID, SELECTED_QUOTE_MOTOR_REGISTRATION_NUMBER } from '../../../constants'

interface HookOffers {
  isLoading: boolean
  vehicles: Array<VehicleV2Type>
  handleClick(id?: string): Promise<void>
}

const useLogic = (send: ServiceSend, currentState: FSMStateType): HookOffers => {
  const { personAllVehicles, isLoading } = useReactQueryPerson()
  const vehicles = filterPersonVehiclesByPartner(PARTNER_NAME as PartnerName, personAllVehicles)
  const { setContext } = useContext(Context)

  useEffect(() => {
    if (!isLoading) {
      if (vehicles.length === 0) {
        send({ type: Events.EMPTY, state: currentState })
      }
      window.scrollTo(0, 0)
    }
  }, [vehicles, isLoading, send, currentState])

  useEffect(() => {
    // Remove the current quoteId and registration number to avoid the wrong version fetching (Fetch current active policy without version)
    setContext({
      [SELECTED_QUOTE_MOTOR_REGISTRATION_NUMBER]: null,
      [QUOTE_ID]: null,
    })
  }, [setContext])

  const handleClick = async (MotorRegistrationNumber?: string): Promise<void> => {
    if (!isString(MotorRegistrationNumber)) {
      return send({ type: Events.FIND_CUSTOM, state: currentState })
    }

    setContext({
      [SELECTED_QUOTE_MOTOR_REGISTRATION_NUMBER]:
        normalizeMotorRegistrationNumber(MotorRegistrationNumber),
    })

    send({ type: Events.NEXT, state: currentState })
  }

  return {
    isLoading,
    vehicles,
    handleClick,
  }
}

export default useLogic
