import React, { FC, useEffect, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { endOfDay, startOfDay } from 'date-fns'
import { get } from 'lodash'

import { FORM_ID, QUESTIONS } from '../../constants'
import { MapResult } from '../../types/interface/map.interface'
import { formatMapLocations } from '../../service/util.service'
import { MapComponent, RFFCustomLocationButton } from '@dg-shared'
import { useClaim } from '../../hooks/shared'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import { FlowServiceType, FSMStateType } from '../../types/interface/fsm.interface'
import { Events } from '../../FSM/shared/constants'
import { updateDateWithTimeISO } from '@dg-util'
import useReactQueryPolicy from '../../hooks/shared/useReactQueryPolicy'
import Loading from '../../pages/qb/Loading/Page'

const useDocumentStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      width: '100%',
      textAlign: 'left',
      [theme.breakpoints.up('md')]: {
        maxWidth: '325px',
        margin: 'auto',
        marginTop: theme.spacing(5),
      },
    },
  })
)

interface AccidentFormValues {
  occurDate?: Date
  time?: Date
  country?: string
  city?: string
  address?: string
}

interface AccidentFormProps {
  service: FlowServiceType
  currentState?: FSMStateType
}

const preProcessFormValues = (values: AccidentFormValues) => {
  let date: string | undefined = undefined

  if (values.occurDate instanceof Date) {
    date = values.occurDate.toDateString()
  }
  const time = values.time?.toTimeString()

  if (time && date) {
    values.time = new Date(`${date} ${time}`)
  }

  return values
}

const WhenWhereForm: FC<AccidentFormProps> = ({ service, currentState }) => {
  const classes = useDocumentStyles()
  const { setClaimMultipleAnswers, claimAnswers } = useClaim(service, currentState)

  const [selectedDataFields, setSelectedDataFields] = useState<AccidentFormValues>({})
  const [mapIsOpened, setMapIsOpened] = useState(false)
  const { policy, isLoading, refetchPolicy } = useReactQueryPolicy()

  useEffect(() => {
    if (!policy) {
      ;(async () => await refetchPolicy())()
    }
  }, [policy, refetchPolicy])

  const formatDataFromState = (data: Record<string, unknown>): AccidentFormValues | null => {
    const addressKey = QUESTIONS.WHERE_DID_IT_HAPPENED
    const dateKey = QUESTIONS.WHEN_DID_IT_HAPPENED
    const occurDate = data[dateKey] as Date
    let time

    // Get a separate time from the dateTime occurDate
    if (occurDate) {
      time = new Date(occurDate)
    }
    const formattedAddress = get(data, addressKey, null)
    const [country, city, address] = formattedAddress?.split(',') || []

    return {
      address: address,
      city: city,
      country: country,
      occurDate,
      time,
    }
  }

  const getClaimLocal = async () => {
    const formattedData = formatDataFromState(claimAnswers)
    formattedData && setSelectedDataFields(formattedData)
  }

  useEffect(() => {
    ;(async () => {
      await getClaimLocal()
    })()
    // TODO: FIx with using correct deps in [] and useCallback. Left here to not break anything
    // eslint-disable-next-line
  }, [])

  const handleMapResult = (res: MapResult | null): void => {
    if (!res || !res?.address_components) {
      setMapIsOpened(false)
      return
    }

    const locations = formatMapLocations(res.address_components)
    setSelectedDataFields((prev) => ({
      ...prev,
      address: locations.address1,
      city: locations.city,
      country: locations.country,
    }))
    setMapIsOpened(false)
  }

  const handleSubmit = async ({ address, city, country, occurDate, time }: AccidentFormValues) => {
    const fullAddress = `${country}, ${city}, ${address}`

    // Update selected date with the selected time
    const finalOccurDateISO = updateDateWithTimeISO(occurDate, time)

    await setClaimMultipleAnswers({
      [QUESTIONS.WHEN_DID_IT_HAPPENED]: finalOccurDateISO,
      [QUESTIONS.WHERE_DID_IT_HAPPENED]: fullAddress,
    })

    service.send({ type: Events.NEXT, state: currentState })
  }

  if (mapIsOpened) {
    return <MapComponent handleClick={handleMapResult} />
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <section className={classes.formContainer}>
      <RFFCustomLocationButton clickHandler={() => setMapIsOpened(true)} />
      <FormBuilder
        id={FORM_ID.ACCIDENT}
        validationArgs={{
          min: startOfDay(new Date(policy?.InsuredFrom)),
          max: endOfDay(new Date()),
        }}
        setFormState={({ values }) => setSelectedDataFields(preProcessFormValues(values))}
        submitHandler={handleSubmit}
        filledFieldsData={selectedDataFields}
        isFormControllable
      />
    </section>
  )
}

export default WhenWhereForm
