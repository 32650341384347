import { Events, FSMCommonTransitions } from '../../constants'

const {
  FNOL_CLAIMS,
  FNOL_CLAIMS_LIST,
  FNOL_ROADSIDE_ASSISTANCE_FLOW,
  FNOL_ROADSIDE_ASSISTANCE_START,
  MY_PAGES,
} = FSMCommonTransitions

export default (FLOW_MACHINE_ID: string) => ({
  [FNOL_ROADSIDE_ASSISTANCE_FLOW]: {
    initial: FNOL_ROADSIDE_ASSISTANCE_START,
    states: {
      [FNOL_ROADSIDE_ASSISTANCE_START]: {
        on: {
          [Events.BACK]: { target: `#${FLOW_MACHINE_ID}.${FNOL_CLAIMS}.${FNOL_CLAIMS_LIST}` },
          [Events.NEXT]: { target: `#${FLOW_MACHINE_ID}.${MY_PAGES}` },
        },
      },
    },
  },
})
