import { FSMConfigVehicleNO, FSMOptionsVehicleNO } from './qb/vehicle/config'
import { FSMVehicleStatePathsNO } from './qb/vehicle/routes'

import { FSMConfigMyPagesNO, FSMOptionsMyPagesNO } from './mypages/vehicle/config'
import { FSMMyPagesStatePathsNO } from './mypages/vehicle/routes'

import { FSMConfigTravelNO, FSMOptionsTravelNO } from './qb/travel/config'
import { FSMTravelStatePathsNO } from './qb/travel/routes'

import { FSMConfigContentNO, FSMOptionsContentNO } from './qb/content/config'
import { FSMContentStatePathsNO } from './qb/content/routes'

import { FSMInputType } from '../../../types/FSM'
import { FlowName } from '../../../config'
import { ProductName } from '../../../types/interface/quote.interface'

// TODO: Fix types for the `options`
const BrandFSMInput: Partial<FSMInputType> = {
  [FlowName.QUOTE_AND_BUY]: {
    [ProductName.VEHICLE]: {
      config: FSMConfigVehicleNO,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options: FSMOptionsVehicleNO,
      routes: FSMVehicleStatePathsNO,
    },
    [ProductName.TRAVEL]: {
      config: FSMConfigTravelNO,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options: FSMOptionsTravelNO,
      routes: FSMTravelStatePathsNO,
    },
    [ProductName.CONTENT]: {
      config: FSMConfigContentNO,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options: FSMOptionsContentNO,
      routes: FSMContentStatePathsNO,
    },
  },
  [FlowName.MY_PAGES]: {
    [ProductName.VEHICLE]: {
      config: FSMConfigMyPagesNO,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options: FSMOptionsMyPagesNO,
      routes: FSMMyPagesStatePathsNO,
    },
    // Use the same as for vehicle
    [ProductName.TRAVEL]: {
      config: FSMConfigMyPagesNO,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options: FSMOptionsMyPagesNO,
      routes: FSMMyPagesStatePathsNO,
    },
    // Use the same as for vehicle
    [ProductName.CONTENT]: {
      config: FSMConfigMyPagesNO,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options: FSMOptionsMyPagesNO,
      routes: FSMMyPagesStatePathsNO,
    },
  },
}

export default BrandFSMInput
