import React, { FC } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { FormHelperText, FormControlLabel, FormControl } from '@material-ui/core'

import MUICustomCheckbox from './MUICustomCheckbox'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customCheckboxWrapper: {
      marginBottom: theme.spacing(3),
    },
    formControl: {
      '& > span:last-child': {
        fontSize: '1rem',
        color: theme.palette.primary.dark,
      },
    },
  })
)

const RFFCustomCheckbox: FC<FieldRenderProps<unknown>> = ({ input, meta, ...props }) => {
  const classes = useStyles()

  return (
    <div className={`${classes.customCheckboxWrapper} ${props.className}`}>
      {/* //TODO: error check - error={true} */}
      <FormControl>
        <FormControlLabel
          control={
            //TODO: make it controllable component
            <MUICustomCheckbox
              // icon={<FavoriteBorder />}
              // checkedIcon={<Favorite />}
              name={input.name}
              onChange={input.onChange}
              checked={!!input.value} // To avoid warning from TS, checked should be boolean
            />
          }
          classes={{
            root: classes.formControl,
          }}
          label={props.fieldLabel}
        />
      </FormControl>
      <FormHelperText>{meta.error}</FormHelperText>
    </div>
  )
}

export default RFFCustomCheckbox
