import React, { FC } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import { Typography, BrandIconShared, FNOLPageWrapper } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import { Button, ButtonGroup } from '@material-ui/core'
import { TRANSLATIONS as T } from '../../../constants'
import { IconType } from '../../../types/interface/icon.interface'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnGroup: {
      display: 'flex',
      height: 'inherit',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '400px',
        margin: 'auto',
        marginTop: theme.spacing(5),
        paddingTop: theme.spacing(3),
      },
    },
    btn: {
      flex: 1,
      borderRadius: 0,
      height: '132px',
      borderColor: theme.palette.info.main,
      '& [class*="label"]': {
        flexDirection: 'column',
        '& svg, p': {
          ...theme.brand.assets.layout?.ctaAnnotation,
        },
      },
      '&:hover': {
        background: theme.palette.primary.main,
        '& [class*="label"]': {
          '& svg, & p': {
            color: theme.palette.primary.contrastText,
            ...theme.brand.typography.overrides?.iconColor,
          },
        },
      },
    },
    btnText: {
      textTransform: 'initial',
      lineHeight: 'normal',
      wordBreak: 'break-word',
    },
    icon: {
      fontSize: theme.spacing(4),
      marginBottom: theme.spacing(0.5),
    },
  })
)

const MyPage: FC<CommonPageProps> = ({ service, currentState }) => {
  const classes = useStyles()
  const { t } = useTranslate(T.MY_PAGE)

  const { send } = service

  const btnGroup = (
    <ButtonGroup className={classes.btnGroup}>
      <Button
        data-testid='policies'
        onClick={() => send({ type: Events.POLICIES, state: currentState })}
        className={classes.btn}
        fullWidth
      >
        <BrandIconShared type={IconType.POLICY} customClass={classes.icon} />
        <Typography testId='policies-btn-title' className={classes.btnText}>
          {t('button.policiesList')}
        </Typography>
      </Button>
      <Button
        data-testid='claims'
        onClick={() => send({ type: Events.FNOL_CLAIMS, state: currentState })}
        className={classes.btn}
        fullWidth
      >
        <BrandIconShared type={IconType.FILE_COPY} customClass={classes.icon} />
        <Typography testId='claims-btn-title' className={classes.btnText}>
          {t('button.reportDamage')}
        </Typography>
      </Button>
    </ButtonGroup>
  )

  const content = (
    <>
      <Typography type='h1' testId='title'>
        {t('title')}
      </Typography>
      {btnGroup}
    </>
  )

  return (
    <FNOLPageWrapper
      breadcrumbsBtnText={t('back')}
      mobileWrapperStyle={{ margin: 0, height: '100%' }}
      desktopWrapperStyle={{ minHeight: 345 }}
    >
      {content}
    </FNOLPageWrapper>
  )
}

export default MyPage
