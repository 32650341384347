import React, { FC } from 'react'
import { ProductWrapper } from '@dg-shared/index'
import { useTranslate } from '../../hooks/shared'
import { Tab, Tabs } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { PartnerName, ProductName } from '../../types/interface/quote.interface'

type ProductSelectPropsType = {
  handleProductChange: (product: ProductName) => void
  selectedProduct: ProductName
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabsWrapper: {
      '& .MuiButtonBase-root': {
        minWidth: 120,
      },
      marginBottom: theme.spacing(3),
    },
  })
)

const ProductSelect: FC<ProductSelectPropsType> = ({ handleProductChange, selectedProduct }) => {
  const { t } = useTranslate()
  const classes = useStyles()
  const productNames: ProductName[] = [ProductName.VEHICLE, ProductName.TRAVEL, ProductName.CONTENT]

  const handleTabChange = (event: React.SyntheticEvent, productNameIndex: number) =>
    handleProductChange(productNames[productNameIndex])

  const tabValue = productNames.indexOf(selectedProduct)

  return (
    <ProductWrapper
      products={[ProductName.CONTENT, ProductName.TRAVEL, ProductName.VEHICLE]}
      partners={[PartnerName.instabank, PartnerName.nord]}
    >
      <Tabs
        textColor='primary'
        indicatorColor='primary'
        value={tabValue}
        onChange={handleTabChange}
        aria-label='title'
        className={classes.tabsWrapper}
      >
        <Tab label={t(`productFilterCar`)} />
        <Tab label={t(`productFilterTravel`)} />
        <Tab label={t(`productFilterContent`)} />
      </Tabs>
    </ProductWrapper>
  )
}

export default ProductSelect
