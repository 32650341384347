import { QueryClient } from '@tanstack/react-query'
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { ErrorStatus } from './constants'

const cacheTime = 1000 * 60 * 60 * 24 // 24 hours
const staleTime = 60 * 60 * 1000 // 1 hour

type HttpErrorType = {
  status: number
}

// Custom retry function.
const customRetry = (retryCount: number, error: HttpErrorType) => {
  const maxRetry = 3
  // No retries for auth errors
  const noRetriesForErrorCodes = [ErrorStatus.UNAUTHORIZED, ErrorStatus.SESSION_EXPIRED]

  return retryCount <= maxRetry && !noRetriesForErrorCodes.includes(error.status)
}

// Custom retry delay function. Maximum sum of delays will be 15 seconds
const customRetryDelay = (retryCount: number) => {
  const maxDelayTime = 5000
  const retryDelays = [
    1000, // 1 second for first retry attempt
    2000, // 2 seconds for the second
    4000, // 4 seconds for the third
    maxDelayTime, // for the third
  ]

  // All retry attempts after last attempt will be with the same maxDelayTime delay time
  return retryDelays[retryCount] || maxDelayTime
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: customRetry,
      retryDelay: customRetryDelay,
      staleTime,
      cacheTime,
      networkMode: 'always',
    },
    mutations: {
      networkMode: 'always',
    },
  },
})

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
  throttleTime: 0, // To cleanup cache on redirect (default throttle cause a bug)
})

persistQueryClient({
  queryClient,
  persister: localStoragePersister,
  maxAge: cacheTime,
  hydrateOptions: {},
  dehydrateOptions: {},
})

export default queryClient
