import React, { FC, useEffect, useState } from 'react'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import PageWrapper from '@dg-shared/PageWrapper'
import { CustomCardHeader, LDContainerV2FSM, LMBasicV3FSM } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import { TRANSLATIONS as T } from '../../../constants'
import { getDataComponentName } from '@dg-util'
import { Card, Tabs, Tab, Divider } from '@material-ui/core'
import Loading from '../../qb/Loading/Page'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import PolicyReviewCard from '@dg-shared/PolicyReviewCard'
import PolicyReviewCardHeader from '@dg-shared/PolicyReviewCardHeader'
import PolicyDocumentsList from '@dg-shared/PolicyDocumentsList'
import useLogic from './useLogic'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backgroundImage: {
      [theme.breakpoints.up('md')]: {
        ...theme.brand.assets.pageBackgrounds.review,
      },
    },
    documentsWrapper: {
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
        paddingTop: theme.spacing(5),
      },
    },
    button: {
      ...theme.brand.assets.layout?.ctaOverride,
      [theme.breakpoints.up('md')]: {
        width: '325px',
      },
    },
    divider: {
      width: '100%',
      opacity: 0.4,
    },
    cardHeaderWithTabs: {
      marginBottom: theme.spacing(2),
    },
  })
)

const PagePolicyReview: FC<CommonPageProps> = () => {
  const { t } = useTranslate(T.POLICY_REVIEW)
  const classes = useStyles()
  const { backBtn, handleClick, isLoading, policy, productProps, refetchPolicy } = useLogic()
  const [tabValue, setTabValue] = useState(0)

  useEffect(() => {
    ;(async () => await refetchPolicy())()
  }, [refetchPolicy, policy?.VersionNo])

  if (isLoading || !policy) {
    return <Loading />
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const CardHeaderWithTabs = () => (
    <div className={classes.cardHeaderWithTabs}>
      <PolicyReviewCardHeader policy={policy} showSelectVersion={true} />
      <Divider className={classes.divider} />
      <Tabs
        textColor='primary'
        indicatorColor='primary'
        value={tabValue}
        onChange={handleTabChange}
        aria-label='title'
      >
        <Tab label={t('details')} />
        <Tab label={t('documents')} />
      </Tabs>
    </div>
  )

  const tabs = [
    <PolicyReviewCard key={0} policy={policy} productProps={productProps}>
      <CardHeaderWithTabs />
    </PolicyReviewCard>,
    <PolicyDocumentsList
      policy={policy}
      key={1}
      handleClick={handleClick}
      className={classes.documentsWrapper}
    >
      <CardHeaderWithTabs />
    </PolicyDocumentsList>,
  ]

  const backgroundImagePerTab: Record<typeof tabValue, string | undefined> = {
    0: classes.backgroundImage,
    1: undefined,
  }

  const backgroundImage = backgroundImagePerTab[tabValue]

  return (
    <PageWrapper
      mobile={LMBasicV3FSM}
      desktop={LDContainerV2FSM}
      breadcrumbsBtnText={t(backBtn.text)}
    >
      {policy && (
        <div data-componentname={getDataComponentName(__filename)}>
          <Card data-testid='review-policy-card' elevation={3} className={backgroundImage}>
            <CustomCardHeader
              title={t(`card.title.${policy.productName}`)}
              priceMonthly={policy.CalculatedPrices.priceMonthly}
              priceMonthlyText={t(`card.priceMonthly`)}
              priceDetailsText={t(`card.priceDetails`, {
                value: policy.CalculatedPrices.taxAnnual || policy.CalculatedPrices.priceAnnual,
              })}
            />
            {tabs[tabValue]}
          </Card>
        </div>
      )}
    </PageWrapper>
  )
}

export default PagePolicyReview
