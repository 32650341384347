import { useQuery } from '@tanstack/react-query'
import apiV2 from '../../service/apiV2.service'
import { reactQueryErrorHandler } from '@dg-util'
import { FSMService } from '../../FSM'
import { ResponseErrorV2Type } from '../../types/ApiV2'

const useReactQueryPerson = () => {
  // Get Person All Vehicles Query
  const {
    data: personAllVehicles,
    refetch: refetchPersonAllVehicles,
    isInitialLoading: isGetPersonAllVehiclesLoading,
    isFetching: isGetPersonAllVehiclesFetching,
    isError: isGetPersonAllVehiclesError,
    error: getPersonAllVehiclesError,
  } = useQuery(['personAllVehicles'], () => apiV2.getPersonAllVehicles(), {
    select: (response) => response.data,
    onError: (error: ResponseErrorV2Type) => reactQueryErrorHandler(error, FSMService.flowService),
  })

  return {
    isLoading: isGetPersonAllVehiclesLoading || isGetPersonAllVehiclesFetching,
    personAllVehicles: personAllVehicles || [],
    refetchPersonAllVehicles,
    isGetPersonAllVehiclesError,
    getPersonAllVehiclesError,
  }
}

export default useReactQueryPerson
