import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { Typography } from '@dg-shared/index'
import { useTranslate } from '../../hooks/shared'
import ProductWrapper from './ProductWrapper'
import { PartnerName, ProductName } from '../../types/interface/quote.interface'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainerMain: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      height: '80px',
      borderRadius: '0 0 10px 10px',
      boxShadow: '0px 6px 15px rgba(13, 51, 32, 0.1)',
      backgroundColor: theme.palette.secondary.contrastText,
      justifyContent: 'space-between',
      zIndex: 10,
      ...theme.brand.assets.layout.desktopHeaderBackground,
    },
    logoDesktopHeader: theme.brand.assets.images.logoDesktopHeader,
    disclaimer: {
      margin: 'auto 0',
    },
    disclaimerWithLogo: {
      margin: 'auto 0',
      display: 'flex',
      alignItems: 'center',
      gridGap: theme.spacing(1),
    },
    logoImageRightCol: theme.brand.assets.images?.logoImageRightCol,
  })
)

const HeaderShared: FC = () => {
  const classes = useStyles()
  const { t } = useTranslate()

  return (
    <Grid container className={classes.gridContainerMain}>
      <Grid item xs={3} className={classes.logoDesktopHeader} />
      <ProductWrapper products={[ProductName.VEHICLE]} partners={[PartnerName.circlek]}>
        <Typography className={classes.disclaimer} type={'h4'}>
          {t(`navBarDisclaimer`)}
        </Typography>
      </ProductWrapper>
    </Grid>
  )
}

export default HeaderShared
