import React, { FC } from 'react'

import CoverageInfoListItem from './CoverageInfoListItem'

interface CoverageInfoListProps {
  // eslint-disable-next-line
  list?: any
}

export type LocalizedListItemAttributesType = 'crossed' | 'static'

export type LocalizedListItemType =
  | string
  | {
      text: string
      attributes: Array<LocalizedListItemAttributesType>
      condition: string
    }

const getListItemText = (item: LocalizedListItemType) => {
  if (typeof item === 'string') {
    return item
  } else {
    return item.text
  }
}

const getListItemAttributes = (item: LocalizedListItemType) => {
  if (typeof item === 'string') {
    return []
  } else {
    return item.attributes
  }
}

/**
 * Builds a list of localized text items from the LocalizedListItemType[]
 * each item could be a localized string or an object for additional formatting
 * and customization through the `attributes` field of the LocalizedListItemType
 *
 * @param list {LocalizedListItemType[]} localized text list items
 * @constructor
 */
const CoverageInfoList: FC<CoverageInfoListProps> = ({ list }) =>
  list?.map((item: LocalizedListItemType, idx: number) => {
    const text = getListItemText(item)
    const attributes = getListItemAttributes(item)
    const crossed = attributes.includes('crossed')
    const key = `list_coverage_item_${idx}`

    return <CoverageInfoListItem text={text} key={key} crossed={crossed} />
  }) || null

export default CoverageInfoList
