import CONFIG from '../config'
import { SessionData } from '../types/interface/session.interface'
import { AuthMethod, AUTH_METHOD_KEY } from '../constants/auth-constants'

export const setSessionStorageAuthItem = (token: string, kid: string, agentId?: string): void => {
  sessionStorage.setItem(
    CONFIG.USER_INFO_KEY,
    JSON.stringify({ token, kid, agentId } as SessionData)
  )
}

export const setSessionAuthMethod = (authMethod: AuthMethod) => {
  sessionStorage.setItem(AUTH_METHOD_KEY, authMethod)
}

export const getSessionAuthMethod = (): AuthMethod | '' => {
  return (sessionStorage.getItem(AUTH_METHOD_KEY) as AuthMethod) || ''
}
