// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import THEME from 'BrandTheme'

import { Typography } from '@dg-shared'

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: 30,
    },
    section: {
      padding: 15,
      margin: 5,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      borderRadius: 10,
      border: '1px solid #ddd',
    },
    row: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      flex: 1,
    },
    colorContainer: {
      width: '15%',
      padding: 10,
      margin: 5,
      borderRadius: 10,
      border: '1px solid #ddd',
    },
    colorBox: {
      width: 60,
      height: 60,
      borderRadius: 10,
    },
  })
)

const StandardsPage: FC = () => {
  const classes = useStyles()
  const headings = Object.entries(THEME.theme.brand.typography.heading)
  const textSizes = Object.entries(THEME.theme.brand.typography.text)
  const textmodifiers = Object.entries(THEME.theme.brand.typography.modifiers)
  const colors = Object.entries(THEME.theme.brand.colors)

  return (
    <section className={classes.content}>
      <Typography type='h1'>UI Standardization</Typography>
      <Typography type='h2' color='main'>
        Colors
      </Typography>
      <div className={classes.row}>
        {colors.map(([color, item], key) => (
          <div className={classes.colorContainer} key={key}>
            <div className={classes.colorBox} style={{ background: item }}></div>
            <Typography>{color}</Typography>
            <pre>{JSON.stringify(item, null, 4)}</pre>
          </div>
        ))}
      </div>
      <div className={classes.row}>
        <div className={classes.section}>
          <Typography type='h2' color='main'>
            Typography Headings
          </Typography>
          {headings.map(([type, item], key) => (
            <div key={key}>
              <Typography type={type}> Heading {type} example</Typography>
              <pre>{JSON.stringify(item, null, 4)}</pre>
            </div>
          ))}
        </div>
        <div className={classes.section}>
          <Typography type='h2' color='main'>
            Typography Text Size
          </Typography>
          {textSizes.map(([size, item], key) => (
            <div key={key}>
              <Typography size={size}> Text size {size} example</Typography>
              <pre>{JSON.stringify(item, null, 4)}</pre>
            </div>
          ))}
        </div>
        <div className={classes.section}>
          <Typography type='h2' color='main'>
            Typography Modifiers
          </Typography>
          {textmodifiers.map(([modifier, item], key) => (
            <div key={key}>
              <Typography modifiers={[modifier]}> Text modifier {modifier} example</Typography>
              <pre>{JSON.stringify(item, null, 4)}</pre>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default StandardsPage
