export const getQueryParam = (queryStr: URLSearchParams | string, name: string): string | null => {
  const getter = new URLSearchParams(queryStr)

  return getter.get(name)
}

// Return URL search query param name case insensitively
export const getQueryParamByName = (name = ''): string => {
  const caseInsensitiveName = name.toLowerCase()
  const params = new URLSearchParams(window.location.search)

  // Params for case insensitive handling
  const caseInsensitiveParams = new URLSearchParams()

  for (const [CaseSensitiveName, value] of params) {
    caseInsensitiveParams.append(CaseSensitiveName.toLowerCase(), value)
  }

  return caseInsensitiveParams.get(caseInsensitiveName)
}
