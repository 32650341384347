import React, { FC, useCallback, useEffect, useState } from 'react'

import { useFNOLStyles } from './styles/useStyles'
import { Events } from '../../../FSM/shared/constants'
import { FORM_ID, QUESTIONS, TRANSLATIONS as T } from '../../../constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { FNOLPageWrapper, Typography } from '@dg-shared'
import { useTranslate, useClaim } from '../../../hooks/shared'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import RFFCustomImageUpload, { Image } from '@dg-shared/forms/RFFCustomFileUpload'
import useReactQueryClaim from '../../../hooks/shared/useReactQueryClaim'

// TODO: Rewrite to REST Api v2
const PageAdditionalInfo: FC<CommonPageProps> = ({ service, currentState }) => {
  const { t } = useTranslate(T.FNOL_OTHER_ADDITIONAL_INFO)
  const FNOLClasses = useFNOLStyles({ externalLink: true })
  const [spinner, setSpinner] = useState<boolean>(false)

  const { setClaimAnswer, claimAnswers } = useClaim(service, currentState)

  const { uploadClaimFiles, uploadClaimFilesResult, updateClaim, updateClaimResult } =
    useReactQueryClaim()

  const [images, setImages] = useState<Array<Image>>([])

  const handleSubmit = async ({ additionalInfo }: Record<string, string>) => {
    await setClaimAnswer(QUESTIONS.ANY_OTHER_INFORMATION_WE_SHOULD_KNOW_OF, additionalInfo)

    setSpinner(true)

    const files = images.map((el) => el.data_url)
    await uploadClaimFiles({ files })
  }

  const updateClaimWithAnswers = useCallback(() => {
    updateClaim(claimAnswers)
  }, [claimAnswers, updateClaim])

  useEffect(() => {
    // Check upload files result. Successful files upload returns ClaimId
    if (uploadClaimFilesResult?.data.ClaimId) {
      updateClaimWithAnswers()
    }
  }, [updateClaimWithAnswers, uploadClaimFilesResult])

  useEffect(() => {
    setSpinner(false)

    // Check update claim result. Successful files upload returns ClaimId
    if (updateClaimResult?.data.ClaimId) {
      service.send({ type: Events.NEXT, state: currentState })
    }
  }, [updateClaimResult, currentState, service])

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t('back')} breadcrumbsStepPercentage={95}>
      <Typography type='h1' className={FNOLClasses.title} testId={'title'}>
        {t('title')}
      </Typography>
      <Typography className={FNOLClasses.description} testId={'subtitle'}>
        {t('subtitle')}
      </Typography>
      <FormBuilder
        id={FORM_ID.ADDITIONAL_INFO}
        classes={FNOLClasses.formContainer}
        submitHandler={handleSubmit}
        optional
        loadingSpinner={spinner}
      >
        <RFFCustomImageUpload images={images} setImages={setImages} />
      </FormBuilder>
    </FNOLPageWrapper>
  )
}

export default PageAdditionalInfo
