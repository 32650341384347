import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import BrandConfig from 'BrandConfig'
import { FORM_ID, TRANSLATIONS as T } from '../../../constants'
import { PartnerName, ProductName } from '../../../types/interface/quote.interface'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { getFlowName, normalizeMotorRegistrationNumber } from '@dg-util'
import {
  LDContainerV2FSM,
  LMBasicV3FSM,
  ProductWrapper,
  RFFAddressAutocomplete,
  Typography,
} from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import PageWrapper from '@dg-shared/PageWrapper'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import useLogic from './useLogic'
import { FlowName } from '../../../config'
import { Events } from '../../../FSM/shared/constants'
import { switchFSM } from '../../../util/productHandler'
import { FSMService } from '../../../FSM'

const useFindStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      margin: `${theme.spacing(3)}px auto`,
      width: '100%',
      height: '100%',
      maxWidth: '424px',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        margin: 'auto',
        marginTop: theme.spacing(3),
        marginBottom: 0,
      },
    },
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(7),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    formAlign: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
    },
    customMobileSubmit: {
      ...theme.brand.assets.layout?.customMobileSubmit,
    },
  })
)

const OffersFind: FC<CommonPageProps> = ({ service }) => {
  const { t } = useTranslate(T.FIND)
  const classes = useFindStyles()
  const { showErrorMessage, title, handleSubmit, isVippsAuth, setShowErrorMessage } =
    useLogic(service)

  const flowName = getFlowName()
  const isMyPages = flowName === FlowName.MY_PAGES

  const prevConfig = service.state?.context?.prevConfig

  const btnBackText = isVippsAuth || isMyPages || prevConfig ? `back` : `logout`

  const body = (
    <section className={classes.body}>
      <ProductWrapper
        products={[ProductName.VEHICLE]}
        partners={[
          PartnerName.instabank,
          PartnerName.nord,
          PartnerName.volkswagen,
          PartnerName.skoda,
          PartnerName.audi,
        ]}
      >
        <FormBuilder
          classes={classes.formAlign}
          submitClass={classes.customMobileSubmit}
          id={FORM_ID.FIND}
          // setShowErrorMessage hide the vehicleEmpty error message if user start type new value.
          changeHandler={() => setShowErrorMessage(false)}
          submitHandler={({ MotorRegistrationNumber }) =>
            handleSubmit({
              MotorRegistrationNumber: normalizeMotorRegistrationNumber(MotorRegistrationNumber),
            })
          }
          errorText={showErrorMessage && t(`error.vehicleEmpty`, { email: BrandConfig.EMAIL })}
        />
      </ProductWrapper>
      <ProductWrapper
        products={[ProductName.CONTENT]}
        partners={[PartnerName.instabank, PartnerName.nord]}
      >
        <RFFAddressAutocomplete
          classesProps={classes.formAlign}
          fieldLabel={t('form.address.label')}
          nextBtnText={t(`next`)}
          submitHandler={(address) => handleSubmit({ address })}
          submitClass={classes.customMobileSubmit}
        />
      </ProductWrapper>
    </section>
  )

  const breadcrumbsBackHandler = () => {
    prevConfig
      ? switchFSM({
          targetStateValue: prevConfig.stateValue,
          targetFlow: prevConfig.flow,
          targetProduct: prevConfig.product,
          FSMService: FSMService,
        })
      : service.send(Events.BACK)
  }

  return (
    <PageWrapper
      mobile={LMBasicV3FSM}
      desktop={LDContainerV2FSM}
      breadcrumbsBtnText={t(btnBackText)}
      breadcrumbsStepPercentage={30}
      breadcrumbsBackHandler={breadcrumbsBackHandler}
      bgColor={`#FAFAFA`} // FIXME: Remove this prop when Background color will be the same on all pages
    >
      <Typography type='h1' className={classes.title}>
        {t(title)}
      </Typography>
      {body}
    </PageWrapper>
  )
}
export default OffersFind
