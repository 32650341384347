import { useState, useEffect } from 'react'

import history from '../../../routes/history'
import { Events } from '../../../FSM/shared/constants'
import { ServiceSend } from '../../../types/interface/fsm.interface'

interface HookAccept {
  loading: boolean
}

const useLogic = (send: ServiceSend): HookAccept => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)

    history.push('/') //FIXME: need to push to the right location
    send(Events.CUSTOM_AUTH_SUCCESS)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    loading,
  }
}
export default useLogic
