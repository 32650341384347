import pino from 'pino'

import { IS_PRODUCTION } from '../config'

export const logger = pino({
  name: 'my-app',
  level: IS_PRODUCTION ? 'error' : 'info',
  prettyPrint: true,
  // prettifier: require('pino-pretty'),
  browser: {
    serialize: true,
    // write: (options) => {
    // }
  },
})

const noop = () => {
  /* no operation */
}

// Hide all logs on production
// replace console.log, error, warn and info with no operational functions
// This might be replaced with a webPack implementation (like TerserPlugin with optimization drop_comments: true)
// - tried the TerserPlugin on DEV but it unsuccessfully - need more investigation
if (IS_PRODUCTION) {
  window.console = {
    ...window.console,
    log: () => noop,
    error: () => noop,
    info: () => noop,
    warn: () => noop,
  }
}
