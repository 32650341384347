import { FlowName } from '../config'
import { getQueryParamByName } from './getQueryParam'
import { ProductName } from '../types/interface/quote.interface'
import { removePersistedState } from '../FSM/util'
import apiV2, { isAuthTokenExist } from '../service/apiV2.service'
import { fullStorageCleanup } from './storageClear'
import { FSM_PRESERVED_DATA_KEY } from '../FSM/shared/constants'
import { SwitchFSMParamsType } from '../types/interface/fsm.interface'
import brandTheme from '../brand/theme/utils'
import { ErrorStatus } from '../constants/error-constants'

// Augmenting the Window Interface for saving global vars
declare global {
  interface Window {
    requestSignOut: boolean
  }
}

export const FLOW_NAME_QUERY_KEY = 'FLOW'
export const PRODUCT_NAME_QUERY_KEY = 'PRODUCT'

export const getProductName = (): ProductName => {
  return queryParamHandler(PRODUCT_NAME_QUERY_KEY, ProductName.VEHICLE)
}

export const getFlowName = (): FlowName => {
  return queryParamHandler(FLOW_NAME_QUERY_KEY, FlowName.QUOTE_AND_BUY)
}

type QueryParamType = FlowName | ProductName

const queryParamHandler = <T extends QueryParamType>(query: string, defaultValue: T): T => {
  // QueryParam value passed in the browser URL query param
  const queryValue = getQueryParamByName(query) as T | undefined

  // Get cached QueryParam value
  const cachedValue = sessionStorage.getItem(query) as T | undefined

  // Set cached QueryParam value if it is passed in query param
  if (queryValue) {
    // QueryParam value change. New QueryParam value came from the url. SignOut should be requested
    // Save this flag in global scope temporarily because we have no state management at this point of code evaluation
    // Also, delete the FSM app state cache to avoid conflicts of flows intersections
    removePersistedState()
    window.requestSignOut = true
    sessionStorage.setItem(query, queryValue)
  }

  // If there is no queryValue and no cachedValue, set defaultValue in cache
  if (!cachedValue && !queryValue) {
    sessionStorage.setItem(query, String(defaultValue))
  }

  return queryValue || cachedValue || defaultValue
}

export const processSignOut = async (): Promise<boolean> => {
  // Check if user is authenticated
  const isAuthenticated = isAuthTokenExist()

  // User is authenticated. Do a sign out.
  if (isAuthenticated) {
    let isCleanupRequired = false

    try {
      const signOutResult = await apiV2.authSignOut()

      // Do cleanup in case successful signout
      isCleanupRequired = signOutResult.data.sessionDropped
    } catch (error) {
      // Do cleanup in case user session is expired
      isCleanupRequired = [
        ErrorStatus.LOGIN_TIMEOUT,
        ErrorStatus.SESSION_EXPIRED,
        ErrorStatus.UNAUTHORIZED,
      ].includes(error.status)
    }

    // Successful sign out cleanup
    if (isCleanupRequired) {
      await fullStorageCleanup()
    }

    return true
  }

  return false
}

export const signOutOnDemand = async () => {
  // Check if sign out requested on demand
  if (window.requestSignOut) {
    await processSignOut()
  }
}

// Switch to new FSM config with preserving current FSM data for back navigation
export const switchFSM = (params: SwitchFSMParamsType) => {
  const { targetStateValue, targetFlow, targetProduct, FSMService, contextOverride } = params

  // Create data for FSM manipulation after switch
  const preservedFSMData = {
    value: targetStateValue,
    context: {
      ...FSMService.flowService.state.context,
      // Save current flow product and state value for back navigation
      prevConfig: {
        stateValue: FSMService.flowService.state.value,
        flow: getFlowName(),
        product: getProductName(),
      },
      ...contextOverride,
    },
  }
  // Set new flow and product (switch FSM)
  sessionStorage.setItem(FLOW_NAME_QUERY_KEY, targetFlow)
  sessionStorage.setItem(PRODUCT_NAME_QUERY_KEY, targetProduct)
  // Save data for future FSM manipulation
  localStorage.setItem(FSM_PRESERVED_DATA_KEY, JSON.stringify(preservedFSMData))

  // Update theme (re-initialize)
  brandTheme.initCallback()

  // Re-init and restart FSM
  FSMService.initMachine()
  FSMService.restart()
}
