import React, { FC, useEffect, useState } from 'react'
import { Typography, FNOLPageWrapper, FNOLButton } from '@dg-shared'
import { useTranslate, useClaim } from '../../../hooks/shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events, FNOLClaimTypes } from '../../../FSM/shared/constants'
import { CLAIM_TYPE_IDS, QUESTIONS, TRANSLATIONS as T } from '../../../constants'
import { useFNOLStyles } from '../shared/styles/useStyles'
import useReactQueryClaim from '../../../hooks/shared/useReactQueryClaim'
import useReactQueryPolicy from '../../../hooks/shared/useReactQueryPolicy'
import Loading from '../../qb/Loading/Page'
import { getPolicyActiveVersions } from '../../mypages/Policies/utils'

const PageList: FC<CommonPageProps> = ({ service, currentState }) => {
  const FNOLClasses = useFNOLStyles({})
  const { t } = useTranslate(T.CLAIMS)
  const { policy, isLoading: isPolicyLoading, refetchPolicy } = useReactQueryPolicy()
  const { initClaim, claim, isLoading: isClaimLoading } = useReactQueryClaim()
  const [claimTypeEvent, setClaimTypeEvent] = useState<Events>(null)
  const { clearClaimFull } = useClaim(service, currentState)

  const activeVersionsNumber = getPolicyActiveVersions(policy).length

  useEffect(() => {
    // Cleanup of current claim on page init
    clearClaimFull()
  }, [clearClaimFull])

  useEffect(() => {
    if (claim?.ClaimId && claimTypeEvent) {
      // Navigate to the next state
      service.send({ type: claimTypeEvent, state: currentState })
    }
  }, [claim, claimTypeEvent, currentState, service])

  useEffect(() => {
    if (!policy) {
      ;(async () => await refetchPolicy())()
    }
  }, [policy, refetchPolicy])

  const claimTypeSelect = ({
    claimTypeEvent,
    label,
    claimType,
  }: {
    claimTypeEvent: Events
    label: string
    claimType: FNOLClaimTypes
  }) => (
    <FNOLButton
      key={claimType}
      handleClick={async () => {
        // Select next state event
        setClaimTypeEvent(claimTypeEvent)

        const { MotorRegistrationNumber, PolicyNumber } = policy

        // Init a new Claim
        await initClaim({
          MotorRegistrationNumber,
          PolicyNumber,
          [QUESTIONS.CHOOSE_CORRECT_CLAIM]: CLAIM_TYPE_IDS[claimType],
        })
      }}
      buttonText={label}
    />
  )

  const claimTypeEvents = [
    {
      claimTypeEvent: Events.FNOL_WINDSHIELD,
      label: `${t('selectType.button.windshield')}`,
      claimType: FNOLClaimTypes.FNOL_WINDSHIELD,
    },
    {
      claimTypeEvent: Events.FNOL_COLLISION,
      label: `${t('selectType.button.collision')}`,
      claimType: FNOLClaimTypes.FNOL_COLLISION,
    },
    {
      claimTypeEvent: Events.FNOL_THEFT,
      label: `${t('selectType.button.theft')}`,
      claimType: FNOLClaimTypes.FNOL_THEFT,
    },
    {
      claimTypeEvent: Events.FNOL_ROADSIDE_ASSISTANCE,
      label: `${t('selectType.button.roadsideAssistance')}`,
      claimType: FNOLClaimTypes.FNOL_ROADSIDE_ASSISTANCE,
    },
    {
      claimTypeEvent: Events.FNOL_OTHER,
      label: `${t('selectType.button.other')}`,
      claimType: FNOLClaimTypes.FNOL_OTHER,
    },
  ]

  const ClaimTypesList = () => (
    <>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('selectType.title')}
      </Typography>
      <Typography testId='subtitle' html={true} className={FNOLClasses.description}>
        {t('selectType.subtitle')}
      </Typography>
      {claimTypeEvents.map((item) => claimTypeSelect(item))}
    </>
  )

  if (isPolicyLoading || isClaimLoading) {
    return <Loading />
  }

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`back`)} breadcrumbsStepPercentage={20}>
      {activeVersionsNumber > 0 ? (
        <ClaimTypesList />
      ) : (
        <Typography testId='title' type='h1' className={FNOLClasses.title}>
          {t('selectType.noActivePolicy')}
        </Typography>
      )}
    </FNOLPageWrapper>
  )
}

export default PageList
