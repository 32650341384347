import React, { useCallback, useState } from 'react'

export const CONTEXT_CACHE_KEY = 'CONTEXT_CACHE'

const getInitialContextState = (): Record<string, unknown> => {
  const initialContextStateString = localStorage.getItem(CONTEXT_CACHE_KEY)
  return initialContextStateString ? JSON.parse(initialContextStateString) : {}
}

type ContextType = {
  context: Record<string, unknown>
  setContext: (newContextState: React.SetStateAction<Record<string, unknown>>) => void
  clearContext: () => void
}

const persistCacheFields = ['acceptCookies']

export const clearContextCache = () => {
  const initialContextState = getInitialContextState()
  const persistCacheData: Record<string, unknown> = {}
  persistCacheFields.forEach((item) => (persistCacheData[item] = initialContextState[item]))
  localStorage.removeItem(CONTEXT_CACHE_KEY)
  localStorage.setItem(CONTEXT_CACHE_KEY, JSON.stringify(persistCacheData))
}

const initialContextState = getInitialContextState()

export const Context = React.createContext<ContextType>({
  context: initialContextState,
  setContext: () => null,
  clearContext: () => null,
})

const ContextProvider = ({
  children,
  testInitialContextState,
}: {
  children: React.ReactNode
  testInitialContextState?: Record<string, unknown>
}) => {
  const [context, setContextState] = useState(testInitialContextState || initialContextState)

  const setContext = useCallback(
    (newContextState: React.SetStateAction<Record<string, unknown>>) => {
      setContextState((prevState: Record<string, unknown>) => {
        const newState = { ...prevState, ...newContextState }
        localStorage.setItem(CONTEXT_CACHE_KEY, JSON.stringify(newState))

        return newState
      })
    },
    []
  )

  const clearContext = useCallback(() => {
    clearContextCache()
    setContextState(getInitialContextState())
  }, [])

  return (
    <Context.Provider value={{ context, setContext, clearContext }}>{children}</Context.Provider>
  )
}

export default ContextProvider
