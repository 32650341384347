import React, { FC } from 'react'
import { Field } from 'react-final-form'

const RFFConditional: FC<{
  when: string
  is: string | Array<string>
  // eslint-disable-next-line no-unused-vars
  updateCallBack(isEnabled: boolean): void
}> = ({ when, is, children, updateCallBack }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => {
      // Check if value equals dependency ("is") for string or included in dependency ("is") for an array.
      const isEnabled = typeof is === 'string' ? value === is : is.includes(value)

      // Evaluate updateCallBack with isEnabled state (enabled = true / disabled = false)
      updateCallBack(isEnabled)

      return isEnabled ? children : null
    }}
  </Field>
)

export default RFFConditional
