import { PolicyV2Type } from '../../../types/ApiV2'

export const sortPolicies = (policies: Partial<PolicyV2Type>[]) =>
  policies.sort(
    (prevDoc, nextDoc) =>
      new Date(nextDoc.InsuredFrom).valueOf() - new Date(prevDoc.InsuredFrom).valueOf()
  )

export const getPolicyActiveVersions = (policy: Partial<PolicyV2Type>) =>
  policy?.Versions?.filter(
    (version: Partial<PolicyV2Type>) =>
      new Date(version.InsuredFrom) <= new Date() && new Date(version.InsuredTo) >= new Date()
  ) ?? []
