export const REGEX_THOUSANDS_SEPARATOR = /(\d)(?=(\d{3})+(?!\d))/g
export const REGEX_EMPTY_SPACE = /\s/g
export const REGEX_NUMBER_EMPTY_SPACE = /[^\d]/g
export const REGEX_MULTIPLE_DIGITS = /^\d+$/
export const REGEX_PRODUCT_ID = /^(\s+)?([a-z]{2})(\s+)?([0-9]{5})(\s+)?$/i
export const REGEX_MILEAGE_FIELD = /^(?!0.)\d+$/
export const REGEX_PAGE_NAME_TRANSFORM = /([A-Z])/g
export const REGEX_SSN = /^(?=[0-9]*$)(?:.{9}|.{11})$/
export const REGEX_SE_SSN = /^(?=[0-9]*$)(?:.{10}|.{12})$/
export const REGEX_SE_PRODUCT_ID = /^(\s+)?([a-z]{3})(\s+)?([0-9]){2}[A-Za-z0-9](\s+)?$/i
