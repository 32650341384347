import { format } from 'date-fns'

// Conversion of date and separate time to the single ISO dateTime
const updateDateWithTimeISO = (date: string | Date, time: Date): string => {
  const timeFormatted = format(time, 'HH:mm:ss')
  const dateFormatted = format(new Date(date), 'YYY-MM-dd')

  return new Date(`${dateFormatted}T${timeFormatted}`).toISOString()
}

export default updateDateWithTimeISO
