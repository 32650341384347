/* eslint-disable import/no-named-as-default-member */
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { getFlowName, getProductName } from '@dg-util'

type TResult = {
  obj: string[]
  string: string
}

export interface TFunction {
  <Key extends keyof TResult = 'string' | 'obj'>(
    str: string,
    values?: {
      [key: string]: unknown
    },
    type?: Key
  ): TResult[Key]
}

const useTranslate = (component = '') => {
  const { t } = useTranslation()
  const productName = getProductName()
  const flowName = getFlowName()

  const translate: TFunction = (str = '', values, type) => {
    // Check translations priorities for overrides
    const translationKeyPriorities = [
      `${component}.${str}.${PARTNER_NAME}.${productName}.${flowName}`, // highest
      `${component}.${PARTNER_NAME}.${productName}.${flowName}.${str}`,
      `${PARTNER_NAME}.${component}.${productName}.${flowName}.${str}`,

      `${component}.${str}.${PARTNER_NAME}.${productName}`,
      `${component}.${PARTNER_NAME}.${productName}.${str}`,
      `${str}.${PARTNER_NAME}.${component}.${productName}`,
      `${PARTNER_NAME}.${component}.${productName}.${str}`,
      `${PARTNER_NAME}.${productName}.${component}.${str}`,
      `${productName}.${PARTNER_NAME}.${component}.${str}`,

      `${component}.${str}.${PARTNER_NAME}`,
      `${component}.${PARTNER_NAME}.${str}`,
      `${str}.${component}.${PARTNER_NAME}`,
      `${PARTNER_NAME}.${component}.${str}`,

      `${str}.${PARTNER_NAME}.${productName}`,
      `${PARTNER_NAME}.${productName}.${str}`,
      `${productName}.${PARTNER_NAME}.${str}`,
      `${PARTNER_NAME}.${str}.${productName}`,
      `${productName}.${str}.${PARTNER_NAME}`,
      `${productName}.${component}.${str}`,
      `${flowName}.${component}.${str}`,
      `${component}.${flowName}.${str}`,
      `${str}.${component}.${flowName}`,

      `${str}.${PARTNER_NAME}`,
      `${PARTNER_NAME}.${str}`,
      `${component}.${str}`,
      `${str}.${component}`,
      `${flowName}.${str}`,
      `${str}.${flowName}`,
      `${productName}.${str}`,
      `${str}.${productName}`,
      str, // default (lowest)
    ]

    let translated

    translationKeyPriorities.some((priorityStr) => {
      if (i18n.exists(priorityStr)) {
        translated = t(priorityStr, values)

        return true
      }
    })

    if (!translated && translated !== null && translated !== '') {
      console.warn({ [`translation for "${str}" not found`]: translationKeyPriorities })

      // Use original text without translation
      translated = str
    }

    return translated as TResult[typeof type]
  }

  return {
    t: translate,
  }
}

export default useTranslate
