import React, { FC, useEffect, useState } from 'react'
import { Typography, FNOLPageWrapper } from '@dg-shared'
import { useTranslate, useClaim } from '../../../hooks/shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import { FLOW_THEFT_QUESTIONS, FORM_ID, TRANSLATIONS as T } from '../../../constants'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import { useFNOLStyles } from '../shared/styles/useStyles'
import { removeAllSpaces } from '../../../service/util.service'

interface AgeValueFormValues {
  age?: string
  value?: string
}

const PageStolenAgeValue: FC<CommonPageProps> = ({ service, currentState }) => {
  const { send } = service
  const { t } = useTranslate(`${T.FNOL_THEFT}.stolenAgeValue`)
  const [selectedDataFields, setSelectedDataFields] = useState<AgeValueFormValues>({})
  const FNOLClasses = useFNOLStyles({ externalLink: true })
  const { setClaimMultipleAnswers, clearAnswer } = useClaim(service, currentState)

  useEffect(() => {
    // Clear previous questions answers
    clearAnswer([FLOW_THEFT_QUESTIONS.HOW_OLD_IS_THE_ITEM, FLOW_THEFT_QUESTIONS.ESTIMATED_VALUE])
  }, [clearAnswer])

  const handleSubmit = async ({ age, value }: AgeValueFormValues) => {
    await setClaimMultipleAnswers({
      [FLOW_THEFT_QUESTIONS.HOW_OLD_IS_THE_ITEM]: removeAllSpaces(age),
      [FLOW_THEFT_QUESTIONS.ESTIMATED_VALUE]: removeAllSpaces(value),
    })
    send(Events.NEXT)
  }

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t('back')} breadcrumbsStepPercentage={65}>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <FormBuilder
        classes={FNOLClasses.formContainer}
        id={FORM_ID.STOLEN_AGE_VALUE}
        setFormState={({ values }) => setSelectedDataFields(values)}
        submitHandler={handleSubmit}
        filledFieldsData={selectedDataFields}
        isFormControllable
      />
    </FNOLPageWrapper>
  )
}

export default PageStolenAgeValue
