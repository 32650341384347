import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Card, CardContent, CardHeader } from '@material-ui/core'

import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { useTranslate } from '../../../hooks/shared'

import VerifyCardContent from 'components/VerifyCardContent'
import { QuoteV2Type } from '../../../types/ApiV2'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      margin: '0 auto',
      maxWidth: '345px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(2),
      },
    },
    header: {
      backgroundColor: theme.brand.colors.cardHeader,
    },
    title: {
      color: theme.brand.colors.textCardHeader,
      fontSize: theme.spacing(3),
      fontWeight: 500,
    },
  })
)

interface CardVehicleProps {
  offer: Partial<QuoteV2Type>
}

const OfferCard: FC<CardVehicleProps> = ({ offer }) => {
  const { t } = useTranslate(`${T.OFFER_VERIFY}.card`)
  const classes = useStyles()

  return (
    <Card elevation={3} className={classes.card}>
      <CardHeader
        titleTypographyProps={{
          className: classes.title,
        }}
        className={classes.header}
        title={t('title')}
      />
      <CardContent>
        <VerifyCardContent offer={offer} />
      </CardContent>
    </Card>
  )
}

export default OfferCard
