import {
  FNOLCollisionWhatHappened,
  FNOLCollisionHowMany,
  FNOLCollisionAnimal,
  FNOLCollisionObject,
  FNOLCollisionResponsible,
  FNOLCollisionInjured,
  FNOLCollisionCaseOfAccident,
  FNOLCollisionRegistrationNumber,
  FNOLCollisionWhenWhere,
  FNOLCollisionAccidentForm,
  FNOLCollisionDamageReport,
  FNOLAdditionalInformation,
  FNOLTimeline,
  FNOLCollisionMoreDetails,
} from '../../../../pages/FNOL'
import { FSMCommonTransitions } from '../../constants'

const {
  FNOL_COLLISION_FLOW,
  FNOL_COLLISION_WHAT_HAPPENED,
  FNOL_COLLISION_HOW_MANY,
  FNOL_COLLISION_ANIMAL,
  FNOL_COLLISION_OBJECT,
  FNOL_COLLISION_RESPONSIBLE,
  FNOL_COLLISION_INJURED,
  FNOL_COLLISION_CASE_OF_ACCIDENT,
  FNOL_COLLISION_REGISTRATION_NUM,
  FNOL_COLLISION_WHEN_WHERE,
  FNOL_COLLISION_ACCIDENT_FORM,
  FNOL_COLLISION_DAMAGE_REPORT,
  FNOL_ADDITIONAL_INFORMATION,
  FNOL_TIME_LINE,
  FNOL_COLLISION_MORE_DETAILS,
} = FSMCommonTransitions

export default [
  {
    is: { [FNOL_COLLISION_FLOW]: FNOL_COLLISION_WHAT_HAPPENED },
    page: FNOLCollisionWhatHappened,
  },
  {
    is: { [FNOL_COLLISION_FLOW]: FNOL_COLLISION_HOW_MANY },
    page: FNOLCollisionHowMany,
  },
  {
    is: { [FNOL_COLLISION_FLOW]: FNOL_COLLISION_ANIMAL },
    page: FNOLCollisionAnimal,
  },
  {
    is: { [FNOL_COLLISION_FLOW]: FNOL_COLLISION_OBJECT },
    page: FNOLCollisionObject,
  },
  {
    is: { [FNOL_COLLISION_FLOW]: FNOL_COLLISION_RESPONSIBLE },
    page: FNOLCollisionResponsible,
  },
  {
    is: { [FNOL_COLLISION_FLOW]: FNOL_COLLISION_INJURED },
    page: FNOLCollisionInjured,
  },
  {
    is: { [FNOL_COLLISION_FLOW]: FNOL_COLLISION_CASE_OF_ACCIDENT },
    page: FNOLCollisionCaseOfAccident,
  },
  {
    is: { [FNOL_COLLISION_FLOW]: FNOL_COLLISION_REGISTRATION_NUM },
    page: FNOLCollisionRegistrationNumber,
  },
  {
    is: { [FNOL_COLLISION_FLOW]: FNOL_COLLISION_WHEN_WHERE },
    page: FNOLCollisionWhenWhere,
  },
  {
    is: { [FNOL_COLLISION_FLOW]: FNOL_COLLISION_ACCIDENT_FORM },
    page: FNOLCollisionAccidentForm,
  },
  {
    is: { [FNOL_COLLISION_FLOW]: FNOL_COLLISION_DAMAGE_REPORT },
    page: FNOLCollisionDamageReport,
  },
  {
    is: { [FNOL_COLLISION_FLOW]: FNOL_ADDITIONAL_INFORMATION },
    page: FNOLAdditionalInformation,
  },
  {
    is: { [FNOL_COLLISION_FLOW]: FNOL_COLLISION_MORE_DETAILS },
    page: FNOLCollisionMoreDetails,
  },
  {
    is: { [FNOL_COLLISION_FLOW]: FNOL_TIME_LINE },
    page: FNOLTimeline,
  },
]
