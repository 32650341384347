import React, { FC } from 'react'
import { isEmpty } from 'lodash'

import { TRANSLATIONS as T } from '../../../constants/translations-constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import Loading from '../../qb/Loading/Page'
import { LDContainerV2FSM, withHidden, LMBasicV3FSM, Typography } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import useReactQueryPolicy from '../../../hooks/shared/useReactQueryPolicy'
import { redirect } from '@dg-util'
import FNOLMyPagesDEVButton from '@dg-shared/FNOLMyPagesDEVButton'
import { FlowName } from '../../../config'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import PoliciesListCards from '../../../components/shared/PoliciesListCards'
import { PolicyV2Type } from '../../../types/ApiV2'

const MobileScreen = withHidden(LMBasicV3FSM, true)
const DesktopScreen = withHidden(LDContainerV2FSM, false)

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(2),
    },
    noPoliciesText: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
)

const PageFNOLPoliciesList: FC<CommonPageProps> = ({ service }) => {
  const { t } = useTranslate(T.POLICIES)
  const classes = useStyles({})
  const { policies, isLoading } = useReactQueryPolicy()

  const title = t('title')
  const backBtnText = t(`logout`, {}, 'string')
  const subtitle = policies?.length > 1 && t('subtitle')

  const filteredPolicies = policies.filter((policy: PolicyV2Type) => {
    return new Date(policy.InsuredTo) > new Date()
  })

  const body = <PoliciesListCards policies={filteredPolicies} service={service} />

  const titleStyle = isEmpty(filteredPolicies) ? { textAlign: 'center' as const } : null

  const noPoliciesText = isEmpty(filteredPolicies) ? (
    <Typography className={classes.noPoliciesText}>{t('noPoliciesText')}</Typography>
  ) : null

  if (isLoading) {
    return <Loading />
  }

  // Logout and switch to QB flow auth page on back button click
  const logoutHandler = () => {
    redirect(`/?flow=${FlowName.QUOTE_AND_BUY}`)
  }

  return (
    <>
      <MobileScreen
        titleHeader={title}
        paragraphSubTitle={subtitle}
        breadcrumbs
        breadcrumbsBtnText={backBtnText}
        send={logoutHandler}
      >
        <FNOLMyPagesDEVButton />
        {noPoliciesText}
        {body}
      </MobileScreen>
      <DesktopScreen breadcrumbs breadcrumbsBtnText={backBtnText} send={logoutHandler}>
        <FNOLMyPagesDEVButton />
        <Typography type={'h1'} className={classes.title} style={titleStyle}>
          {title}
        </Typography>
        {noPoliciesText}
        {subtitle && <Typography>{subtitle}</Typography>}
        {body}
      </DesktopScreen>
    </>
  )
}

export default PageFNOLPoliciesList
