import React, { FC, ReactElement, Suspense, useEffect, useState } from 'react'
import { Router } from 'react-router-dom'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import TagManager from 'react-gtm-module'
import { getQueryParamsData } from '@dg-util'
import { inspect } from '@xstate/inspect'

import './i18n'
import brandTheme from 'BrandTheme'
import history from './routes/history'
import Loading from './pages/qb/Loading/Page'
import { FSMService, FSMProvider } from './FSM'
import packageJson from '../package.json'
import errorLoggerService from './service/errorLogger.service'
import { PartnerName } from './types/interface/quote.interface'
import CONFIG, { ENV } from './config'
import { IconType } from './types/interface/icon.interface'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import queryClient from './queryClient'
import ContextProvider from '@dg-shared/ContextProvider'
import AgentStatus from '@dg-shared/AgentStatus'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Theme } from 'material-ui'

errorLoggerService.setPartner(PARTNER_NAME as PartnerName)

// xState visualizer (inspector) switch
const xStateVisualizerEnabled = localStorage.getItem('xStateVisualizerEnabled')

// Enable XState inspector in debug mode with enabled DEV_TOOLS and xStateVisualizerEnabled true
if (CONFIG.DEV_TOOLS && xStateVisualizerEnabled) inspect({ iframe: false })

// Get product name, GTM data layer, sellingChannel from initialization from URL query param under the hood)
const queryParamsData = getQueryParamsData()
const productName = queryParamsData.productName
const dataLayerSettings = queryParamsData.dataLayerSettings

// Initialize GTM with TRACKING_ID targeted on specific BRAND, PRODUCT, ENV
// Set additional data layer settings for marketing and analytics
TagManager.initialize({
  gtmId: CONFIG.TRACKING_ID[productName][ENV],
  dataLayer: dataLayerSettings,
})

const AppWrapper: FC<{
  children?: ReactElement[] | ReactElement
  queryClientTest?: QueryClient
  testInitialContextState?: Record<string, unknown>
}> = ({ children, queryClientTest, testInitialContextState }) => {
  const [theme, setTheme] = useState<Theme>(brandTheme.theme)

  useEffect(() => {
    brandTheme.setCreateThemeCallback(setTheme)
  }, [])

  const content = children ? (
    // TODO: Fix TS history types here. Maybe after migrating to the `react-router-dom` v6 it will be easier
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Router history={history}>
      <FSMProvider>{children}</FSMProvider>
    </Router>
  ) : (
    <Loading />
  )

  return (
    <Suspense fallback={IconType.LOADING}>
      <QueryClientProvider client={queryClientTest || queryClient}>
        <ContextProvider testInitialContextState={testInitialContextState}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>{content}</MuiPickersUtilsProvider>
            <p hidden>{`app v${packageJson.version}`}</p>
            <AgentStatus flowService={FSMService.flowService} />
          </ThemeProvider>
        </ContextProvider>
      </QueryClientProvider>
    </Suspense>
  )
}

export default AppWrapper
