import { getSentryPerformanceTransaction } from './performanceLogger.service'
import { SessionDataBID, SessionDataNoBID } from '../types/interface/session.interface'
import { getPolicyNumberWithVersionUriPath, getSessionStorageAuthData } from './util.service'
import CONFIG, { SESSION_ID_KEY } from '../config'
import { getProductName, getSellingChannel } from '@dg-util'
import { BUILD_CONSTANTS as BC, ErrorStatus } from '../constants'
import {
  AgentPersonLoginResponseType,
  AuthRegularV2ResponseType,
  AuthRegularV2InputType,
  CancelInsurancesResponseType,
  ClaimV2ResponseType,
  ClaimV2Type,
  PartyV2Type,
  PartyV2UpdateResponseType,
  PersonAllVehiclesV2ResponseType,
  PolicyAllV2ResponseType,
  PolicyDocumentV2ResponseType,
  PolicyV2ChangeRequestType,
  PolicyV2ResponseType,
  PolicyV2Type,
  ProductPropsV2ResponseType,
  QuoteV2ResponseType,
  QuoteV2Type,
  QuoteV2UpdateResponseType,
  RequestV2BodyType,
  RequestV2ParamsType,
  REST_V2_ENDPOINTS,
  SignOutV2ResponseType,
  UserAddressResponseType,
  InsuranceCompaniesV2ResponseType,
} from '../types/ApiV2'
import errorLoggerService from './errorLogger.service'
import { getSessionAuthMethod } from '@dg-util/sessionStorage'
import { AuthMethod } from '../constants/auth-constants'
import { UserAuthInput } from '../types'
import { GOOGLE_MAP_URL_LIST } from '../constants/google-map-constants'
import { AddressInterface } from '../types/interface/map.interface'
import { getDebugRequestBody } from '../util/debugUtils'

export const getAuthTokens = () => {
  const parsedUserInfo: SessionDataNoBID = getSessionStorageAuthData(CONFIG.USER_INFO_KEY)
  const parsedUserInfoBid: SessionDataBID = getSessionStorageAuthData(CONFIG.USER_INFO_KEY_BID)
  const token: string = parsedUserInfo?.token || ''
  const bidToken: string = parsedUserInfoBid?.token || ''
  const sessionId: string = sessionStorage.getItem(SESSION_ID_KEY)

  return { token, bidToken, [SESSION_ID_KEY]: sessionId }
}

export const getApiV2Headers = () => {
  const basicHeaders = {
    ProductName: getProductName(),
    PartnerName: PARTNER_NAME,
  }
  const authTokens = getAuthTokens()

  return {
    'Content-Type': 'application/json',
    ...basicHeaders,
    ...authTokens,
  }
}

export const isAuthTokenExist = (): boolean => {
  const authMethod = getSessionAuthMethod()

  // Skip checking of Auth tokens for Vipps auth since it is based on httpOnly cookies that are unavailable in JS
  if (authMethod && [AuthMethod.VIPPS].includes(authMethod)) {
    return true
  }

  const { token, bidToken } = getAuthTokens()
  return !!(token || bidToken)
}

// Security check for auth tokens in headers.
// Throws an error in case tokens are absent.
export const checkAuthTokens = () => {
  // Throw an error in case there are no security tokens
  if (!isAuthTokenExist()) {
    throw new ApiV2Error('Authentication token Error', ErrorStatus.SESSION_EXPIRED, 'No auth token')
  }
}

export type ExtraType = { integration?: string }

// Custom error class
export class ApiV2Error extends Error {
  status: number
  statusText: string
  response: Record<string, string>

  constructor(message: string, status: number, statusText: string, response = {}) {
    super(message)
    this.status = status
    this.statusText = statusText
    this.response = response
  }
}

export const getExtendedError = async (resp: Response) => {
  let response: Record<string, unknown>

  try {
    // Get response text first
    const responseText = await resp.text()

    // Try to parse the text as JSON
    response = JSON.parse(responseText)
  } catch (parseError) {
    // If parsing as JSON fails, use the response text directly
    response = {
      message: parseError.message,
      status: resp.status,
      statusText: resp.statusText,
    }
  }

  // Get error from response
  const errorBody = response.error as Record<string, unknown>
  const errorMessage = errorBody
    ? `HTTP Error: ${errorBody.status || resp.status}. Code: ${errorBody.code} ${errorBody.message}`
    : `HTTP Error: ${resp.status} ${resp.statusText}`

  // Expose ApiV2Error with additional information
  return new ApiV2Error(errorMessage, resp.status, resp.statusText, response)
}

// REST API service
class ApiV2 {
  baseUrl = BC.API_V2_REST_URL
  baseIsUrl = BC.API_IS_V2_REST_URL
  getDefaultApiParams() {
    return { headers: getApiV2Headers(), credentials: 'include' }
  }
  preprocessRequestBody<T>(body: T): T {
    const debugBody = getDebugRequestBody()
    return debugBody || body
  }
  async getRaw(url: string, params?: RequestV2ParamsType, extra?: ExtraType) {
    const transaction = getSentryPerformanceTransaction('apiV2')
    const span = transaction.startChild({
      op: url,
      description: JSON.stringify(params),
    })

    try {
      const resp = await fetch(url, {
        method: 'GET',
        ...params,
      })

      if (!resp.ok) {
        throw await getExtendedError(resp)
      }

      span.setStatus('ok')

      return await resp.json()
    } catch (error) {
      span.setStatus(error.status)

      errorLoggerService.captureException(error, { integration: 'bff:apiv2', url, ...extra })

      throw error
    } finally {
      span.finish()
      transaction.finish()
    }
  }
  async get(url: string, params?: RequestV2ParamsType, extra?: ExtraType) {
    // Check auth tokens before request
    checkAuthTokens()
    const urlFull = `${this.baseUrl}${url}`
    return this.getRaw(urlFull, { ...this.getDefaultApiParams(), ...params }, extra)
  }
  // Insecure Get without checking auth tokens
  async getIs(url: string, params?: RequestV2ParamsType, extra?: ExtraType) {
    const urlFull = `${this.baseIsUrl}${url}`
    return this.getRaw(urlFull, { ...this.getDefaultApiParams(), ...params }, extra)
  }

  async postRaw(
    url: string,
    body?: RequestV2BodyType,
    params?: RequestV2ParamsType,
    extra?: ExtraType
  ) {
    const transaction = getSentryPerformanceTransaction('apiV2')
    const span = transaction.startChild({
      op: url,
      description: JSON.stringify(params),
    })

    try {
      const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        ...params,
      })

      if (!resp.ok) {
        throw await getExtendedError(resp)
      }

      span.setStatus('ok')

      return await resp.json()
    } catch (error) {
      span.setStatus(error.status)

      errorLoggerService.captureException(error, { integration: 'bff:apiv2', url, ...extra })

      throw error
    } finally {
      span.finish()
      transaction.finish()
    }
  }
  async post(
    url: string,
    body?: RequestV2BodyType,
    params?: RequestV2ParamsType,
    extra?: ExtraType
  ) {
    // Check auth tokens before request
    checkAuthTokens()
    const urlFull = `${this.baseUrl}${url}`
    return this.postRaw(
      urlFull,
      this.preprocessRequestBody(body),
      { ...this.getDefaultApiParams(), ...params },
      extra
    )
  }
  // Insecure Posy without checking auth tokens
  async postIs(
    url: string,
    body?: RequestV2BodyType,
    params?: RequestV2ParamsType,
    extra?: ExtraType
  ) {
    const urlFull = `${this.baseIsUrl}${url}`
    return this.postRaw(
      urlFull,
      this.preprocessRequestBody(body),
      { ...this.getDefaultApiParams(), ...params },
      extra
    )
  }

  // Put
  async putRaw(
    url: string,
    body?: RequestV2BodyType,
    params?: RequestV2ParamsType,
    extra?: ExtraType
  ) {
    const transaction = getSentryPerformanceTransaction('apiV2')
    const span = transaction.startChild({
      op: url,
      description: JSON.stringify(params),
    })

    try {
      const resp = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(body),
        ...params,
      })

      if (!resp.ok) {
        throw await getExtendedError(resp)
      }

      span.setStatus('ok')

      return await resp.json()
    } catch (error) {
      span.setStatus(error.status)

      errorLoggerService.captureException(error, { integration: 'bff:apiv2', url, ...extra })

      throw error
    } finally {
      span.finish()
      transaction.finish()
    }
  }
  async put(
    url: string,
    body?: RequestV2BodyType,
    params?: RequestV2ParamsType,
    extra?: ExtraType
  ) {
    // Check auth tokens before request
    checkAuthTokens()
    const urlFull = `${this.baseUrl}${url}`
    return this.putRaw(
      urlFull,
      this.preprocessRequestBody(body),
      { ...this.getDefaultApiParams(), ...params },
      extra
    )
  }
  // Insecure Put without checking auth tokens
  async putIs(
    url: string,
    body?: RequestV2BodyType,
    params?: RequestV2ParamsType,
    extra?: ExtraType
  ) {
    const urlFull = `${this.baseIsUrl}${url}`
    return this.putRaw(
      urlFull,
      this.preprocessRequestBody(body),
      { ...this.getDefaultApiParams(), ...params },
      extra
    )
  }

  async patchRaw(
    url: string,
    body?: RequestV2BodyType,
    params?: RequestV2ParamsType,
    extra?: ExtraType
  ) {
    const transaction = getSentryPerformanceTransaction('apiV2')
    const span = transaction.startChild({
      op: url,
      description: JSON.stringify(params),
    })

    try {
      const resp = await fetch(url, {
        method: 'PATCH',
        body: JSON.stringify(body),
        ...params,
      })

      if (!resp.ok) {
        throw await getExtendedError(resp)
      }

      span.setStatus('ok')

      return await resp.json()
    } catch (error) {
      span.setStatus(error.status)

      errorLoggerService.captureException(error, { integration: 'bff:apiv2', url, ...extra })

      throw error
    } finally {
      span.finish()
      transaction.finish()
    }
  }
  async patch(
    url: string,
    body?: RequestV2BodyType,
    params?: RequestV2ParamsType,
    extra?: ExtraType
  ) {
    // Check auth tokens before request
    checkAuthTokens()
    const urlFull = `${this.baseUrl}${url}`
    return this.patchRaw(
      urlFull,
      this.preprocessRequestBody(body),
      { ...this.getDefaultApiParams(), ...params },
      extra
    )
  }
  // Insecure Patch without checking auth tokens
  async patchIs(
    url: string,
    body?: RequestV2BodyType,
    params?: RequestV2ParamsType,
    extra?: ExtraType
  ) {
    const urlFull = `${this.baseIsUrl}${url}`
    return this.patchRaw(
      urlFull,
      this.preprocessRequestBody(body),
      { ...this.getDefaultApiParams(), ...params },
      extra
    )
  }

  async getAddress(): Promise<UserAddressResponseType> {
    return this.get(REST_V2_ENDPOINTS.userAddress, undefined, {
      integration: 'bff:apiv2:userAddress',
    })
  }

  // Quote
  async initQuote(
    data: Partial<QuoteV2Type>,
    params?: RequestV2ParamsType
  ): Promise<QuoteV2ResponseType> {
    // Set Quote SellingChannel before new quote init
    const sellingChannel = getSellingChannel()
    if (sellingChannel) {
      data.SellingChannel = sellingChannel
    }

    return this.put(`${REST_V2_ENDPOINTS.quote}/init`, data, params, {
      integration: 'bff:apiv2:quote',
    })
  }

  async updateQuote(
    quoteId: number,
    data: Partial<QuoteV2Type>,
    params?: RequestV2ParamsType
  ): Promise<QuoteV2UpdateResponseType> {
    return this.patch(`${REST_V2_ENDPOINTS.quote}/${quoteId}`, data, params, {
      integration: 'bff:apiv2:quote',
    })
  }

  async getQuote(quoteId: number, params?: RequestV2ParamsType): Promise<QuoteV2ResponseType> {
    return this.get(`${REST_V2_ENDPOINTS.quote}/${quoteId}`, params, {
      integration: 'bff:apiv2:quote',
    })
  }

  async acceptQuote(quoteId: number, params?: RequestV2ParamsType): Promise<QuoteV2ResponseType> {
    return this.patch(`${REST_V2_ENDPOINTS.quote}/accept/${quoteId}`, params, {
      integration: 'bff:apiv2:quote',
    })
  }

  async setOwnershipQuote(
    quoteId: number,
    params?: RequestV2ParamsType
  ): Promise<QuoteV2ResponseType> {
    return this.patch(`${REST_V2_ENDPOINTS.quote}/ownership/${quoteId}`, params, {
      integration: 'bff:apiv2:quote',
    })
  }

  async verifyQuote(quoteId: number, params?: RequestV2ParamsType): Promise<QuoteV2ResponseType> {
    return this.post(`${REST_V2_ENDPOINTS.quote}/verify/${quoteId}`, params, {
      integration: 'bff:apiv2:quote',
    })
  }

  // Claim
  async getClaim(claimId: number, params?: RequestV2ParamsType): Promise<ClaimV2ResponseType> {
    return this.get(`${REST_V2_ENDPOINTS.claim}/${claimId}`, params, {
      integration: 'bff:apiv2:claim',
    })
  }

  async initClaim(
    data: Partial<ClaimV2Type>,
    params?: RequestV2ParamsType
  ): Promise<ClaimV2ResponseType> {
    return this.put(`${REST_V2_ENDPOINTS.claim}/init`, data, params, {
      integration: 'bff:apiv2:claim',
    })
  }

  async updateClaim(
    claimId: number,
    data: Partial<ClaimV2Type>,
    params?: RequestV2ParamsType
  ): Promise<ClaimV2ResponseType> {
    return this.patch(`${REST_V2_ENDPOINTS.claim}/${claimId}`, data, params, {
      integration: 'bff:apiv2:claim',
    })
  }

  async uploadClaimFiles(
    claimId: number,
    data: RequestV2ParamsType,
    params?: RequestV2ParamsType
  ): Promise<ClaimV2ResponseType> {
    return this.post(`${REST_V2_ENDPOINTS.claim}/upload/${claimId}`, data, params, {
      integration: 'bff:apiv2:claim',
    })
  }

  // Agent
  async agentPersonLogin(
    data: UserAuthInput,
    params?: RequestV2ParamsType
  ): Promise<AgentPersonLoginResponseType> {
    return this.postIs(
      `${REST_V2_ENDPOINTS.agent}/person-login`,
      data as unknown as RequestV2BodyType,
      params,
      {
        integration: 'bff:apiv2:agent',
      }
    )
  }

  // Policy
  async getAllPolicies(params?: RequestV2ParamsType): Promise<PolicyAllV2ResponseType> {
    return this.get(`${REST_V2_ENDPOINTS.policy}/getAll`, params, {
      integration: 'bff:apiv2:policy',
    })
  }

  async getPolicy(
    policyNumber: number,
    policyVersion?: number,
    params?: RequestV2ParamsType
  ): Promise<PolicyV2ResponseType> {
    const policyNumberWithVersion = getPolicyNumberWithVersionUriPath(policyNumber, policyVersion)
    return this.get(`${REST_V2_ENDPOINTS.policy}/getOne/${policyNumberWithVersion}`, params, {
      integration: 'bff:apiv2:policy',
    })
  }

  async getPolicyDocument(
    correspondenceId: number,
    params?: RequestV2ParamsType
  ): Promise<PolicyDocumentV2ResponseType> {
    return this.get(`${REST_V2_ENDPOINTS.policy}/getDocument/${correspondenceId}`, params, {
      integration: 'bff:apiv2:policy',
    })
  }

  async updatePolicy(
    policyNumber: number,
    data: Partial<PolicyV2Type>,
    params?: RequestV2ParamsType
  ): Promise<PolicyV2ResponseType> {
    return this.patch(`${REST_V2_ENDPOINTS.policy}/${policyNumber}`, data, params, {
      integration: 'bff:apiv2:policy',
    })
  }

  async updateMileagePolicy(
    policyNumber: number,
    data: Pick<PolicyV2Type, 'OdometerReading' | 'DateOdometerReading'>,
    params?: RequestV2ParamsType
  ): Promise<Pick<PolicyV2ResponseType['data'], 'OdometerReading' | 'DateOdometerReading'>> {
    return this.patch(`${REST_V2_ENDPOINTS.policy}/updateMileage/${policyNumber}`, data, params, {
      integration: 'bff:apiv2:policy',
    })
  }

  async calculatePolicy(
    policyNumber: number,
    policyVersion: number | undefined,
    data: Partial<PolicyV2Type>,
    params?: RequestV2ParamsType
  ): Promise<PolicyV2ResponseType> {
    const policyNumberWithVersion = getPolicyNumberWithVersionUriPath(policyNumber, policyVersion)
    return this.post(
      `${REST_V2_ENDPOINTS.policy}/calculate/${policyNumberWithVersion}`,
      data,
      params,
      {
        integration: 'bff:apiv2:policy',
      }
    )
  }

  async changePolicy(
    policyNumber: number,
    policyVersion: number | undefined,
    data: Partial<PolicyV2ChangeRequestType>,
    params?: RequestV2ParamsType
  ): Promise<PolicyV2ResponseType> {
    const policyNumberWithVersion = getPolicyNumberWithVersionUriPath(policyNumber, policyVersion)
    return this.patch(
      `${REST_V2_ENDPOINTS.policy}/change/${policyNumberWithVersion}`,
      data,
      params,
      {
        integration: 'bff:apiv2:policy',
      }
    )
  }

  // Datasource ProductProps
  async getDatasourceProductProps(
    params?: RequestV2ParamsType
  ): Promise<ProductPropsV2ResponseType> {
    return this.get(`${REST_V2_ENDPOINTS.datasource}/productProps`, params, {
      integration: 'bff:apiv2:datasource',
    })
  }

  // Datasource Insurance Companies
  async getDatasourceInsuranceCompanies(
    params?: RequestV2ParamsType
  ): Promise<InsuranceCompaniesV2ResponseType> {
    return this.get(`${REST_V2_ENDPOINTS.datasource}/insuranceCompanies`, params, {
      integration: 'bff:apiv2:datasource',
    })
  }

  // Party
  async updateParty(
    data: Partial<PartyV2Type>,
    params?: RequestV2ParamsType
  ): Promise<PartyV2UpdateResponseType> {
    return this.patch(`${REST_V2_ENDPOINTS.party}`, data, params, {
      integration: 'bff:apiv2:party',
    })
  }

  async getPartyOwn(params?: RequestV2ParamsType): Promise<PartyV2UpdateResponseType> {
    return this.get(`${REST_V2_ENDPOINTS.party}`, params, {
      integration: 'bff:apiv2:party',
    })
  }

  async cancelInsurelyInsurances(
    collectionId: string,
    insurances: { externalId: string }[],
    email: string,
    params?: RequestV2ParamsType
  ): Promise<CancelInsurancesResponseType> {
    const data = {
      collectionId: collectionId,
      insurances: insurances,
      email: email,
    }
    return this.post(`${REST_V2_ENDPOINTS.insurely}/cancel`, data, params, {
      integration: 'bff:apiv2:insurely',
    })
  }

  // Person All Vehicles
  async getPersonAllVehicles(
    params?: RequestV2ParamsType
  ): Promise<PersonAllVehiclesV2ResponseType> {
    return this.get(`${REST_V2_ENDPOINTS.person}/allVehicles`, params, {
      integration: 'bff:apiv2:person/allVehicles',
    })
  }

  // Authentication
  async authRegular(
    userInput: AuthRegularV2InputType,
    params?: RequestV2ParamsType
  ): Promise<AuthRegularV2ResponseType> {
    return this.postIs(`${REST_V2_ENDPOINTS.auth}/regular`, userInput, params, {
      integration: 'bff:apiv2:auth',
    })
  }

  async authSignOut(params?: RequestV2ParamsType): Promise<SignOutV2ResponseType> {
    return this.get(`${REST_V2_ENDPOINTS.auth}/signout`, params, {
      integration: 'bff:apiv2:auth',
    })
  }

  async getSessBankId(bankIdParams: string, params?: RequestV2ParamsType): Promise<void> {
    return this.getRaw(
      `${BC.REST_URL}${REST_V2_ENDPOINTS.authBankId}/${bankIdParams}`,
      { ...this.getDefaultApiParams(), ...params },
      {
        integration: 'bff:bankId',
      }
    )
  }

  async clearCookies(params?: RequestV2ParamsType): Promise<void> {
    return this.getRaw(
      `${BC.REST_URL}${REST_V2_ENDPOINTS.clearCookie}`,
      { ...this.getDefaultApiParams(), ...params },
      {
        integration: 'bff:auth',
      }
    )
  }

  async getVippsLink(params?: RequestV2ParamsType): Promise<{ vLink: string; errVipps: boolean }> {
    return this.getRaw(
      `${BC.REST_URL}${REST_V2_ENDPOINTS.getVippsLink}?partnerName=${PARTNER_NAME}`,
      { ...this.getDefaultApiParams(), ...params },
      {
        integration: 'bff:vipps',
      }
    )
  }

  // Google Maps
  async geocodeLatLngUrl(
    lat: number,
    lng: number,
    params?: RequestV2ParamsType
  ): Promise<{ results: Array<AddressInterface | null> }> {
    return this.getRaw(GOOGLE_MAP_URL_LIST.geocodeLatLngUrl(lat, lng), params, {
      integration: 'fe:googleMaps',
    })
  }

  async geolocateUrl(
    params?: RequestV2ParamsType
  ): Promise<{ location: { lat: number; lng: number } }> {
    return this.postRaw(GOOGLE_MAP_URL_LIST.geolocateUrl(), undefined, params, {
      integration: 'fe:googleMaps',
    })
  }
}

const apiV2 = new ApiV2()

export default apiV2
