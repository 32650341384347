import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { TranslatedTextType } from '../../hooks/shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonNext: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.contrastText,
      ...theme.brand.assets.layout?.ctaOverride,
      fontSize: '1rem',
      margin: 'auto',
      marginTop: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        marginTop: '0',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '345px',
      },
    },
  })
)

interface ExternalSubmitBtn {
  disabled: boolean
  customClassName?: string
  targetId: string
  text: TranslatedTextType
}

const ExternalSubmitBtn: FC<ExternalSubmitBtn> = ({
  targetId,
  disabled,
  text,
  customClassName,
}) => {
  const classes = useStyles()

  return (
    <Button
      type='submit'
      variant='contained'
      size='medium'
      form={targetId}
      className={`${classes.buttonNext} ${customClassName}`}
      disabled={disabled}
      fullWidth
      data-testid={`submit-btn-${targetId}`}
    >
      {text}
    </Button>
  )
}

export default ExternalSubmitBtn
