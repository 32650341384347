import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { IS_ACCEPTANCE, IS_PRODUCTION } from '../../config'
import { Button } from '@material-ui/core'
import { FSMService } from '../../FSM'
import { Events } from '../../FSM/shared/constants'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnDebug: {
      position: 'absolute',
      top: 22,
      left: 380,
      color: theme.palette.primary.main,
      zIndex: 1000,
      [theme.breakpoints.down('sm')]: {
        right: 0,
        left: 100,
        width: '40%',
      },
    },
  })
)

// Navigates to FNOL My Pages for DEBUG QA needs on DEV
const FNOLMyPagesDEVButton = () => {
  const classes = useStyles()
  return !IS_PRODUCTION && !IS_ACCEPTANCE ? (
    <Button
      className={classes.btnDebug}
      onClick={() => FSMService.flowService.send(Events.MY_PAGES)}
    >
      FNOL Legacy MyPage
    </Button>
  ) : null
}

export default FNOLMyPagesDEVButton
