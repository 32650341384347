import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

import { StylesProps } from '../../../types/interface/styles.interface'
import vippsImageSvg from '../../../static/images/vipps/VippsBtnImage.svg'
import { redirect } from '@dg-util'

export const useVippsBtnStyles = makeStyles((theme: Theme) =>
  createStyles({
    // TODO: Due to Vipps Documentation
    vipps: (props: StylesProps) => ({
      width: '230px',
      height: '48px',
      margin: `15px auto`,
      color: theme.palette.success.contrastText,
      background: `url(${props.backgroundImage}) no-repeat left`,
      backgroundSize: 'cover',
    }),
  })
)

interface BtnVipps {
  url: string
  disabled: boolean
}

const VippsBtn: FC<BtnVipps> = ({ url, disabled }) => {
  const classes = useVippsBtnStyles({ backgroundImage: vippsImageSvg })

  return (
    <Button
      data-testid='vipps-button'
      variant='contained'
      size='medium'
      disabled={disabled || !url}
      className={classes.vipps}
      fullWidth
      onClick={() => redirect(url)}
    />
  )
}

export default VippsBtn
