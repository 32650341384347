import React, { ChangeEvent, FC, useState } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { TextField, FormControl } from '@material-ui/core'
import { Autocomplete, LoadScript } from '@react-google-maps/api'
import { AddressValues, MapResult } from '../../../types/interface/map.interface'
import { GOOGLE_MAP_API_KEY, GOOGLE_MAPS_LIBRARIES } from '../../../constants/google-map-constants'
import { FSMBtnNext } from '@dg-shared'
import { TranslatedTextType } from '../../../types'
import { omit } from 'lodash'
import { formatMapLocations, isAddressValid } from '../../../service/util.service'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customInput: {
      marginBottom: theme.spacing(5),
      '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
        {
          display: 'none',
        },
      '& input[type="number"]': {
        '-moz-appearance': 'textfield',
      },
      '& > p': {
        color: theme.palette.info.contrastText,
      },
    },
    customLabel: {
      color: theme.brand.colors.textSecondary,
      '& + .MuiInput-formControl': {
        marginTop: theme.spacing(1),
      },
      position: 'static',
      lineHeight: 1.4, // 22
    },
    formControl: {
      width: '100%',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
)

const RFFAddressAutocomplete: FC<{
  fieldLabel: TranslatedTextType
  nextBtnText: TranslatedTextType
  classesProps?: string
  submitClass?: string
  submitHandler(address: AddressValues): Promise<void>
}> = ({ fieldLabel, nextBtnText, submitHandler, classesProps, submitClass }) => {
  const classes = useStyles()
  const [autoComplete, setAutoComplete] = useState<google.maps.places.Autocomplete | null>(null)
  const [currentAddress, setCurrentAddress] = useState<MapResult | null>(null)

  const onLoadAutocomplete = (autocomplete: google.maps.places.Autocomplete) => {
    setAutoComplete(autocomplete)
  }

  const handleChangedPlace = () => {
    const { formatted_address, address_components } = !!autoComplete && autoComplete.getPlace()

    if (!formatted_address && !address_components) {
      return
    }

    setCurrentAddress({
      formatted_address,
      address_components,
    })
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    autoComplete.setComponentRestrictions({ country: ['no'] })
    setCurrentAddress({
      formatted_address: event.target.value,
      address_components: [],
    })
  }

  const handleSubmit = () => {
    const formattedAddress = formatMapLocations(currentAddress.address_components)
    return submitHandler(omit(formattedAddress, 'country'))
  }

  return (
    <section className={`${classes.wrapper} ${classesProps}`}>
      <div>
        <LoadScript googleMapsApiKey={GOOGLE_MAP_API_KEY} libraries={GOOGLE_MAPS_LIBRARIES}>
          <Autocomplete onLoad={onLoadAutocomplete} onPlaceChanged={handleChangedPlace}>
            <FormControl className={classes.formControl}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                  className: classes.customLabel,
                }}
                inputProps={{ ['data-testid']: `address-input` }}
                fullWidth
                type={'text'}
                className={classes.customInput}
                name={'address-input'}
                value={currentAddress?.formatted_address}
                placeholder={''}
                onChange={handleInputChange}
                label={fieldLabel || null}
              />
            </FormControl>
          </Autocomplete>
        </LoadScript>
      </div>
      <FSMBtnNext
        buttonText={nextBtnText}
        customClassName={submitClass}
        disabled={!isAddressValid(currentAddress?.address_components)}
        handleClick={handleSubmit}
      />
    </section>
  )
}

export default RFFAddressAutocomplete
