import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Typography } from '@dg-shared'

export type TableDataType = {
  title: string
  thead?: JSX.Element[]
  tbody: Array<JSX.Element[]>
}

type LandingCompareTablePropsType = {
  className: string
  data: TableDataType
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      backgroundColor: theme.brand.colors.cardHeader,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
    },
    tableWrapper: {
      borderRadius: theme.spacing(1),
      backgroundColor: theme.brand.colors.clean,
      boxShadow: `0 0 ${theme.spacing(2)}px ${theme.brand.colors.modal}`,
    },
    table: {
      padding: `0 ${theme.spacing(2)}px`,
      borderSpacing: 0,
      '& td, & th': {
        padding: theme.spacing(3),
      },
      '& tr:not(:last-child) td, & th': {
        borderBottom: `1px solid ${theme.brand.colors.background}`,
      },
    },
    tableDataWrapper: {
      overflowX: 'scroll',
    },
  })
)

const Table: FC<LandingCompareTablePropsType> = ({ className, data }) => {
  const { title, tableWrapper, tableDataWrapper, table } = useStyles()

  return (
    <div className={[tableWrapper, className].join(' ')}>
      <Typography type='h2' className={title} color={'textCardHeader'}>
        {data.title}
      </Typography>
      <div className={tableDataWrapper}>
        <table className={table}>
          {data.thead ? (
            <thead>
              <tr>
                {data.thead.map((header, key) => (
                  <th key={key}>{header}</th>
                ))}
              </tr>
            </thead>
          ) : null}
          <tbody>
            {data.tbody.map((row, rowKey) => (
              <tr key={rowKey}>
                {row.map((cell, cellKey) => (
                  <td key={cellKey}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
