import { FNOLWindshield } from '../../../../pages/FNOL'
import { FSMCommonTransitions } from '../../constants'

const { FNOL_WINDSHIELD_FLOW, FNOL_WINDSHIELD_START } = FSMCommonTransitions

export default [
  {
    is: { [FNOL_WINDSHIELD_FLOW]: FNOL_WINDSHIELD_START },
    page: FNOLWindshield,
  },
]
