import { assign, EventObject } from 'xstate'
import { get } from 'lodash'
import { redirect, setSessionAuthMethod } from '@dg-util'
import CONFIG, { FlowName } from '../../config'
import {
  FLOW_NAME_QUERY_KEY,
  getFlowName,
  getProductName,
  processSignOut,
  switchFSM,
} from '@dg-util/productHandler'
import { AuthMethod } from '../../constants/auth-constants'
import { DefaultContext } from 'xstate/lib/types'
import messagesQueue, { MessageType } from '../../service/messagesQueue'
import BrandConfig from 'BrandConfig'
import { FSMService } from '..'
import { FSMCommonTransitions } from './constants'

const setUserAuthorized = assign({
  authorized: true,
})

const setBackPath = assign({
  backPath: (context, event) => get(event, 'state'),
})

const signOut = (context: DefaultContext) => {
  ;(async () => {
    const signOutResult = await processSignOut()

    // Successfull sign out
    if (signOutResult) {
      // Redirects handling
      redirectOnSignOut(context)
    }
  })()

  return assign({ authorized: false, authMethod: '' })
}

const setRegularAuth = assign({
  authMethod: () => {
    setSessionAuthMethod(AuthMethod.REGULAR)
    return AuthMethod.REGULAR
  },
})

const setVippsAuth = assign({
  authMethod: () => {
    // Set Vipps auth method to verify it in services (has no access to hooks or FSM context)
    setSessionAuthMethod(AuthMethod.VIPPS)
    return AuthMethod.VIPPS
  },
})

const setBankIdAuth = assign({
  authMethod: () => {
    setSessionAuthMethod(AuthMethod.BANKID)
    return AuthMethod.BANKID
  },
})

const setAgentAuth = assign({
  authMethod: () => {
    setSessionAuthMethod(AuthMethod.AGENT)
    return AuthMethod.AGENT
  },
})

const switchFlow = () => {
  const flow = sessionStorage.getItem(FLOW_NAME_QUERY_KEY)
  if (flow === FlowName.MY_PAGES) {
    redirect(`/?flow=${FlowName.QUOTE_AND_BUY}`)
  }
}

// Redirects to agent portal in case the auth method is AGENT
const redirectToAgentPortal = (context: DefaultContext) => {
  if (context.authMethod === AuthMethod.AGENT) {
    // Redirect with pending timeout, so the original action will have time to finish its processing
    setTimeout(() => {
      redirect(CONFIG.AGENT_PORTAL_HOST)
    }, 500)
  }
}

// Handle Brand / product / flow specific redirect on sign out
const redirectOnSignOut = (context: DefaultContext) => {
  // Check if it is an agent flow and redirect to the Agent Portal if necessary
  redirectToAgentPortal(context)

  // Redirect for My Pages
  const flowName = getFlowName()

  if (flowName === FlowName.MY_PAGES) {
    const productName = getProductName()
    const redirectLinkMap = BrandConfig.MYPAGES_LOGOUT_REDIRECT
    const redirectLink = redirectLinkMap[productName as keyof typeof redirectLinkMap]

    if (redirectLink) {
      redirect(redirectLink)
    }
  }

  // Cleanup of app-state with usage of SwitchFSM
  const targetProduct = getProductName()
  const targetFlow = getFlowName()
  const targetStateValue = FSMCommonTransitions.AUTH

  switchFSM({
    targetStateValue,
    targetFlow,
    targetProduct,
    FSMService,
    contextOverride: { authorized: false, authMethod: '', prevConfig: undefined },
  })
}

// Set event in message queue for usage after cleanup and page reload
const setMessageQueueEvent = (context: DefaultContext, event: EventObject) => {
  messagesQueue.setMessage({
    id: event.type,
    message: event as unknown as MessageType['message'],
  })
}

export default {
  setUserAuthorized,
  setBackPath,
  signOut,
  switchFlow,
  setVippsAuth,
  setBankIdAuth,
  setAgentAuth,
  setRegularAuth,
  redirectToAgentPortal,
  setMessageQueueEvent,
}
