import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FlowName, IS_ACCEPTANCE, IS_PRODUCTION } from '../../config'
import { Button } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnStandards: {
      position: 'absolute',
      top: '20px',
      left: '350px',
      color: theme.palette.secondary.contrastText,
    },
  })
)

// Navigates to My Pages Flow
const FNOLDevButton = () => {
  const classes = useStyles()

  return !IS_PRODUCTION && !IS_ACCEPTANCE ? (
    <Button
      className={classes.btnStandards}
      onClick={() => (location.href = `/?flow=${FlowName.MY_PAGES}&product=vehicle`)}
    >
      MyPages Flow
    </Button>
  ) : null
}

export default FNOLDevButton
