const CONFIG = {
  EMAIL: 'forsikring@instabank.no',
  ADVANCE_PURCHASE_INFO_LINK: '',
  SUPPORT_LINK: 'https://instabank.no/kundesenter-kontaktoss',
  MAIN_LINK: {
    vehicle: 'https://instabank.no/forsikring/hort-om-var-reiseforsikring',
    travel: 'https://instabank.no/forsikring/hort-om-var-instabank-bilforsikring',
    content: 'https://instabank.no/forsikring/hort-om-var-instabank-bilforsikring',
  },
  MYPAGES_LOGOUT_REDIRECT: {
    vehicle: 'https://instabank.no/forsikring/bil',
    travel: 'https://instabank.no/forsikring/instabank-reiseforsikring',
    content: 'https://instabank.no/forsikring/innboforsikring',
  },
  INSURANCE: 'https://instabank.no/forsikring',
  PRIVACY_POLICY_LINK: 'https://www.digisure.no/personopplysninger',
  PARTNER_PRIVACY_POLICY_LINK: '',
  PARTNER_NAME: 'instabank',
  PARTNER_NAME_AVTALEGIRO: 'Instabank',
  VIPPS_AUTH: true,
  TRANSLATION_LANGUAGES: ['no-NO'],
  GOOGLE_RECAPTCHA_LINKS: {
    PRIVACY: 'https://policies.google.com/privacy?hl=no',
    TERMS: 'https://policies.google.com/terms?hl=no',
  },
  FNOL: {
    PageWindshield: {
      iconLink: 'https://booking.bilglass.no/',
    },
    PageRoadsideAssistance: {
      iconLink: 'https://rsa2.falck.no/',
    },
  },
  INSTABANK_REVIEW_LINK: 'https://instabank.no/forsikring/instabank-reiseforsikring',
  DOCUMENTS: {
    PRIVACY_STATEMENT: '',
    LP: {
      IPID: '',
      ANSVAR: '',
      DELKASKO: '',
      KASKO: '',
      SUPER: '',
    },
  },
}

export default CONFIG
