import React, { FC } from 'react'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import LandingPageWrapper from '@dg-shared/LandingPageWrapper'
import LandingQuestoinsAnswersBodyVehicle from 'components/LandingQABody.vehicle'
import { getDataComponentName } from '@dg-util'

const QuestionsAndAnswersPage: FC<CommonPageProps> = ({}) => {
  return (
    <LandingPageWrapper data-componentname={getDataComponentName(__filename)}>
      <LandingQuestoinsAnswersBodyVehicle />
    </LandingPageWrapper>
  )
}

export default QuestionsAndAnswersPage
