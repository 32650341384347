import React, { FC, lazy, Suspense } from 'react'
import { getProductName } from '@dg-util'
import { IconType } from '../../../types/interface/icon.interface'

const ReviewPage: FC = (props) => {
  const ProductReviewPage = lazy(() => import(`./ReviewPage.${getProductName()}.tsx`))
  return (
    <Suspense fallback={IconType.LOADING}>
      <ProductReviewPage {...props} />
    </Suspense>
  )
}

export default ReviewPage
