import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '../../shared/typography/Typography'
import { useTranslate } from '../../../hooks/shared'
import { TRANSLATIONS } from '../../../constants'

const useAuthStyles = makeStyles((theme: Theme) =>
  createStyles({
    captchaText: {
      display: 'block',
      marginTop: theme.spacing(1),
      textAlign: 'center',
      '& a': {
        color: 'inherit',
      },
    },
  })
)

interface ReCaptchaPrivacyTermsProps {
  links: {
    PRIVACY: string
    TERMS: string
  }
  customClassName?: string
}

const ReCaptchaPrivacyTerms: FC<ReCaptchaPrivacyTermsProps> = ({
  customClassName,
  links: { PRIVACY, TERMS },
}) => {
  const { t } = useTranslate(TRANSLATIONS.COMMON_RECAPTCHA)

  const classes = useAuthStyles()

  return (
    <Typography
      component='span'
      size='small'
      testId='privacy-policy-terms'
      color='textDisclaimer'
      modifiers={['preFormatted']}
      className={`${classes.captchaText} ${customClassName}`}
    >
      {t('text.0')}
      <a href={PRIVACY} target='_blank' rel='noreferrer'>
        {t('text.1')}
      </a>
      {t('text.2')}
      <a href={TERMS} target='_blank' rel='noreferrer'>
        {t('text.3')}
      </a>
      {t('text.4')}
    </Typography>
  )
}

export default ReCaptchaPrivacyTerms
