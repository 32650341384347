import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { range } from 'lodash'

import { TRANSLATIONS as T } from '../../../../constants/translations-constants'
import { IconType } from '../../../../types/interface/icon.interface'
import { Typography, BrandIconShared } from '@dg-shared'
import { useTranslate } from '../../../../hooks/shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkIcon: {
      minWidth: theme.spacing(4),
      color: theme.palette.primary.main,
      alignSelf: 'flex-start',
      marginTop: theme.spacing(0.25),
    },
    closeIcon: {
      minWidth: theme.spacing(4),
      color: theme.palette.error.main,
      alignSelf: 'flex-start',
      marginTop: theme.spacing(0.25),
    },
    listItem: {
      padding: '0',
      '&:first-child': {
        marginBottom: theme.spacing(0.5),
      },
    },
    listItemText: {
      color: theme.palette.primary.dark,
      fontSize: theme.spacing(2),
      whiteSpace: 'pre-wrap',
    },
    errorText: {
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(2),
      },
    },
  })
)

interface AdvantagesTextListProps {
  annualAmount: string
}

const AdvantagesTextList: FC<AdvantagesTextListProps> = ({ annualAmount }) => {
  const { t } = useTranslate(T.OFFER_COMPARE_PRICE)
  const classes = useStyles()

  return parseInt(annualAmount, 10) > 0 ? (
    <List>
      {range(3).map((el) => {
        return (
          <ListItem key={el} className={classes.listItem}>
            <ListItemIcon className={classes.checkIcon}>
              <BrandIconShared color='secondary' type={IconType.CHECK} />
            </ListItemIcon>
            {el === 0 ? (
              <Typography className={classes.listItemText} testId='advantage-text-annual-amount'>
                <Trans
                  className={classes.listItemText}
                  i18nKey={`${T.OFFER_COMPARE_PRICE}.advantages.0`}
                  values={{ annualAmount }}
                />
              </Typography>
            ) : (
              <ListItemText
                primary={t(`advantages.${el}`)}
                classes={{ primary: classes.listItemText }}
              />
            )}
          </ListItem>
        )
      })}
    </List>
  ) : (
    <>
      <Typography testId='disadvantages-0' className={classes.errorText}>
        <Trans className={classes.errorText} i18nKey={`${T.OFFER_COMPARE_PRICE}.disadvantages.0`} />
      </Typography>
      <Typography testId='disadvantages-1'>{t('disadvantages.1')}</Typography>
    </>
  )
}

export default AdvantagesTextList
