import React, { FC, useContext } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../../../constants'
import { CTA_TYPE_PURCHASED_DONE } from '../../../../constants/cta-constants'
import { FSMBtnNext, CTA, Typography } from '@dg-shared'
import { useTranslate } from '../../../../hooks/shared'
import { FlowServiceType } from '../../../../types/interface/fsm.interface'
import { getDataComponentName } from '@dg-util'
import useReactQueryPolicy from '../../../../hooks/shared/useReactQueryPolicy'
import { switchFSM } from '@dg-util/productHandler'
import { Context } from '@dg-shared/ContextProvider'
import { FSMService } from '../../../../FSM'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    annotation: {
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(3),
      },
    },
    footer: {
      justifyContent: 'center',
      margin: '0 !important',
    },
    button: {
      ...theme.brand.assets.layout?.ctaOverride,
    },
  })
)

interface MyPagesQBDoneProps {
  flowService: FlowServiceType
}

const MyPagesQBDone: FC<MyPagesQBDoneProps> = ({ flowService }) => {
  const { t } = useTranslate(T.OFFER_PURCHASED)
  const classes = useStyles()
  const { refetchPolicies } = useReactQueryPolicy()
  const prevConfig = flowService.state?.context?.prevConfig
  const { setContext } = useContext(Context)

  const clickHandler = async () => {
    // Set context loading to use in the parent page for loader
    setContext({ isLoading: true })

    // Renew policies list after successful Quote acceptance
    await refetchPolicies()
    // Navigate back to the previous FSM state with a timeout to finalize caching before FSM switch
    setTimeout(() => {
      setContext({ isLoading: undefined })
      switchFSM({
        targetStateValue: prevConfig.stateValue,
        targetFlow: prevConfig.flow,
        targetProduct: prevConfig.product,
        FSMService: FSMService,
      })
    }, 100)
  }

  return (
    <>
      <Typography
        testId='done-annotation'
        className={classes.annotation}
        data-componentname={getDataComponentName(__filename)}
      >
        {t('done.annotation')}
      </Typography>
      <CTA context={CTA_TYPE_PURCHASED_DONE} containerClass={classes.footer} />
      <FSMBtnNext
        customClassName={classes.button}
        buttonText={t(`finished`)}
        handleClick={clickHandler}
      />
    </>
  )
}

export default MyPagesQBDone
