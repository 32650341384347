import React, { FC, useEffect } from 'react'

import { Typography, FNOLPageWrapper } from '@dg-shared'
import { useTranslate, useClaim } from '../../../hooks/shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import { FNOL_COLLISION_QUESTIONS, FORM_ID, TRANSLATIONS as T } from '../../../constants'
import { useFNOLStyles } from '../shared/styles/useStyles'
import FormBuilder from '@dg-shared/forms/FormBuilder'

const PageObject: FC<CommonPageProps> = ({ service, currentState }) => {
  const FNOLClasses = useFNOLStyles({})
  const { t } = useTranslate(`${T.COLLISION}.object`)
  const { handleAnswerClick, clearAnswer } = useClaim(service, currentState)

  useEffect(() => {
    clearAnswer(FNOL_COLLISION_QUESTIONS.WHAT_DID_YOU_HIT)
  }, [clearAnswer])

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`back`)} breadcrumbsStepPercentage={50}>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <Typography testId='subtitle' html={true} className={FNOLClasses.description}>
        {t('subtitle')}
      </Typography>
      <FormBuilder
        id={FORM_ID.OBJECT}
        classes={FNOLClasses.formContainer}
        submitHandler={({ object }: Record<string, string>) =>
          handleAnswerClick(FNOL_COLLISION_QUESTIONS.WHAT_DID_YOU_HIT, object, Events.NEXT)
        }
      />
    </FNOLPageWrapper>
  )
}

export default PageObject
