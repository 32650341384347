import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../../constants'
import { Events } from '../../../FSM/shared/constants'
import useLogic from './useLogic'
import ChangeItem from './Item'
import Loading from '../Loading/Page'
import { LMBasicV3FSM, LDContainerV2FSM, FSMBtnNext, Typography } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import PageWrapper from '@dg-shared/PageWrapper'
import { PartnerName } from '../../../types/interface/quote.interface'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    annotation: {
      marginBottom: theme.spacing(2),
    },
    buttonNext: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      ...theme.brand.assets.layout?.btnNext,
      ...theme.brand.assets.layout?.changeNextBtn,
      [theme.breakpoints.up('md')]: {
        margin: 'auto',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        maxWidth: '345px',
      },
    },
  })
)

const ChangePage: FC<CommonPageProps> = ({ service }) => {
  const { t } = useTranslate(T.OFFER_CHANGE)
  const classes = useStyles()

  const {
    loading,
    quote,
    formBtnDisabled,
    handleQuoteUpdate,
    initialFormValues,
    updateFormState,
    formOptionsValues,
  } = useLogic()

  const outlinedNextButton = PARTNER_NAME !== PartnerName.skoda

  //FIXME: Task created: DFE-1584
  const offerItem = (
    <ChangeItem
      itemData={quote}
      initialFormValues={initialFormValues}
      formOptionsValues={formOptionsValues}
      updateFormState={updateFormState}
      updateOfferHandler={handleQuoteUpdate}
      submitDisabled={formBtnDisabled}
    />
  )

  const buttonNext = !!quote && (
    <FSMBtnNext
      id='ctaBtnNext'
      handleClick={() => service.send(Events.NEXT)}
      buttonText={t(`next`)}
      customClassName={classes.buttonNext}
      outlined={outlinedNextButton}
    />
  )

  if (loading || !quote) {
    return <Loading />
  }

  //FIXME: Refactor this quote check
  return (
    quote && (
      <PageWrapper
        mobile={LMBasicV3FSM}
        desktop={LDContainerV2FSM}
        breadcrumbsBtnText={t(`back`)}
        breadcrumbsStepPercentage={50}
      >
        <Typography type='h1'>{t('title')}</Typography>
        <Typography testId='change-annotation' className={classes.annotation}>
          {t('subtitle')}
        </Typography>
        {offerItem}
        {buttonNext}
      </PageWrapper>
    )
  )
}

export default ChangePage
