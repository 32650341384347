import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import { Typography } from '@dg-shared'
import { FormFieldV2 } from '../../../../types/interface/form.interface'

interface PhoneLabelProps {
  field: FormFieldV2
  invalid: boolean
}

const PhoneFieldLabel: FC<PhoneLabelProps> = ({ invalid }) => {
  return (
    <Typography testId='text-phone-number' color={invalid ? 'textError' : 'textSecondary'}>
      <Trans i18nKey={'verify.form.phone.label'} />
    </Typography>
  )
}

export default PhoneFieldLabel
