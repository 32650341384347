import React, { FC, useEffect, useState } from 'react'

import { Typography, FNOLPageWrapper, FNOLButton } from '@dg-shared'
import { useTranslate, useClaim } from '../../../hooks/shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import { useFNOLStyles } from '../shared/styles/useStyles'
import { FNOL_COLLISION_QUESTIONS, TRANSLATIONS as T } from '../../../constants'
import RFFCustomImageUpload, { Image } from '@dg-shared/forms/RFFCustomFileUpload'
import LoadingSpinner from '../../../components/shared/forms/MUICustomSpinner'
import useReactQueryClaim from '../../../hooks/shared/useReactQueryClaim'

const PageDamageReport: FC<CommonPageProps> = ({ service, currentState }) => {
  const FNOLClasses = useFNOLStyles({})
  const { t } = useTranslate(`${T.COLLISION}.damageReport`)
  const { setClaimAnswer } = useClaim(service, currentState)

  const { uploadClaimFiles, uploadClaimFilesResult } = useReactQueryClaim()

  const [images, setImages] = useState<Array<Image>>([])
  const [spinner, setSpinner] = useState(false)

  const handleSubmit = async ({ reportDamage }: Record<string, string>) => {
    await setClaimAnswer(FNOL_COLLISION_QUESTIONS.HAVE_YOU_FILLED_FORM_PAPER, reportDamage)

    setSpinner(true)

    const files = images.map((el) => el.data_url)
    await uploadClaimFiles({ files })
  }

  useEffect(() => {
    setSpinner(false)

    // Check upload files result. Successful files upload returns ClaimId
    if (uploadClaimFilesResult?.data.ClaimId) {
      service.send({ type: Events.NEXT, state: currentState })
    }
  }, [currentState, service, uploadClaimFilesResult])

  return (
    <FNOLPageWrapper
      breadcrumbsBtnText={t(`back`)}
      breadcrumbsStepPercentage={90}
      mobileWrapperStyle={{
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <RFFCustomImageUpload images={images} setImages={setImages} />
      {spinner ? (
        <LoadingSpinner />
      ) : (
        <FNOLButton
          testId='nextButton'
          customClassName={FNOLClasses.button}
          handleClick={handleSubmit}
          buttonText={t(`next`)}
        />
      )}
    </FNOLPageWrapper>
  )
}

export default PageDamageReport
