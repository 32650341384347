import { FC, useEffect } from 'react'
import { ServiceSend } from '../../../types/interface/fsm.interface'
import history from '../../../routes/history'
import { Events } from '../../../FSM/shared/constants'
import { useLocation } from 'react-router-dom'

const AcceptPage: FC<{ send: ServiceSend }> = ({ send }) => {
  const location = useLocation()
  const paymentStatus = location.pathname.split('/').filter(Boolean)[1]
  const isFailure = paymentStatus === 'failure'

  useEffect(() => {
    if (isFailure) {
      history.push('/') // FIXME: need to push to the right location
      send(Events.PAYMENT_FAILURE)
      return
    }

    history.push('/') // FIXME: need to push to the right location
    send(Events.PAYMENT_SUCCESS)
  }, [isFailure, send])

  return null
}

export default AcceptPage
