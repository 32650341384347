import React, { FC, ComponentType } from 'react'
import { Hidden } from '@material-ui/core'

import { getDisplayName } from '../../../service/util.service'

// eslint-disable-next-line @typescript-eslint/ban-types
const withHidden = <P extends object>(
  Component: ComponentType<P>,
  showOnMobile: boolean
): FC<P> => {
  const WithHidden: FC<P> = ({ children, ...props }) => (
    <Hidden mdUp={showOnMobile} smDown={!showOnMobile}>
      <Component {...(props as P)}>{children}</Component>
    </Hidden>
  )

  WithHidden.displayName = `WithHidden(${getDisplayName(Component)})`

  return WithHidden
}

export default withHidden
