import { FC, useEffect } from 'react'
import { Events } from '../../../FSM/shared/constants'
import { FSMService } from '../../../FSM'
import { getSearchParams } from '@dg-util'
import { SESSION_ID_KEY } from '../../../config'

const AgentLoginPage: FC = () => {
  useEffect(() => {
    const params = getSearchParams()
    const sessionId = params.sessionId

    if (sessionId) {
      sessionStorage.setItem(SESSION_ID_KEY, sessionId)
      FSMService.flowService.send(Events.AGENT_AUTH)
    } else {
      alert('No session ID')
    }
  }, [])

  return null
}

export default AgentLoginPage
