import QuoteAndBuyFormValidation from './qb/validation'
import MyPagesFormValidation from './mypages/validation'
import { FlowName } from '../../../config'
import { BrandFormValidationInterface } from '../../../form/shared/mypages/validation'

const FormValidation: BrandFormValidationInterface = {
  [FlowName.QUOTE_AND_BUY]: QuoteAndBuyFormValidation,
  [FlowName.MY_PAGES]: MyPagesFormValidation,
}

export default FormValidation
