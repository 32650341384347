import './logger/logger' // should hide logs on production
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import ErrorBoundary from '@dg-shared/ErrorBoundary'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
)
