import React, { ChangeEvent, FC, useContext } from 'react'
import { PolicyV2Type } from '../../types/ApiV2'
import { getPolicyDates } from '@dg-util'
import { MenuItem, Select } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Context } from '@dg-shared/ContextProvider'
import { SELECTED_POLICY_VERSION } from '../../constants'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      maxWidth: theme.spacing(40),
      height: theme.spacing(4),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      ...theme.brand.assets.layout?.policySelect,
    },
  })
)

type PolicyVersionSelectPropsType = {
  policy: Partial<PolicyV2Type>
}

const PolicyVersionSelect: FC<PolicyVersionSelectPropsType> = ({ policy }) => {
  const { setContext } = useContext(Context)
  const changeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    setContext({ [SELECTED_POLICY_VERSION]: event.target.value })
  }
  const classes = useStyles()

  return (
    <Select
      name='policyVersionSelect'
      value={policy.VersionNo}
      onChange={changeHandler}
      IconComponent={KeyboardArrowDown}
      displayEmpty
      variant='outlined'
      className={classes.select}
    >
      {policy.Versions.map((item) => {
        const { dateFrom, dateTo } = getPolicyDates(item as PolicyV2Type)
        return (
          <MenuItem
            key={item.VersionNo}
            value={item.VersionNo}
          >{`${dateFrom} - ${dateTo}`}</MenuItem>
        )
      })}
    </Select>
  )
}

export default PolicyVersionSelect
