import { CTAContext } from '../types/interface/cta.interface'
import { TRANSLATIONS as T } from '../constants/translations-constants'
import BrandConfig from 'BrandConfig'

export const CTA_TYPE_INSTABANK_LINK = 'INSTABANK_LINK'
export const CTA_TYPE_PURCHASED_DONE = 'PURCHASED_DONE'
export const CTA_TYPE_POLICY_DONE = 'POLICY_DONE'
export const CTA_TYPE_TIMELINE = 'TIMELINE'

export const CTA_CONTEXT: CTAContext = {
  INSTABANK_LINK: {
    btnText: `${T.OFFER_PURCHASED}.CTA.link`,
    ctaText: 'CTA.question',
    linkText: 'CTA.link',
    linkPath: BrandConfig.SUPPORT_LINK,
    blank: true,
    returnUrl: '',
  },
  PURCHASED_DONE: {
    btnText: `${T.OFFER_PURCHASED}.CTA.link`,
    ctaText: `${T.OFFER_PURCHASED}.CTA.question`,
    linkText: `${T.OFFER_PURCHASED}.CTA.link`,
    emailText: `${T.OFFER_PURCHASED}.CTA.contactEmail`,
    linkPath: BrandConfig.SUPPORT_LINK,
    blank: true,
    returnUrl: '',
  },
  POLICY_DONE: {
    btnText: `${T.POLICY_DONE}.CTA.link`,
    ctaText: `${T.POLICY_DONE}.CTA.question`,
    linkText: `${T.POLICY_DONE}.CTA.link`,
    emailText: `${T.POLICY_DONE}.CTA.contactEmail`,
    linkPath: BrandConfig.SUPPORT_LINK,
    blank: true,
    returnUrl: '',
  },
  TIMELINE: {
    btnText: `CTA.link`,
    ctaText: `CTA.question`,
    linkText: `CTA.link`,
    linkPath: BrandConfig.SUPPORT_LINK,
    returnUrl: '',
  },
}
