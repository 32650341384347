import { ErrorTypes } from '../types/interface/error.interface'
import { Events } from '../FSM/shared/constants'

export enum ErrorCode {
  'ERROR_CODE_QUOTE_CONSTRAINTS' = 2000,
  'ERROR_CODE_QUOTE_GENERAL' = 1000,
  'ERROR_CODE_QUOTE_VALIDATION' = 1002,
  'ERROR_CODE_BANKID_OUT' = 1003,
  'ERROR_CODE_UNKNOWN_CHARACTERISTICS' = 2001,
  'ERROR_CODE_SESSION_EXPIRED' = 1001,
  'ERROR_CODE_REG_NUM' = 3001,
  'ERROR_CODE_NO_QUOTE' = 20010,
  'ERROR_CODE_AUTH_ACCOUNT_LOCKED' = 10021,
}

export enum ErrorStatus {
  'UNAUTHORIZED' = 401,
  'SESSION_EXPIRED' = 403,
  'LOGIN_TIMEOUT' = 440,
  'PROXY_AUTH_REQUIRED' = 407,
}

export const ERROR_QUOTE_CONSTRAINTS = 'ERROR_QUOTE_CONSTRAINTS'
export const ERROR_QUOTE_VALIDATION = 'ERROR_QUOTE_VALIDATION'

export const AUTH_ERROR_TRANSLATION_KEYS: { [type: string]: string } = {
  [Events.WRONG_PRODUCT_ID]: 'error.regNumNotFound',
  [Events.AUTH_ERROR]: `error.sessionGeneral`,
  [Events.SESSION_EXPIRED]: 'error.sessionExpired',
}

export const ERROR_TYPE: ErrorTypes = {
  [ErrorCode.ERROR_CODE_QUOTE_CONSTRAINTS]: {
    code: ErrorCode.ERROR_CODE_QUOTE_CONSTRAINTS,
    title: 'underwriting.title',
    content: 'underwriting.content',
    linkText: 'underwriting.linkText',
    emailText: 'underwriting.emailText',
    showBtn: true,
    errorCardDesktop: 'errorCardSecondary',
    errorCardMobile: 'errorCardMobileMain',
  },
  [ErrorCode.ERROR_CODE_QUOTE_VALIDATION]: {
    code: ErrorCode.ERROR_CODE_QUOTE_VALIDATION,
    title: 'underwriting.title',
    content: 'underwriting.content',
    linkText: 'underwriting.linkText',
    emailText: 'underwriting.emailText',
    showBtn: true,
    errorCardDesktop: 'errorCardSecondary',
    errorCardMobile: 'errorCardMobileMain',
  },
  [ErrorCode.ERROR_CODE_QUOTE_GENERAL]: {
    code: ErrorCode.ERROR_CODE_QUOTE_GENERAL,
    title: 'title',
    content: 'content',
    linkText: 'CTA.link',
    showBtn: true,
    errorCardDesktop: 'errorCardMain',
    errorCardMobile: 'errorCardMobileMain',
  },
  [ErrorCode.ERROR_CODE_NO_QUOTE]: {
    code: ErrorCode.ERROR_CODE_NO_QUOTE,
    title: 'underwriting.title',
    content: 'underwriting.content',
    linkText: 'underwriting.linkText',
    emailText: 'underwriting.emailText',
    showBtn: true,
    errorCardDesktop: 'errorCardSecondary',
    errorCardMobile: 'errorCardMobileMain',
  },
  [ErrorCode.ERROR_CODE_UNKNOWN_CHARACTERISTICS]: {
    code: ErrorCode.ERROR_CODE_UNKNOWN_CHARACTERISTICS,
    title: 'title',
    content: 'content',
    linkText: 'CTA.link',
    showBtn: true,
    errorCardDesktop: 'errorCardMain',
    errorCardMobile: 'errorCardMobileMain',
  },
  [ErrorCode.ERROR_CODE_SESSION_EXPIRED]: {
    code: ErrorCode.ERROR_CODE_SESSION_EXPIRED,
    title: 'title',
    content: 'content',
    linkText: 'CTA.link',
    showBtn: true,
    errorCardDesktop: 'errorCardMain',
    errorCardMobile: 'errorCardMobileMain',
  },
  [ErrorCode.ERROR_CODE_BANKID_OUT]: {
    code: ErrorCode.ERROR_CODE_BANKID_OUT,
    title: 'bankid.title',
    content: 'bankid.content',
    linkText: 'CTA.link',
    showBtn: true,
    errorCardDesktop: 'errorCardMain',
    errorCardMobile: 'errorCardMobileMain',
  },
  [ErrorCode.ERROR_CODE_REG_NUM]: {
    code: ErrorCode.ERROR_CODE_REG_NUM,
    title: 'title',
    content: 'content',
    linkText: 'CTA.link',
    showBtn: true,
    errorCardDesktop: 'errorCardMain',
    errorCardMobile: 'errorCardMobileMain',
  },
  [ErrorCode.ERROR_CODE_AUTH_ACCOUNT_LOCKED]: {
    code: ErrorCode.ERROR_CODE_AUTH_ACCOUNT_LOCKED,
    title: 'title',
    content: 'content',
    linkText: 'CTA.link',
    showBtn: true,
    errorCardDesktop: 'errorCardMain',
    errorCardMobile: 'errorCardMobileMain',
  },
}
