import { useState, useEffect, MutableRefObject, useContext } from 'react'
import { has } from 'lodash'
import { startOfDay } from 'date-fns'
import { SELECTED_POLICY_NUMBER } from '../../../constants'
import { Context } from '@dg-shared/ContextProvider'

import { COOKIE_KID } from '../../../constants/auth-constants'
import { Events } from '../../../FSM/shared/constants'
import CONFIG, { CountyCode } from '../../../config'
import {
  OfferVerifyFormSubmitData,
  OfferVerifyFormData,
  VerifyFormValues,
  FormState,
} from '../../../types/interface/form.interface'
import { DateState } from '../../../types/interface/dateState.interface'
import { SessionData, SessionDataNoBID } from '../../../types/interface/session.interface'
import { ProductName, PartnerName } from '../../../types/interface/quote.interface'
import { getCookieByName, defineOfferDate, formatPolicyDate, getProductName } from '@dg-util'
import useReactQueryQuote from '../../../hooks/shared/useReactQueryQuote'
import { getSessionStorageAuthData } from '../../../service/util.service'
import { ServiceSend } from '../../../types/interface/fsm.interface'
import { QuoteV2Type } from '../../../types/ApiV2'
import useReactQueryParty from '../../../hooks/shared/useReactQueryParty'

interface HookVerify {
  loading: boolean
  btnDisabled: boolean
  formData: OfferVerifyFormData | false
  KID: string | boolean
}

//TODO: Remove send function when payment for Swedish patrner is integrated.
const useLogic = (send: ServiceSend): HookVerify => {
  const [formValue, setFormValue] = useState<VerifyFormValues>({})
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [quote, setQuote] = useState<Partial<QuoteV2Type> | null>(null)
  const { setContext } = useContext(Context)
  const [date, setDate] = useState<DateState>({
    min: startOfDay(new Date()),
    max: null,
    selected: null,
  })
  const { quote: fetchedQuote, isLoading, acceptQuote, verifyQuote } = useReactQueryQuote()
  const { updateParty, isLoading: isPartyLoading } = useReactQueryParty({
    // Disable get own party to avoid exposing own email and phone before update
    partyQueryEnabled: false,
  })
  const parsedUserInfo: SessionData = getSessionStorageAuthData(CONFIG.USER_INFO_KEY)
  const KID: string | boolean =
    (!!parsedUserInfo && (parsedUserInfo as SessionDataNoBID).kid) || getCookieByName(COOKIE_KID)

  useEffect(() => {
    if (fetchedQuote) {
      setQuote(fetchedQuote)

      defineDate(fetchedQuote)
      // TODO: Clarify whether we can receive previous company just for Vehicle product for NO partner. We don't have this field for SE for sure.
      if (
        fetchedQuote.productName === ProductName.VEHICLE &&
        fetchedQuote.country === CountyCode.NO
      ) {
        defineCompany(fetchedQuote)
      }
      window.scrollTo(0, 0)
    }
  }, [fetchedQuote])

  // Get max, min and selected date acording to product type and partner
  const defineDate = (quote: Partial<QuoteV2Type>, nonDefaultCompany?: number) => {
    const definedDate = defineOfferDate(quote, nonDefaultCompany)
    setDate(definedDate)
    setFormValue((prevState) => ({ ...prevState, policyDate: definedDate.selected }))
  }

  //FYI: Set insuranceCompany value to null if we don't display this field. Correct value would be set on BFF
  const defineCompany = (quote: Partial<QuoteV2Type>): void => {
    if (!quote.displayPrevCompanyField) {
      setFormValue((prevState) => ({
        ...prevState,
        insuranceCompany: null,
      }))
    }
  }

  useEffect(() => {
    if (quote && [ProductName.CONTENT, ProductName.TRAVEL].includes(getProductName())) {
      defineDate(quote, Number(formValue.insuranceCompany))
    }
    // TODO: FIx with using correct deps in [] and useCallback. Left here to not break anything
    // eslint-disable-next-line
  }, [has(formValue, 'insuranceCompany') && formValue.insuranceCompany])

  useEffect(() => {
    if (quote?.PolicyNumber) {
      setContext({ [SELECTED_POLICY_NUMBER]: quote?.PolicyNumber })

      const parsedUserInfo: SessionData = getSessionStorageAuthData(CONFIG.USER_INFO_KEY)
      const isAgent = !!parsedUserInfo.agentId

      // Skip payment for Agents
      if (isAgent) {
        send(Events.PAYMENT_SUCCESS)
      }

      // Skip payment for the CircleK
      if (PARTNER_NAME === PartnerName.circlek) {
        send(Events.NEXT)
      }
    }
  }, [quote, setContext, send])

  const changeHandler = ({ values }: FormState<never>): void => setFormValue(values)

  const handleSubmit = async (
    form: MutableRefObject<HTMLFormElement | null>,
    values: OfferVerifyFormSubmitData
  ) => {
    const PreviousCompanyId = values.insuranceCompany
    const InsuredFrom = formatPolicyDate(values.policyDate)

    setLoading(true)

    await updateParty({
      Email: values.email,
      MobilePhone: values.phone,
    })

    // Verify Quote
    await verifyQuote({
      PreviousCompanyId,
      InsuredFrom,
    })

    // Accept Quote before the payment
    await acceptQuote()

    // Check if user is an Agent
    const parsedUserInfo: SessionData = getSessionStorageAuthData(CONFIG.USER_INFO_KEY)
    const isAgent = !!parsedUserInfo.agentId

    // Skip payment for Agents and CircleK
    if (isAgent || PARTNER_NAME === PartnerName.circlek) {
      return
    }

    // Timeout required for a complete Quote cache update before the payment
    // Cache used for the Accept page - avoids extra requests
    setTimeout(() => {
      setLoading(false)
      form.current?.submit()
      setLoading(true)
    }, 2000)
  }

  const formData = !!quote && {
    quote,
    handleSubmit,
    changeHandler,
    date,
    formValue,
    handleBtnDisabled: setBtnDisabled,
  }

  return {
    loading: loading || isLoading || isPartyLoading,
    KID,
    formData,
    btnDisabled,
  }
}
export default useLogic
