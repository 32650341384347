import { FSMCommonTransitions } from '../FSM/shared/constants'

export const AUTH = '/auth' //Used in BackButtonShared component
export const AUTH_REGULAR = '/auth/regular'
export const OFFER_VERIFY = '/verify'
export const SIGN_IN_BANK_ID = '/authBankId'
export const CLAIMS = '/claims'
export const FNOL_WINDSHIELD = '/fnol/windshield'
export const FNOL_COLLISION = '/fnol/collision'
export const FNOL_ROADSIDE_ASSISTANCE = '/fnol/roadside-assistance'
export const FNOL_OTHER = '/fnol/other'

export const AUTH_CHOICE = `/${FSMCommonTransitions.AUTH_CHOICE}`
export const AUTH_AGENT = `/${FSMCommonTransitions.AUTH_AGENT}`
export const REGULAR = `/${FSMCommonTransitions.AUTH_REGULAR}`
export const DONE = `/${FSMCommonTransitions.QB_PURCHASED_DONE}`
export const MILEAGE = `/${FSMCommonTransitions.QB_PURCHASED_MILEAGE}`
export const ERROR = `/${FSMCommonTransitions.ERROR}`
export const FAILURE = `/${FSMCommonTransitions.QB_PURCHASED_FAILURE}`

export const POLICY_DONE = `/${FSMCommonTransitions.POLICY_DONE}`

export enum FlowType {
  qb,
  mypages,
}
