import { Events, FSMCommonTransitions } from '../../constants'
import { ERROR_QUOTE_CONSTRAINTS, ERROR_QUOTE_VALIDATION } from '../../../../constants'
import { BaseActionObject, StatesConfig } from 'xstate'
import { ContextType, StateSchemaType } from '../../config'

const { AUTH_BANK_ID, AUTH, AUTH_BANK_ID_START, POLICIES, ERROR } = FSMCommonTransitions

export default (
  FLOW_MACHINE_ID: string
): StatesConfig<ContextType, StateSchemaType, BaseActionObject> => ({
  [AUTH_BANK_ID]: {
    initial: AUTH_BANK_ID_START,
    states: {
      [AUTH_BANK_ID_START]: {
        on: {
          [Events.CUSTOM_AUTH_SUCCESS]: {
            target: `#${FLOW_MACHINE_ID}.${POLICIES}`,
            actions: ['setUserAuthorized', 'setBankIdAuth'],
          },
          [Events.CUSTOM_AUTH_FAILURE]: {
            target: `#${FLOW_MACHINE_ID}.${ERROR}`,
          },
          [Events.CUSTOM_AUTH_START]: {
            target: `#${FLOW_MACHINE_ID}.${AUTH_BANK_ID}`,
          },
        },
      },
    },
  },
  [ERROR]: {
    on: {
      [Events.BACK]: [
        {
          target: `${AUTH}`,
          actions: ['signOut', 'switchFlow'],
          cond: (ctx, type, state) => {
            const errorType = state.state.event?.errorType

            return [ERROR_QUOTE_CONSTRAINTS, ERROR_QUOTE_VALIDATION].includes(errorType)
          },
        },
        {
          target: `${AUTH}`,
          actions: ['signOut', 'switchFlow'],
          cond: (ctx) => {
            const backPath = ctx.backPath as Record<string, string>

            return !!backPath.bankId
          },
        },
      ],
    },
  },
})
