import React, { FC, useEffect } from 'react'
import { Typography, FNOLButton, FNOLPageWrapper } from '@dg-shared'
import { useTranslate, useClaim } from '../../../hooks/shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import { FLOW_THEFT_QUESTIONS, TRANSLATIONS as T } from '../../../constants'
import { useFNOLStyles } from '../shared/styles/useStyles'

const PageStolenInCar: FC<CommonPageProps> = ({ service, currentState }) => {
  const { t } = useTranslate(`${T.FNOL_THEFT}.stolenInCar`)
  const FNOLClasses = useFNOLStyles({ externalLink: true })
  const { handleAnswerClick, clearAnswer } = useClaim(service, currentState)

  useEffect(() => {
    clearAnswer(FLOW_THEFT_QUESTIONS.WHAT_WAS_STOLEN)
  }, [clearAnswer])

  const itemSelect = ({ answer, label }: { answer: number; label: string }) => (
    <FNOLButton
      testId={label}
      key={label}
      handleClick={() =>
        handleAnswerClick(FLOW_THEFT_QUESTIONS.WHAT_WAS_STOLEN, answer, Events.NEXT)
      }
      buttonText={t(label)}
    />
  )

  const eventItems = [
    { label: 'button.mobilePhone', answer: 1 },
    { label: 'button.stereo', answer: 2 },
    { label: 'button.bag', answer: 3 },
    { label: 'button.other', answer: 4 },
  ]

  const ItemsList = () => <>{eventItems.map((item) => itemSelect(item))}</>

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t('back')} breadcrumbsStepPercentage={60}>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <Typography testId='subtitle' className={FNOLClasses.description}>
        {t('subtitle')}
      </Typography>
      <ItemsList />
    </FNOLPageWrapper>
  )
}

export default PageStolenInCar
