// This service responsible for the temporary messages management
// Useful for temporary messages that is impossible to handle after cleanup and page reload.
// Example: error message you want to show on the auth page after signOut and cleanup
const MESSAGES_QUEUE_SESSION_KEY = 'MESSAGES_QUEUE'

export type MessageType = {
  id: string
  message: string | Record<string, unknown>
}

type MessagesQueueType = {
  messages: MessageType[]
  updateMessagesStorage: (messages: MessageType[]) => void
  setMessage: (message: MessageType) => void
  getMessage: (id: string) => MessageType | undefined
}

const sessionMessages = sessionStorage.getItem(MESSAGES_QUEUE_SESSION_KEY)
const messages: MessageType[] = sessionMessages ? JSON.parse(sessionMessages) : []

const messagesQueue: MessagesQueueType = {
  messages,
  updateMessagesStorage: function (messages: MessageType[]) {
    sessionStorage.setItem(MESSAGES_QUEUE_SESSION_KEY, JSON.stringify(messages))
  },
  setMessage: function (message: MessageType) {
    this.messages.push(message)
    this.updateMessagesStorage(this.messages)
  },

  // Returns the message and delete it if found
  getMessage: function (id: string): MessageType | undefined {
    const message: MessageType = this.messages.find((item: MessageType) => item.id === id)

    if (message) {
      // remove the message from the messages queue
      this.messages = this.messages.filter((item: MessageType) => item.id !== id)
      this.updateMessagesStorage(this.messages)
    }

    return message
  },
}

export default messagesQueue
