import React, { FC, useEffect } from 'react'
import { Typography, FNOLPageWrapper, FNOLButton } from '@dg-shared'
import { useTranslate, useClaim } from '../../../hooks/shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { Events } from '../../../FSM/shared/constants'
import {
  TRANSLATIONS as T,
  FLOW_THEFT_QUESTIONS,
  FLOW_THEFT_CLAIM_TYPE_IDS,
} from '../../../constants'
import { useFNOLStyles } from '../shared/styles/useStyles'

const PageMoreInfo: FC<CommonPageProps> = ({ service, currentState }) => {
  const { t } = useTranslate(`${T.FNOL_THEFT}.moreInfo`)
  const FNOLClasses = useFNOLStyles({ externalLink: true })
  const { handleAnswerClick, clearAnswer } = useClaim(service, currentState)

  useEffect(() => {
    clearAnswer(FLOW_THEFT_QUESTIONS.WHAT_HAS_HAPPENED)
  }, [clearAnswer])

  const itemSelect = ({ event, label }: { event: Events; label: string }) => (
    <FNOLButton
      testId={label}
      key={label}
      handleClick={() =>
        handleAnswerClick(
          FLOW_THEFT_QUESTIONS.WHAT_HAS_HAPPENED,
          FLOW_THEFT_CLAIM_TYPE_IDS[event],
          event
        )
      }
      buttonText={t(label)}
    />
  )

  const eventItems = [
    { event: Events.FNOL_THEFT_STOLEN_CAR, label: 'button.carStolen' },
    { event: Events.FNOL_THEFT_STOLEN_IN_CAR, label: 'button.stolenInCar' },
    { event: Events.FNOL_THEFT_STOLEN_FROM_CAR, label: 'button.stolenFromCar' },
  ]

  const ItemsList = () => <>{eventItems.map((item) => itemSelect(item))}</>

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t('back')} breadcrumbsStepPercentage={50}>
      <Typography testId='title' type='h1' className={FNOLClasses.title}>
        {t('title')}
      </Typography>
      <Typography testId='subtitle' className={FNOLClasses.description}>
        {t('subtitle')}
      </Typography>
      <ItemsList />
    </FNOLPageWrapper>
  )
}

export default PageMoreInfo
