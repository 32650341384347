import { createTheme, Theme, ThemeOptions } from '@material-ui/core'
import { ThemeBrandAssetsType, ThemeBrandColorsType, ThemeBrandTypographyType } from './types'
import { defaultAssets, defaultColors, defaultTypography } from './defaultTheme'
import { ProductName } from '../../types/interface/quote.interface'

type BrandThemeConfig = Partial<
  Pick<BrandTheme, 'colors' | 'typography' | 'assets' | 'overrides' | 'productName'>
>

class BrandTheme {
  productName: ProductName = ProductName.VEHICLE
  colors: ThemeBrandColorsType = defaultColors
  typography: ThemeBrandTypographyType = defaultTypography
  assets: ThemeBrandAssetsType = defaultAssets
  overrides: Record<string, unknown>
  themeProps: ThemeOptions['props'] = {}
  theme: Theme
  // Used to reinitialize the whole theme through the setup
  initCallback: () => BrandTheme
  // Used to update theme state after theme creation
  createThemeCallback: (theme: Theme) => void = () => {
    /** noop */
  }

  constructor() {
    this.createTheme()
  }

  setCreateThemeCallback(createThemeCallback: (theme: Theme) => void) {
    this.createThemeCallback = createThemeCallback

    return this
  }

  setup(config: BrandThemeConfig) {
    const {
      productName = this.productName,
      colors = this.colors,
      typography = this.typography,
      assets = this.assets,
      overrides = this.assets,
    } = config
    this.productName = productName
    this.colors = colors
    this.typography = typography
    this.assets = assets
    this.overrides = overrides

    this.createTheme()

    return this
  }

  createTheme() {
    this.theme = createTheme(
      this.getBrandedTheme(this.colors, this.typography, this.assets, this.overrides)
    )

    this.createThemeCallback(this.theme)
  }

  init(initCallback: () => BrandTheme): BrandTheme {
    this.initCallback = initCallback

    return this.initCallback()
  }

  getBrandedTheme(
    brandColors: ThemeBrandColorsType = this.colors,
    brandTypography: ThemeBrandTypographyType = this.typography,
    brandAssets: ThemeBrandAssetsType = this.assets,
    brandOverrides?: Record<string, unknown>
  ): ThemeOptions {
    return {
      breakpoints: {
        values: {
          xs: 0,
          sm: 321,
          md: 435,
          lg: 1025,
          xl: 1920,
        },
      },
      // Default palette mapped with Brand Related Theme Options
      palette: {
        primary: {
          main: brandColors.main,
          contrastText: brandColors.background,
          dark: brandColors.textMain,
          light: brandColors.background,
        },
        secondary: {
          main: brandColors.headingMain,
          dark: brandColors.textSecondary,
          contrastText: brandColors.clean,
          light: brandColors.clean,
        },
        info: {
          main: brandColors.textPlaceholder,
          contrastText: brandColors.textDisclaimer,
          dark: brandColors.textSecondary,
          light: brandColors.textDisclaimer,
        },
        error: {
          main: brandColors.textError,
          contrastText: brandColors.textDisclaimer,
          dark: brandColors.textSecondary,
          light: brandColors.textDisclaimer,
        },
        success: {
          main: brandColors.main,
          contrastText: brandColors.background,
          dark: brandColors.main,
          light: brandColors.background,
        },
        warning: {
          main: brandColors.textError,
          contrastText: brandColors.textDisclaimer,
          dark: brandColors.textSecondary,
          light: brandColors.textDisclaimer,
        },
      },
      // Brand Related Theme Options
      brand: {
        colors: brandColors,
        typography: brandTypography,
        assets: brandAssets,
      },
      typography: {
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          'Segoe UI',
          'Roboto',
          'Oxygen',
          'Ubuntu',
          'Cantarell',
          'Fira Sans',
          'Droid Sans',
          'Helvetica Neue',
          'sans-serif',
          ...brandTypography.extraFonts,
        ].join(','),
        h1: brandTypography.heading.h1,
        h2: brandTypography.heading.h2,
        h3: brandTypography.heading.h3,
        h4: brandTypography.heading.h4,
        h5: brandTypography.heading.h5,
        h6: brandTypography.heading.h6,
        body1: {
          color: brandColors.textMain,
          ...brandTypography.text.normal,
        },
        body2: {
          color: brandColors.textSecondary,
          ...brandTypography.text.small,
        },
        caption: {
          color: brandColors.textDisclaimer,
          ...brandTypography.text.small,
        },
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            '@font-face': brandTypography.customFontFaces,
            code: {
              fontFamily: [
                'source-code-pro',
                'Menlo, Monaco',
                'Consolas',
                'Courier New',
                'monospace',
                ...brandTypography.extraFonts,
              ].join(','),
            },
          },
        },
        MuiCard: {
          root: {
            borderRadius: '8px',
            boxShadow: '0px 6px 15px rgba(13, 51, 32, 0.1)',
          },
        },
        MuiButton: {
          root: {
            height: 48,
            borderRadius: '8px',
            padding: '10px',
            '&:hover': {
              color: brandColors.clean,
              backgroundColor: brandColors.buttonActive,
              opacity: 0.75,
            },
          },
          contained: {
            '&:hover': {
              color: brandColors.clean,
              backgroundColor: brandColors.buttonActive,
              opacity: 0.75,
              '& .MuiSvgIcon-root': {
                color: brandColors.clean,
              },
              '@media (hover: none)': {
                backgroundColor: brandColors.buttonActive,
              },
            },
          },
        },
        MuiOutlinedInput: {
          root: {
            border: 0,
            borderRadius: '8px',
            boxShadow: '0px 6px 20px rgba(13, 51, 32, 0.1)',
          },
          notchedOutline: {
            border: 0,
          },
          focused: {},
        },
        MuiInputLabel: {
          shrink: {
            transform: 'translate(0, 1.5px) scale(1)',
          },
        },
        MuiInputBase: {
          root: {
            fontSize: '16px',
          },
          input: {
            '&:invalid': {
              color: brandColors.textError,
            },
          },
        },
        MuiSelect: {
          select: {
            '&:focus': {
              backgroundColor: 'transparent',
            },
          },
        },
        MuiMenuItem: {
          root: {
            color: brandColors.textMain,
            fontSize: '1rem',
          },
        },
        MuiRadio: {
          root: {
            color: brandColors.main,
            '&$checked': {
              color: brandColors.main,
            },
            '&$checked + $label': {
              backgroundColor: 'yellow',
            },
          },
          colorSecondary: {
            color: brandColors.main,
            '&$checked': {
              color: brandColors.main,
            },
          },
          checked: {},
        },
        MuiSwitch: {
          colorSecondary: {
            '&$checked': {
              color: brandColors.main,
            },
            '&$checked + $track': {
              backgroundColor: brandColors.main,
            },
          },
          checked: {},
          track: {},
        },
        MuiFormLabel: {
          root: {
            color: brandColors.textSecondary,
            '&$focused': {
              color: brandColors.textSecondary,
            },
          },
        },
        ...brandOverrides,
      },
      props: this.themeProps,
    }
  }
}

export default new BrandTheme()
