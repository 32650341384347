import {
  FNOLOther,
  FNOLOtherLocationAndTime,
  FNOLAdditionalInformation,
  FNOLTimeline,
  FNOLOtherLegalAmount,
  FNOLOtherFireCause,
  FNOLOtherLegalDate,
} from '../../../../pages/FNOL'

import { FSMCommonTransitions } from '../../constants'

const {
  FNOL_OTHER_FLOW,
  FNOL_OTHER_START,
  FNOL_OTHER_FIRE_CAUSE,
  FNOL_OTHER_DAMAGE_LOCATION_AND_TIME,
  FNOL_OTHER_TIMELINE,
  FNOL_OTHER_LEGAL_DATE,
  FNOL_OTHER_LEGAL_AMOUNT,
  FNOL_ADDITIONAL_INFORMATION,
} = FSMCommonTransitions

export default [
  {
    is: { [FNOL_OTHER_FLOW]: FNOL_OTHER_START },
    page: FNOLOther,
  },
  {
    is: { [FNOL_OTHER_FLOW]: FNOL_OTHER_DAMAGE_LOCATION_AND_TIME },
    page: FNOLOtherLocationAndTime,
  },
  {
    is: { [FNOL_OTHER_FLOW]: FNOL_ADDITIONAL_INFORMATION },
    page: FNOLAdditionalInformation,
  },
  {
    is: { [FNOL_OTHER_FLOW]: FNOL_OTHER_TIMELINE },
    page: FNOLTimeline,
  },
  {
    is: { [FNOL_OTHER_FLOW]: FNOL_OTHER_LEGAL_DATE },
    page: FNOLOtherLegalDate,
  },
  {
    is: { [FNOL_OTHER_FLOW]: FNOL_OTHER_LEGAL_AMOUNT },
    page: FNOLOtherLegalAmount,
  },
  {
    is: { [FNOL_OTHER_FLOW]: FNOL_OTHER_FIRE_CAUSE },
    page: FNOLOtherFireCause,
  },
]
