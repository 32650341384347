export const ERROR_TYPES = {
  bankidError: {
    type: 'bankid',
    title: 'En feil oppstod',
    text: 'Det tok dessverre for lang tid å fullføre identifiseringen. Prøv gjerne på nytt.',
  },
  connectionError: {
    type: 'connection',
    title: 'En feil oppstod',
    text: 'Vi fikk dessverre ikke kontakt med ditt forsikringsselskap akkura nå. Forsøk gjerne igjen senere.',
  },
  userInput: {
    type: 'userInput',
    title: 'Godkjenning mangler dessverre',
    text: `Det ser dessverre ut som at du må godkjenne at vi henter dine forsikringsdata hos ditt selskap.`,
  },
  noInsuranceFound: {
    type: 'noInsuranceFound',
    title: 'Vi fant ingen forsikring.',
    text: `Vi fant dessverre ikke noen forsikring på ditt fødselsnummer hos dette selskapet.`,
  },
}

export const STATUSES_PROCESSING = ['LOGIN', 'RUNNING', 'COLLECTING']
export const COLLECTING = 'COLLECTING'
export const STATUSES_FAILURE = ['TIMEOUT', 'FAILED']
export const IS_COMPLETE = 'COMPLETE'
export const IS_USER_INPUT = 'USER INPUT'
export const IS_COMPLETED_EMPTY = 'COMPLETED EMPTY'
export const IS_COMPLETED_PARTIAL = 'COMPLETED PARTIAL'

export const BAD_REQUEST_ERROR_CODE = 11000

export const INSURELY_RESULTS = 'insurelyResults'
export const SELECTED_INSURELY_QUOTE = 'selectedInsurelyQuote'
export const INSURELY_COLLECTION_ID = 'insurelyCollectionId'
