import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import noTranslation from '../public/assets/i18n/no-NO/translation.json'
import seTranslation from '../public/assets/i18n/sv-SE/translation.json'

// import { getFlowName } from './util'
import BrandConfig from 'BrandConfig'
// const flowName = getFlowName()

// eslint-disable-next-line import/no-named-as-default-member
i18n
  //.use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      resources: {
        'no-NO': {
          translation: noTranslation,
        },
        'sv-SE': {
          translation: seTranslation,
        },
      },
      lng: BrandConfig.TRANSLATION_LANGUAGES[0],
      supportedLngs: BrandConfig.TRANSLATION_LANGUAGES,
      fallbackLng: false,
      debug: false,
      returnObjects: true,
      load: 'currentOnly',
      keySeparator: '.',
      interpolation: {
        escapeValue: false,
        formatSeparator: ',',
      },
      react: {
        useSuspense: false,
      },
    },
    (error, t) => {
      if (error) return console.error('something went wrong loading', error)
      t('key') // -> same as i18next.t
    }
  )

export default i18n
