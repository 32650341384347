import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { IS_ACCEPTANCE, IS_PRODUCTION } from '../../config'
import { Button } from '@material-ui/core'
import { Events } from '../../FSM/shared/constants'
import { FSMService } from '../../FSM'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnStandards: {
      position: 'absolute',
      top: '20px',
      left: '500px',
      color: theme.palette.secondary.contrastText,
    },
  })
)

// Navigates to Playground page on LOCAL and DEV environments
const PlaygroundButton = () => {
  const classes = useStyles()

  return !IS_PRODUCTION && !IS_ACCEPTANCE ? (
    <Button
      className={classes.btnStandards}
      onClick={() => FSMService.flowService.send(Events.PLAYGROUND)}
    >
      Playground
    </Button>
  ) : null
}

export default PlaygroundButton
