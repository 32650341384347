import React, { FC } from 'react'

import { TRANSLATIONS as T } from '../../../constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import WhenWhereForm from '../../../components/Claims/WhenWhereForm'
import { Typography, FNOLPageWrapper } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'

const PageLocationAndTime: FC<CommonPageProps> = ({ service, currentState }) => {
  const { t } = useTranslate(T.FNOL_OTHER_WHEN_WHERE)

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t(`back`)} breadcrumbsStepPercentage={70}>
      <Typography type='h1'>{t('title')}</Typography>
      <Typography>{t('subtitle')}</Typography>
      <WhenWhereForm currentState={currentState} service={service} />
    </FNOLPageWrapper>
  )
}

export default PageLocationAndTime
