import { Grid, Hidden } from '@material-ui/core'
import React, { FC, useEffect } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import LandingHeaderMenu from '@dg-shared/landing/LandingHeaderMenu'
import { FSMService } from '../../FSM'
import FSMBtnNext from './FSMBtnNext'
import { Events } from '../../FSM/shared/constants'
import AcceptCookies from './AcceptCookies'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageWrapper: {
      backgroundColor: theme.brand.colors.background,
    },
    headerWrapper: {
      height: 80,
      top: 0,
      position: 'fixed',
      zIndex: 10,
      width: '100%',
      display: 'flex',
      backgroundColor: theme.palette.secondary.contrastText,
      boxShadow: '0px 6px 15px rgba(13, 51, 32, 0.1)',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      borderRadius: '0 0 10px 10px',
      [theme.breakpoints.down('md')]: {
        height: 'auto',
        paddingBottom: 10,
        display: 'grid',
        borderRadius: 0,
      },
    },
    container: {
      minHeight: '100vh',
      justifyContent: 'center',
      alignContent: 'start',
      position: 'relative',
      backgroundColor: theme.brand.colors.background,
      marginTop: 80,
      [theme.breakpoints.down('sm')]: {
        marginTop: 110,
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        paddingBottom: theme.spacing(5),
      },
    },
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        maxWidth: '968px',
        minWidth: 340,
        padding: theme.spacing(3),
      },
    },
    ctaOverride: {
      height: 40,
      ...theme.brand.assets.layout?.ctaOverride,
    },
    header: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      height: 'auto',
      justifyContent: 'space-between',
      zIndex: 10,
    },
    mobileContacts: {
      display: 'flex',
      justifyContent: 'end',
      gap: theme.spacing(1.5),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    logoDesktop: theme.brand.assets.images.logoDesktopHeader,
    logoMobile: theme.brand.assets.images.logoMobileHeader,
  })
)

const LandingPageWrapper: FC<{ children: React.ReactNode; 'data-componentname': string }> = ({
  children,
  'data-componentname': dataComponentName,
}) => {
  const classes = useStyles()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className={classes.pageWrapper} data-componentname={dataComponentName}>
        <Grid item xs={12} className={classes.headerWrapper}>
          <div className={classes.header}>
            {/*Desktop Logo*/}
            <Hidden mdUp={false} smDown={true}>
              <Grid item xs={3} className={classes.logoDesktop} />
            </Hidden>
            {/*Mobile Logo*/}
            <Hidden mdUp={true} smDown={false}>
              <Grid item xs={6} className={classes.logoMobile} />
            </Hidden>
            <LandingHeaderMenu />
          </div>
          <div className={classes.mobileContacts}>
            <FSMBtnNext
              buttonText='Beregn din pris på 1 min'
              handleClick={() => {
                FSMService.flowService.send(Events.AUTH)
              }}
              customClassName={`${classes.ctaOverride}`}
            />
          </div>
        </Grid>
        <Grid container component='article' className={classes.container}>
          <Grid item xs={12} className={classes.bodyContainer}>
            {children}
          </Grid>
        </Grid>
      </div>
      <AcceptCookies />
    </>
  )
}

export default LandingPageWrapper
