import { includes, isObject, isNil, get } from 'lodash'
import { MachineConfigType } from '../FSM/shared/config'
import { StateValue } from 'xstate'

export default ({ states }: MachineConfigType, curState: StateValue): string => {
  if (isNil(states) || isNil(curState)) return 'back'

  // if state is object, e.g. {ownership: review}, get its value
  const stateEventKey = isObject(curState) && Object.values(curState)[0]
  // if state is object, e.g. {ownership: review}, get its key
  const stateKey = isObject(curState) ? Object.keys(curState)[0] : curState
  // find by key state object in config, get all its possible states, find there current state with all its transitions
  const currentConfig = isObject(curState)
    ? states[stateKey]?.states[stateEventKey as string]
    : states[stateKey]

  // event target could be either an object's property (back: {target: 'auth'}) or simple string (back: 'auth')
  const backTargetPath =
    get(currentConfig, ['on', 'BACK', 'target']) || get(currentConfig, ['on', 'BACK'])

  // if back event for current state aims to auth, show logout text, otherwise - back
  return includes(backTargetPath, 'auth') ? 'logout' : 'back'
}
