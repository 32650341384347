import React, { FC, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { AUTH_PORTAL_REQUEST_REDIRECT_KEY, Events } from '../../../FSM/shared/constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { getCookieByName, getDataComponentName, reactQueryErrorHandler, redirect } from '@dg-util'
import { logoMax, logoMin } from 'BrandedImages'
import StandardsButton from '@dg-shared/StandardsButton'
import FNOLDevButton from '@dg-shared/FNOLDevButton'
import PlaygroundButton from '@dg-shared/PlaygroundButton'
import { FSMBtnNext, LDCardTwoCol, LMBgHeader, withHidden } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import { ERROR_TYPE, ErrorCode, TRANSLATIONS as T } from '../../../constants'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import CONFIG, { FlowName, IS_LOCAL } from '../../../config'
import LoadingPage from '../../qb/Loading/Page'
import { useLocation } from 'react-router-dom'
import history from '../../../routes/history'
import apiV2 from '../../../service/apiV2.service'
import { FSMService } from '../../../FSM'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttons: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    nextBtn: {
      width: '230px',
      height: '48px',
      margin: '0 auto',
      ...theme.brand.assets.layout?.nextBtn,
    },
  })
)

const MobileScreen = withHidden(LMBgHeader, true)
const DesktopScreen = withHidden(LDCardTwoCol, false)

const Page: FC<CommonPageProps> = ({ service }) => {
  const classes = useStyles()
  const { t } = useTranslate(T.AUTH)
  const [isLoading, setIsLoading] = useState(false)
  const { pathname } = useLocation()

  const authPortalUri = `${CONFIG.AUTH_PORTAL_HOST}/?partner=${PARTNER_NAME}${
    // Add local env query param for handling local redirects
    IS_LOCAL ? '&env=local' : ''
  }`

  // FYI: We receive one-time token from BankID which we use to get i2i's token.
  const setTokenInSession = async (sessionBankId: string) => {
    const { token } = JSON.parse(sessionBankId)

    await sessionStorage.setItem(
      CONFIG.USER_INFO_KEY_BID,
      JSON.stringify({
        token,
      })
    )
  }

  const getTokenFromCookie = async (bankIdParams: string) => {
    let getSessionBankId: string = getCookieByName('sessionBankId')
    // NB: We don't have cookie on DEV&local because user is redirected to UI from bankID(only on DEV&local).

    if (isEmpty(getSessionBankId)) {
      // NB: We need to send a login request to BFF the same as redirect from i2i on ACC&PROD envs
      try {
        await apiV2.getSessBankId(bankIdParams)
      } catch (error) {
        reactQueryErrorHandler(error, FSMService.flowService)
      }

      getSessionBankId = getCookieByName('sessionBankId')
    }

    return getSessionBankId
  }

  const signIn = async () => {
    const bankIdParams = pathname.split('/').splice(2, 3).join('/')
    const token = await getTokenFromCookie(bankIdParams)

    if (!token) {
      setIsLoading(false)
      history.push('/')
      return service.send(Events.CUSTOM_AUTH_FAILURE)
    }

    await setTokenInSession(token)

    setIsLoading(false)
    history.push('/')
    service.send(Events.CUSTOM_AUTH_SUCCESS)
  }

  useEffect(() => {
    setIsLoading(true)

    // Check if auth portal redirect requested for automatic redirect
    if (!sessionStorage.getItem(AUTH_PORTAL_REQUEST_REDIRECT_KEY)) {
      // Set request redirect to auth portal
      sessionStorage.setItem(AUTH_PORTAL_REQUEST_REDIRECT_KEY, '1')

      // Redirect to Auth Portal
      redirect(authPortalUri)
    } else {
      const isSuccessfulAuthentication = pathname?.search(/success/) > 0
      const isBankIdOutError = pathname?.includes('/out')

      // Handling BankID OUT parameter
      if (isBankIdOutError) {
        history.push('/')
        service.send({
          type: Events.ERROR,
          errorType: ERROR_TYPE[ErrorCode.ERROR_CODE_BANKID_OUT],
          state: service.state.value,
        })
      } else if (isSuccessfulAuthentication) {
        ;(async () => {
          await signIn()
        })()
      } else {
        // Navigate to QB auth page if nothing happens for a 3 seconds (unsuccessful redirect from the BankId)
        const timeoutErrorHandler = setTimeout(() => {
          // Remove redirect requesting
          sessionStorage.removeItem(AUTH_PORTAL_REQUEST_REDIRECT_KEY)

          redirect(`/?flow=${FlowName.QUOTE_AND_BUY}`)
        }, 3000)

        return () => {
          clearTimeout(timeoutErrorHandler)
        }
      }
    }
    // eslint-disable-next-line
  }, [])

  const Buttons = () => (
    <div className={classes.buttons} data-componentname={getDataComponentName(__filename)}>
      <FSMBtnNext
        testId='button-next'
        handleClick={() => {
          service.send(Events.NEXT)
          // Redirect to Auth Portal
          redirect(authPortalUri)
        }}
        buttonText={t('login')}
        customClassName={classes.nextBtn}
        outlined
      />
    </div>
  )

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <>
      <MobileScreen
        headerTitle={t('title')}
        headerSubTitle={t('subtitle')}
        textLogoAlt={t('title')}
        imageLogo={logoMin}
        brandPageBackground='mainMobile'
      >
        <Buttons />
      </MobileScreen>

      <DesktopScreen
        imageLogo={logoMax}
        textLogoAlt={t('title')}
        brandPageBackground='main'
        brandPageBackgroundCard='mainCard'
      >
        <StandardsButton />
        <FNOLDevButton />
        <PlaygroundButton />
        <Buttons />
      </DesktopScreen>
    </>
  )
}

export default Page
