import React, { FC, useContext } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import { TRANSLATIONS as T } from '../../../../constants'
import { PartnerName, ProductName } from '../../../../types/interface/quote.interface'
import { Typography, ProductWrapper } from '@dg-shared'
import { useTranslate } from '../../../../hooks/shared'
import ComparePolicyCard from './ComparePolicyCard'
import { InsurelyResultType } from '../../../../types/insurelyTypes'
import { Context } from '@dg-shared/ContextProvider'
import { INSURELY_RESULTS, SELECTED_INSURELY_QUOTE } from '../../../../constants/insurely-constants'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: 'auto',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(5),
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '345px',
      },
    },
    disclaimer: {
      alignSelf: 'flex-end',
    },
  })
)

const OfferCompareOffers: FC = () => {
  const { t } = useTranslate(T.OFFER_COMPARE_POLICY)
  const classes = useStyles()
  const { context, setContext } = useContext(Context)
  const insurelyResults = context[INSURELY_RESULTS] as InsurelyResultType[]
  const selectOffer = (selectedInsurelyQuote: InsurelyResultType) => {
    setContext({
      [SELECTED_INSURELY_QUOTE]: selectedInsurelyQuote,
    })
  }

  return (
    <>
      <Grid container className={classes.wrapper}>
        {insurelyResults?.map((offer: InsurelyResultType) => {
          const renewalDate = new Date(offer.insurance.renewalDate)
          if (renewalDate > new Date()) {
            return (
              <ComparePolicyCard
                item={offer}
                key={`card_price_${offer.insurance.externalId}`}
                chooseOffer={() => selectOffer(offer)}
              />
            )
          }
        })}

        <ProductWrapper
          partners={[
            PartnerName.instabank,
            PartnerName.volkswagen,
            PartnerName.skoda,
            PartnerName.audi,
            PartnerName.nord,
          ]}
          products={[ProductName.VEHICLE]}
        >
          <Typography className={classes.disclaimer} color='textDisclaimer' size='small'>
            {t(`priceWarning`)}
          </Typography>
        </ProductWrapper>
      </Grid>
    </>
  )
}

export default OfferCompareOffers
