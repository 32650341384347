import {
  PagePoliciesList,
  PagePolicyReview,
  PagePolicyVerify,
  PagePolicyChange,
  PagePolicyDone,
} from '../../../../pages/mypages'
import { FSMCommonTransitions } from '../../constants'

const { POLICIES, POLICIES_LIST, POLICY_REVIEW, POLICY_VERIFY, POLICY_CHANGE, POLICY_DONE } =
  FSMCommonTransitions

export default [
  {
    is: { [POLICIES]: POLICIES_LIST },
    page: PagePoliciesList,
  },
  {
    is: { [POLICIES]: POLICY_REVIEW },
    page: PagePolicyReview,
  },
  {
    is: { [POLICIES]: POLICY_VERIFY },
    page: PagePolicyVerify,
  },
  {
    is: { [POLICIES]: POLICY_CHANGE },
    page: PagePolicyChange,
  },
  {
    is: { [POLICIES]: POLICY_DONE },
    page: PagePolicyDone,
  },
]
