import React, { FC } from 'react'
import { Card, CardActionArea, Tooltip } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import CloudDownload from '@material-ui/icons/CloudDownload'

import { DocumentCardProps } from '../../types/interface/policy.interface'
import { Typography } from '@dg-shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: 'flex',
      textAlign: 'left',
      borderRadius: '3px',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.down('sm')]: {
        '&:first-child': {
          marginTop: theme.spacing(1),
        },
      },
      '& svg': {
        ...theme.brand.assets.layout?.ctaAnnotation,
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        '& p, svg': {
          color: theme.palette.primary.contrastText,
          ...theme.brand.typography.overrides?.iconColor,
        },
      },
    },
    cardContent: {
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    },
    icon: {
      color: theme.palette.primary.main,
      marginRight: theme.spacing(1.5),
    },
    downloadIcon: {
      color: theme.palette.primary.main,
      position: 'absolute',
      right: '0',
    },
    text: {
      marginBottom: '0',
    },
    footer: {
      display: 'flex',
      marginTop: theme.spacing(2),
    },
  })
)

const PolicyDocumentCard: FC<DocumentCardProps> = ({ data, docType, openFile }) => {
  const classes = useStyles()

  return (
    <Card
      className={classes.card}
      onClick={() => openFile(data.CorrespondenceId)}
      data-testid={'document-card'}
    >
      <CardActionArea className={classes.cardContent}>
        <header className={classes.header}>
          <PictureAsPdfIcon className={classes.icon} />
          <Typography className={classes.text}>{docType}</Typography>
          <Tooltip title='Last ned'>
            <CloudDownload className={classes.downloadIcon} />
          </Tooltip>
        </header>
      </CardActionArea>
    </Card>
  )
}

export default PolicyDocumentCard
