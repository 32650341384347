import { useMutation, useQuery } from '@tanstack/react-query'
import apiV2 from '../../service/apiV2.service'
import { QuoteV2Type, ResponseErrorV2Type } from '../../types/ApiV2'
import { useContext } from 'react'
import { Context } from '@dg-shared/ContextProvider'
import { QUOTE_ID } from '../../constants'
import { reactQueryErrorHandler } from '@dg-util'
import { FSMService } from '../../FSM'
import queryClient from '../../queryClient'

const useReactQueryQuote = () => {
  const { context, setContext } = useContext(Context)
  const quoteId = parseInt(context[QUOTE_ID] as string)

  // Initiate a new Quote
  const {
    mutateAsync: initQuote,
    isLoading: isInitQuoteLoading,
    data: initiatedQuote,
    isError: isInitQuoteError,
    error: initQuoteError,
    status: initQuoteStatus,
  } = useMutation((data: Partial<QuoteV2Type>) => apiV2.initQuote(data), {
    onSuccess: (response) => {
      const quoteId = response.data.QuoteId

      // Set initiated quote id in cache
      setContext({ [QUOTE_ID]: quoteId })

      // Set quote data in query cache
      queryClient.setQueryData(['quote', quoteId], (quote: Partial<QuoteV2Type>) => ({
        ...quote,
        ...response,
      }))
    },
    onError: (error: ResponseErrorV2Type) => reactQueryErrorHandler(error, FSMService.flowService),
  })

  // Update Quote Mutation
  const {
    mutateAsync: updateQuote,
    isLoading: isUpdateQuoteLoading,
    data: updateQuoteResult,
    isError: isUpdateQuoteError,
    error: updateQuoteError,
  } = useMutation((data: Partial<QuoteV2Type>) => apiV2.updateQuote(quoteId, data), {
    async onSuccess(response) {
      // Set quote data in query cache (avoid re-fetch GET request)
      queryClient.setQueryData(['quote', quoteId], (oldQuoteData: Partial<QuoteV2Type>) => ({
        ...oldQuoteData,
        ...response,
      }))
    },
    onError: (error: ResponseErrorV2Type) => reactQueryErrorHandler(error, FSMService.flowService),
  })

  // Get Quote Query
  const {
    data: quote,
    refetch: refetchQuote,
    isInitialLoading: isGetQuoteLoading,
  } = useQuery(['quote', quoteId], () => apiV2.getQuote(quoteId), {
    select: (response) => response.data,
    enabled: !!quoteId,
    onError: (error: ResponseErrorV2Type) => reactQueryErrorHandler(error, FSMService.flowService),
  })

  // Accept Quote Mutation
  const {
    isLoading: isAcceptQuoteLoading,
    mutateAsync: acceptQuote,
    data: quotePolicy,
  } = useMutation(() => apiV2.acceptQuote(quoteId), {
    onSuccess: (response) => {
      // Update Quote cache after accept
      queryClient.setQueryData(['quote', quoteId], (oldQuoteData: Partial<QuoteV2Type>) => ({
        ...oldQuoteData,
        ...response,
      }))
    },
    onError: (error: ResponseErrorV2Type) => reactQueryErrorHandler(error, FSMService.flowService),
  })

  // Set Ownership Quote Mutation
  const {
    mutateAsync: setOwnershipQuote,
    isLoading: isSetOwnershipQuoteLoading,
    data: setOwnershipQuoteResult,
  } = useMutation((data: Partial<QuoteV2Type>) => apiV2.setOwnershipQuote(quoteId, data), {
    async onSuccess(response) {
      // Update Quote cache after set ownership
      queryClient.setQueryData(['quote', quoteId], (oldQuoteData: Partial<QuoteV2Type>) => ({
        ...oldQuoteData,
        ...response,
      }))
    },
    onError: (error: ResponseErrorV2Type) => reactQueryErrorHandler(error, FSMService.flowService),
  })

  // Verify Quote Mutation
  const {
    mutateAsync: verifyQuote,
    isLoading: isVerifyQuoteLoading,
    data: verifyQuoteResult,
  } = useMutation((data: Partial<QuoteV2Type>) => apiV2.verifyQuote(quoteId, data), {
    async onSuccess(response) {
      // Update Quote cache after verification response
      queryClient.setQueryData(['quote', quoteId], (oldQuoteData: Partial<QuoteV2Type>) => ({
        ...oldQuoteData,
        ...response,
      }))
    },
    onError: (error: ResponseErrorV2Type) => reactQueryErrorHandler(error, FSMService.flowService),
  })

  return {
    quoteId,
    initQuote,
    isInitQuoteError,
    initQuoteError,
    updateQuote,
    isUpdateQuoteError,
    updateQuoteError,
    acceptQuote,
    setOwnershipQuote,
    verifyQuote,
    isLoading:
      isUpdateQuoteLoading ||
      isGetQuoteLoading ||
      isAcceptQuoteLoading ||
      isInitQuoteLoading ||
      isSetOwnershipQuoteLoading ||
      isVerifyQuoteLoading ||
      initQuoteStatus === 'loading',
    updateQuoteResult,
    setOwnershipQuoteResult,
    verifyQuoteResult,
    initiatedQuote: initiatedQuote?.data,
    quote,
    quotePolicy,
    refetchQuote,
  }
}

export default useReactQueryQuote
