import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Typography } from '@dg-shared'
import { TranslatedTextType } from '../../../types'
import CustomSwitch from '@dg-shared/layouts/CustomSwitch'
import {
  AUTH_AGENT,
  AUTH_CHOICE,
  DONE,
  ERROR,
  FAILURE,
  MILEAGE,
  POLICY_DONE,
  REGULAR,
} from '../../../constants'
import { getDataComponentName } from '@dg-util'

export const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      headerTextWrapper: {
        ...theme.brand.assets.layout?.customHeaderTextWrapper,
      },
      headerTitleStyle: {
        marginBottom: theme.spacing(2),
        whiteSpace: 'pre-line',
        lineHeight: '1.3',
        ...theme.brand.assets.layout?.mobileCustomHeadingText,
        [theme.breakpoints.down('md')]: {
          ...theme.brand.assets.layout?.mobileCustomTitle,
        },
      },
      headerSubTitleStyle: {
        whiteSpace: 'pre-line',
        lineHeight: '1.3',
        ...theme.brand.assets.layout?.mobileCustomHeadingText,
        [theme.breakpoints.down('md')]: {
          ...theme.brand.assets.layout?.mobileCustomSubTitle,
        },
      },
    }),
  { index: 1 }
)

type MobileHeaderTextPropsType = {
  headerTitle?: TranslatedTextType
  headerSubTitle?: TranslatedTextType
  headerClass?: string
}

const MobileHeaderText: FC<MobileHeaderTextPropsType> = ({
  headerTitle,
  headerSubTitle,
  headerClass = '',
}) => {
  const classes = useStyles({
    height: window.innerHeight,
  })
  const showTextOn = [AUTH_CHOICE, MILEAGE, ERROR, DONE, FAILURE, REGULAR, AUTH_AGENT, POLICY_DONE]

  return (
    <CustomSwitch showOn={showTextOn}>
      <div
        className={classes.headerTextWrapper}
        data-componentname={getDataComponentName(__filename)}
      >
        {!!headerTitle && (
          <Typography type='h1' className={`${classes.headerTitleStyle} ${headerClass}`}>
            {headerTitle}
          </Typography>
        )}
        {!!headerSubTitle && (
          <Typography className={classes.headerSubTitleStyle} type='h3'>
            {headerSubTitle}
          </Typography>
        )}
      </div>
    </CustomSwitch>
  )
}

export default MobileHeaderText
