import { FC, useEffect } from 'react'
import { Events } from '../../../FSM/shared/constants'
import { FSMService } from '../../../FSM'

const LandingPage: FC = () => {
  useEffect(() => {
    FSMService.flowService.send(Events.LANDING_PAGE)
  }, [])

  return null
}

export default LandingPage
