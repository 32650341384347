import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core'

import { IconType } from '../../types/interface/icon.interface'
import { BrandIconShared } from '@dg-shared'

const useCoverageInfoListItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkIcon: {
      color: theme.palette.primary.main,
      alignSelf: 'flex-start',
      marginTop: theme.spacing(0.5),
      minWidth: theme.spacing(4),
    },
    closeIcon: {
      color: theme.palette.error.main,
      alignSelf: 'flex-start',
      marginTop: theme.spacing(0.5),
      minWidth: theme.spacing(4),
    },
    listItem: {
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
    listItemText: {
      color: theme.palette.primary.dark,
      fontSize: '16px',
      lineHeight: '22px',
    },
  })
)

interface CoverageInfoListItemProps {
  text: string
  crossed?: boolean
}

const CoverageInfoListItem: FC<CoverageInfoListItemProps> = ({ text, crossed }) => {
  const classes = useCoverageInfoListItemStyles()

  return (
    <ListItem className={classes.listItem}>
      <ListItemIcon className={crossed ? classes.closeIcon : classes.checkIcon}>
        <BrandIconShared
          type={crossed ? IconType.ERROR : IconType.CHECK}
          color={crossed ? 'textError' : 'secondary'}
        />
      </ListItemIcon>
      <ListItemText primary={text} classes={{ primary: classes.listItemText }} />
    </ListItem>
  )
}

export default CoverageInfoListItem
