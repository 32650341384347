import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useCommonLegalAidStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      width: '100%',
      height: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: '345px',
        margin: 'auto',
        marginTop: theme.spacing(4),
      },
    },
    submitBtn: {
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        margin: 'auto auto 0',
      },
    },
    form: {
      height: '100%',
    },
  })
)

export default useCommonLegalAidStyles
