import React, { useState } from 'react'
import { IconType } from '../../../types/interface/icon.interface'
import { BrandIconShared, Typography } from '@dg-shared'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

type ExpandableItemType = {
  title: string
  children: React.ReactNode
  customClass?: string
  customDetailsClass?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleClass: {
      display: 'flex',
      whiteSpace: 'nowrap',
      marginLeft: -theme.spacing(3),
      cursor: 'pointer',
      background: 'none',
      border: 'none',
      padding: 0,
      '& [data-component="typography"]': {
        textAlign: 'left',
        whiteSpace: 'normal',
      },
    },
    details: {
      marginTop: theme.spacing(1),
      overflow: 'hidden',
      transition: `all 0.3s`,
    },
    closedItem: {
      maxHeight: 0,
      margin: 0,
    },
    openedItem: {
      maxHeight: 1000,
    },
  })
)

const ExpandableItem = ({
  title,
  children,
  customClass,
  customDetailsClass,
}: ExpandableItemType) => {
  const { titleClass, details, openedItem, closedItem } = useStyles()
  const [open, setOpen] = useState<boolean>(false)
  const openCoverDetails = () => {
    if (open) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }
  const stateClass = open ? openedItem : closedItem
  const icon = open ? IconType.ARROW_DOWN : IconType.CHEVRON_RIGHT
  return (
    <>
      <section className={customClass}>
        <button className={titleClass} onClick={() => openCoverDetails()}>
          <BrandIconShared type={icon} color={'secondary'} />
          <Typography>{title}</Typography>
        </button>
        <div className={[details, stateClass, customDetailsClass].join(' ')}>{children}</div>
      </section>
    </>
  )
}

export default ExpandableItem
