import React, { FC, ReactElement } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../../constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import { LDContainerV2FSM, LMBasicV3FSM, Typography } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import PageWrapper from '@dg-shared/PageWrapper'
import useLogic from './useLogic'
import { Events } from '../../../FSM/shared/constants'
import { Card, CardActionArea, CardContent } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      margin: `${theme.spacing(3)}px auto`,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        margin: 'auto',
        marginTop: theme.spacing(3),
        marginBottom: 0,
        flexDirection: 'column',
        justifyContent: 'flex-start',
      },
    },
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(7),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    card: {
      width: 250,
      border: `2px solid ${theme.brand.colors.clean}`,
      '&:hover': {
        border: `2px solid ${theme.brand.colors.main}`,
      },
      margin: theme.spacing(0, 1, 3),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: theme.spacing(1, 0),
      },
      '& .MuiCardContent-root': {
        minHeight: 120,
      },
    },
  })
)

type CardItemProps = {
  children: ReactElement | ReactElement[]
  testId: string
  onClick: () => void
}

const ChooseAddress: FC<CommonPageProps> = ({ service, currentState }) => {
  const { t } = useTranslate(T.CHOOSE_ADDRESS)
  const classes = useStyles()
  const { addressData, setAddress } = useLogic(service, currentState)

  const CardItem: FC<CardItemProps> = ({ children, onClick, testId }) => (
    <Card elevation={3} className={classes.card} data-testid={testId}>
      <CardActionArea onClick={onClick}>
        <CardContent>{children}</CardContent>
      </CardActionArea>
    </Card>
  )

  return (
    <PageWrapper
      mobile={LMBasicV3FSM}
      desktop={LDContainerV2FSM}
      breadcrumbsBtnText={t(`logout`)}
      breadcrumbsStepPercentage={30}
    >
      <Typography type='h1' className={classes.title}>
        {t('title')}
      </Typography>
      <section className={classes.body}>
        {addressData
          ? addressData.map((address, key) => (
              <CardItem
                key={key}
                testId='CardItem-address'
                onClick={() =>
                  setAddress({
                    address: {
                      address1: address.address1,
                      city: address.city,
                      postNumber: address.postNumber,
                    },
                  })
                }
              >
                <Typography type='h2'>{address.address1}</Typography>
                <Typography component='span' style={{ marginRight: 12 }}>
                  {address.postNumber}
                </Typography>
                <Typography component='b' modifiers={['bold']}>
                  {address.city}
                </Typography>
              </CardItem>
            ))
          : null}
        <CardItem
          onClick={() => service.send({ type: Events.NEXT, state: currentState })}
          testId='CardItem-find'
        >
          <Typography type='h2'>{t('searchButton.title')}</Typography>
          <Typography>{t('searchButton.subtitle')}</Typography>
        </CardItem>
      </section>
    </PageWrapper>
  )
}
export default ChooseAddress
