import { useMutation, useQuery } from '@tanstack/react-query'
import apiV2 from '../../service/apiV2.service'
import { useContext } from 'react'
import { Context } from '@dg-shared/ContextProvider'
import { SELECTED_POLICY_NUMBER, SELECTED_POLICY_VERSION } from '../../constants'
import { reactQueryErrorHandler } from '@dg-util'
import { FSMService } from '../../FSM'
import { PolicyV2ChangeRequestType, PolicyV2Type, ResponseErrorV2Type } from '../../types/ApiV2'
import queryClient from '../../queryClient'

type UseReactQueryPolicyArgsType = {
  // Default Get All Policies fetching enabled value
  policiesQueryEnabled?: boolean
}

const useReactQueryPolicy = (options: UseReactQueryPolicyArgsType = {}) => {
  const { context } = useContext(Context)
  const policyNumber = parseInt(context[SELECTED_POLICY_NUMBER] as string)
  const policyVersion = parseInt(context[SELECTED_POLICY_VERSION] as string)
  const { policiesQueryEnabled = true } = options

  // Get Policies Query
  const {
    data: policies,
    refetch: refetchPolicies,
    isInitialLoading: isGetPoliciesLoading,
    isFetching: isGetPoliciesFetching,
    isError: isPoliciesError,
    error: policiesError,
  } = useQuery(['policies'], () => apiV2.getAllPolicies(), {
    select: (response) => response.data,
    enabled: policiesQueryEnabled,
    onError: (error: ResponseErrorV2Type) => reactQueryErrorHandler(error, FSMService.flowService),
  })

  // Get Policy Query
  const {
    data: policy,
    refetch: refetchPolicy,
    isFetching: isGetPolicyFetching,
    isInitialLoading: isGetPolicyLoading,
    isError: isPolicyError,
    error: policyError,
  } = useQuery(
    ['policy', policyNumber, policyVersion],
    () => apiV2.getPolicy(policyNumber, policyVersion),
    {
      select: (response) => response.data,
      enabled: false,
      onError: (error: ResponseErrorV2Type) =>
        reactQueryErrorHandler(error, FSMService.flowService),
    }
  )

  // Update Policy Mutation
  const {
    mutateAsync: updatePolicy,
    isLoading: isUpdatePolicyLoading,
    data: updatePolicyResult,
    isError: isUpdatePolicyError,
    error: updatePolicyError,
  } = useMutation((data: Partial<PolicyV2Type>) => apiV2.updatePolicy(policyNumber, data), {
    async onSuccess() {
      // Refetch Policy after update
      await refetchPolicy()
    },
    onError: (error: ResponseErrorV2Type) => reactQueryErrorHandler(error, FSMService.flowService),
  })

  // Update Policy Odometer Reading (Mileage) Mutation
  const {
    mutateAsync: updateMileagePolicy,
    isLoading: isUpdateMileagePolicyLoading,
    data: updateMileagePolicyResult,
    isError: isUpdateMileagePolicyError,
    error: updateMileagePolicyError,
  } = useMutation(
    (data: Pick<PolicyV2Type, 'OdometerReading' | 'DateOdometerReading'>) =>
      apiV2.updateMileagePolicy(policyNumber, data),
    {
      onError: (error: ResponseErrorV2Type) =>
        reactQueryErrorHandler(error, FSMService.flowService),
    }
  )

  // Calculate Price Policy Mutation
  const {
    mutateAsync: calculatePolicy,
    isLoading: isCalculatePolicyLoading,
    data: calculatePolicyResult,
    isError: isCalculatePolicyError,
    error: calculatePolicyError,
  } = useMutation(
    (data: Partial<PolicyV2Type>) => apiV2.calculatePolicy(policyNumber, policyVersion, data),
    {
      onSuccess: (response) => {
        queryClient.setQueryData(
          ['policy', policyNumber, policyVersion],
          (policy: Partial<PolicyV2Type>) => ({
            ...policy,
            ...response,
          })
        )
      },
      onError: (error: ResponseErrorV2Type) =>
        reactQueryErrorHandler(error, FSMService.flowService),
    }
  )

  // Change Policy Mutation
  const {
    mutateAsync: changePolicy,
    isLoading: isChangePolicyLoading,
    data: changePolicyResult,
    isError: isChangePolicyError,
    error: changePolicyError,
  } = useMutation(
    (data: Partial<PolicyV2ChangeRequestType>) =>
      apiV2.changePolicy(policyNumber, policyVersion, data),
    {
      async onSuccess(response) {
        // Update policy in cache
        queryClient.setQueryData(
          ['policy', policyNumber, policyVersion],
          (policy: Partial<PolicyV2Type>) => ({
            ...policy,
            ...response,
          })
        )

        // Update policies list
        await refetchPolicies()
      },
      onError: (error: ResponseErrorV2Type) =>
        reactQueryErrorHandler(error, FSMService.flowService),
    }
  )

  return {
    isLoading:
      isGetPoliciesLoading ||
      isGetPolicyLoading ||
      isUpdatePolicyLoading ||
      isChangePolicyLoading ||
      isCalculatePolicyLoading ||
      isGetPolicyFetching ||
      isUpdateMileagePolicyLoading ||
      isGetPoliciesFetching,
    policies: policies || [],
    isPoliciesError,
    policiesError,
    policy,
    isPolicyError,
    policyError,
    refetchPolicy,
    refetchPolicies,
    changePolicy,
    updatePolicy,
    updateMileagePolicy,
    changePolicyResult,
    updatePolicyResult,
    updateMileagePolicyResult,
    isChangePolicyError,
    isUpdatePolicyError,
    isUpdateMileagePolicyError,
    changePolicyError,
    updatePolicyError,
    updateMileagePolicyError,

    // Calculate policy
    calculatePolicy,
    calculatePolicyResult,
    isCalculatePolicyError,
    calculatePolicyError,
  }
}

export default useReactQueryPolicy
