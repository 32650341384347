import React, { FC } from 'react'

import { Typography, FNOLPageWrapper } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import WhenWhereForm from '../../../components/Claims/WhenWhereForm'
import { TRANSLATIONS as T } from '../../../constants'

const PageStolenWhenWhere: FC<CommonPageProps> = ({ service }) => {
  const { t } = useTranslate(`${T.FNOL_THEFT}.stolenWhenWhere`)

  const Content = () => (
    <>
      <Typography testId='title' type='h2'>
        {t('title')}
      </Typography>
      <Typography testId='subtitle'>{t('subtitle')}</Typography>
      <WhenWhereForm service={service} />
    </>
  )

  return (
    <FNOLPageWrapper breadcrumbsBtnText={t('back')} breadcrumbsStepPercentage={70}>
      <Content />
    </FNOLPageWrapper>
  )
}

export default PageStolenWhenWhere
