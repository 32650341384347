import React, { FC, useEffect } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { TRANSLATIONS as T } from '../../../../constants/translations-constants'
import {
  CoverageDetailedInfo,
  FSMBtnNext,
  LDCardTwoColV2FSM,
  LMBasicV3FSM,
  Typography,
  withHidden,
} from '@dg-shared'
import { useTranslate } from '../../../../hooks/shared'
import { FlowServiceType } from '../../../../types/interface/fsm.interface'
import AdvantagesTextList from './AdvantagesTextList'
import { formatWithThousandSpace } from '../../../../service/util.service'
import PriceTwinCards from './PriceTwinCards'
import { Events } from '../../../../FSM/shared/constants'
import { get, has } from 'lodash'
import useLogic from '../useLogic'
import Loading from '../../Loading/Page'

const MobileScreen = withHidden(LMBasicV3FSM, true)
const DesktopScreen = withHidden(LDCardTwoColV2FSM, false)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nextButton: {
      margin: 0,
      ...theme.brand.assets.layout?.ctaOverride,
      [theme.breakpoints.up('md')]: {
        maxWidth: '345px',
        marginTop: theme.spacing(5),
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(3),
      },
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      gap: theme.spacing(3),
    },
  })
)

export type PagePropsType = {
  service: FlowServiceType
}

const QBComparePage: FC<PagePropsType> = (props) => {
  const { service } = props
  const { t } = useTranslate(T.OFFER_COMPARE_PRICE)
  const classes = useStyles()
  const {
    quote,
    offerDifference,
    selectedInsurelyQuote,
    coverageData,
    setQuoteCompetitorPrice,
    loading,
  } = useLogic(service)

  useEffect(() => {
    if (!selectedInsurelyQuote) {
      service.send(Events.INSURELY_NO_OFFERS_FOUND)
    }
    // TODO: FIx with using correct deps in [] and useCallback. Left here to not break anything
    // eslint-disable-next-line
  }, [selectedInsurelyQuote])

  useEffect(() => {
    if (quote && selectedInsurelyQuote) {
      // Set Quote Competitor Price
      setQuoteCompetitorPrice(selectedInsurelyQuote.insurance.premiumAmountYearRounded).catch(
        (error) => {
          throw new Error(error)
        }
      )
    }
  }, [quote, selectedInsurelyQuote, setQuoteCompetitorPrice])

  if (loading || !quote) {
    return <Loading />
  }

  const content =
    quote && selectedInsurelyQuote ? (
      <>
        <AdvantagesTextList annualAmount={formatWithThousandSpace(offerDifference)} />
        <PriceTwinCards send={service.send} quote={quote} offerToCompare={selectedInsurelyQuote} />
        <Typography testId='compare-additionalInfo'>{t('additionalInfo')}</Typography>
      </>
    ) : null

  const coverageDetails = has(coverageData, 'type') && (
    <CoverageDetailedInfo
      coverageType={quote.CarPackage}
      translationCondition={get(quote, 'superCover', false) ? 'isSuper' : 'isNotSuper'}
      showDialogMobile={false}
      send={service.send}
    />
  )

  const btnNext = content ? (
    <div className={classes.wrapper}>
      <FSMBtnNext
        handleClick={() => service.send(Events.NEXT)}
        buttonText={t('submitBtn')}
        customClassName={classes.nextButton}
      />
    </div>
  ) : null

  const subtitle = (
    <Typography testId='compare-subtitle' color='textMain'>
      {t('subtitle')}
    </Typography>
  )

  return (
    <>
      <MobileScreen
        send={service.send}
        breadcrumbs
        breadcrumbsBtnText={t(`back`)}
        breadcrumbsStepPercentage={60}
      >
        <Typography type='h1'>{t(`title`, { coverageName: coverageData?.name })}</Typography>
        {subtitle}
        {content}
        {coverageDetails}
        {btnNext}
      </MobileScreen>
      <DesktopScreen
        send={service.send}
        title={t(`title`, { coverageName: coverageData?.name })}
        subTitle={subtitle}
        breadcrumbs
        breadcrumbsBtnText={t(`back`)}
        breadcrumbsStepPercentage={60}
        colLeft={content}
        colRight={coverageDetails}
        nextBtn={btnNext}
      />
    </>
  )
}

export default QBComparePage
