import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Grid, Hidden } from '@material-ui/core'

import { LColThreeBasicFSMProps } from '../../../types/interface/layouts.interface'
import { FSMBreadcrumbs, Typography, BrandedHeaderShared } from '@dg-shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      paddingBottom: theme.spacing(4),
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        minWidth: '750px',
      },
    },
    container: {
      width: '100%',
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    containerTextHead: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: '920px',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    containerContent: (alignContent: unknown) => ({
      [theme.breakpoints.up('md')]: {
        justifyContent: alignContent ? 'center' : '',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: '1160px',
        paddingLeft: 0,
        paddingRight: 0,
      },
    }),
    title: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(2),
    },
    subTitle: {
      marginBottom: theme.spacing(3),
    },
    h3SubTitle: {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(5),
      },
    },
  })
)

const LColThreeBasic: FC<LColThreeBasicFSMProps> = ({
  send,
  titleHeader,
  H2SubTitle,
  paragraphSubTitle,
  breadcrumbsBtnText,
  breadcrumbsStepPercentage,
  transProps,
  children,
  alignContent = false,
  breadcrumbs = false,
}) => {
  const classes = useStyles(alignContent)

  return (
    <Grid container direction='column' component='article' className={classes.page}>
      <Hidden smDown>
        <BrandedHeaderShared />
      </Hidden>
      <Grid item xs={12} className={classes.container}>
        {breadcrumbs && (
          <FSMBreadcrumbs
            send={send}
            buttonText={breadcrumbsBtnText}
            stepsPercentage={breadcrumbsStepPercentage}
          />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        direction='column'
        className={`${classes.container} ${classes.containerTextHead}`}
      >
        {!!titleHeader && (
          <Typography type='h1' className={classes.title}>
            {titleHeader}
          </Typography>
        )}
        {!!H2SubTitle && (
          <Typography type='h2' className={classes.subTitle}>
            {H2SubTitle}
          </Typography>
        )}
        {!!paragraphSubTitle && (
          <Typography className={classes.subTitle}>{paragraphSubTitle}</Typography>
        )}
        {!!transProps && <Typography className={classes.h3SubTitle}>{transProps}</Typography>}
      </Grid>
      <Grid container className={`${classes.container} ${classes.containerContent}`}>
        {children}
      </Grid>
    </Grid>
  )
}

export default LColThreeBasic
