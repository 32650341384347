import React, { FC } from 'react'
import { Hidden, CardContent } from '@material-ui/core'
import { Events } from '../../FSM/shared/constants'
import { CoverageDetailedInfo, Typography } from '@dg-shared'
import { useTranslate } from '../../hooks/shared'
import BtnEdit from '@dg-shared/BtnEdit'
import useContentStyles from '../../hooks/shared/useContentStyles'
import { FSMService } from '../../FSM'
import { isCurrentActivePolicy } from '@dg-util'
import Icon from '@material-ui/core/Icon'
import { ProductName } from '../../types/interface/quote.interface'
import PolicyReviewVehicleDetails from '@dg-shared/PolicyReviewVehicleDetails'
import PolicyReviewTravelDetails from '@dg-shared/PolicyReviewTravelDetails'
import PolicyReviewContentDetails from '@dg-shared/PolicyReviewContentDetails'
import { PolicyReviewCardProps } from '../../types'

const PolicyReviewCard: FC<PolicyReviewCardProps> = ({ policy, productProps, children }) => {
  const classes = useContentStyles({ isImage: true })
  const { t } = useTranslate()

  const coverageInfo = (
    <CoverageDetailedInfo
      send={FSMService.flowService.send}
      state={FSMService.flowService.state.value}
      coverageType={policy.CarPackage}
      translationCondition={policy.SuperCoverage ? 'isSuper' : 'isNotSuper'}
    />
  )

  const policyReviewCardDetailsPerProduct: Record<ProductName, React.FC<PolicyReviewCardProps>> = {
    [ProductName.VEHICLE]: PolicyReviewVehicleDetails,
    [ProductName.TRAVEL]: PolicyReviewTravelDetails,
    [ProductName.CONTENT]: PolicyReviewContentDetails,
  }

  const PolicyDetails = policyReviewCardDetailsPerProduct[policy.productName]

  // Don't allow to change policy which is inactive or invalid
  const isEditable = isCurrentActivePolicy(policy)

  return productProps && policy ? (
    <CardContent className={classes.twoCol}>
      <section
        className={classes.colLeft}
        data-testid={'quote-info-block'}
        style={{ justifyContent: 'start' }}
      >
        {children}
        <>
          <PolicyDetails policy={policy} productProps={productProps} />
          {isEditable ? (
            <BtnEdit
              id='change-offer-button'
              testId='change-offer-button'
              handleClick={() => FSMService.flowService.send(Events.CHANGE)}
              text={t(`editButtonLabel`)}
            />
          ) : (
            <div className={classes.inactivePolicyText}>
              <Icon fontSize='small' className={classes.infoIconStyle}>
                info_outlined
              </Icon>
              <Typography color={'textSecondary'}>{t(`inactivePolicy`)}</Typography>
            </div>
          )}
        </>
      </section>
      <Hidden smDown>
        <div className={classes.colRight}>{coverageInfo}</div>
      </Hidden>
    </CardContent>
  ) : null
}

export default PolicyReviewCard
