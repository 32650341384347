import React, { FC } from 'react'

import Loading from '../Loading/Page'
import useLogic from './useLogic'
import { ServiceSend } from '../../../types/interface/fsm.interface'

const CustomAuthPage: FC<{ send: ServiceSend }> = ({ send }) => {
  const { loading } = useLogic(send)

  if (loading) return <Loading />

  return null
}

export default CustomAuthPage
