import { FormConfigType } from '../../../types/interface/form.interface'
import { FORM_ID, FORMAT_DAY_MONTH_YEAR_DOT } from '../../../constants'
import { normalizeFormat } from '@dg-util'
import { RFFCustomDatePicker, RFFCustomTextField, RFFCustomTimePicker } from '@dg-shared'
import { formatWithThousandSpace, toUpperCase } from '../../../service/util.service'
import { FlowName } from '../../../config'
import QuoteAndBuyFormConfig, {
  generalVerifyFormConfig,
  contentChangeFormConfig,
  travelChangeFormConfig,
  vehicleChangeFormConfig,
} from '../qb/config'
import _ from 'lodash'

export interface BrandFormConfigInterface {
  [FlowName.QUOTE_AND_BUY]?: FormConfigType
  [FlowName.MY_PAGES]?: FormConfigType
}

// Reuse QB form configurations for MyPages
const policyVerifyFormConfig = _.cloneDeep(generalVerifyFormConfig)
const policyVehicleChangeFormConfig = _.cloneDeep(vehicleChangeFormConfig)
const policyTravelChangeFormConfig = _.cloneDeep(travelChangeFormConfig)
const policyContentChangeFormConfig = _.cloneDeep(contentChangeFormConfig)

// Modify QB FormConfigs for MyPages configs
delete policyVerifyFormConfig.fields.insuranceCompany

// Disable specific fields for editing for policy change form
policyVehicleChangeFormConfig.fields.CarPackage.componentProps.isDisabled = true
policyVehicleChangeFormConfig.fields.CarBonus.componentProps.isDisabled = true

// Change Form configs ID's
policyVerifyFormConfig.id = FORM_ID.POLICY_VERIFY
policyVehicleChangeFormConfig.id = FORM_ID.POLICY_CHANGE
policyTravelChangeFormConfig.id = FORM_ID.POLICY_CHANGE
policyContentChangeFormConfig.id = FORM_ID.POLICY_CHANGE

const MyPagesFormConfig: FormConfigType = {
  [FORM_ID.ACCIDENT]: {
    vehicle: {
      id: FORM_ID.ACCIDENT,
      fields: {
        address: {
          name: 'address',
          format: normalizeFormat,
          component: RFFCustomTextField,
          componentProps: {
            autoFocus: true,
          },
        },
        city: {
          name: 'city',
          format: normalizeFormat,
          component: RFFCustomTextField,
        },
        country: {
          name: 'country',
          format: normalizeFormat,
          component: RFFCustomTextField,
        },
        occurDate: {
          name: 'occurDate',
          type: 'tel',
          component: RFFCustomDatePicker,
          componentProps: {
            fieldMaxDate: Date.now(),
            fieldInputVariant: 'outlined',
            formatType: FORMAT_DAY_MONTH_YEAR_DOT,
          },
        },
        time: {
          name: 'time',
          type: 'tel',
          component: RFFCustomTimePicker,
          componentProps: {
            fieldInputVariant: 'outlined',
          },
        },
      },
      initialValues: {},
    },
  },
  [FORM_ID.COMPENSATION]: {
    vehicle: {
      id: FORM_ID.COMPENSATION,
      fields: {
        compensation: {
          name: 'compensation',
          component: RFFCustomTextField,
          parse: formatWithThousandSpace,
          componentProps: {
            inputAdornmentText: 'NOK',
          },
        },
      },
      initialValues: {},
    },
  },
  [FORM_ID.DATE]: {
    vehicle: {
      id: FORM_ID.DATE,
      fields: {
        date: {
          name: 'date',
          type: 'tel',
          component: RFFCustomDatePicker,
          componentProps: {
            fieldMaxDate: Date.now(),
            fieldInputVariant: 'outlined',
            formatType: FORMAT_DAY_MONTH_YEAR_DOT,
          },
        },
      },
      initialValues: {},
    },
  },
  [FORM_ID.ADDITIONAL_INFO]: {
    vehicle: {
      id: FORM_ID.ADDITIONAL_INFO,
      fields: {
        additionalInfo: {
          name: 'additionalInfo',
          component: RFFCustomTextField,
          componentProps: {
            multiline: true,
            autoFocus: true,
          },
        },
      },
      initialValues: {},
    },
  },
  [FORM_ID.STOLEN_AGE_VALUE]: {
    vehicle: {
      id: FORM_ID.STOLEN_AGE_VALUE,
      fields: {
        age: {
          name: 'age',
          type: 'tel',
          parse: formatWithThousandSpace,
          component: RFFCustomTextField,
        },
        value: {
          name: 'value',
          type: 'tel',
          component: RFFCustomTextField,
          parse: formatWithThousandSpace,
          componentProps: {
            inputAdornmentText: 'NOK',
          },
        },
      },
      initialValues: {},
    },
  },
  [FORM_ID.POLICE_CASE]: {
    vehicle: {
      id: FORM_ID.POLICE_CASE,
      fields: {
        caseNumber: {
          name: 'caseNumber',
          component: RFFCustomTextField,
          componentProps: {
            multiline: true,
            autoFocus: true,
          },
        },
      },
      initialValues: {},
    },
  },
  [FORM_ID.REGISTRATION_NUMBER]: {
    vehicle: {
      id: FORM_ID.REGISTRATION_NUMBER,
      fields: {
        registrationNum: {
          name: 'registrationNum',
          type: 'text',
          component: RFFCustomTextField,
          format: toUpperCase,
          componentProps: {
            multiline: false,
            autoFocus: true,
            fieldPlaceholder: 'AB12345',
          },
        },
      },
      initialValues: {},
    },
  },
  [FORM_ID.MORE_DETAILS]: {
    vehicle: {
      id: FORM_ID.MORE_DETAILS,
      fields: {
        moreDetails: {
          name: 'moreDetails',
          component: RFFCustomTextField,
          componentProps: {
            multiline: true,
            autoFocus: true,
          },
        },
      },
      initialValues: {},
    },
  },
  [FORM_ID.OBJECT]: {
    vehicle: {
      id: FORM_ID.OBJECT,
      fields: {
        object: {
          name: 'object',
          component: RFFCustomTextField,
          componentProps: {
            multiline: true,
            autoFocus: true,
          },
        },
      },
      initialValues: {},
    },
  },
  [FORM_ID.POLICY_VERIFY]: {
    vehicle: policyVerifyFormConfig,
    travel: policyVerifyFormConfig,
    content: policyVerifyFormConfig,
  },
  [FORM_ID.POLICY_CHANGE]: {
    vehicle: policyVehicleChangeFormConfig,
    travel: policyTravelChangeFormConfig,
    content: policyContentChangeFormConfig,
  },
  // Add QB form config
  ...QuoteAndBuyFormConfig,
}

export default MyPagesFormConfig
