import { createContext } from 'react'
import { FlowServiceType, FSMStateType } from '../types/interface/fsm.interface'

export interface FSMContext {
  currentState: FSMStateType | null
  service: FlowServiceType | null
}

const FSMRouterContext = createContext<FSMContext>({
  currentState: null,
  service: null,
})

const FSMRouterProvider = FSMRouterContext.Provider
const FSMRouterConsumer = FSMRouterContext.Consumer

export { FSMRouterProvider, FSMRouterConsumer }
