import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'

import { FlowType } from '../../constants/route-constants'
import BtnBack from './BtnBackV2'
import Typography from './typography/Typography'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      height: '50px',
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
      },
    },
    gridItemLeft: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        marginLeft: `-${theme.spacing(1)}px`,
      },
    },
    gridItemRight: {
      width: theme.spacing(15),
    },
    gridItemPositionProgressbarWrapper: {
      paddingTop: '0.5rem',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '0.7rem',
      },
    },
    progressValue: {
      lineHeight: 1,
      marginBottom: theme.spacing(0.5),
    },
    barColorPrimary: {
      backgroundColor: theme.palette.info.main,
      borderRadius: theme.spacing(11),
      '& > *': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
)

export interface BreadcrumbsProps {
  flow: FlowType
  goTo?: string
  buttonText?: string
  stepsPercentage?: number | null
}

const BreadcrumbsV2: FC<BreadcrumbsProps> = ({
  flow,
  goTo,
  buttonText,
  stepsPercentage = null,
}) => {
  const classes = useStyles()
  const progressBlock = stepsPercentage && (
    <div className={classes.gridItemRight}>
      <div className={classes.gridItemPositionProgressbarWrapper}>
        <Typography className={classes.progressValue}>{`${stepsPercentage}%`}</Typography>
      </div>
      <LinearProgress
        variant='determinate'
        value={stepsPercentage}
        className={classes.barColorPrimary}
      />
    </div>
  )

  return (
    <div className={classes.container}>
      <div className={classes.gridItemLeft}>
        {!!goTo || !!buttonText ? (
          <BtnBack flow={flow} goTo={goTo} buttonText={buttonText} />
        ) : (
          <BtnBack flow={flow} />
        )}
      </div>
      {progressBlock}
    </div>
  )
}

export default BreadcrumbsV2
