import { useContext, useEffect, useState } from 'react'
import { FlowServiceType, FSMStateType } from '../../../types/interface/fsm.interface'
import apiV2 from '../../../service/apiV2.service'
import { Events } from '../../../FSM/shared/constants'
import { FormFindValues } from '../Find/useLogic'
import { SELECTED_QUOTE_ADDRESS } from '../../../constants'
import { Context } from '@dg-shared/ContextProvider'
import { UserAddressItemResponseType } from '../../../types/ApiV2'

export type AddressDataType = {
  address1: string
  city: string
  country: string
  postNumber: string
}

const useLogic = (service: FlowServiceType, currentState: FSMStateType) => {
  const [addressData, setAddressData] = useState<AddressDataType[]>()
  const { setContext } = useContext(Context)

  const setAddress = async (values: FormFindValues): Promise<void> => {
    setContext({
      [SELECTED_QUOTE_ADDRESS]: values.address,
    })

    service.send({ type: Events.REVIEW, state: currentState })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    ;(async () => {
      const { addresses } = await apiV2.getAddress()

      // Take only DSF address according to the requirements
      const dsfAddress: UserAddressItemResponseType[] = addresses?.filter(
        (item) => item.address_type === 'dsf'
      )

      if (dsfAddress) {
        const addressData: AddressDataType[] = []
        dsfAddress.map((addressItem) => {
          const { address, city, country, postNumber } = addressItem
          addressData.push({
            address1: address,
            city,
            country,
            postNumber,
          })
        })

        setAddressData(addressData)
      }
    })()
  }, [])

  return {
    addressData,
    setAddress,
  }
}

export default useLogic
