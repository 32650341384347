import React, { FC } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Hidden, Card, CardMedia, CardContent, Divider } from '@material-ui/core'
import { OfferItemProps, PartnerName, ProductName } from '../../../types/interface/quote.interface'
import { Typography, CustomCardHeader, ProductWrapper } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import { exampleCarImg } from 'BrandedImages'
import { QuoteV2Type } from '../../../types/ApiV2'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    twoCol: () => ({
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(5),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        ...theme.brand.assets.images.changeVehicleOwnerPageCard,
      },
    }),
    colLeft: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(2),
      },
      [theme.breakpoints.up('lg')]: {
        marginRight: theme.spacing(4),
      },
    },
    colRight: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(2),
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(4),
      },
    },
    itemImage: {
      width: '100%',
      maxWidth: '220px',
      margin: 'auto',
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2),
      },
    },
    cardTitle: {
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2),
      },
    },
    cardSubTitle: {
      marginBottom: theme.spacing(1.5),
      '& + *': {
        marginBottom: theme.spacing(3),
      },
    },
  })
)

interface OwnershipCardProps extends OfferItemProps {
  itemData: Partial<QuoteV2Type>
}

const OfferCard: FC<OwnershipCardProps> = ({
  itemData: {
    CarMake,
    CarModel,
    ManufacuringYear,
    MotorRegistrationNumber,
    CalculatedPrices: { priceMonthly, priceAnnual, taxAnnual },
  },
  colLeft,
  colRight,
  showDivider,
}) => {
  const { t } = useTranslate()
  const classes = useStyles()

  const priceDetails = taxAnnual || priceAnnual

  return (
    <Card elevation={3}>
      <CustomCardHeader
        title={t(`offer`)}
        priceMonthly={priceMonthly}
        priceMonthlyText={t(`card.priceMonthly`)}
        priceDetailsText={t(`card.priceDetails`, { value: priceDetails })}
      />
      <CardContent className={classes.twoCol}>
        <section className={classes.colLeft}>
          {/* //NOTE: Need block container for Safari when use forms */}
          <section>
            <ProductWrapper
              partners={[PartnerName.circlek, PartnerName.instabank]}
              products={[ProductName.VEHICLE]}
            >
              <CardMedia
                component='img'
                alt='Example Car'
                image={exampleCarImg}
                className={classes.itemImage}
              />
            </ProductWrapper>
          </section>
          <Typography type='h2' className={classes.cardTitle}>
            {`${ManufacuringYear} ${CarMake} ${CarModel}`}
          </Typography>
          <Typography type='h3' className={classes.cardSubTitle}>
            {t(`registrationNum`, { regNum: MotorRegistrationNumber })}
          </Typography>
          {showDivider ? (
            <Divider />
          ) : (
            <Hidden mdUp>
              <Divider />
            </Hidden>
          )}

          {colLeft}
        </section>

        <Hidden smDown>
          <div className={classes.colRight}>{colRight}</div>
        </Hidden>
      </CardContent>
    </Card>
  )
}

export default OfferCard
