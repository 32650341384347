import React, { FC } from 'react'
import PageWrapper from '@dg-shared/PageWrapper'
import { CustomCardHeader, LDContainerV2FSM, LMBasicV3FSM, FSMBtnNext } from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'
import { TRANSLATIONS as T } from '../../../constants'
import { getDataComponentName } from '@dg-util'
import { Card, Divider } from '@material-ui/core'
import Loading from '../../qb/Loading/Page'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import PolicyReviewCardHeader from '@dg-shared/PolicyReviewCardHeader'
import useLogic from './useLogic'
import { FSMService } from '../../../FSM'
import { Events } from '../../../FSM/shared/constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import PolicyChangeCard from '@dg-shared/PolicyChangeCard'
import { PartnerName } from '../../../types/interface/quote.interface'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backgroundImage: {
      [theme.breakpoints.up('md')]: {
        ...theme.brand.assets.pageBackgrounds.review,
      },
    },
    buttonSubmit: {
      maxWidth: '345px',
      margin: 'auto',
    },
    documentsWrapper: {
      padding: theme.spacing(3),
    },
    divider: {
      width: '46%',
      marginLeft: theme.spacing(2),
      opacity: 0.4,
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(0),
        width: '100%',
      },
    },
    buttonNext: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      ...theme.brand.assets.layout?.btnNext,
      [theme.breakpoints.up('md')]: {
        margin: 'auto',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        maxWidth: '345px',
      },
    },
    policyChangeCard: {
      padding: theme.spacing(3),
    },
  })
)

const PagePolicyChange: FC<CommonPageProps> = () => {
  const { t } = useTranslate(T.POLICY_CHANGE)
  const classes = useStyles()
  const { backBtn, isLoading, policy, calculatePolicy, productProps } = useLogic()

  if (isLoading) {
    return <Loading />
  }

  const buttonNext = (
    <FSMBtnNext
      id='ctaBtnNext'
      handleClick={() => FSMService.flowService.send(Events.NEXT)}
      buttonText={t(`next`)}
      customClassName={classes.buttonNext}
      outlined={[PartnerName.skoda, PartnerName.volkswagen, PartnerName.audi].includes(
        PARTNER_NAME as PartnerName
      )}
    />
  )

  const content = policy && (
    <div data-componentname={getDataComponentName(__filename)}>
      <Card data-testid='change-policy-card' elevation={3} className={classes.backgroundImage}>
        <CustomCardHeader
          title={t(`card.title.${policy.productName}`)}
          priceMonthly={policy.CalculatedPrices.priceMonthly}
          priceMonthlyText={t(`card.priceMonthly`)}
          priceDetailsText={t(`card.priceDetails`, {
            value: policy.CalculatedPrices.taxAnnual || policy.CalculatedPrices.priceAnnual,
          })}
        />
        <PolicyReviewCardHeader policy={policy} classContainer={classes.policyChangeCard} />
        <Divider className={classes.divider} />
        <PolicyChangeCard
          updatePolicy={calculatePolicy}
          key={0}
          policy={policy}
          productProps={productProps}
        />
      </Card>
    </div>
  )

  return (
    <PageWrapper
      mobile={LMBasicV3FSM}
      desktop={LDContainerV2FSM}
      breadcrumbsBtnText={t(backBtn.text)}
    >
      {content}
      {buttonNext}
    </PageWrapper>
  )
}

export default PagePolicyChange
