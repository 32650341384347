import { ThemeBrandAssetsType, ThemeBrandColorsType, ThemeBrandTypographyType } from './types'

export const defaultColors: ThemeBrandColorsType = {
  main: '#00AD4B',
  secondary: '#4cad77',
  background: '#FAFAFA',
  headingMain: '#00534C',
  headingSecondary: '#00534C',
  textMain: '#333F48',
  textSecondary: '#768692',
  textError: '#F84971',
  textPlaceholder: '#C8CFD3',
  textDisclaimer: '#B6BABC',
  buttonActive: '#00AD4B',
  buttonInactive: '#4cad77',
  textInactive: '#C8CFD3',
  inputActive: '#4cad77',
  inputInactive: '#59856d',
  linkMain: '#00AD4B',
  linkSecondary: '#00AD4B',
  modal: '#C4C4C4',
  shadow: '#000000',
  clean: '#FFFFFF',
  cardHeader: '#00534C',
  textCardHeader: '#FFF',
  textCardFooter: '#00AD4B',
}

export const defaultTypography: ThemeBrandTypographyType = {
  heading: {
    h1: {
      fontSize: '1.75rem', // 28px
      fontWeight: 500,
      color: defaultColors.headingMain,
    },
    h2: {
      fontSize: '1.5rem', // 24px
      fontWeight: 500,
      color: defaultColors.headingMain,
    },
    h3: {
      fontSize: '1.25rem', // 20px
      fontWeight: 400,
      color: defaultColors.headingSecondary,
    },
    h4: {
      fontSize: '1rem', // 16px
      fontWeight: 500,
      color: defaultColors.headingMain,
    },
    h5: {
      fontSize: '0.75rem', // 12px
      fontWeight: 400,
      color: defaultColors.textMain,
    },
    h6: {
      fontSize: '0.75rem', // 12px
      fontWeight: 400,
      color: defaultColors.headingMain,
    },
  },
  text: {
    xSmall: {
      fontSize: '0.688rem', // 11px
      lineHeight: 1.4,
    },
    small: {
      fontSize: '0.75rem', // 12px
      lineHeight: 1.4,
    },
    normal: {
      fontSize: '1rem', // 16px
      lineHeight: 1.4,
    },
    large: {
      fontSize: '1.5rem', // 24px
      lineHeight: 1.4,
    },
    xLarge: {
      fontSize: '2.5rem', // 40px
      lineHeight: 1.4,
    },
  },
  modifiers: {
    bold: {
      fontWeight: 700,
    },
    decorated: {
      textDecoration: 'underline',
    },
    unDecorated: {
      textDecoration: 'none',
      '& *': {
        textDecoration: 'none',
      },
    },
    withHover: {
      '&:hover': {
        opacity: 0.75,
        cursor: 'pointer',
      },
    },
    preFormatted: {
      whiteSpace: 'pre',
    },
  },
  extraFonts: [],
}

export const defaultAssets: ThemeBrandAssetsType = {
  pageBackgrounds: {
    main: { background: defaultColors.main },
    mainMobile: { background: defaultColors.main },
    mainCard: { background: defaultColors.main },
    secondary: { background: defaultColors.secondary },
    secondaryMobile: { background: defaultColors.secondary },
    secondaryCard: { background: defaultColors.secondary },
    loading: { background: defaultColors.background },
    loadingMobile: { background: defaultColors.background },
    error: { background: defaultColors.textError },
  },
  images: {},
}
