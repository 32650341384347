const linkForIcon = {
  FNOL: {
    PageWindshield: {
      iconLink: 'https://booking.bilglass.no/',
    },
    PageRoadsideAssistance: {
      iconLink: 'https://rsa2.falck.no/',
    },
  },
}

export default linkForIcon
