import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { TRANSLATIONS as T, FORM_ID } from '../../../constants'
import { CommonPageProps } from '../../../types/interface/fsm.interface'
import Loading from '../../../pages/qb/Loading/Page'
import Card from '../../../pages/qb/Ownership/Card'
import useLogic from '../../../pages/qb/Ownership/useLogic'
import FormBuilder from '@dg-shared/forms/FormBuilder'
import {
  withHidden,
  LMBasicV3FSM,
  LDContainerV2FSM,
  ExternalSubmitBtn,
  Typography,
} from '@dg-shared'
import { useTranslate } from '../../../hooks/shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnExternal: {
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(5),
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(3),
        width: '100%',
        margin: '0',
      },
    },
    annotation: {
      marginBottom: theme.spacing(2),
    },
  })
)

const MobileScreen = withHidden(LMBasicV3FSM, true)
const DesktopScreen = withHidden(LDContainerV2FSM, false)

const OwnershipPageComponent: FC<CommonPageProps> = ({ service }) => {
  const classes = useStyles()
  const { t } = useTranslate(T.OFFER_OWNERSHIP)
  const { quote, loading, btnDisabled, setBtnDisabled, handleSetOwnership } = useLogic(service.send)

  const body = (
    <>
      <FormBuilder
        id={FORM_ID.OWNERSHIP}
        submitHandler={handleSetOwnership}
        handleBtnDisabled={setBtnDisabled}
        customSubmit
      />
      <Typography color='textSecondary'>{t('bodyInfoText')}</Typography>
    </>
  )

  const submitBtn = (
    <ExternalSubmitBtn
      text={t(`next`)}
      targetId={FORM_ID.OWNERSHIP}
      disabled={btnDisabled}
      customClassName={classes.btnExternal}
    />
  )

  if (loading) {
    return <Loading />
  }

  return quote ? (
    <>
      <MobileScreen
        send={service.send}
        breadcrumbs
        breadcrumbsBtnText={t(`back`)}
        titleHeader={t('title')}
        paragraphSubTitle={t('annotation')}
        breadcrumbsStepPercentage={70}
      >
        <Card itemData={quote} colLeft={body} />
        {submitBtn}
      </MobileScreen>
      <DesktopScreen send={service.send} breadcrumbs breadcrumbsStepPercentage={70}>
        <Typography type='h1'>{t('title')}</Typography>
        <Typography testId='ownership-annotation' className={classes.annotation}>
          {t('annotation')}
        </Typography>
        <Card itemData={quote} colLeft={body} showDivider />
        {submitBtn}
      </DesktopScreen>
    </>
  ) : null
}

export default OwnershipPageComponent
