export const base64ToArrayBuffer = (base64: string) => {
  const binaryString = window.atob(base64)
  const binaryLen = binaryString.length
  const bytes = new Uint8Array(binaryLen)
  for (let i = 0; i < binaryLen; i++) {
    // ascii char code
    bytes[i] = binaryString.charCodeAt(i)
  }

  return bytes
}

export const saveByteArray = (data: BlobPart[], name: string) => {
  const a = document.createElement('a')
  const blob = new Blob(data, { type: 'octet/stream' }),
    url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = name
  a.click()
  window.URL.revokeObjectURL(url)
}
