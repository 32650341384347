import { useMutation, useQuery } from '@tanstack/react-query'
import apiV2 from '../../service/apiV2.service'
import { useContext } from 'react'
import { Context } from '@dg-shared/ContextProvider'
import { CLAIM_ID } from '../../constants'
import { reactQueryErrorHandler } from '@dg-util'
import { FSMService } from '../../FSM'
import { ClaimV2Type, ResponseErrorV2Type } from '../../types/ApiV2'

const useReactQueryClaim = () => {
  const { context, setContext } = useContext(Context)
  const claimId = parseInt(context[CLAIM_ID] as string)

  // Get Claim Query
  const {
    data: claim,
    refetch: refetchClaim,
    isInitialLoading: isGetClaimLoading,
    isError: isClaimError,
    error: claimError,
  } = useQuery(['claim', claimId], () => apiV2.getClaim(claimId), {
    select: (response) => response.data,
    enabled: !!claimId,
    onError: (error: ResponseErrorV2Type) => reactQueryErrorHandler(error, FSMService.flowService),
  })

  // Init Claim Mutation
  const {
    mutate: initClaim,
    isLoading: isInitClaimLoading,
    data: initClaimResult,
  } = useMutation((data: Partial<ClaimV2Type>) => apiV2.initClaim(data), {
    async onSuccess(response) {
      setContext({ [CLAIM_ID]: response.data.ClaimId })

      return response.data
    },
    onError: (error: ResponseErrorV2Type) => reactQueryErrorHandler(error, FSMService.flowService),
  })

  // Update Claim Mutation
  const {
    mutate: updateClaim,
    isLoading: isUpdateClaimLoading,
    data: updateClaimResult,
  } = useMutation((data: Partial<ClaimV2Type>) => apiV2.updateClaim(claimId, data), {
    async onSuccess(response) {
      return response.data
    },
    onError: (error: ResponseErrorV2Type) => reactQueryErrorHandler(error, FSMService.flowService),
  })

  // Upload files mutation
  const {
    mutate: uploadClaimFiles,
    isLoading: isUploadClaimFilesLoading,
    data: uploadClaimFilesResult,
  } = useMutation((data: { files: string[] }) => apiV2.uploadClaimFiles(claimId, data), {
    async onSuccess(response) {
      return response.data
    },
    onError: (error: ResponseErrorV2Type) => reactQueryErrorHandler(error, FSMService.flowService),
  })

  return {
    isLoading:
      isGetClaimLoading || isInitClaimLoading || isUpdateClaimLoading || isUploadClaimFilesLoading,
    claim,
    isClaimError,
    claimError,
    refetchClaim,
    initClaim,
    initClaimResult,
    updateClaim,
    updateClaimResult,
    uploadClaimFiles,
    uploadClaimFilesResult,
  }
}

export default useReactQueryClaim
