// The component wrapper that render element everywhere besides PROD environment
// Useful for disabling functionality on PROD but leaving it on DEV, ACC and TEST environment
import React, { CSSProperties, FC, ReactNode } from 'react'
import { IS_PRODUCTION } from '../../config'
const DebugWrapper: FC<{ children: ReactNode; style?: CSSProperties; className?: string }> = ({
  children,
  style,
  className,
}) => {
  const content =
    style || className ? (
      <div style={style} className={className}>
        {children}
      </div>
    ) : (
      <>{children}</>
    )

  return !IS_PRODUCTION ? content : null
}

export default DebugWrapper
